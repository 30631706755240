import {
  EnumClassV2SubjectCodes,
  EnumCourseV2SubjectCodes,
} from "../__generated__/graphql";

// COURSE V2 SUBJECT CODES

export const courseSubjectCodesToStringMap: {
  [key in EnumCourseV2SubjectCodes]: string;
} = {
  [EnumCourseV2SubjectCodes.Ceds01]: "Language arts",
  [EnumCourseV2SubjectCodes.Ceds02]: "Mathematics",
  [EnumCourseV2SubjectCodes.Ceds03]: "Life and physical sciences",
  [EnumCourseV2SubjectCodes.Ceds04]: "Social sciences and history",
  [EnumCourseV2SubjectCodes.Ceds05]: "Visual and performing arts",
  [EnumCourseV2SubjectCodes.Ceds07]: "Religious education and theology",
  [EnumCourseV2SubjectCodes.Ceds08]: "Physical, health, and safety education",
  [EnumCourseV2SubjectCodes.Ceds09]: "Military science",
  [EnumCourseV2SubjectCodes.Ceds10]: "Information technology",
  [EnumCourseV2SubjectCodes.Ceds11]:
    "Communication and audio/visual technology",
  [EnumCourseV2SubjectCodes.Ceds12]: "Business and marketing",
  [EnumCourseV2SubjectCodes.Ceds13]: "Manufacturing",
  [EnumCourseV2SubjectCodes.Ceds14]: "Health care sciences",
  [EnumCourseV2SubjectCodes.Ceds15]:
    "Public, protective, and government service",
  [EnumCourseV2SubjectCodes.Ceds16]: "Hospitality and tourism",
  [EnumCourseV2SubjectCodes.Ceds17]: "Architecture and construction",
  [EnumCourseV2SubjectCodes.Ceds18]: "Agriculture, food, and natural resources",
  [EnumCourseV2SubjectCodes.Ceds19]: "Human services",
  [EnumCourseV2SubjectCodes.Ceds20]:
    "Transportation, distribution, and logistics",
  [EnumCourseV2SubjectCodes.Ceds21]: "Engineering and technology",
  [EnumCourseV2SubjectCodes.Ceds22]: "Miscellaneous",
  [EnumCourseV2SubjectCodes.Ceds23]: "Non-subject-specific",
  [EnumCourseV2SubjectCodes.Ceds24]: "World languages",
  [EnumCourseV2SubjectCodes.El01]: "Special education",
  [EnumCourseV2SubjectCodes.El02]: "Professional development",
};

// Create a reverse mapping of the above object
export const courseSubjectCodesToEnumMap: {
  [key: string]: EnumCourseV2SubjectCodes;
} = Object.keys(courseSubjectCodesToStringMap).reduce((acc, key) => {
  acc[courseSubjectCodesToStringMap[key as EnumCourseV2SubjectCodes]] =
    key as EnumCourseV2SubjectCodes;
  return acc;
}, {} as { [key: string]: EnumCourseV2SubjectCodes });

export function courseSubjectCodesEnumArrayToStringArray(
  enumArray: EnumCourseV2SubjectCodes[]
): string[] {
  return enumArray.map((enumValue) => courseSubjectCodesToStringMap[enumValue]);
}

export function courseSubjectCodesEnumArrayToDropdownValues(
  enumArray: EnumCourseV2SubjectCodes[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: courseSubjectCodesToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function courseSubjectCodesStringArrayToEnumArray(
  stringArray: string[]
): EnumCourseV2SubjectCodes[] {
  return stringArray.map(
    (stringValue) => courseSubjectCodesToEnumMap[stringValue]
  );
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function courseSubjectCodesDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(courseSubjectCodesToStringMap).map((key) => {
    return {
      label: courseSubjectCodesToStringMap[key as EnumCourseV2SubjectCodes],
      value: key,
    };
  });
}

// CLASS V2 SUBJECT CODES

export const classSubjectCodesToStringMap: {
  [key in EnumClassV2SubjectCodes]: string;
} = {
  [EnumClassV2SubjectCodes.Ceds01]: "Language arts",
  [EnumClassV2SubjectCodes.Ceds02]: "Mathematics",
  [EnumClassV2SubjectCodes.Ceds03]: "Life and physical sciences",
  [EnumClassV2SubjectCodes.Ceds04]: "Social sciences and history",
  [EnumClassV2SubjectCodes.Ceds05]: "Visual and performing arts",
  [EnumClassV2SubjectCodes.Ceds07]: "Religious education and theology",
  [EnumClassV2SubjectCodes.Ceds08]: "Physical, health, and safety education",
  [EnumClassV2SubjectCodes.Ceds09]: "Military science",
  [EnumClassV2SubjectCodes.Ceds10]: "Information technology",
  [EnumClassV2SubjectCodes.Ceds11]: "Communication and audio/visual technology",
  [EnumClassV2SubjectCodes.Ceds12]: "Business and marketing",
  [EnumClassV2SubjectCodes.Ceds13]: "Manufacturing",
  [EnumClassV2SubjectCodes.Ceds14]: "Health care sciences",
  [EnumClassV2SubjectCodes.Ceds15]:
    "Public, protective, and government service",
  [EnumClassV2SubjectCodes.Ceds16]: "Hospitality and tourism",
  [EnumClassV2SubjectCodes.Ceds17]: "Architecture and construction",
  [EnumClassV2SubjectCodes.Ceds18]: "Agriculture, food, and natural resources",
  [EnumClassV2SubjectCodes.Ceds19]: "Human services",
  [EnumClassV2SubjectCodes.Ceds20]:
    "Transportation, distribution, and logistics",
  [EnumClassV2SubjectCodes.Ceds21]: "Engineering and technology",
  [EnumClassV2SubjectCodes.Ceds22]: "Miscellaneous",
  [EnumClassV2SubjectCodes.Ceds23]: "Non-subject-specific",
  [EnumClassV2SubjectCodes.Ceds24]: "World languages",
  [EnumClassV2SubjectCodes.El01]: "Special education",
  [EnumClassV2SubjectCodes.El02]: "Professional development",
};

// Create a reverse mapping of the above object
export const classSubjectCodesToEnumMap: {
  [key: string]: EnumClassV2SubjectCodes;
} = Object.keys(classSubjectCodesToStringMap).reduce((acc, key) => {
  acc[classSubjectCodesToStringMap[key as EnumClassV2SubjectCodes]] =
    key as EnumClassV2SubjectCodes;
  return acc;
}, {} as { [key: string]: EnumClassV2SubjectCodes });

export function classSubjectCodesEnumArrayToStringArray(
  enumArray: EnumClassV2SubjectCodes[]
): string[] {
  return enumArray.map((enumValue) => classSubjectCodesToStringMap[enumValue]);
}

export function classSubjectCodesEnumArrayToDropdownValues(
  enumArray: EnumClassV2SubjectCodes[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: classSubjectCodesToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function classSubjectCodesStringArrayToEnumArray(
  stringArray: string[]
): EnumClassV2SubjectCodes[] {
  return stringArray.map(
    (stringValue) => classSubjectCodesToEnumMap[stringValue]
  );
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function classSubjectCodesDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(classSubjectCodesToStringMap).map((key) => {
    return {
      label: classSubjectCodesToStringMap[key as EnumClassV2SubjectCodes],
      value: key,
    };
  });
}
