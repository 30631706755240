import { observer } from "mobx-react";
import { useEffect, useReducer } from "react";
import BlockSchedule from "../../models/BlockSchedule";
import RotationDay from "../../models/RotationDay";
import BlockScheduleEditableTable from "../EditableTable/BlockSchedule.jsx";

export type BlockScheduleFormEntity = {
  rotationDayId: string;
  rotationDayName: string;
  id?: string;
  startTime?: string;
  endTime?: string;
};

type BlockScheduleFormState = {
  blockSchedules: BlockScheduleFormEntity[];
};

const initialState: BlockScheduleFormState = {
  blockSchedules: [],
};

const formReducer = (state: BlockScheduleFormState, action: any) => {
  switch (action.type) {
    case "update_block_schedule":
      const { blockScheduleIndex, blockScheduleKey, blockScheduleValue } =
        action.payload;

      console.log("blockScheduleIndex", blockScheduleIndex);
      console.log("blockScheduleKey", blockScheduleKey);
      console.log("blockScheduleValue", blockScheduleValue);

      return {
        ...state,
        blockSchedules: state.blockSchedules.map((blockSchedule, index) => {
          if (index === blockScheduleIndex) {
            return {
              ...blockSchedule,
              [blockScheduleKey]: blockScheduleValue,
            };
          }

          return blockSchedule;
        }),
      };

    default:
      return state;
  }
};

function BlockSchedules({
  activeBlockSchedules,
  setModifiedBlockSchedules,
  rotationDays,
}: {
  activeBlockSchedules: BlockSchedule[];
  setModifiedBlockSchedules: React.Dispatch<
    React.SetStateAction<BlockScheduleFormEntity[]>
  >;
  rotationDays: RotationDay[];
}) {
  const init = (initialState: BlockScheduleFormState) => {
    // We loop over all the rotation days since there should be on row per rotation day

    const blockSchedules: BlockScheduleFormEntity[] = rotationDays.map(
      (rotationDay) => {
        // Check if there is a block schedule for this rotation day
        const findRotationDayBlockSchedule = activeBlockSchedules.find(
          (blockSchedule) => blockSchedule.rotationDayId === rotationDay.id
        );

        if (!findRotationDayBlockSchedule) {
          return {
            id: undefined,
            rotationDayId: rotationDay.id,
            rotationDayName: rotationDay.abbreviation,
            startTime: undefined,
            endTime: undefined,
          };
        } else {
          return {
            id: findRotationDayBlockSchedule.id,
            rotationDayId: rotationDay.id,
            rotationDayName: rotationDay.abbreviation,
            startTime: findRotationDayBlockSchedule.startTime,
            endTime: findRotationDayBlockSchedule.endTime,
          };
        }
      }
    );

    return {
      blockSchedules,
    };
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  useEffect(() => {
    setModifiedBlockSchedules(state.blockSchedules);
  }, [state, setModifiedBlockSchedules]);

  return (
    <div>
      <BlockScheduleEditableTable
        blockSchedules={state.blockSchedules}
        updateBlockSchedule={(
          rowIndex: number,
          rowKey: string,
          rowValue: any
        ) => {
          dispatchFormAction({
            type: "update_block_schedule",
            payload: {
              blockScheduleIndex: rowIndex,
              blockScheduleKey: rowKey,
              blockScheduleValue: rowValue,
            },
          });
        }}
      />
    </div>
  );
}

export default observer(BlockSchedules);
