import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import { PopulateCalendarSchedules } from "../graphql/scheduling/scheduling.mutations";
import { GetCalendarSchedules } from "../graphql/scheduling/scheduling.queries";
import CalendarSchedule from "../models/CalendarSchedule";
import {
  CreateCalendarScheduleInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class CalendarScheduleStore extends BaseStore<CalendarSchedule> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, CalendarSchedule, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchCalendarSchedules = (calendarId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetCalendarSchedules,
          variables: {
            where: {
              calendar: {
                id: calendarId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const calendarSchedules = res.data.calendarSchedules;

          if (!calendarSchedules) {
            reject(false);
          }

          runInAction("Populate calendar schedules", () => {
            calendarSchedules.forEach((calendarSchedule) => {
              invariant(
                calendarSchedule.calendar,
                "Calendar Schedule must have a calendar"
              );

              const sanitizeCalendarSchedule = {
                blockScheduleId: calendarSchedule.blockSchedule
                  ? calendarSchedule.blockSchedule.id
                  : null,
                createdAt: calendarSchedule.createdAt,
                deletedAt: calendarSchedule.deletedAt,
                calendarId: calendarSchedule.calendar.id,
                id: calendarSchedule.id,
                endTime: calendarSchedule.endTime,
                rotationDayId: calendarSchedule.rotationDay
                  ? calendarSchedule.rotationDay.id
                  : null,
                startTime: calendarSchedule.startTime,
                updatedAt: calendarSchedule.updatedAt,
              };

              this.add(sanitizeCalendarSchedule);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  saveCalendarSchedules = (
    calendarSchedules: CreateCalendarScheduleInput[],
    sessionId: string,
    sectionId: string
  ): Promise<Boolean> => {
    this.isSaving = true;

    console.log("Calendar Schedules", calendarSchedules);

    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: PopulateCalendarSchedules,
          variables: {
            section: {
              id: sectionId,
            },
            session: {
              id: sessionId,
            },
            data: calendarSchedules,
          },
        })
        .then((res) => {
          if (!res.data || !res.data.populateCalendarSchedules) {
            throw Error("Failed to update calendar schedules.");
          }

          const success = res.data.populateCalendarSchedules;

          // Fetch the calendar for section and id
          const { calendars } = this.rootStore;

          const sectionCalendar = calendars.getCalendarsForSectionAndSession(
            sectionId,
            sessionId
          );

          if (success) {
            // Refresh the calendar schedules
            runInAction(() => {
              this.fetchCalendarSchedules(
                sectionCalendar ? sectionCalendar.id : ""
              );
            });
          }

          resolve(success);
        })
        .catch((e) => {
          console.log("Error", e);
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isSaving = false;
          });
          resolve(true);
        });
    });
  };

  //   @action
  //   async assignClassSchedules(classIds: string[], rotationScheduleId: string) {
  //     const res = await this.apolloClient.mutate({
  //       mutation: AssignClassSchedules,
  //       variables: {
  //         classIds,
  //         rotationScheduleId,
  //       },
  //     });

  //     if (!res.data || !res.data.assignClassSchedules) {
  //       throw Error("Failed to assign class schedules.");
  //     }

  //     const { calendars, ui } = this.rootStore;

  //     // We refetch all the calendars for the current rotation schedule
  //     if (ui.activeSessionId) {
  //       await calendars.fetchSessionRotationCalendars(ui.activeSessionId);
  //     }

  //     return res.data.assignClassSchedules;
  //   }

  getCalendarsSchedulesForCalendar = (
    calendarID: string
  ): CalendarSchedule[] => {
    return this.sortedData.filter(
      (calendarSchedule) =>
        calendarSchedule.calendarId === calendarID &&
        !calendarSchedule.deletedAt
    );
  };
}
