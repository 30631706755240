import { gql } from "../../__generated__/gql";

export const GetRotationSchedule = gql(`
    query GetRotationSchedule($where: RotationScheduleWhereUniqueInput!) {
        rotationSchedule(where: $where) {
            createdAt
            deletedAt
            id
            name
            session {
                id 
            }
            rotationDays {
                id
            }
            calendarDays {
                id
            }
            skipNoSchoolDays
            daysOfWeek
            gradeLevels
            updatedAt 
        }
    }
`);

export const GetRotationSchedules = gql(`
    query GetRotationSchedules($where: RotationScheduleWhereInput!, $orderBy: [RotationScheduleOrderByInput!]) {
        rotationSchedules(where: $where, orderBy: $orderBy) {
            createdAt
            deletedAt
            id
            name
            session {
                id 
            }
            rotationDays {
                id
            }
            calendarDays {
                id
            }
            skipNoSchoolDays
            daysOfWeek
            gradeLevels
            updatedAt 
        }
    }
`);

export const GetRotationDays = gql(`
    query GetRotationDays($where: RotationDayWhereInput!, $orderBy: [RotationDayOrderByInput!]) {
        rotationDays(where: $where, orderBy: $orderBy) {
            abbreviation
            createdAt
            dayIndex
            deletedAt 
            description
            id
            rotationSchedule {
                id
            }
            updatedAt
        }
    }
`);

export const GetBlocks = gql(`
    query GetBlocks($where: BlockWhereInput!, $orderBy: [BlockOrderByInput!]) {
        blocks(where: $where, orderBy: $orderBy) {
            abbreviation
            color 
            createdAt 
            deletedAt
            description 
            id 
            rotationSchedule {
                id
            }
            updatedAt 
        }
    }
`);

export const GetBlockSchedules = gql(`
    query GetBlockSchedules($where: BlockScheduleWhereInput!, $orderBy: [BlockScheduleOrderByInput!]) {
        blockSchedules(where: $where, orderBy: $orderBy) {
            alternateSchedule {
                id
            }
            block {
                id
            }
            createdAt
            deletedAt
            endTime 
            id 
            rotationDay {
                id
            }
            startTime 
            updatedAt 
        }
    }
`);

export const GetAlternateSchedules = gql(`
    query GetAlternateSchedules($where: AlternateScheduleWhereInput!, $orderBy: [AlternateScheduleOrderByInput!]) {
        alternateSchedules(where: $where, orderBy: $orderBy) {
            createdAt 
            deletedAt
            id 
            name
            rotationSchedule {
                id
            }
            updatedAt 
        }
    }
`);

export const GetCalendar = gql(`
    query GetCalendar($where: CalendarWhereUniqueInput!) {
        calendar(where: $where) {
            calendarType
            classV2 {
                id
            }
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            id
            rotationSchedule {
                id
            }
            section {
                id
            }
            session {
                id
            }
            timezone
            updatedAt
            urlId
            user {
                id
            }
        }
    }
`);

export const GetCalendarByUrlId = gql(`
    query GetCalendarByUrlId($where: CalendarUrlIdInput!) {
        calendarByUrlId(where: $where) {
            calendarType
            classV2 {
                id
            }
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            id
            rotationSchedule {
                id
            }
            section {
                id
            }
            session {
                id
            }
            timezone
            updatedAt
            urlId
            user {
                id
            }
        }
    }
`);

export const GetCalendars = gql(`
    query GetCalendars($where: CalendarWhereInput!, $orderBy: [CalendarOrderByInput!]) {
        calendars(where: $where, orderBy: $orderBy) {
            calendarType
            classV2 {
                id
            }
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            id
            rotationSchedule {
                id
            }
            section {
                id
            }
            session {
                id
            }
            timezone
            updatedAt
            urlId
            user {
                id
            }
        }
    }
`);

export const GetCalendarSchedules = gql(`
    query GetCalendarSchedules($where: CalendarScheduleWhereInput!, $orderBy: [CalendarScheduleOrderByInput!]) {
        calendarSchedules(where: $where, orderBy: $orderBy) {
            blockSchedule {
                id
            }
            calendar {
                id
            }
            createdAt
            deletedAt
            endTime
            id 
            rotationDay {
                id
            }
            startTime
            updatedAt
        }
    }
`);

export const GetCalendarDays = gql(`
    query GetCalendarDays($where: CalendarDayWhereInput!) {
        calendarDays(where: $where) {
            alternateSchedule {
                id
                name
                blockSchedules {
                    id
                    block {
                        id
                        abbreviation
                        color
                        description
                    }
                    startTime
                    endTime
                }
            }
            calendar {
                id
                timezone
            }
            createdAt
            date
            edlinkId
            id
            rotationDay {
                id
                abbreviation
                blockSchedules {
                    id
                    block {
                        id
                        abbreviation
                        color
                        description
                    }
                    startTime
                    endTime
                }
            }
            rotationSchedule {
                id
            }
            updatedAt
        }
    }
`);

export const GetEvents = gql(`
    query GetEvents($where: EventWhereInput!, $orderBy: [EventOrderByInput!]) {
        events(where: $where, orderBy: $orderBy) {
            allDay 
            calendar {
                id
                timezone
                calendarType
            }
            calendarDay {
                id
            }
            calendarSchedule {
                id
                blockSchedule {
                    id
                    block {
                        id
                        abbreviation
                        color
                        description
                    }
                }
            }
            createdAt
            date 
            deletedAt
            description
            edlinkId 
            endTime 
            eventType 
            id 
            startTime 
            timezone 
            title 
            updatedAt 
            urlId 
            recurring
        }
    }
`);

export const GetCalendarEntries = gql(`
    query GetCalendarEntries($where: CalendarEntryWhereInput!) {
        calendarEntries(where: $where) {
            calendar {
                id
            }
            color
            createdAt
            id
            user {
                id
            }
        }
    }
`);
