import { gql } from "../../__generated__/gql";

export const GetDepartments = gql(`
    query GetDepartments($where: DepartmentWhereInput!, $orderBy: [DepartmentOrderByInput!]) {
        departments(where: $where, orderBy: $orderBy) {
            createdAt
            deletedAt
            description
            district {
                id
            }
            edlinkId 
            id
            identifiers 
            name 
            properties
            schools {
                id
            }
            subjects {
                id
            } 
            updatedAt 
            urlId 
            users {
                id
            }
        }
    }
`);
