import update from "immutability-helper";
import { useEffect, useMemo, useReducer, useState } from "react";
import "./style.css";
import Table from "./Table";
import { ActionTypes, SECTION_TIMEBLOCKING_COLUMNS } from "./utils";

function reducer(state, action) {
  // Action will return the rowIndex and the columnId

  switch (action.type) {
    case ActionTypes.UPDATE_STATE_FROM_PROPS:
      return {
        ...state,
        columns: action.columns,
        data: action.data,
      };
    case ActionTypes.ADD_ROW:
      // create the new rotation day and add it to the table
      if (state.shouldAddNewRow) {
        return;
      }

      state.setShouldAddNewRow(true);

      return update(state, {
        skipReset: { $set: true },
      });

    case ActionTypes.DELETE_ROW:
      // delete the rotation day for the given index and remove it from the table

      if (state.deleteRowIndex !== null) {
        return;
      }

      state.setDeleteRowIndex(action.rowIndex);

      return update(state, {
        skipReset: { $set: true },
      });

    case ActionTypes.UPDATE_CELL:
      // state.updateRotationDay(action.rowIndex, action.columnId, action.value);

      console.log("Update cell", action);

      if (state.updateRowData !== null) {
        return;
      }

      state.setUpdateRowData({
        rowIndex: action.rowIndex,
        columnId: action.columnId,
        value: action.value,
      });

      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.rowIndex]: { [action.columnId]: { $set: action.value } },
        },
      });

    case ActionTypes.ENABLE_RESET:
      return update(state, { skipReset: { $set: true } });
    default:
      return state;
  }
}

function SectionTimeblockingTable(props) {
  const { sectionTimeblocks, updateSectionTimeblock } = props;

  const [updateRowData, setUpdateRowData] = useState(null);

  // We must construct the initial state from the units prop

  const initialState = useMemo(() => {
    // Columns are constructed with fixed (type) as well as variable properties (e.g. options)
    // The fixed columns are: Title, Dates, Status, Standards, and Lessons

    // const columns = SECTION_TIMEBLOCKING_COLUMNS.map((column) => {
    //   // If the column is the block column, we need to pass in the blocks
    //   if (column.id === "block") {
    //     return {
    //       ...column,
    //       options: blocks,
    //     };
    //   }

    //   return column;
    // });

    const columns = [...SECTION_TIMEBLOCKING_COLUMNS];

    return {
      columns,
      data: sectionTimeblocks,
      skipReset: false,
      updateSectionTimeblock,
      updateRowData,
      setUpdateRowData,
    };

    // Variable columns must be constructed from the course unitAttributes (JSON) property
  }, [
    sectionTimeblocks,
    updateSectionTimeblock,
    updateRowData,
    setUpdateRowData,
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);

  // Use effect for updating a row
  useEffect(() => {
    if (updateRowData !== null) {
      updateSectionTimeblock(
        updateRowData.rowIndex,
        updateRowData.columnId,
        updateRowData.value
      );
      setUpdateRowData(null);
    }
  }, [updateRowData, updateSectionTimeblock]);

  useEffect(() => {
    dispatch({ type: ActionTypes.ENABLE_RESET });
  }, [state.data, state.columns]);

  useEffect(() => {
    // We can manipulate the columns here
    // const columns = SECTION_TIMEBLOCKING_COLUMNS.map((column) => {
    //   // If the column is the block column, we need to pass in the blocks
    //   if (column.id === "block") {
    //     return {
    //       ...column,
    //       options: blocks,
    //     };
    //   }

    //   return column;
    // });

    const columns = [...SECTION_TIMEBLOCKING_COLUMNS];

    // Data is constructed from the units prop

    const data = sectionTimeblocks.map((sectionTimeblock) => {
      return {
        id: sectionTimeblock.id,
        rotationDay: sectionTimeblock.rotationDayName,
        blockId: sectionTimeblock.blockId,
        startTime: sectionTimeblock.startTime,
        endTime: sectionTimeblock.endTime,
        blockDropdownOptions: sectionTimeblock.blockDropdownOptions,
      };
    });

    dispatch({
      type: ActionTypes.UPDATE_STATE_FROM_PROPS,
      columns,
      data,
    });
  }, [sectionTimeblocks]);

  return (
    <div
      className="overflow-hidden"
      style={{
        width: "100%",
        height: "100%",
        padding: 10,
      }}
    >
      <Table
        columns={state.columns}
        data={state.data}
        dispatch={dispatch}
        skipReset={state.skipReset}
        showNewRow={false}
      />
    </div>
  );
}

export default SectionTimeblockingTable;
