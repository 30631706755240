import { action, computed, observable } from "mobx";
import { EnumSectionV2State } from "../__generated__/graphql";
import Calendar from "./Calendar";
import CalendarSchedule from "./CalendarSchedule";
import DeletableModel from "./DeletableModel";
import Enrollment from "./Enrollment";

class SectionV2 extends DeletableModel {
  @observable
  id: string;

  @observable
  classV2Id: string;

  @observable
  description: string | null;

  @observable
  edlinkId: string | null;

  @observable
  identifiers: any | null;

  @observable
  locale: string | null;

  @observable
  name: string;

  @observable
  urlId: string;

  @observable
  properties: any | null;

  @observable
  state: EnumSectionV2State;

  @observable
  timezone: string | null;

  @observable
  activeCalendarId: string | null = null;

  @action
  setActiveCalendarId = (calendarId: string) => {
    this.activeCalendarId = calendarId;
  };

  @computed
  get activeCalendar(): Calendar | null {
    const { calendars } = this.store.rootStore;

    if (!this.activeCalendarId) {
      return null;
    }

    const calendar = calendars.get(this.activeCalendarId);

    return calendar;
  }

  @computed
  get hasCalendar(): boolean {
    const { calendars, ui, sessions } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    const sectionCalendar = calendars.getCalendarsForSectionAndSession(
      this.id,
      activeSession.id
    );

    return !!sectionCalendar;
  }

  @computed
  get rotationScheduleId(): string | null {
    const { calendars, ui, sessions } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    const sectionCalendar = calendars.getCalendarsForSectionAndSession(
      this.id,
      activeSession.id
    );

    if (!sectionCalendar) {
      return null;
    }

    return sectionCalendar.rotationScheduleId;
  }

  @computed
  get calendarSchedules(): CalendarSchedule[] {
    const { calendars, calendarSchedules, sessions, ui } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    const sectionCalendar = calendars.getCalendarsForSectionAndSession(
      this.id,
      activeSession.id
    );

    if (!sectionCalendar) {
      return [];
    }

    const findCalendarSchedules =
      calendarSchedules.getCalendarsSchedulesForCalendar(sectionCalendar.id);

    return findCalendarSchedules;
  }

  @computed
  get schedulingDone(): boolean {
    const { calendars, calendarSchedules, sessions, ui } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    const sectionCalendar = calendars.getCalendarsForSectionAndSession(
      this.id,
      activeSession.id
    );

    if (!sectionCalendar) {
      return false;
    }

    const findCalendarSchedules =
      calendarSchedules.getCalendarsSchedulesForCalendar(sectionCalendar.id);

    return findCalendarSchedules.length > 0;
  }

  @computed
  get blockOccurences(): string[] | null {
    const { calendars, calendarSchedules, sessions, ui } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    const sectionCalendar = calendars.getCalendarsForSectionAndSession(
      this.id,
      activeSession.id
    );

    if (!sectionCalendar) {
      return null;
    }

    const findCalendarSchedules =
      calendarSchedules.getCalendarsSchedulesForCalendar(sectionCalendar.id);

    const blocks: Map<string, string> = new Map();

    findCalendarSchedules.forEach((calendarSchedule: CalendarSchedule) => {
      if (calendarSchedule.blockId && calendarSchedule.blockName) {
        blocks.set(calendarSchedule.blockId, calendarSchedule.blockName);
      }
    });

    // We return all the values of the map
    return blocks.size > 0 ? Array.from(blocks.values()) : null;
  }

  @computed
  get dayOccurences(): string[] | null {
    const { calendars, calendarSchedules, sessions, ui } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    const sectionCalendar = calendars.getCalendarsForSectionAndSession(
      this.id,
      activeSession.id
    );

    if (!sectionCalendar) {
      return null;
    }

    const findCalendarSchedules =
      calendarSchedules.getCalendarsSchedulesForCalendar(sectionCalendar.id);

    const rotationDays: Map<string, string> = new Map();

    findCalendarSchedules.forEach((calendarSchedule: CalendarSchedule) => {
      if (calendarSchedule.rotationDayId && calendarSchedule.rotationDayName) {
        rotationDays.set(
          calendarSchedule.rotationDayId,
          calendarSchedule.rotationDayName
        );
      }
    });

    // We return all the values of the map
    return rotationDays.size > 0 ? Array.from(rotationDays.values()) : null;
  }

  @computed
  get enrollments(): Enrollment[] {
    const { enrollments } = this.store.rootStore;
    const sectionEnrollments = enrollments.getEnrollmentsForSection(this.id);
    return sectionEnrollments;
  }

  @computed
  get calendars(): Calendar[] {
    const { calendars } = this.store.rootStore;
    const sectionCalendars = calendars.getCalendarsForSection(this.id);
    return sectionCalendars;
  }

  @computed
  get primaryEnrollment(): Enrollment | null {
    const { enrollments } = this.store.rootStore;
    const sectionEnrollments = enrollments.getEnrollmentsForSection(this.id);
    return (
      sectionEnrollments.find((enrollment: any) => enrollment.primary) || null
    );
  }
}

export default SectionV2;
