import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction, toJS } from "mobx";
import {
  AssignClassSchedules,
  CreateCalendar,
  UpdateCalendar,
} from "../graphql/scheduling/scheduling.mutations";
import {
  GetCalendar,
  GetCalendarByUrlId,
  GetCalendars,
} from "../graphql/scheduling/scheduling.queries";
import Calendar from "../models/Calendar";
import {
  CalendarCreateInput,
  CalendarUpdateInput,
  CalendarWhereUniqueInput,
  EnumCalendarCalendarType,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class CalendarStore extends BaseStore<Calendar> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Calendar, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetch = (id: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetCalendar,
          variables: {
            where: {
              id,
            },
          },
        })
        .then((res) => {
          const calendar = res.data.calendar;

          invariant(calendar, "Calendar must exist");
          invariant(calendar.session, "Rotation Calendar must have a session");
          invariant(
            calendar.calendarType,
            "Calendar must have a calendar type"
          );

          // Fetch events for each calendar
          this.rootStore.calendarDays.fetchCalendarDays(calendar.id);
          this.rootStore.events.fetchCalendarEvents(calendar.id);
          this.rootStore.calendarSchedules.fetchCalendarSchedules(calendar.id);

          const sanitizeCalendar = {
            calendarType: calendar.calendarType,
            classV2Id: calendar.classV2 ? calendar.classV2.id : null,
            createdAt: calendar.createdAt,
            deletedAt: calendar.deletedAt,
            districtId: calendar.district ? calendar.district.id : null,
            edlinkId: calendar.edlinkId,
            id: calendar.id,
            rotationScheduleId: calendar.rotationSchedule
              ? calendar.rotationSchedule.id
              : null,
            sectionId: calendar.section ? calendar.section.id : null,
            sessionId: calendar.session.id,
            timezone: calendar.timezone,
            updatedAt: calendar.updatedAt,
            urlId: calendar.urlId,
            userId: calendar.user ? calendar.user.id : null,
          };

          this.add(sanitizeCalendar);

          resolve(true);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
        });
    });
  };

  fetchCalendarsForClass = (classId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetCalendars,
          variables: {
            where: {
              classV2: {
                id: classId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const calendars = res.data.calendars;

          console.log("Fetch calendars for class", calendars);

          if (!calendars) {
            reject(false);
          }

          runInAction("Populate calendars", () => {
            calendars.forEach((calendar) => {
              invariant(
                calendar.session,
                "Rotation Calendar must have a session"
              );

              invariant(
                calendar.calendarType,

                "Calendar must have a calendar type"
              );

              // Fetch events for each calendar
              this.rootStore.calendarDays.fetchCalendarDays(calendar.id);
              this.rootStore.events.fetchCalendarEvents(calendar.id);
              this.rootStore.calendarSchedules.fetchCalendarSchedules(
                calendar.id
              );

              // Fetch rotation schedule for each calendar
              if (calendar.rotationSchedule) {
                this.rootStore.rotationSchedules.fetch(
                  calendar.rotationSchedule.id
                );
              }

              const sanitizeCalendar = {
                calendarType: calendar.calendarType,
                classV2Id: calendar.classV2 ? calendar.classV2.id : null,
                createdAt: calendar.createdAt,
                deletedAt: calendar.deletedAt,
                districtId: calendar.district ? calendar.district.id : null,
                edlinkId: calendar.edlinkId,
                id: calendar.id,
                rotationScheduleId: calendar.rotationSchedule
                  ? calendar.rotationSchedule.id
                  : null,
                sectionId: calendar.section ? calendar.section.id : null,
                sessionId: calendar.session.id,
                timezone: calendar.timezone,
                updatedAt: calendar.updatedAt,
                urlId: calendar.urlId,
                userId: calendar.user ? calendar.user.id : null,
              };

              this.add(sanitizeCalendar);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetCalendarByUrlId,
          variables: {
            where: {
              urlId,
            },
          },
        })
        .then((res) => {
          const calendar = res.data.calendarByUrlId;

          invariant(calendar, "Calendar must exist");
          invariant(calendar.session, "Rotation Calendar must have a session");
          invariant(
            calendar.calendarType,
            "Calendar must have a calendar type"
          );

          if (
            calendar.calendarType === EnumCalendarCalendarType.SessionDefault
          ) {
            invariant(
              calendar.rotationSchedule,
              "Session Default Calendar must have a rotation schedule"
            );
            // Fetch other data we need to populate the session
            // We need to fetch Rotation Schedule, Calendar Days, Events, Session
            this.rootStore.rotationSchedules.fetch(
              calendar.rotationSchedule.id
            );

            this.rootStore.calendarDays.fetchCalendarDays(calendar.id);

            this.rootStore.events.fetchCalendarEvents(calendar.id);

            this.rootStore.sessions.fetch(calendar.session.id);
          } else if (
            calendar.calendarType === EnumCalendarCalendarType.SectionDefault
          ) {
            // Fetch other data we need to populate the section Calendar
            // We need to fetch events, calendar Schedule Main Rotation Schedule (SessionDefault) Calendar, Calendar Days of the main (SessionDefault) calendar,

            invariant(
              calendar.section,
              "Section Default Calendar must have a section"
            );

            invariant(
              calendar.rotationSchedule,
              "Section Default Calendar must have a rotation schedule"
            );

            // 1. Events
            this.rootStore.events.fetchCalendarEvents(calendar.id);

            // 2. Calendar Schedule Main Rotation Schedule (SessionDefault) Calendar
            this.rootStore.rotationSchedules.fetch(
              calendar.rotationSchedule.id
            );

            // 3. Calendar for the main rotation schedule (SessionDefault) calendar -> This will fetch the calendar days & calendar schedules
            this.fetchSessionRotationCalendars(calendar.session.id);

            // 4. Session
            this.rootStore.sessions.fetch(calendar.session.id);

            // 5. Section
            this.rootStore.sections.fetch(calendar.section.id);
          }

          // Fetch events for each calendar
          this.rootStore.calendarDays.fetchCalendarDays(calendar.id);
          this.rootStore.events.fetchCalendarEvents(calendar.id);
          this.rootStore.calendarSchedules.fetchCalendarSchedules(calendar.id);

          const sanitizeCalendar = {
            calendarType: calendar.calendarType,
            classV2Id: calendar.classV2 ? calendar.classV2.id : null,
            createdAt: calendar.createdAt,
            deletedAt: calendar.deletedAt,
            districtId: calendar.district ? calendar.district.id : null,
            edlinkId: calendar.edlinkId,
            id: calendar.id,
            rotationScheduleId: calendar.rotationSchedule
              ? calendar.rotationSchedule.id
              : null,
            sectionId: calendar.section ? calendar.section.id : null,
            sessionId: calendar.session.id,
            timezone: calendar.timezone,
            updatedAt: calendar.updatedAt,
            urlId: calendar.urlId,
            userId: calendar.user ? calendar.user.id : null,
          };

          this.add(sanitizeCalendar);

          resolve(true);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
        });
    });
  };

  @action
  fetchSessionRotationCalendars = (sessionId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetCalendars,
          variables: {
            where: {
              session: {
                id: sessionId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const calendars = res.data.calendars;

          if (!calendars) {
            reject(false);
          }

          runInAction("Populate calendars", () => {
            calendars.forEach((calendar) => {
              invariant(
                calendar.session,
                "Rotation Calendar must have a session"
              );

              invariant(
                calendar.calendarType,
                "Calendar must have a calendar type"
              );

              // Fetch events for each calendar
              this.rootStore.calendarDays.fetchCalendarDays(calendar.id);
              this.rootStore.events.fetchCalendarEvents(calendar.id);
              this.rootStore.calendarSchedules.fetchCalendarSchedules(
                calendar.id
              );

              const sanitizeCalendar = {
                calendarType: calendar.calendarType,
                classV2Id: calendar.classV2 ? calendar.classV2.id : null,
                createdAt: calendar.createdAt,
                deletedAt: calendar.deletedAt,
                districtId: calendar.district ? calendar.district.id : null,
                edlinkId: calendar.edlinkId,
                id: calendar.id,
                rotationScheduleId: calendar.rotationSchedule
                  ? calendar.rotationSchedule.id
                  : null,
                sectionId: calendar.section ? calendar.section.id : null,
                sessionId: calendar.session.id,
                timezone: calendar.timezone,
                updatedAt: calendar.updatedAt,
                urlId: calendar.urlId,
                userId: calendar.user ? calendar.user.id : null,
              };

              this.add(sanitizeCalendar);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<Calendar>): Promise<Calendar> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as CalendarCreateInput);
      } else {
        return this.update(
          rest as CalendarUpdateInput,
          { id } as CalendarWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: CalendarCreateInput): Promise<Calendar> {
    const res = await this.apolloClient.mutate({
      mutation: CreateCalendar,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createCalendar) {
      throw Error("Failed to create district.");
    }

    const calendar = res.data.createCalendar;

    invariant(calendar.session, "Rotation Calendar must have a session");

    invariant(calendar.calendarType, "Calendar must have a calendar type");

    // Fetch events for each calendar
    this.rootStore.calendarDays.fetchCalendarDays(calendar.id);
    this.rootStore.events.fetchCalendarEvents(calendar.id);

    const sanitizeCalendar = {
      calendarType: calendar.calendarType,
      classV2Id: calendar.classV2 ? calendar.classV2.id : null,
      createdAt: calendar.createdAt,
      deletedAt: calendar.deletedAt,
      districtId: calendar.district ? calendar.district.id : null,
      edlinkId: calendar.edlinkId,
      id: calendar.id,
      rotationScheduleId: calendar.rotationSchedule
        ? calendar.rotationSchedule.id
        : null,
      sectionId: calendar.section ? calendar.section.id : null,
      sessionId: calendar.session.id,
      timezone: calendar.timezone,
      updatedAt: calendar.updatedAt,
      urlId: calendar.urlId,
      userId: calendar.user ? calendar.user.id : null,
    };

    return this.add(sanitizeCalendar);
  }

  @action
  async update(
    data: CalendarUpdateInput,
    where: CalendarWhereUniqueInput
  ): Promise<Calendar> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateCalendar,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateCalendar) {
      throw Error("Failed to update class.");
    }

    const calendar = res.data.updateCalendar;

    invariant(calendar.session, "Rotation Calendar must have a session");

    invariant(calendar.calendarType, "Calendar must have a calendar type");

    // Fetch events for each calendar
    this.rootStore.calendarDays.fetchCalendarDays(calendar.id);
    this.rootStore.events.fetchCalendarEvents(calendar.id);

    const sanitizeCalendar = {
      calendarType: calendar.calendarType,
      classV2Id: calendar.classV2 ? calendar.classV2.id : null,
      createdAt: calendar.createdAt,
      deletedAt: calendar.deletedAt,
      districtId: calendar.district ? calendar.district.id : null,
      edlinkId: calendar.edlinkId,
      id: calendar.id,
      rotationScheduleId: calendar.rotationSchedule
        ? calendar.rotationSchedule.id
        : null,
      sectionId: calendar.section ? calendar.section.id : null,
      sessionId: calendar.session.id,
      timezone: calendar.timezone,
      updatedAt: calendar.updatedAt,
      urlId: calendar.urlId,
      userId: calendar.user ? calendar.user.id : null,
    };

    return this.add(sanitizeCalendar);
  }

  @action
  async assignClassSchedules(classIds: string[], rotationScheduleId: string) {
    const res = await this.apolloClient.mutate({
      mutation: AssignClassSchedules,
      variables: {
        classIds,
        rotationScheduleId,
      },
    });

    if (!res.data || !res.data.assignClassSchedules) {
      throw Error("Failed to assign class schedules.");
    }

    const { calendars, ui } = this.rootStore;

    // We refetch all the calendars for the current rotation schedule
    if (ui.activeSessionId) {
      await calendars.fetchSessionRotationCalendars(ui.activeSessionId);
    }

    return res.data.assignClassSchedules;
  }

  getCalendarsForRotationSchedule = (
    rotationScheduleId: string
  ): Calendar[] => {
    console.log("get calendars for Rotation Schedule Id", rotationScheduleId);

    return this.sortedData.filter(
      (calendar) =>
        calendar.rotationScheduleId === rotationScheduleId &&
        !calendar.deletedAt
    );
  };

  getCalendarsForClass = (classID: string, sessionId: string): Calendar[] => {
    return this.sortedData.filter(
      (calendar) =>
        calendar.classV2Id === classID &&
        calendar.sessionId === sessionId &&
        !calendar.deletedAt
    );
  };

  getCalendarsForSection = (sectionId: string): Calendar[] => {
    console.log("Section id", sectionId);

    console.log(
      "Sorted data",
      this.sortedData.map((c) => toJS(c.toGQLAttributes()))
    );

    return this.sortedData.filter(
      (calendar) => calendar.sectionId === sectionId && !calendar.deletedAt
    );
  };

  getCalendarsForSectionAndSession = (
    sectionId: string,
    sessionId: string
  ): Calendar | null => {
    const calendar = this.sortedData.find(
      (calendar) =>
        calendar.sectionId === sectionId &&
        calendar.sessionId === sessionId &&
        !calendar.deletedAt
    );
    return calendar || null;
  };

  getAllCalendars = (): Calendar[] => {
    return this.sortedData.filter((calendar) => !calendar.deletedAt);
  };

  getByUrlParam = (urlId: string): Calendar | undefined => {
    return this.sortedData.find((calendar) => urlId.endsWith(calendar.urlId));
  };
}
