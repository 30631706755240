import {
  EnumEventEventType,
  EnumSessionSessionType,
  EnumSessionState,
} from "../__generated__/graphql";

export const sessionStateToStringMap: {
  [key in EnumSessionState]: string;
} = {
  [EnumSessionState.Active]: "Active",
  [EnumSessionState.Completed]: "Completed",
  [EnumSessionState.Upcoming]: "Upcoming",
};

export const sessionTypeToStringMap: {
  [key in EnumSessionSessionType]: string;
} = {
  [EnumSessionSessionType.GradingPeriod]: "Grading Period",
  [EnumSessionSessionType.SchoolYear]: "School Year",
  [EnumSessionSessionType.Semester]: "Semester",
  [EnumSessionSessionType.Term]: "Term",
};

export function sessionStateEnumToDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(sessionStateToStringMap).map((key) => {
    return {
      label: sessionStateToStringMap[key as EnumSessionState],
      value: key,
    };
  });
}

export function sessionTypeEnumToDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(sessionTypeToStringMap).map((key) => {
    return {
      label: sessionTypeToStringMap[key as EnumSessionSessionType],
      value: key,
    };
  });
}

export function sessionStateEnumToSelectedOption(selected: EnumSessionState): {
  label: string;
  value: string;
} {
  return {
    label: sessionStateToStringMap[selected],
    value: selected,
  };
}

export function sessionTypeEnumToSelectedOption(
  selected: EnumSessionSessionType
): {
  label: string;
  value: string;
} {
  return {
    label: sessionTypeToStringMap[selected],
    value: selected,
  };
}

// Event Type enum

export const eventTypeToStringMap: {
  [key in EnumEventEventType]: string;
} = {
  [EnumEventEventType.FirstDay]: "First Day of school",
  [EnumEventEventType.LastDay]: "Last Day of school",
  [EnumEventEventType.NoSchool]: "No school day",
  [EnumEventEventType.Holiday]: "Holiday",
  [EnumEventEventType.Event]: "Event",
  [EnumEventEventType.Period]: "Period",
  [EnumEventEventType.Occasion]: "Occasion",
};

export function eventTypeEnumToDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(eventTypeToStringMap).map((key) => {
    return {
      label: eventTypeToStringMap[key as EnumEventEventType],
      value: key,
    };
  });
}

export function eventTypeEnumToSelectedOption(selected: EnumEventEventType): {
  label: string;
  value: string;
} {
  return {
    label: eventTypeToStringMap[selected],
    value: selected,
  };
}
