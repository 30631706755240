import invariant from "invariant";
import { createDropdownAction } from ".";
import SchoolLogo from "../components/Avatars/SchoolLogo";
import RotationSchedule from "../models/RotationSchedule";
import { ActionContext, CalendarView } from "../types";

export const createRotationsList = ({
  activeSessionId,
  stores,
}: ActionContext) => {
  invariant(activeSessionId, "activeSessionId must be defined");

  const { rotationSchedules, sessions } = stores;

  const activeSession = sessions.getByUrlParam(activeSessionId);

  invariant(activeSession, "activeSession must be defined");

  const rotations = rotationSchedules.getRotationSchedulesForSession(
    activeSession.id
  );

  return createDropdownAction({
    type: "radio-group",
    text: "Change active rotation",
    trackingName: "Active rotation changed",
    itemIndicator: "check",
    onValueChange: (context, value) => {
      const { stores } = context;

      const { sessions } = stores;

      const activeSession = sessions.getByUrlParam(activeSessionId);

      invariant(activeSession, "activeSession must be defined");

      activeSession.setActiveRotationScheduleId(value);
    },
    value: (context) => {
      const { stores } = context;

      const activeSession = stores.sessions.getByUrlParam(activeSessionId);

      invariant(activeSession, "activeSession must be defined");

      return activeSession.activeRotationScheduleId || "";
    },
    children: rotations.map((rotationSchedule: RotationSchedule) => {
      return createDropdownAction({
        type: "radio-item",
        id: `rotation-${rotationSchedule.id}`,
        text: rotationSchedule.name,
        trackingName: "Switch rotation",
        // icon: (
        //   <div
        //     className={clsx("h-2 w-2 rounded-full ", `bg-${section.color}-500`)}
        //   />
        // ),
        value: rotationSchedule.id,
      });
    }),
  });
};

export const sessionCalendarViewToggle = createDropdownAction({
  type: "radio-group",
  text: "Calendar view",
  trackingName: "Calendar view toggle",
  itemIndicator: "check",
  onValueChange: (context, value) => {
    const { stores } = context;
    stores.ui.setSessionCalendarView(value as CalendarView);
  },
  value: (context) => {
    const { stores } = context;
    return stores.ui.sessionCalendarView;
  },
  children: [
    createDropdownAction({
      type: "radio-item",
      text: "Day",
      trackingName: "Change calendar view to Day",
      value: "day",
    }),
    createDropdownAction({
      type: "radio-item",
      text: "Week",
      trackingName: "Change calendar view to Week",
      value: "week",
    }),
    createDropdownAction({
      type: "radio-item",
      text: "Month",
      trackingName: "Change calendar view to Month",
      value: "month",
    }),
  ],
});

export const sharedCalendarViewToggle = createDropdownAction({
  type: "radio-group",
  text: "Calendar view",
  trackingName: "Calendar view toggle",
  itemIndicator: "check",
  onValueChange: (context, value) => {
    const { stores } = context;
    stores.ui.setSharedCalendarView(value as CalendarView);
  },
  value: (context) => {
    const { stores } = context;
    return stores.ui.sharedCalendarView;
  },
  children: [
    createDropdownAction({
      type: "radio-item",
      text: "Day",
      trackingName: "Change calendar view to Day",
      value: "day",
    }),
    createDropdownAction({
      type: "radio-item",
      text: "Week",
      trackingName: "Change calendar view to Week",
      value: "week",
    }),
    createDropdownAction({
      type: "radio-item",
      text: "Month",
      trackingName: "Change calendar view to Month",
      value: "month",
    }),
    // createDropdownAction({
    //   type: "radio-item",
    //   text: "Agenda",
    //   trackingName: "Change calendar view to Agenda",
    //   value: "agenda",
    // }),
  ],
});

export const createSchoolsList = ({
  // activeDistrictId,
  stores,
}: ActionContext) => {
  // invariant(activeDistrictId, "activeDistrictId must be defined");

  const { ui, schools, districts } = stores;

  // invariant(ui.activeDistrictId, "activeDistrictId must be defined");

  if (!ui.activeDistrictId) return null;

  const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

  // invariant(activeDistrict, "activeDistrict must be defined");

  if (!activeDistrict) return null;

  const districtSchools = schools.getSchoolsForDistrict(activeDistrict.id);

  // invariant(ui.activeSchoolId, "activeSchoolId must be defined");

  if (!ui.activeSchoolId) return null;

  const activeSchool = schools.getByUrlParam(ui.activeSchoolId);

  // invariant(activeSchool, "activeSchool must be defined");

  if (!activeSchool) return null;

  return createDropdownAction({
    type: "radio-group",
    text: "Change school",
    trackingName: "Active school changed",
    itemIndicator: "check",
    icon: (
      <SchoolLogo
        schoolData={{
          initial: activeSchool.name[0],
          id: activeSchool.id,
          // color: planner.color,
        }}
        size={24}
      />
    ),
    onValueChange: (context, value) => {
      const { stores } = context;

      const { ui } = stores;

      ui.setActiveSchoolId(value);
    },
    value: (context) => {
      const { stores } = context;

      const { ui } = stores;

      return ui.activeSchoolId || "";
    },
    children: districtSchools.map((school) => {
      console.log(school);
      return createDropdownAction({
        type: "radio-item",
        id: `school-${school.urlId}`,
        text: school.name,
        trackingName: "Switch school",
        // icon: (
        //   <div
        //     className={clsx("h-2 w-2 rounded-full ", `bg-${section.color}-500`)}
        //   />
        // ),
        value: school.urlId,
      });
    }),
  });
};

export const createSectionsList = ({
  activeClassId,
  stores,
}: ActionContext) => {
  invariant(activeClassId, "activeClassId must be defined");

  const { classes, sections } = stores;

  const activeClass = classes.getByUrlParam(activeClassId);

  invariant(activeClass, "activeClass must be defined");

  const classSections = sections.getSectionsForClass(activeClass.id);

  return createDropdownAction({
    type: "radio-group",
    text: "Change section",
    trackingName: "Active section changed",
    itemIndicator: "check",
    onValueChange: (context, value) => {
      const { stores } = context;

      const { ui } = stores;

      ui.setActiveSectionId(value);
    },
    value: (context) => {
      const { stores } = context;

      const { ui } = stores;

      return ui.activeSectionId || "";
    },
    children: classSections.map((section) => {
      return createDropdownAction({
        type: "radio-item",
        id: `section-${section.id}`,
        text: section.name,
        trackingName: "Switch section",
        // icon: (
        //   <div
        //     className={clsx("h-2 w-2 rounded-full ", `bg-${section.color}-500`)}
        //   />
        // ),
        value: section.urlId,
      });
    }),
  });
};
