import { action, autorun, computed, observable } from "mobx";
import { generateRandomColor500 } from "../colors-list";

import { CalendarView } from "../types";
import LocalStorage from "../utils/LocalStorage";
import { theme } from "../utils/theme";

export enum Theme {
  Light = "light",
  Dark = "dark",
}

const STORE_NAME = "UI";

let appSidebarHidden = window.location.search.includes(
  "appSidebarVisible=false"
);

export default class UiStore {
  @observable
  theme: Theme = Theme.Light;

  @observable
  mainSidebarWidth: number;

  @observable
  sessionCalendarView: CalendarView = "week";

  @observable
  sharedCalendarView: CalendarView = "week";

  @observable
  mainSidebarCollapsed: boolean;

  @observable
  secondarySidebarWidth: number;

  @observable
  secondarySidebarCollapsed: boolean;

  @observable
  mainSidebarResizing: boolean;

  @observable
  plannerColorsMap: { [key: string]: string } = {};

  @observable
  courseColorsMap: { [key: string]: string } = {};

  @observable
  districtColorsMap: { [key: string]: string } = {};

  @observable
  schoolColorsMap: { [key: string]: string } = {};

  @observable
  userColorsMap: { [key: string]: string } = {};

  @observable
  noScrollBounceRef: HTMLDivElement | null = null;

  @observable
  showMobileSidebar = false;

  @observable
  activeDistrictId?: string;

  @observable
  activeSchoolId?: string;

  @observable
  activeClassId?: string;

  @observable
  activeSectionId?: string;

  @observable
  activeSessionId?: string;

  @observable
  activeCourseId?: string;

  @observable
  activeRotationScheduleId?: string;

  constructor() {
    // Rehydrate
    const data: Partial<UiStore> = LocalStorage.retrieve(STORE_NAME) || {};

    // Set the values for the keys we have in storage
    this.sessionCalendarView = data.sessionCalendarView || "week";
    this.sharedCalendarView = data.sharedCalendarView || "week";
    this.mainSidebarWidth = data.mainSidebarWidth || theme.mainSidebarWidth;
    this.mainSidebarCollapsed = data.mainSidebarCollapsed || false;
    this.secondarySidebarWidth =
      data.secondarySidebarWidth || theme.secondarySidebarWidth;
    this.plannerColorsMap = data.plannerColorsMap || {};
    this.userColorsMap = data.userColorsMap || {};
    this.courseColorsMap = data.courseColorsMap || {};

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });
  }

  @action
  setActiveDistrictId = (id: string | undefined) => {
    this.activeDistrictId = id;
  };

  @action
  setActiveSchoolId = (id: string | undefined) => {
    this.activeSchoolId = id;
  };

  @action
  setActiveClassId = (id: string | undefined) => {
    this.activeClassId = id;
  };

  @action
  setActiveSectionId = (id: string | undefined) => {
    this.activeSectionId = id;
  };

  @action
  setActiveSessionId = (id: string | undefined) => {
    this.activeSessionId = id;
  };

  @action
  setActiveCourseId = (id: string | undefined) => {
    this.activeCourseId = id;
  };

  @action
  setActiveRotationScheduleId = (id: string | undefined) => {
    this.activeRotationScheduleId = id;
  };

  @action
  setMainSidebarWidth = (width: number) => {
    this.mainSidebarWidth = width;
  };

  @action
  setSecondarySidebarWidth = (width: number) => {
    this.secondarySidebarWidth = width;
  };

  @action
  collapseMainSidebar = () => {
    this.mainSidebarCollapsed = true;
  };

  @action
  setMainSidebarResizing = (resizing: boolean) => {
    this.mainSidebarResizing = resizing;
  };

  @action
  toggleMobileSidebar = () => {
    this.showMobileSidebar = !this.showMobileSidebar;
  };

  @action
  hideMobileSidebar = () => {
    this.showMobileSidebar = false;
  };

  @action
  toggleMainSidebar = () => {
    appSidebarHidden = false;
    this.mainSidebarCollapsed = !this.mainSidebarCollapsed;
  };

  @computed
  get isMainSidebarCollapsed() {
    return this.mainSidebarCollapsed || appSidebarHidden;
  }

  @computed
  get toLocalLocalStorageData() {
    return {
      sessionCalendarView: this.sessionCalendarView,
      sharedCalendarView: this.sharedCalendarView,
      mainSidebarWidth: this.mainSidebarWidth,
      mainSidebarCollapsed: this.mainSidebarCollapsed,
      secondarySidebarWidth: this.secondarySidebarWidth,
      plannerColorsMap: this.plannerColorsMap,
      userColorsMap: this.userColorsMap,
      courseColorsMap: this.courseColorsMap,
    };
  }
  @action
  setSessionCalendarView = (view: CalendarView) => {
    this.sessionCalendarView = view;
  };

  @action
  setSharedCalendarView = (view: CalendarView) => {
    this.sharedCalendarView = view;
  };

  @action
  setNoScrollBounceRef = (ref: HTMLDivElement | null) => {
    this.noScrollBounceRef = ref;
  };

  @action
  getPlannerColor = (id: string) => {
    // Check if the color is already in the map
    if (this.plannerColorsMap[id]) {
      return this.plannerColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.plannerColorsMap[id] = color;

    return color;
  };

  @action
  getDistrictColor = (id: string) => {
    // Check if the color is already in the map
    if (this.districtColorsMap[id]) {
      return this.districtColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.districtColorsMap[id] = color;

    return color;
  };

  @action
  getSchoolColor = (id: string) => {
    // Check if the color is already in the map
    if (this.schoolColorsMap[id]) {
      return this.schoolColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.schoolColorsMap[id] = color;

    return color;
  };

  @action
  getCourseColor = (id: string) => {
    // Check if the color is already in the map
    if (this.courseColorsMap[id]) {
      return this.courseColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.courseColorsMap[id] = color;

    return color;
  };

  @action
  getUserColor = (id: string) => {
    // Check if the color is already in the map
    if (this.userColorsMap[id]) {
      return this.userColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.userColorsMap[id] = color;

    return color;
  };

  @action
  clear = () => {
    this.sharedCalendarView = "week";
    this.sessionCalendarView = "week";
    this.mainSidebarWidth = theme.mainSidebarWidth;
    this.mainSidebarCollapsed = false;
    this.secondarySidebarWidth = theme.secondarySidebarWidth;
    this.secondarySidebarCollapsed = false;
    this.plannerColorsMap = {};
    this.userColorsMap = {};
    this.courseColorsMap = {};
  };
}
