import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { DateTime } from "luxon";
import { action, observable, runInAction } from "mobx";
import {
  CreateEvent,
  SetFirstAndLastDayEvents,
  UpdateEvent,
} from "../graphql/scheduling/scheduling.mutations";
import { GetEvents } from "../graphql/scheduling/scheduling.queries";
import Event from "../models/Event";
import {
  EnumCalendarCalendarType,
  EnumEventEventType,
  EventCreateInput,
  EventUpdateInput,
  EventWhereUniqueInput,
  FirstLastDaysInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export type FirstAndLastDaysResponse = {
  startEvent: Event;
  endEvent: Event;
};

export default class EventsStore extends BaseStore<Event> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Event, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchCalendarEvents = (calendarId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetEvents,
          variables: {
            where: {
              calendar: {
                id: calendarId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const events = res.data.events;

          if (!events) {
            reject(false);
          }

          runInAction("Populate events", () => {
            events.forEach((event) => {
              invariant(event.calendar, "An Event must have a calendar");
              invariant(event.eventType, "An Event must have an eventType");

              // We add the event to the calendarEvents store

              let title = event.title;
              let color = "blue";

              if (
                event.eventType === EnumEventEventType.Period &&
                event.calendar.calendarType ===
                  EnumCalendarCalendarType.SectionDefault
              ) {
                if (event.calendarSchedule) {
                  title =
                    event.calendarSchedule.blockSchedule &&
                    event.calendarSchedule.blockSchedule.block
                      ? event.calendarSchedule.blockSchedule.block.abbreviation
                      : "Period";
                  color =
                    event.calendarSchedule.blockSchedule &&
                    event.calendarSchedule.blockSchedule.block &&
                    event.calendarSchedule.blockSchedule.block.color
                      ? event.calendarSchedule.blockSchedule.block.color
                      : "blue";
                }
              }

              if (
                event.eventType === EnumEventEventType.Holiday ||
                event.eventType === EnumEventEventType.NoSchool ||
                event.eventType === EnumEventEventType.FirstDay ||
                event.eventType === EnumEventEventType.LastDay
              ) {
                color = "black";
              }

              if (!event.deletedAt) {
                this.rootStore.calendarEvents.add({
                  id: event.id,
                  title,
                  start: event.date || event.startTime,
                  end: event.date || event.endTime,
                  allDay: event.allDay || !!event.date,
                  calendarId: event.calendar.id,
                  eventType:
                    event.eventType === EnumEventEventType.Holiday ||
                    event.eventType === EnumEventEventType.NoSchool ||
                    event.eventType === EnumEventEventType.FirstDay ||
                    event.eventType === EnumEventEventType.LastDay
                      ? "special-event"
                      : "event",
                  color,
                  formattedDate: DateTime.fromISO(
                    event.date || event.startTime
                  ).toFormat("yyyy-MM-dd"),
                  editable: true,
                });
              }

              const sanitizeEvent = {
                allDay: event.allDay,
                calendarId: event.calendar.id,
                calendarDayId: event.calendarDay ? event.calendarDay.id : null,
                createdAt: event.createdAt,
                date: event.date,
                deletedAt: event.deletedAt,
                description: event.description,
                edlinkId: event.edlinkId,
                endTime: event.endTime,
                eventType: event.eventType,
                id: event.id,
                startTime: event.startTime,
                timezone: event.timezone,
                title: event.title,
                updatedAt: event.updatedAt,
                urlId: event.urlId,
                recurring: event.recurring,
              };

              this.add(sanitizeEvent);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<Event>): Promise<Event> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as EventCreateInput);
      } else {
        return this.update(
          rest as EventUpdateInput,
          { id } as EventWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: EventCreateInput): Promise<Event> {
    const res = await this.apolloClient.mutate({
      mutation: CreateEvent,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createEvent) {
      throw Error("Failed to create event.");
    }

    const event = res.data.createEvent;

    invariant(event.calendar, "An Event must have a calendar");
    invariant(event.eventType, "An Event must have an eventType");

    let title = event.title;
    let color = "blue";

    if (
      event.eventType === EnumEventEventType.Period &&
      event.calendar.calendarType === EnumCalendarCalendarType.SectionDefault
    ) {
      if (event.calendarSchedule) {
        title =
          event.calendarSchedule.blockSchedule &&
          event.calendarSchedule.blockSchedule.block
            ? event.calendarSchedule.blockSchedule.block.abbreviation
            : "Period";
        color =
          event.calendarSchedule.blockSchedule &&
          event.calendarSchedule.blockSchedule.block &&
          event.calendarSchedule.blockSchedule.block.color
            ? event.calendarSchedule.blockSchedule.block.color
            : "blue";
      }
    }

    if (
      event.eventType === EnumEventEventType.Holiday ||
      event.eventType === EnumEventEventType.NoSchool ||
      event.eventType === EnumEventEventType.FirstDay ||
      event.eventType === EnumEventEventType.LastDay
    ) {
      color = "black";
    }

    if (!event.deletedAt) {
      this.rootStore.calendarEvents.add({
        id: event.id,
        title,
        start: event.date || event.startTime,
        end: event.date || event.endTime,
        allDay: event.allDay || !!event.date,
        calendarId: event.calendar.id,
        eventType:
          event.eventType === EnumEventEventType.Holiday ||
          event.eventType === EnumEventEventType.NoSchool ||
          event.eventType === EnumEventEventType.FirstDay ||
          event.eventType === EnumEventEventType.LastDay
            ? "special-event"
            : "event",
        color,
        formattedDate: DateTime.fromISO(event.date || event.startTime).toFormat(
          "yyyy-MM-dd"
        ),
        editable: true,
      });
    }

    const sanitizeEvent = {
      allDay: event.allDay,
      calendarId: event.calendar.id,
      calendarDayId: event.calendarDay ? event.calendarDay.id : null,
      createdAt: event.createdAt,
      date: event.date,
      deletedAt: event.deletedAt,
      description: event.description,
      edlinkId: event.edlinkId,
      endTime: event.endTime,
      eventType: event.eventType,
      id: event.id,
      startTime: event.startTime,
      timezone: event.timezone,
      title: event.title,
      updatedAt: event.updatedAt,
      urlId: event.urlId,
      recurring: event.recurring,
    };

    return this.add(sanitizeEvent);
  }

  async update(
    data: EventUpdateInput,
    where: EventWhereUniqueInput
  ): Promise<Event> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateEvent,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateEvent) {
      throw Error("Failed to update event.");
    }

    const event = res.data.updateEvent;

    invariant(event.calendar, "An Event must have a calendar");
    invariant(event.eventType, "An Event must have an eventType");

    let title = event.title;
    let color = "blue";

    if (
      event.eventType === EnumEventEventType.Period &&
      event.calendar.calendarType === EnumCalendarCalendarType.SectionDefault
    ) {
      if (event.calendarSchedule) {
        title =
          event.calendarSchedule.blockSchedule &&
          event.calendarSchedule.blockSchedule.block
            ? event.calendarSchedule.blockSchedule.block.abbreviation
            : "Period";
        color =
          event.calendarSchedule.blockSchedule &&
          event.calendarSchedule.blockSchedule.block &&
          event.calendarSchedule.blockSchedule.block.color
            ? event.calendarSchedule.blockSchedule.block.color
            : "blue";
      }
    }

    if (
      event.eventType === EnumEventEventType.Holiday ||
      event.eventType === EnumEventEventType.NoSchool ||
      event.eventType === EnumEventEventType.FirstDay ||
      event.eventType === EnumEventEventType.LastDay
    ) {
      color = "black";
    }

    if (!event.deletedAt) {
      this.rootStore.calendarEvents.add({
        id: event.id,
        title,
        start: event.date || event.startTime,
        end: event.date || event.endTime,
        allDay: event.allDay || !!event.date,
        calendarId: event.calendar.id,
        eventType:
          event.eventType === EnumEventEventType.Holiday ||
          event.eventType === EnumEventEventType.NoSchool ||
          event.eventType === EnumEventEventType.FirstDay ||
          event.eventType === EnumEventEventType.LastDay
            ? "special-event"
            : "event",
        color,
        formattedDate: DateTime.fromISO(event.date || event.startTime).toFormat(
          "yyyy-MM-dd"
        ),
        editable: true,
      });
    } else {
      // Remove it from calendarEvents Store
      this.rootStore.calendarEvents.remove(event.id);
    }

    const sanitizeEvent = {
      allDay: event.allDay,
      calendarId: event.calendar.id,
      calendarDayId: event.calendarDay ? event.calendarDay.id : null,
      createdAt: event.createdAt,
      date: event.date,
      deletedAt: event.deletedAt,
      description: event.description,
      edlinkId: event.edlinkId,
      endTime: event.endTime,
      eventType: event.eventType,
      id: event.id,
      startTime: event.startTime,
      timezone: event.timezone,
      title: event.title,
      updatedAt: event.updatedAt,
      urlId: event.urlId,
      recurring: event.recurring,
    };

    return this.add(sanitizeEvent);
  }

  async updateEventAndCalendarEvent(
    data: EventUpdateInput,
    where: EventWhereUniqueInput
  ): Promise<Event> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateEvent,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateEvent) {
      throw Error("Failed to update event.");
    }

    const event = res.data.updateEvent;

    invariant(event.calendar, "An Event must have a calendar");
    invariant(event.eventType, "An Event must have an eventType");

    const sanitizeEvent = {
      allDay: event.allDay,
      calendarId: event.calendar.id,
      calendarDayId: event.calendarDay ? event.calendarDay.id : null,
      createdAt: event.createdAt,
      date: event.date,
      deletedAt: event.deletedAt,
      description: event.description,
      edlinkId: event.edlinkId,
      endTime: event.endTime,
      eventType: event.eventType,
      id: event.id,
      startTime: event.startTime,
      timezone: event.timezone,
      title: event.title,
      updatedAt: event.updatedAt,
      urlId: event.urlId,
      recurring: event.recurring,
    };

    let title = event.title;
    let color = "blue";

    if (
      event.eventType === EnumEventEventType.Period &&
      event.calendar.calendarType === EnumCalendarCalendarType.SectionDefault
    ) {
      if (event.calendarSchedule) {
        title =
          event.calendarSchedule.blockSchedule &&
          event.calendarSchedule.blockSchedule.block
            ? event.calendarSchedule.blockSchedule.block.abbreviation
            : "Period";
        color =
          event.calendarSchedule.blockSchedule &&
          event.calendarSchedule.blockSchedule.block &&
          event.calendarSchedule.blockSchedule.block.color
            ? event.calendarSchedule.blockSchedule.block.color
            : "blue";
      }
    }

    if (
      event.eventType === EnumEventEventType.Holiday ||
      event.eventType === EnumEventEventType.NoSchool ||
      event.eventType === EnumEventEventType.FirstDay ||
      event.eventType === EnumEventEventType.LastDay
    ) {
      color = "black";
    }

    this.rootStore.calendarEvents.add({
      id: event.id,
      title,
      start: event.date || event.startTime,
      end: event.date || event.endTime,
      allDay: event.allDay || !!event.date,
      calendarId: event.calendar.id,
      eventType:
        event.eventType === EnumEventEventType.Holiday ||
        event.eventType === EnumEventEventType.NoSchool ||
        event.eventType === EnumEventEventType.FirstDay ||
        event.eventType === EnumEventEventType.LastDay
          ? "special-event"
          : "event",
      color,
      formattedDate: DateTime.fromISO(event.date || event.startTime).toFormat(
        "yyyy-MM-dd"
      ),
      editable: true,
    });

    return this.add(sanitizeEvent);
  }

  @action
  async setFirstAndLastDayEvents(
    data: FirstLastDaysInput
  ): Promise<FirstAndLastDaysResponse> {
    const res = await this.apolloClient.mutate({
      mutation: SetFirstAndLastDayEvents,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.setFirstAndLastDayEvents) {
      throw Error("Failed to set first & last day events.");
    }

    const { startEvent, endEvent } = res.data.setFirstAndLastDayEvents;

    invariant(startEvent.calendar, "An Event must have a calendar");
    invariant(startEvent.eventType, "An Event must have an eventType");

    let startTitle = startEvent.title;
    let color = "black";

    if (!startEvent.deletedAt) {
      this.rootStore.calendarEvents.add({
        id: startEvent.id,
        title: startTitle,
        start: startEvent.date,
        end: startEvent.date,
        allDay: true,
        calendarId: startEvent.calendar.id,
        eventType: "special-event",
        color,
        formattedDate: DateTime.fromISO(startEvent.date).toFormat("yyyy-MM-dd"),
        editable: true,
      });
    }

    const sanitizeStartEvent = {
      allDay: startEvent.allDay,
      calendarId: startEvent.calendar.id,
      calendarDayId: startEvent.calendarDay ? startEvent.calendarDay.id : null,
      createdAt: startEvent.createdAt,
      date: startEvent.date,
      deletedAt: startEvent.deletedAt,
      description: startEvent.description,
      edlinkId: startEvent.edlinkId,
      endTime: startEvent.endTime,
      eventType: startEvent.eventType,
      id: startEvent.id,
      startTime: startEvent.startTime,
      timezone: startEvent.timezone,
      title: startEvent.title,
      updatedAt: startEvent.updatedAt,
      urlId: startEvent.urlId,
      recurring: startEvent.recurring,
    };

    invariant(endEvent.calendar, "An Event must have a calendar");
    invariant(endEvent.eventType, "An Event must have an eventType");

    let endTitle = endEvent.title;

    if (!endEvent.deletedAt) {
      this.rootStore.calendarEvents.add({
        id: endEvent.id,
        title: endTitle,
        start: endEvent.date,
        end: endEvent.date,
        allDay: true,
        calendarId: endEvent.calendar.id,
        eventType: "special-event",
        color,
        formattedDate: DateTime.fromISO(endEvent.date).toFormat("yyyy-MM-dd"),
        editable: true,
      });
    }

    const sanitizeEndEvent = {
      allDay: endEvent.allDay,
      calendarId: endEvent.calendar.id,
      calendarDayId: endEvent.calendarDay ? endEvent.calendarDay.id : null,
      createdAt: endEvent.createdAt,
      date: endEvent.date,
      deletedAt: endEvent.deletedAt,
      description: endEvent.description,
      edlinkId: endEvent.edlinkId,
      endTime: endEvent.endTime,
      eventType: endEvent.eventType,
      id: endEvent.id,
      startTime: endEvent.startTime,
      timezone: endEvent.timezone,
      title: endEvent.title,
      updatedAt: endEvent.updatedAt,
      urlId: endEvent.urlId,
      recurring: endEvent.recurring,
    };

    const startEventForResponse = this.add(sanitizeStartEvent);
    const endEventForResponse = this.add(sanitizeEndEvent);

    return {
      startEvent: startEventForResponse,
      endEvent: endEventForResponse,
    };
  }

  getEventsForCalendar = (calendarId: string): Event[] => {
    console.log("get events for Calendar Id", calendarId);

    return this.sortedData.filter(
      (event) => event.calendarId === calendarId && !event.deletedAt
    );
  };

  @action
  removeEvents = (calendarId: string) => {
    this.sortedData
      .filter((day) => day.calendarId === calendarId)
      .forEach((day) => {
        this.remove(day.id);
      });
  };

  @action
  removeEventsForCalendarDay = (calendarDayId: string) => {
    this.sortedData
      .filter((event) => event.calendarDayId === calendarDayId)
      .forEach((event) => {
        this.remove(event.id);
      });
  };
}
