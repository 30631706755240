import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, computed, observable, runInAction } from "mobx";
import {
  CreateClassV2,
  UpdateClassV2,
} from "../graphql/classV2/classV2.mutations";
import { GetClassV2, GetClassV2s } from "../graphql/classV2/classV2.queries";
import ClassV2 from "../models/ClassV2";
import SectionV2 from "../models/SectionV2";
import {
  ClassV2CreateInput,
  ClassV2UpdateInput,
  ClassV2WhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class ClassesStore extends BaseStore<ClassV2> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, ClassV2, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetch = async (id: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetClassV2,
          variables: {
            where: {
              id,
            },
          },
        })
        .then((res) => {
          const classV2 = res.data.classV2;

          invariant(classV2, "ClassV2 must exist");
          invariant(classV2.school, "School must have a district");
          invariant(classV2.state, "ClassV2 must have a state");

          const sanitizeClass = {
            createdAt: classV2.createdAt,
            deletedAt: classV2.deletedAt,
            courseId: classV2.course ? classV2.course.id : null,
            description: classV2.description,
            edlinkId: classV2.edlinkId,
            gradeLevels: classV2.gradeLevels || [],
            id: classV2.id,
            identifiers: classV2.identifiers,
            locale: classV2.locale,
            name: classV2.name,
            properties: classV2.properties,
            schoolId: classV2.school.id,
            sectionIds: classV2.sections.map((section) => section.id),
            sessionIds: classV2.sessions.map((session) => session.id),
            state: classV2.state,
            subjectCodes: classV2.subjectCodes || [],
            timezone: classV2.timezone,
            updatedAt: classV2.updatedAt,
            urlId: classV2.urlId,
          };

          this.add(sanitizeClass);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchSchoolClasses = (schoolId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetClassV2s,
          variables: {
            where: {
              school: {
                id: schoolId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const classes = res.data.classV2s;

          if (!classes) {
            reject(false);
          }

          runInAction("Populate classes", () => {
            classes.forEach((classV2) => {
              console.log(classV2);

              invariant(classV2.school, "School must have a district");
              invariant(classV2.state, "ClassV2 must have a state");

              const sanitizeClass = {
                createdAt: classV2.createdAt,
                deletedAt: classV2.deletedAt,
                courseId: classV2.course ? classV2.course.id : null,
                description: classV2.description,
                edlinkId: classV2.edlinkId,
                gradeLevels: classV2.gradeLevels || [],
                id: classV2.id,
                identifiers: classV2.identifiers,
                locale: classV2.locale,
                name: classV2.name,
                properties: classV2.properties,
                schoolId: classV2.school.id,
                sectionIds: classV2.sections.map((section) => section.id),
                sessionIds: classV2.sessions.map((session) => session.id),
                state: classV2.state,
                subjectCodes: classV2.subjectCodes || [],
                timezone: classV2.timezone,
                updatedAt: classV2.updatedAt,
                urlId: classV2.urlId,
              };

              this.add(sanitizeClass);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchSessionClasses = (sessionId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetClassV2s,
          variables: {
            where: {
              sessions: {
                some: {
                  id: {
                    equals: sessionId,
                  },
                },
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const classes = res.data.classV2s;

          console.log("Session classes", classes);

          if (!classes) {
            reject(false);
          }

          const { sections } = this.rootStore;

          runInAction("Populate classes", () => {
            classes.forEach((classV2) => {
              console.log(classV2);

              invariant(classV2.school, "School must have a district");
              invariant(classV2.state, "ClassV2 must have a state");

              sections.fetchClassSections(classV2.id);

              const sanitizeClass = {
                createdAt: classV2.createdAt,
                deletedAt: classV2.deletedAt,
                courseId: classV2.course ? classV2.course.id : null,
                description: classV2.description,
                edlinkId: classV2.edlinkId,
                gradeLevels: classV2.gradeLevels || [],
                id: classV2.id,
                identifiers: classV2.identifiers,
                locale: classV2.locale,
                name: classV2.name,
                properties: classV2.properties,
                schoolId: classV2.school.id,
                sectionIds: classV2.sections.map((section) => section.id),
                sessionIds: classV2.sessions.map((session) => session.id),
                state: classV2.state,
                subjectCodes: classV2.subjectCodes || [],
                timezone: classV2.timezone,
                updatedAt: classV2.updatedAt,
                urlId: classV2.urlId,
              };

              this.add(sanitizeClass);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<ClassV2>): Promise<ClassV2> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as ClassV2CreateInput);
      } else {
        return this.update(
          rest as ClassV2UpdateInput,
          { id } as ClassV2WhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: ClassV2CreateInput): Promise<ClassV2> {
    const res = await this.apolloClient.mutate({
      mutation: CreateClassV2,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createClassV2) {
      throw Error("Failed to create district.");
    }

    const classV2 = res.data.createClassV2;

    invariant(classV2.school, "School must have a district");
    invariant(classV2.state, "ClassV2 must have a state");

    const { sections } = this.rootStore;

    sections.fetchClassSections(classV2.id);

    const sanitizeClass = {
      createdAt: classV2.createdAt,
      deletedAt: classV2.deletedAt,
      courseId: classV2.course ? classV2.course.id : null,
      description: classV2.description,
      edlinkId: classV2.edlinkId,
      gradeLevels: classV2.gradeLevels || [],
      id: classV2.id,
      identifiers: classV2.identifiers,
      locale: classV2.locale,
      name: classV2.name,
      properties: classV2.properties,
      schoolId: classV2.school.id,
      sectionIds: classV2.sections.map((section) => section.id),
      sessionIds: classV2.sessions.map((session) => session.id),
      state: classV2.state,
      subjectCodes: classV2.subjectCodes || [],
      timezone: classV2.timezone,
      updatedAt: classV2.updatedAt,
      urlId: classV2.urlId,
    };

    return this.add(sanitizeClass);
  }

  async update(
    data: ClassV2UpdateInput,
    where: ClassV2WhereUniqueInput
  ): Promise<ClassV2> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateClassV2,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateClassV2) {
      throw Error("Failed to update class.");
    }

    const classV2 = res.data.updateClassV2;

    invariant(classV2.school, "School must have a district");
    invariant(classV2.state, "ClassV2 must have a state");

    const sanitizeClass = {
      createdAt: classV2.createdAt,
      deletedAt: classV2.deletedAt,
      courseId: classV2.course ? classV2.course.id : null,
      description: classV2.description,
      edlinkId: classV2.edlinkId,
      gradeLevels: classV2.gradeLevels || [],
      id: classV2.id,
      identifiers: classV2.identifiers,
      locale: classV2.locale,
      name: classV2.name,
      properties: classV2.properties,
      schoolId: classV2.school.id,
      sectionIds: classV2.sections.map((section) => section.id),
      sessionIds: classV2.sessions.map((session) => session.id),
      state: classV2.state,
      subjectCodes: classV2.subjectCodes || [],
      timezone: classV2.timezone,
      updatedAt: classV2.updatedAt,
      urlId: classV2.urlId,
    };

    return this.add(sanitizeClass);
  }

  getClassesForSchool = (schoolId: string): ClassV2[] => {
    return this.sortedData.filter(
      (classV2) => classV2.schoolId === schoolId && !classV2.deletedAt
    );
  };

  getClassesForSession = (sessionId: string): ClassV2[] => {
    return this.sortedData.filter(
      (classV2) => classV2.sessionIds.includes(sessionId) && !classV2.deletedAt
    );
  };

  getClassesForCourse = (courseId: string): ClassV2[] => {
    return this.sortedData.filter(
      (classV2) => classV2.courseId === courseId && !classV2.deletedAt
    );
  };

  getByUrlParam = (urlId: string): ClassV2 | undefined => {
    return this.sortedData.find((classV2) => classV2.urlId === urlId);
  };

  @computed
  get getSections(): SectionV2[] {
    const { sections, ui } = this.rootStore;

    if (!ui.activeClassId) return [];

    return sections.getSectionsForClass(ui.activeClassId);
  }
}
