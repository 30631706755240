import {
  Eventcalendar,
  MbscSelectedDateChangeEvent,
  momentTimezone,
  setOptions,
} from "@mobiscroll/react";
import { EventcalendarBase } from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { useCallback, useMemo, useState } from "react";
import { colorsMap400 } from "../../../colors-list";
import {
  EnumReinitializeRotationDayChange,
  ReinitializeRotationChange,
} from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";

momentTimezone.moment = moment;

type ReinitializeRotationDaysPreviewProps = {
  firstDate: string;
  lastDate: string;
  currentDate: string;
  changes: ReinitializeRotationChange[];
  onCancel: () => void;
  onSubmit: () => void;
};

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const getDatetimeInLocalTimezone = (datetime: Date): string | null => {
  // Step 1: Convert the user-selected date to DateTime object in calendar timezone
  let timezoneTime = DateTime.fromObject(
    {
      year: datetime.getFullYear(),
      month: datetime.getMonth() + 1, // JS month is 0 indexed, but DateTime's is not.
      day: datetime.getDate(), // Get the day of the month, not the day of the week.
      hour: datetime.getHours(),
      minute: datetime.getMinutes(),
    },
    {
      zone: "utc",
    }
  );

  // Step 2: Convert the calendarTimezone DateTime to UTC format for storage
  const utcTime = timezoneTime.toUTC();

  // Return the formatted UTC time as a string
  return utcTime.toISO();
};

export default function ReinitializeRotationDaysPreview({
  firstDate,
  lastDate,
  changes,
  currentDate,
  onCancel,
  onSubmit,
}: ReinitializeRotationDaysPreviewProps) {
  console.log("firstDate", firstDate);
  console.log("lastDate", lastDate);
  console.log("changes", changes);
  console.log("currentDate", currentDate);

  //
  const eventsToDisplay = useMemo(() => {
    const events = changes.map((change) => {
      let color = "#000000";

      if (change.change === EnumReinitializeRotationDayChange.Added) {
        color = colorsMap400["green"];
      } else if (change.change === EnumReinitializeRotationDayChange.Removed) {
        color = colorsMap400["red"];
      } else if (change.change === EnumReinitializeRotationDayChange.Shifted) {
        color = colorsMap400["yellow"];
      } else if (
        change.change === EnumReinitializeRotationDayChange.Unchanged
      ) {
        color = colorsMap400["blue"];
      }

      let description = "";

      if (change.change === EnumReinitializeRotationDayChange.Added) {
        description = "Added";
      } else if (change.change === EnumReinitializeRotationDayChange.Removed) {
        description = "Removed";
      } else if (change.change === EnumReinitializeRotationDayChange.Shifted) {
        description =
          "Shifted " +
          change.shiftAmount +
          " days. (Earlier on " +
          DateTime.fromISO(change.beforeDate, {
            zone: "utc",
          }).toFormat("LLL dd, yy") +
          ")";
      } else if (
        change.change === EnumReinitializeRotationDayChange.Unchanged
      ) {
        description = "Unchanged";
      }

      return {
        start: change.date,
        end: change.date,
        title: change.rotationDay.abbreviation,
        description,
        allDay: true,
        color,
      };
    });

    return events;
  }, [changes, currentDate]);

  const [selectedDate, setCurrentDate] = useState<string>(currentDate);

  const onSelectedDateChange = (
    event: MbscSelectedDateChangeEvent,
    inst: EventcalendarBase
  ) => {
    setCurrentDate(event.date as string);
  };

  const renderEventContent = useCallback((data: any) => {
    console.log("data", data);

    return (
      <div className="flex flex-col">
        <div className="text-md flex w-full font-semibold">
          {data.original.title}
        </div>
        <div className="text-sm font-medium">{data.original.description}</div>
      </div>
    );
  }, []);

  //   const renderScheduleEvent = useCallback((data: any) => {
  //     const backgroundColor = colorsMap200[data.color];
  //     const textColor = colorsMap600[data.color];

  //     if (data.allDay) {
  //       return null;
  //     } else {
  //       return (
  //         <div
  //           className={clsx(
  //             "md-custom-event-cont group m-1",
  //             "md-custom-event-cont",
  //             "cursor-pointer"
  //           )}
  //           style={{
  //             backgroundColor,
  //           }}
  //           // onClick={() => {
  //           // }}
  //         >
  //           <div className={clsx("md-custom-event-wrapper")}>
  //             <div className="md-custom-event-details h-[90%]">
  //               <div className="flex justify-between">
  //                 <div>
  //                   <div
  //                     className={clsx("pt-1", "font-medium")}
  //                     style={{
  //                       color: textColor,
  //                       overflow: "hidden",
  //                       textOverflow: "ellipsis",
  //                       display: "-webkit-box",
  //                       WebkitLineClamp: 1,
  //                       WebkitBoxOrient: "vertical",
  //                     }}
  //                   >
  //                     {data.title}
  //                   </div>
  //                   <div
  //                     className="font-medium"
  //                     style={{
  //                       color: textColor,
  //                       overflow: "hidden",
  //                       textOverflow: "ellipsis",
  //                       display: "-webkit-box",
  //                       WebkitLineClamp: 1,
  //                       WebkitBoxOrient: "vertical",
  //                     }}
  //                   >
  //                     {data.start}
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     }
  //   }, []);

  return (
    <div className="flex h-full w-full flex-col">
      {/* Title */}
      <div className="flex w-full justify-center py-8">
        <div className="text-xl font-semibold">
          Preview Rotation Day Changes
        </div>
      </div>

      {/* Calendar */}
      <div className="flex w-full flex-1">
        <Eventcalendar
          width={"100%"}
          render={true}
          onSelectDateChange={onSelectedDateChange}
          selectedDate={selectedDate}
          view={{
            calendar: {
              labels: true,
              type: "month",
              popover: true,
            },
          }}
          data={eventsToDisplay}
          min={firstDate}
          max={lastDate}
          renderEventContent={renderEventContent}
          dataTimezone={"utc"}
          timezonePlugin={momentTimezone}
        />
      </div>

      {/* Submit buttons */}
      <div className="flex items-center">
        <div className="flex w-full justify-center py-8">
          <div className="flex w-full justify-end px-8">
            <ButtonLarge
              buttonText="Cancel"
              theme="secondary"
              type="button"
              onClick={() => {
                onCancel();
              }}
              className="mr-4"
              rounded={"medium"}
            />

            <ButtonLarge
              buttonText="Submit"
              theme="primary"
              type="button"
              onClick={() => {
                onSubmit();
              }}
              rounded={"medium"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
