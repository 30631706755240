import { useLocation, useNavigate } from "react-router-dom";
import { useModalContext } from "../contexts/modals";
import { useToastsContext } from "../contexts/toasts";
import { DropdownActionContext } from "../types";
import useStores from "./useStores";

export default function useDropdownContext(
  overrides?: Partial<DropdownActionContext>
): DropdownActionContext {
  const stores = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const modalContext = useModalContext();
  const toastsContext = useToastsContext();

  return {
    menuType: "dropdown",
    userId: stores.auth.user?.id,
    activeSessionId: stores.ui.activeSessionId,
    activeDistrictId: stores.ui.activeDistrictId,
    activeSchoolId: stores.ui.activeSchoolId,
    activeClassId: stores.ui.activeClassId,
    activeSectionId: stores.ui.activeSectionId,
    navigate,
    location,
    stores,
    modalContext,
    toastsContext,
    ...overrides,
  };
}
