import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateDepartment,
  UpdateDepartment,
} from "../graphql/departments/departments.mutations";
import { GetDepartments } from "../graphql/departments/departments.queries";
import Department from "../models/Department";
import {
  DepartmentCreateInput,
  DepartmentUpdateInput,
  DepartmentWhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class DepartmentsStore extends BaseStore<Department> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Department, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchDistrictDepartments = (districtId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetDepartments,
          variables: {
            where: {
              district: {
                id: districtId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const departments = res.data.departments;

          if (!departments) {
            reject(false);
          }

          runInAction("Populate departments", () => {
            departments.forEach((department) => {
              console.log(department);

              invariant(department.district, "Department must have a district");

              const sanitizeDepartment = {
                createdAt: department.createdAt,
                deletedAt: department.deletedAt,
                description: department.description,
                districtId: department.district.id,
                edlinkId: department.edlinkId,
                id: department.id,
                identifiers: department.identifiers,
                name: department.name,
                properties: department.properties,
                schoolIds: department.schools.map((school) => school.id),
                subjectIds: department.subjects.map((subject) => subject.id),
                updatedAt: department.updatedAt,
                urlId: department.urlId,
                users: department.users.map((user) => user.id),
              };

              this.add(sanitizeDepartment);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<Department>): Promise<Department> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as DepartmentCreateInput);
      } else {
        return this.update(
          rest as DepartmentUpdateInput,
          { id } as DepartmentWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: DepartmentCreateInput): Promise<Department> {
    const res = await this.apolloClient.mutate({
      mutation: CreateDepartment,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createDepartment) {
      throw Error("Failed to create department.");
    }

    const department = res.data.createDepartment;

    invariant(department.district, "Department must have a district");

    const sanitizeDepartment = {
      createdAt: department.createdAt,
      deletedAt: department.deletedAt,
      description: department.description,
      districtId: department.district.id,
      edlinkId: department.edlinkId,
      id: department.id,
      identifiers: department.identifiers,
      name: department.name,
      properties: department.properties,
      schoolIds: department.schools.map((school) => school.id),
      subjectIds: department.subjects.map((subject) => subject.id),
      updatedAt: department.updatedAt,
      urlId: department.urlId,
      users: department.users.map((user) => user.id),
    };

    return this.add(sanitizeDepartment);
  }

  async update(
    data: DepartmentUpdateInput,
    where: DepartmentWhereUniqueInput
  ): Promise<Department> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateDepartment,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateDepartment) {
      throw Error("Failed to update department.");
    }

    const department = res.data.updateDepartment;

    invariant(department.district, "Department must have a district");

    const sanitizeDepartment = {
      createdAt: department.createdAt,
      deletedAt: department.deletedAt,
      description: department.description,
      districtId: department.district.id,
      edlinkId: department.edlinkId,
      id: department.id,
      identifiers: department.identifiers,
      name: department.name,
      properties: department.properties,
      schoolIds: department.schools.map((school) => school.id),
      subjectIds: department.subjects.map((subject) => subject.id),
      updatedAt: department.updatedAt,
      urlId: department.urlId,
      users: department.users.map((user) => user.id),
    };

    return this.add(sanitizeDepartment);
  }

  getDepartmentsForDistrict = (districtId: string): Department[] => {
    return this.sortedData.filter(
      (school) => school.districtId === districtId && !school.deletedAt
    );
  };
}
