import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateAlternateSchedule,
  UpdateAlternateSchedule,
} from "../graphql/scheduling/scheduling.mutations";
import { GetAlternateSchedules } from "../graphql/scheduling/scheduling.queries";
import AlternateSchedule from "../models/AlternateSchedule";
import {
  AlternateScheduleCreateInput,
  AlternateScheduleUpdateInput,
  AlternateScheduleWhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AlternateScheduleStore extends BaseStore<AlternateSchedule> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, AlternateSchedule, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchRotationAlternateSchedules = (rotationScheduleId: string) => {
    this.isLoading = true;

    const { fetchAlternateDayBlockSchedule } = this.rootStore.blockSchedules;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetAlternateSchedules,
          variables: {
            where: {
              rotationSchedule: {
                id: rotationScheduleId,
              },
            },
            orderBy: {
              name: SortOrder.Asc,
            },
          },
        })
        .then((res) => {
          const alternateSchedules = res.data.alternateSchedules;

          if (!alternateSchedules) {
            reject(false);
          }

          runInAction("Populate alternate schedules", () => {
            alternateSchedules.forEach((alternateSchedule) => {
              console.log(alternateSchedule);

              invariant(
                alternateSchedule.rotationSchedule,
                "Alternate schedule must have a rotation schedule"
              );

              fetchAlternateDayBlockSchedule(alternateSchedule.id);

              const sanitizeAlternateSchedule = {
                createdAt: alternateSchedule.createdAt,
                deletedAt: alternateSchedule.deletedAt,
                id: alternateSchedule.id,
                name: alternateSchedule.name,
                rotationScheduleId: alternateSchedule.rotationSchedule.id,
                updatedAt: alternateSchedule.updatedAt,
              };

              this.add(sanitizeAlternateSchedule);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  // Save

  @action
  save = async (
    args: Partial<AlternateSchedule>
  ): Promise<AlternateSchedule> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as AlternateScheduleCreateInput);
      } else {
        return this.update(
          rest as AlternateScheduleUpdateInput,
          { id } as AlternateScheduleWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: AlternateScheduleCreateInput): Promise<AlternateSchedule> {
    const res = await this.apolloClient.mutate({
      mutation: CreateAlternateSchedule,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createAlternateSchedule) {
      throw Error("Failed to create alternate day.");
    }

    const alternateSchedule = res.data.createAlternateSchedule;

    invariant(
      alternateSchedule.rotationSchedule,
      "Alternate schedule must have a rotation schedule"
    );

    const sanitizeAlternateSchedule = {
      createdAt: alternateSchedule.createdAt,
      deletedAt: alternateSchedule.deletedAt,
      id: alternateSchedule.id,
      name: alternateSchedule.name,
      rotationScheduleId: alternateSchedule.rotationSchedule.id,
      updatedAt: alternateSchedule.updatedAt,
    };

    return this.add(sanitizeAlternateSchedule);
  }

  async update(
    data: AlternateScheduleUpdateInput,
    where: AlternateScheduleWhereUniqueInput
  ): Promise<AlternateSchedule> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateAlternateSchedule,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateAlternateSchedule) {
      throw Error("Failed to update alternate schedule.");
    }

    const alternateSchedule = res.data.updateAlternateSchedule;

    invariant(
      alternateSchedule.rotationSchedule,
      "Alternate schedule must have a rotation schedule"
    );

    const sanitizeAlternateSchedule = {
      createdAt: alternateSchedule.createdAt,
      deletedAt: alternateSchedule.deletedAt,
      id: alternateSchedule.id,
      name: alternateSchedule.name,
      rotationScheduleId: alternateSchedule.rotationSchedule.id,
      updatedAt: alternateSchedule.updatedAt,
    };

    return this.add(sanitizeAlternateSchedule);
  }

  getAlternateDaysForRotationSchedule = (
    rotationScheduleId: string
  ): AlternateSchedule[] => {
    console.log(
      "get Alternate Schedule for Rotation schedule Id",
      rotationScheduleId
    );

    return this.sortedData.filter(
      (schedule) => schedule.rotationScheduleId === rotationScheduleId
    );
  };
}
