import { gql } from "../../__generated__/gql";

export const GetClassV2 = gql(`
    query GetClassV2($where: ClassV2WhereUniqueInput!) {
        classV2(where: $where) {
            createdAt
            deletedAt
            course {
                id
            }
            description
            edlinkId
            gradeLevels
            id
            identifiers
            locale
            name
            properties
            school {
                id
            }
            sections {
                id
            }
            sessions {
                id
            }
            state 
            subjectCodes
            timezone
            updatedAt
            urlId
        }
    }
`);

export const GetClassV2s = gql(`
    query GetClassV2s($where: ClassV2WhereInput!, $orderBy: [ClassV2OrderByInput!]) {
        classV2s(where: $where, orderBy: $orderBy) {
            createdAt
            deletedAt
            course {
                id
            }
            description
            edlinkId
            gradeLevels
            id
            identifiers
            locale
            name
            properties
            school {
                id
            }
            sections {
                id
            }
            sessions {
                id
            }
            state 
            subjectCodes
            timezone
            updatedAt
            urlId
        }
    }
`);
