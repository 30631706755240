import { computed, observable } from "mobx";
import DeletableModel from "./DeletableModel";

class Department extends DeletableModel {
  @observable
  id: string;

  @observable
  description: string | null;

  @observable
  districtId: string;

  @observable
  edlinkId: string | null;

  @observable
  identifiers: any | null;

  @observable
  name: string;

  @observable
  properties: any | null;

  @observable
  schoolIds: string[];

  @observable
  subjectIds: string[];

  @observable
  urlId: string | null;

  @observable
  users: string[];

  @computed
  get schoolNames(): string {
    const { schools } = this.store.rootStore;

    if (this.schoolIds.length === 0) return "-";

    return this.schoolIds
      .map((schoolId) => {
        const school = schools.get(schoolId);

        return school ? school.name : "";
      })
      .join(", ");
  }

  @computed
  get subjectNames(): string {
    const { subjects } = this.store.rootStore;

    if (this.subjectIds.length === 0) return "-";

    return this.subjectIds
      .map((subjectId) => {
        const subject = subjects.get(subjectId);

        return subject ? subject.name : "";
      })
      .join(", ");
  }
}

export default Department;
