import {
  EnumClassV2GradeLevels,
  EnumCourseV2GradeLevels,
  EnumRotationScheduleGradeLevels,
  EnumSchoolGradeLevels,
  EnumUserDistrictRoles,
  EnumUserGradeLevels,
} from "../__generated__/graphql";

// SCHOOL GRADE LEVELS

export const schoolGradeLevelsToStringMap: {
  [key in EnumSchoolGradeLevels]: string;
} = {
  [EnumSchoolGradeLevels.Birth]: "Birth",
  [EnumSchoolGradeLevels.Prenatal]: "Prenatal",
  [EnumSchoolGradeLevels.It]: "Infant",
  [EnumSchoolGradeLevels.Pr]: "Preschool",
  [EnumSchoolGradeLevels.Pk]: "Prekindergarten",
  [EnumSchoolGradeLevels.Tk]: "Transitional Kindergarten",
  [EnumSchoolGradeLevels.Kg]: "Kindergarten",
  [EnumSchoolGradeLevels.G1]: "1",
  [EnumSchoolGradeLevels.G2]: "2",
  [EnumSchoolGradeLevels.G3]: "3",
  [EnumSchoolGradeLevels.G4]: "4",
  [EnumSchoolGradeLevels.G5]: "5",
  [EnumSchoolGradeLevels.G6]: "6",
  [EnumSchoolGradeLevels.G7]: "7",
  [EnumSchoolGradeLevels.G8]: "8",
  [EnumSchoolGradeLevels.G9]: "9",
  [EnumSchoolGradeLevels.G10]: "10",
  [EnumSchoolGradeLevels.G11]: "11",
  [EnumSchoolGradeLevels.G12]: "12",
  [EnumSchoolGradeLevels.G13]: "13",
  [EnumSchoolGradeLevels.Ps]: "Postsecondary",
  [EnumSchoolGradeLevels.Al]: "Adult Education",
  [EnumSchoolGradeLevels.Ug]: "Ungraded",
  [EnumSchoolGradeLevels.Cl]: "Cl",
  [EnumSchoolGradeLevels.Other]: "Other",
};

// Create a reverse mapping of the above object
export const schoolGradeLevelsToEnumMap: {
  [key: string]: EnumSchoolGradeLevels;
} = Object.keys(schoolGradeLevelsToStringMap).reduce((acc, key) => {
  acc[schoolGradeLevelsToStringMap[key as EnumSchoolGradeLevels]] =
    key as EnumSchoolGradeLevels;
  return acc;
}, {} as { [key: string]: EnumSchoolGradeLevels });

export function schoolGradesEnumArrayToStringArray(
  enumArray: EnumSchoolGradeLevels[]
): string[] {
  return enumArray.map((enumValue) => schoolGradeLevelsToStringMap[enumValue]);
}

export function schoolGradesEnumArrayToDropdownValues(
  enumArray: EnumSchoolGradeLevels[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: schoolGradeLevelsToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function schoolGradesStringArrayToEnumArray(
  stringArray: string[]
): EnumSchoolGradeLevels[] {
  return stringArray.map(
    (stringValue) => schoolGradeLevelsToEnumMap[stringValue]
  );
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function schoolGradeLevelDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(schoolGradeLevelsToStringMap).map((key) => {
    return {
      label: schoolGradeLevelsToStringMap[key as EnumSchoolGradeLevels],
      value: key,
    };
  });
}

// COURSE V2 GRADE LEVELS

export const courseGradeLevelsToStringMap: {
  [key in EnumSchoolGradeLevels]: string;
} = {
  [EnumSchoolGradeLevels.Birth]: "Birth",
  [EnumSchoolGradeLevels.Prenatal]: "Prenatal",
  [EnumSchoolGradeLevels.It]: "Infant",
  [EnumSchoolGradeLevels.Pr]: "Preschool",
  [EnumSchoolGradeLevels.Pk]: "Prekindergarten",
  [EnumSchoolGradeLevels.Tk]: "Transitional Kindergarten",
  [EnumSchoolGradeLevels.Kg]: "Kindergarten",
  [EnumSchoolGradeLevels.G1]: "1",
  [EnumSchoolGradeLevels.G2]: "2",
  [EnumSchoolGradeLevels.G3]: "3",
  [EnumSchoolGradeLevels.G4]: "4",
  [EnumSchoolGradeLevels.G5]: "5",
  [EnumSchoolGradeLevels.G6]: "6",
  [EnumSchoolGradeLevels.G7]: "7",
  [EnumSchoolGradeLevels.G8]: "8",
  [EnumSchoolGradeLevels.G9]: "9",
  [EnumSchoolGradeLevels.G10]: "10",
  [EnumSchoolGradeLevels.G11]: "11",
  [EnumSchoolGradeLevels.G12]: "12",
  [EnumSchoolGradeLevels.G13]: "13",
  [EnumSchoolGradeLevels.Ps]: "Postsecondary",
  [EnumSchoolGradeLevels.Al]: "Adult Education",
  [EnumSchoolGradeLevels.Ug]: "Ungraded",
  [EnumSchoolGradeLevels.Cl]: "Cl",
  [EnumSchoolGradeLevels.Other]: "Other",
};

// Create a reverse mapping of the above object
export const courseGradeLevelsToEnumMap: {
  [key: string]: EnumCourseV2GradeLevels;
} = Object.keys(courseGradeLevelsToStringMap).reduce((acc, key) => {
  acc[courseGradeLevelsToStringMap[key as EnumCourseV2GradeLevels]] =
    key as EnumCourseV2GradeLevels;
  return acc;
}, {} as { [key: string]: EnumCourseV2GradeLevels });

export function courseGradesEnumArrayToStringArray(
  enumArray: EnumCourseV2GradeLevels[]
): string[] {
  return enumArray.map((enumValue) => courseGradeLevelsToStringMap[enumValue]);
}

export function courseGradesEnumArrayToDropdownValues(
  enumArray: EnumCourseV2GradeLevels[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: courseGradeLevelsToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function courseGradesStringArrayToEnumArray(
  stringArray: string[]
): EnumCourseV2GradeLevels[] {
  return stringArray.map(
    (stringValue) => courseGradeLevelsToEnumMap[stringValue]
  );
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function courseGradeLevelDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(courseGradeLevelsToStringMap).map((key) => {
    return {
      label: courseGradeLevelsToStringMap[key as EnumCourseV2GradeLevels],
      value: key,
    };
  });
}

// CLASS V2 GRADE LEVELS

export const classGradeLevelsToStringMap: {
  [key in EnumClassV2GradeLevels]: string;
} = {
  [EnumClassV2GradeLevels.Birth]: "Birth",
  [EnumClassV2GradeLevels.Prenatal]: "Prenatal",
  [EnumClassV2GradeLevels.It]: "Infant",
  [EnumClassV2GradeLevels.Pr]: "Preschool",
  [EnumClassV2GradeLevels.Pk]: "Prekindergarten",
  [EnumClassV2GradeLevels.Tk]: "Transitional Kindergarten",
  [EnumClassV2GradeLevels.Kg]: "Kindergarten",
  [EnumClassV2GradeLevels.G1]: "1",
  [EnumClassV2GradeLevels.G2]: "2",
  [EnumClassV2GradeLevels.G3]: "3",
  [EnumClassV2GradeLevels.G4]: "4",
  [EnumClassV2GradeLevels.G5]: "5",
  [EnumClassV2GradeLevels.G6]: "6",
  [EnumClassV2GradeLevels.G7]: "7",
  [EnumClassV2GradeLevels.G8]: "8",
  [EnumClassV2GradeLevels.G9]: "9",
  [EnumClassV2GradeLevels.G10]: "10",
  [EnumClassV2GradeLevels.G11]: "11",
  [EnumClassV2GradeLevels.G12]: "12",
  [EnumClassV2GradeLevels.G13]: "13",
  [EnumClassV2GradeLevels.Ps]: "Postsecondary",
  [EnumClassV2GradeLevels.Al]: "Adult Education",
  [EnumClassV2GradeLevels.Ug]: "Ungraded",
  [EnumClassV2GradeLevels.Cl]: "Cl",
  [EnumClassV2GradeLevels.Other]: "Other",
};

// Create a reverse mapping of the above object
export const classGradeLevelsToEnumMap: {
  [key: string]: EnumClassV2GradeLevels;
} = Object.keys(classGradeLevelsToStringMap).reduce((acc, key) => {
  acc[classGradeLevelsToStringMap[key as EnumClassV2GradeLevels]] =
    key as EnumClassV2GradeLevels;
  return acc;
}, {} as { [key: string]: EnumClassV2GradeLevels });

export function classGradesEnumArrayToStringArray(
  enumArray: EnumClassV2GradeLevels[]
): string[] {
  return enumArray.map((enumValue) => classGradeLevelsToStringMap[enumValue]);
}

export function classGradesEnumArrayToDropdownValues(
  enumArray: EnumClassV2GradeLevels[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: classGradeLevelsToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function classGradesStringArrayToEnumArray(
  stringArray: string[]
): EnumClassV2GradeLevels[] {
  return stringArray.map(
    (stringValue) => classGradeLevelsToEnumMap[stringValue]
  );
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function classGradeLevelDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(classGradeLevelsToStringMap).map((key) => {
    return {
      label: classGradeLevelsToStringMap[key as EnumClassV2GradeLevels],
      value: key,
    };
  });
}

// USER GRADE LEVELS

export const userGradeLevelsToStringMap: {
  [key in EnumUserGradeLevels]: string;
} = {
  [EnumUserGradeLevels.Birth]: "Birth",
  [EnumUserGradeLevels.Prenatal]: "Prenatal",
  [EnumUserGradeLevels.It]: "Infant",
  [EnumUserGradeLevels.Pr]: "Preschool",
  [EnumUserGradeLevels.Pk]: "Prekindergarten",
  [EnumUserGradeLevels.Tk]: "Transitional Kindergarten",
  [EnumUserGradeLevels.Kg]: "Kindergarten",
  [EnumUserGradeLevels.G1]: "1",
  [EnumUserGradeLevels.G2]: "2",
  [EnumUserGradeLevels.G3]: "3",
  [EnumUserGradeLevels.G4]: "4",
  [EnumUserGradeLevels.G5]: "5",
  [EnumUserGradeLevels.G6]: "6",
  [EnumUserGradeLevels.G7]: "7",
  [EnumUserGradeLevels.G8]: "8",
  [EnumUserGradeLevels.G9]: "9",
  [EnumUserGradeLevels.G10]: "10",
  [EnumUserGradeLevels.G11]: "11",
  [EnumUserGradeLevels.G12]: "12",
  [EnumUserGradeLevels.G13]: "13",
  [EnumUserGradeLevels.Ps]: "Postsecondary",
  [EnumUserGradeLevels.Al]: "Adult Education",
  [EnumUserGradeLevels.Ug]: "Ungraded",
  [EnumUserGradeLevels.Cl]: "Cl",
  [EnumUserGradeLevels.Other]: "Other",
};

// Create a reverse mapping of the above object
export const userGradeLevelsToEnumMap: {
  [key: string]: EnumUserGradeLevels;
} = Object.keys(userGradeLevelsToStringMap).reduce((acc, key) => {
  acc[userGradeLevelsToStringMap[key as EnumUserGradeLevels]] =
    key as EnumUserGradeLevels;
  return acc;
}, {} as { [key: string]: EnumUserGradeLevels });

export function userGradesEnumArrayToStringArray(
  enumArray: EnumUserGradeLevels[]
): string[] {
  return enumArray.map((enumValue) => userGradeLevelsToStringMap[enumValue]);
}

export const userRolestoEnumMap: {
  [key: string]: EnumUserDistrictRoles;
} = {
  Administrator: EnumUserDistrictRoles.Administrator,
  Aide: EnumUserDistrictRoles.Aide,
  Designer: EnumUserDistrictRoles.Designer,
  DistrictAdministrator: EnumUserDistrictRoles.DistrictAdministrator,
  Guardian: EnumUserDistrictRoles.Guardian,
  Member: EnumUserDistrictRoles.Member,
  Parent: EnumUserDistrictRoles.Parent,
  Staff: EnumUserDistrictRoles.Staff,
  Student: EnumUserDistrictRoles.Student,
  Ta: EnumUserDistrictRoles.Ta,
  Teacher: EnumUserDistrictRoles.Teacher,
};

export const userRolesToStringMap: {
  [key in EnumUserDistrictRoles]: string;
} = {
  [EnumUserDistrictRoles.Administrator]: "Administrator",
  [EnumUserDistrictRoles.Aide]: "Aide",
  [EnumUserDistrictRoles.Designer]: "Designer",
  [EnumUserDistrictRoles.DistrictAdministrator]: "District Administrator",
  [EnumUserDistrictRoles.Guardian]: "Guardian",
  [EnumUserDistrictRoles.Member]: "Member",
  [EnumUserDistrictRoles.Parent]: "Parent",
  [EnumUserDistrictRoles.Staff]: "Staff",
  [EnumUserDistrictRoles.Student]: "Student",
  [EnumUserDistrictRoles.Ta]: "TA",
  [EnumUserDistrictRoles.Teacher]: "Teacher",
};

export function userGradesEnumArrayToDropdownValues(
  enumArray: EnumUserGradeLevels[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: userGradeLevelsToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function userGradesStringArrayToEnumArray(
  stringArray: string[]
): EnumUserGradeLevels[] {
  return stringArray.map(
    (stringValue) => userGradeLevelsToEnumMap[stringValue]
  );
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function userGradeLevelDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(userGradeLevelsToStringMap).map((key) => {
    return {
      label: userGradeLevelsToStringMap[key as EnumUserGradeLevels],
      value: key,
    };
  });
}

// above three functions but for user roles
export function userRolesEnumArrayToStringArray(
  enumArray: EnumUserDistrictRoles[]
): string[] {
  return enumArray.map((enumValue) => userRolesToStringMap[enumValue]);
}

export function userRolesEnumArrayToDropdownValues(
  enumArray: EnumUserDistrictRoles[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: userRolesToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function userRolesStringArrayToEnumArray(
  stringArray: string[]
): EnumUserDistrictRoles[] {
  return stringArray.map((stringValue) => userRolestoEnumMap[stringValue]);
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function userRoleDropdownOptions() {
  return Object.keys(userRolesToStringMap).map((key) => {
    return {
      label: userRolesToStringMap[key as EnumUserDistrictRoles],
      value: key,
    };
  });
}

// ROTATION SCHEDULE GRADE LEVELS

export const rotationScheduleGradeLevelsToStringMap: {
  [key in EnumRotationScheduleGradeLevels]: string;
} = {
  [EnumRotationScheduleGradeLevels.Birth]: "Birth",
  [EnumRotationScheduleGradeLevels.Prenatal]: "Prenatal",
  [EnumRotationScheduleGradeLevels.It]: "Infant",
  [EnumRotationScheduleGradeLevels.Pr]: "Preschool",
  [EnumRotationScheduleGradeLevels.Pk]: "Prekindergarten",
  [EnumRotationScheduleGradeLevels.Tk]: "Transitional Kindergarten",
  [EnumRotationScheduleGradeLevels.Kg]: "Kindergarten",
  [EnumRotationScheduleGradeLevels.G1]: "1",
  [EnumRotationScheduleGradeLevels.G2]: "2",
  [EnumRotationScheduleGradeLevels.G3]: "3",
  [EnumRotationScheduleGradeLevels.G4]: "4",
  [EnumRotationScheduleGradeLevels.G5]: "5",
  [EnumRotationScheduleGradeLevels.G6]: "6",
  [EnumRotationScheduleGradeLevels.G7]: "7",
  [EnumRotationScheduleGradeLevels.G8]: "8",
  [EnumRotationScheduleGradeLevels.G9]: "9",
  [EnumRotationScheduleGradeLevels.G10]: "10",
  [EnumRotationScheduleGradeLevels.G11]: "11",
  [EnumRotationScheduleGradeLevels.G12]: "12",
  [EnumRotationScheduleGradeLevels.G13]: "13",
  [EnumRotationScheduleGradeLevels.Ps]: "Postsecondary",
  [EnumRotationScheduleGradeLevels.Al]: "Adult Education",
  [EnumRotationScheduleGradeLevels.Ug]: "Ungraded",
  [EnumRotationScheduleGradeLevels.Cl]: "Cl",
  [EnumRotationScheduleGradeLevels.Other]: "Other",
};

// Create a reverse mapping of the above object
export const rotationScheduleGradeLevelsToEnumMap: {
  [key: string]: EnumRotationScheduleGradeLevels;
} = Object.keys(rotationScheduleGradeLevelsToStringMap).reduce((acc, key) => {
  acc[
    rotationScheduleGradeLevelsToStringMap[
      key as EnumRotationScheduleGradeLevels
    ]
  ] = key as EnumRotationScheduleGradeLevels;
  return acc;
}, {} as { [key: string]: EnumRotationScheduleGradeLevels });

export function rotationScheduleGradesEnumArrayToStringArray(
  enumArray: EnumRotationScheduleGradeLevels[]
): string[] {
  return enumArray.map(
    (enumValue) => rotationScheduleGradeLevelsToStringMap[enumValue]
  );
}

export function rotationScheduleGradesEnumArrayToDropdownValues(
  enumArray: EnumRotationScheduleGradeLevels[]
): { label: string; value: string }[] {
  return enumArray.map((enumValue) => {
    return {
      label: rotationScheduleGradeLevelsToStringMap[enumValue],
      value: enumValue,
    };
  });
}

export function rotationScheduleGradesStringArrayToEnumArray(
  stringArray: string[]
): EnumRotationScheduleGradeLevels[] {
  return stringArray.map(
    (stringValue) => rotationScheduleGradeLevelsToEnumMap[stringValue]
  );
}

// Return the options for a dropdown menu - each option should include 'text' and 'value' properties
export function rotationScheduleGradeLevelDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(rotationScheduleGradeLevelsToStringMap).map((key) => {
    return {
      label:
        rotationScheduleGradeLevelsToStringMap[
          key as EnumRotationScheduleGradeLevels
        ],
      value: key,
    };
  });
}
