import { useEffect, useRef } from "react";

const useOnUnmount = (onUnmountFn: (...params: Array<any>) => any) => {
  const callbackRef = useRef(onUnmountFn);
  callbackRef.current = onUnmountFn;

  useEffect(() => {
    return () => {
      callbackRef.current();
    };
  }, []);
};

export default useOnUnmount;
