import { gql } from "../../__generated__/gql";

export const CreateDistrict = gql(`
    mutation createDistrictWithDrive($data: DistrictCreateInput!) {
        createDistrictWithDrive(data: $data) {
            address
            createdAt
            deletedAt
            id
            identifiers
            edlinkId
            edlinkIntegrations {
                id
                provider {
                    application
                    name
                }
            }
            id
            identifiers
            locale
            name
            properties
            schools {
                id
            }
            timezone
            updatedAt
            users {
                id
            }
            urlId
        }
    }
`);

export const MakeUserActive = gql(/* Graphql */ `
mutation makeUserActive($makeUserActiveInput: MakeUserActiveInput!) {
  makeUserActive(makeUserActiveInput: $makeUserActiveInput)
}
`);

export const ChangeUserLicense = gql(/* Graphql */ `
mutation changeUserLicense($userLicenseActiveInput: UserLicenseActiveInput!) {
  changeUserLicense(userLicenseActiveInput: $userLicenseActiveInput)
}
`);

export const MakeUsersActiveBulk = gql(/* Graphql */ `
mutation makeUsersActiveBulk($bulkUserActiveInput: BulkUserActiveInput!) {
  makeUsersActiveBulk(bulkUserActiveInput: $bulkUserActiveInput)
}
`);

export const ChangeUserLicensesBulk = gql(/* Graphql */ `
mutation changeUserLicenseBulk($bulkLicenseActiveInput: BulkLicenseActiveInput!) {
  changeUserLicenseBulk(bulkLicenseActiveInput: $bulkLicenseActiveInput)
}
`);

export const UpdateDistrict = gql(`
    mutation updateDistrict($data: DistrictUpdateInput!, $where: DistrictWhereUniqueInput!) {
        updateDistrict(data: $data, where: $where) {
            address
            createdAt
            deletedAt
            id
            identifiers
            edlinkId
            edlinkIntegrations {
                id
                provider {
                    application
                    name
                }
            }
            id
            identifiers
            locale
            name
            properties
            schools {
                id
            }
            timezone
            updatedAt
            users {
                id
            }
            urlId
        }
    }
`);
