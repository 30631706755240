import { PlusIcon } from "@radix-ui/react-icons";
import Tooltip from "../../Tooltip";
import { ActionTypes, Constants } from "../utils";

export default function AddColumnHeader({ getHeaderProps, dataDispatch }) {
  return (
    <div {...getHeaderProps()} className="th noselect d-inline-block">
      <Tooltip content="Add column" dir="top">
        <div
          className="th-content d-flex justify-content-center bg-slate2 hover:bg-slate3 "
          onClick={(e) =>
            dataDispatch({
              type: ActionTypes.ADD_COLUMN_TO_LEFT,
              columnId: Constants.ADD_COLUMN_ID,
              focus: true,
            })
          }
        >
          <PlusIcon className="text-slate11 h-5 w-5" />
        </div>
      </Tooltip>
    </div>
  );
}
