import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateDistrict,
  UpdateDistrict,
} from "../graphql/districts/districts.mutations";
import {
  GetDistrict,
  GetDistricts,
} from "../graphql/districts/districts.queries";
import District, { EdlinkIntegrationType } from "../models/District";
import {
  DistrictCreateInput,
  DistrictUpdateInput,
  DistrictWhereUniqueInput,
  EnumDistrictWorkspaceType,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class DistrictsStore extends BaseStore<District> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, District, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  // READ
  @action
  fetchAllDistricts = () => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetDistricts,
          variables: {
            where: {},
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const districts = res.data.districts;

          if (!districts) {
            reject(false);
          }

          runInAction("Populate districts", () => {
            districts.forEach((district) => {
              console.log(district);

              const sanitizeDistrict = {
                address: district.address,
                createdAt: district.createdAt,
                deletedAt: district.deletedAt,
                edlinkId: district.edlinkId,
                edlinkIntegrations: district.edlinkIntegrations.map(
                  (edlinkIntegration: any) => {
                    return {
                      id: edlinkIntegration.id,
                      providerName: edlinkIntegration.provider
                        ? edlinkIntegration.provider.name
                        : "",
                      providerApplication: edlinkIntegration.provider
                        ? edlinkIntegration.provider.application
                        : "",
                    } as EdlinkIntegrationType;
                  }
                ),
                id: district.id,
                identifiers: district.identifiers,
                name: district.name,
                properties: district.properties,
                schoolIds: district.schools.map((school) => school.id),
                timezone: district.timezone,
                updatedAt: district.updatedAt,
                userIds: district.users.map((user) => user.id),
                urlId: district.urlId,
                users: district.users,
                payments: district.payments,
                drives: district.drives,
                workspaceType:
                  district.workspaceType as EnumDistrictWorkspaceType,
              };

              this.add(sanitizeDistrict);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchDistrict = (districtId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetDistrict,
          variables: {
            where: {
              id: districtId,
            },
          },
        })
        .then((res) => {
          invariant(res.data, "District data is undefined");

          const district = res.data.district;

          invariant(district, "District is undefined");

          if (!district) {
            reject(false);
          }

          const sanitizeDistrict = {
            address: district.address,
            createdAt: district.createdAt,
            deletedAt: district.deletedAt,
            edlinkId: district.edlinkId,
            edlinkIntegrations: district.edlinkIntegrations.map(
              (edlinkIntegration) => {
                return {
                  id: edlinkIntegration.id,
                  providerName: edlinkIntegration.provider
                    ? edlinkIntegration.provider.name
                    : "",
                  providerApplication: edlinkIntegration.provider
                    ? edlinkIntegration.provider.application
                    : "",
                } as EdlinkIntegrationType;
              }
            ),
            id: district.id,
            identifiers: district.identifiers,
            name: district.name,
            properties: district.properties,
            schoolIds: district.schools.map((school) => school.id),
            timezone: district.timezone,
            updatedAt: district.updatedAt,
            userIds: district.users.map((user) => user.id),
            urlId: district.urlId,
            payments: district.payments,
            drives: district.drives,
            workspaceType: district.workspaceType as EnumDistrictWorkspaceType,
          };

          this.add(sanitizeDistrict);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  // CREATE AND EDIT
  // Function to save the course to the database
  @action
  save = async (args: Partial<District>): Promise<District> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as DistrictCreateInput);
      } else {
        return this.update(
          rest as DistrictUpdateInput,
          { id } as DistrictWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: DistrictCreateInput): Promise<District> {
    const res = await this.apolloClient.mutate({
      mutation: CreateDistrict,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createDistrictWithDrive) {
      throw Error("Failed to create district.");
    }

    const district = res.data.createDistrictWithDrive;

    const sanitizeDistrict = {
      address: district.address,
      createdAt: district.createdAt,
      deletedAt: district.deletedAt,
      edlinkId: district.edlinkId,
      edlinkIntegrations: district.edlinkIntegrations.map(
        (edlinkIntegration) => {
          return {
            id: edlinkIntegration.id,
            providerName: edlinkIntegration.provider
              ? edlinkIntegration.provider.name
              : "",
            providerApplication: edlinkIntegration.provider
              ? edlinkIntegration.provider.application
              : "",
          } as EdlinkIntegrationType;
        }
      ),
      id: district.id,
      identifiers: district.identifiers,
      name: district.name,
      properties: district.properties,
      schoolIds: district.schools.map((school) => school.id),
      timezone: district.timezone,
      updatedAt: district.updatedAt,
      userIds: district.users.map((user) => user.id),
      urlId: district.urlId,
    };

    return this.add(sanitizeDistrict);
  }

  async update(
    data: DistrictUpdateInput,
    where: DistrictWhereUniqueInput
  ): Promise<District> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateDistrict,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateDistrict) {
      throw Error("Failed to update course.");
    }

    const district = res.data.updateDistrict;

    const sanitizeDistrict = {
      address: district.address,
      createdAt: district.createdAt,
      deletedAt: district.deletedAt,
      edlinkId: district.edlinkId,
      edlinkIntegrations: district.edlinkIntegrations.map(
        (edlinkIntegration) => {
          return {
            id: edlinkIntegration.id,
            providerName: edlinkIntegration.provider
              ? edlinkIntegration.provider.name
              : "",
            providerApplication: edlinkIntegration.provider
              ? edlinkIntegration.provider.application
              : "",
          } as EdlinkIntegrationType;
        }
      ),
      id: district.id,
      identifiers: district.identifiers,
      name: district.name,
      properties: district.properties,
      schoolIds: district.schools.map((school) => school.id),
      timezone: district.timezone,
      updatedAt: district.updatedAt,
      userIds: district.users.map((user) => user.id),
      urlId: district.urlId,
    };

    return this.add(sanitizeDistrict);
  }

  getByUrlParam = (urlId: string): District | undefined => {
    return this.sortedData.find(
      (district) => urlId.endsWith(district.urlId) && !district.deletedAt
    );
  };
}
