import { gql } from "../../__generated__/gql";

export const GetSession = gql(`
    query GetSession($where: SessionWhereUniqueInput!) {
        session(where: $where) {
            createdAt 
            deletedAt
            district {
                id
            }
            edlinkId
            endDate
            id
            identifiers
            name
            properties
            school {
                id
            }
            sessionType
            state
            startDate 
            updatedAt
            urlId
        }
    }
`);

export const GetSessions = gql(`
    query GetSessions($where: SessionWhereInput!, $orderBy: [SessionOrderByInput!]) {
        sessions(where: $where, orderBy: $orderBy) {
            createdAt 
            deletedAt
            district {
                id
            }
            edlinkId
            endDate
            id
            identifiers
            name
            properties
            school {
                id
            }
            sessionType
            state
            startDate 
            updatedAt
            urlId
        }
    }
`);
