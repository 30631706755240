import { ReactElement, useEffect, useState } from "react";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as ScrollArea from "@radix-ui/react-scroll-area";

import { clsx } from "clsx";

import { gemoji, Gemoji } from "gemoji";

import { TwitterPicker } from "react-color";
import {
  colorsMap200,
  colorsMap300,
  colorsMap300Flipped,
} from "../../colors-list";
import { IconsList, IconType } from "../../icons-list";

import "./Icons.css";

type Props = {
  setIcon: (icon: string | undefined) => void;
  setEmoji: (emoji: string | undefined) => void;
  triggerComponent: ReactElement;
  color: string;
  setColor: (color: string) => void;
  hideColorPicker?: boolean;
  hideIconPicker?: boolean;
};

export default function NewPicker1(props: Props) {
  const {
    setIcon,
    setEmoji,
    color,
    setColor,
    triggerComponent,
    hideColorPicker,
    hideIconPicker,
  } = props;

  // create new set to store unique categories
  const [categories, setCategories] = useState<string[]>([]);
  const [iconCategories, setIconCategories] = useState<string[]>([]);

  useEffect(() => {
    const gemojiCategories = new Set<string>();
    gemoji.forEach((emoji: Gemoji) => {
      // add category to set
      gemojiCategories.add(emoji.category);
    });
    setCategories(Array.from(gemojiCategories));
  }, []);

  useEffect(() => {
    const iconCategories = new Set<string>();
    IconsList.forEach((icon: IconType) => {
      iconCategories.add(icon.group);
    });
    setIconCategories(Array.from(iconCategories));
  }, []);

  const [iconMap, setIconMap] = useState<Map<string, IconType[]>>(new Map());

  // use useEffect to set iconMap state
  useEffect(() => {
    if (iconCategories.length === 0) return;

    // create new map
    const newIconMap = new Map<string, IconType[]>();

    // loop through categories set
    iconCategories.forEach(
      (category: string) => {
        // filter icons list by group and save in new array
        const icons = IconsList.filter((icon: IconType) => {
          return icon.group === category;
        });

        // add category and icons to map
        newIconMap.set(category, icons);
      },
      [iconCategories]
    );

    // set iconMap state
    setIconMap(newIconMap);
  }, [iconCategories]);

  const [currentIconCategory] = useState("education");
  const currentIcons: IconType[] | undefined = iconMap.get(currentIconCategory);
  const iconGroup = currentIcons && (
    <div className="flex flex-wrap px-4">
      {currentIcons.map((icon: IconType) => (
        <DropdownMenu.Item
          className="mr-2 mb-2 h-[24px] w-[24px] "
          key={icon.name}
        >
          <button
            className="flex h-[32px] w-[32px] items-center justify-center rounded-md hover:bg-gray-100"
            onClick={() => {
              setEmoji(undefined);
              setIcon(icon.name);
            }}
          >
            <icon.component className="h-[20px] w-[20px]" />
          </button>
        </DropdownMenu.Item>
      ))}
    </div>
  );

  const colorsToPick = Object.keys(colorsMap300)
    .filter((color: string) => color !== "sky")
    .map((color: string) => colorsMap300[color]);

  const colorsGroup = (
    <TwitterPicker
      color={color}
      onChange={(color) => {
        const colorName = colorsMap300Flipped[color.hex];
        const color200 = colorsMap200[colorName];
        setColor(color200);
      }}
      colors={colorsToPick}
      triangle="hide"
      width="100%"
      className="shadow-none-[!important]"
      styles={{
        default: {
          body: {
            boxShadow: "none",
          },
          card: {
            boxShadow: "none",
          },
          label: {
            boxShadow: "none",
          },
          triangle: {
            boxShadow: "none",
          },
          triangleShadow: {
            boxShadow: "none",
          },
          hash: {
            display: "none",
          },
          input: {
            boxShadow: "none",
          },
          swatch: {
            boxShadow: "none",
          },
          clear: {
            boxShadow: "none",
          },
        },
      }}
    />
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{triggerComponent}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          sideOffset={5}
          className={clsx(
            "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down bg-white",
            "shadow-blackA7 w-48 rounded-lg shadow-[0_2px_10px] md:w-80",
            "z-[500] flex flex-wrap"
          )}
        >
          <ScrollArea.Root
            className={clsx(
              hideIconPicker ? "h-[112px] " : "h-[275px]",
              "w-full overflow-hidden bg-white pt-2"
            )}
          >
            <ScrollArea.Viewport className="h-full w-full rounded-lg">
              {hideColorPicker ? null : colorsGroup}
              {hideIconPicker || hideColorPicker ? null : (
                <hr className="mr-5 ml-5 mb-3" />
              )}
              {hideIconPicker ? null : iconGroup}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              className="bg-blackA6 hover:bg-blackA8 flex touch-none select-none p-0.5 transition-colors duration-[160ms] ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
              orientation="vertical"
            >
              <ScrollArea.Thumb className="bg-slate10 relative flex-1 rounded-[10px] before:absolute before:top-1/2 before:left-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
