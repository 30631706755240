import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { Constants } from "../utils";
import AddColumnHeader from "./AddColumnHeader";
import DataTypeIcon from "./DataTypeIcon";
import EmptyColumnHeader from "./EmptyColumnHeader";
import HeaderMenu from "./HeaderMenu";
import RowNumberHeader from "./RowNumberHeader";

export default function Header({
  column: { id, created, label, dataType, getHeaderProps, getResizerProps },
  setSortBy,
  dataDispatch,
}) {
  const [showHeaderMenu, setShowHeaderMenu] = useState(created || false);
  const [headerMenuAnchorRef, setHeaderMenuAnchorRef] = useState(null);
  const [headerMenuPopperRef, setHeaderMenuPopperRef] = useState(null);
  const headerMenuPopper = usePopper(headerMenuAnchorRef, headerMenuPopperRef, {
    placement: "bottom",
    strategy: "absolute",
  });

  /* when the column is newly created, set it to open */
  useEffect(() => {
    if (created) {
      setShowHeaderMenu(true);
    }
  }, [created]);

  function getHeader() {
    if (id === Constants.ADD_COLUMN_ID) {
      return (
        <AddColumnHeader
          dataDispatch={dataDispatch}
          getHeaderProps={getHeaderProps}
        />
      );
    }

    if (id === Constants.ROW_NUMBER_COLUMN_ID) {
      return <RowNumberHeader getHeaderProps={getHeaderProps} />;
    }

    if (id === Constants.DELETE_ROW_ID) {
      return <EmptyColumnHeader getHeaderProps={getHeaderProps} />;
    }

    return (
      <>
        <div {...getHeaderProps()} className="th noselect d-inline-block">
          <div
            className="th-content text-slate11 bg-slate2 text-[15px]"
            // onClick={() => setShowHeaderMenu(true)}
            ref={setHeaderMenuAnchorRef}
          >
            <span className="mr-2">
              <DataTypeIcon dataType={dataType} />
            </span>
            {label}
          </div>
          <div {...getResizerProps()} className="resizer" />
        </div>
        {showHeaderMenu && (
          <div className="overlay" onClick={() => setShowHeaderMenu(false)} />
        )}
        {showHeaderMenu && (
          <HeaderMenu
            label={label}
            dataType={dataType}
            popper={headerMenuPopper}
            popperRef={setHeaderMenuPopperRef}
            dataDispatch={dataDispatch}
            setSortBy={setSortBy}
            columnId={id}
            setShowHeaderMenu={setShowHeaderMenu}
          />
        )}
      </>
    );
  }

  return getHeader();
}
