import invariant from "invariant";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { useModalContext } from "../../../contexts/modals";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import CalendarDay from "../../../models/CalendarDay";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import { EnumCalendarDayChangeType } from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";
import ReinitializeRotationDaysPreview from "../../Scheduling/ReinitializeRotationDaysPreview";
import Switch from "../../Switch";

type CalendarDayFormState = {
  rotationDay: DropdownOptionType | null;
  alternateSchedule: DropdownOptionType | null;
  reinitializeRotationDays: boolean | null;
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: CalendarDayFormState = {
  rotationDay: null,
  alternateSchedule: null,
  reinitializeRotationDays: null,
};

const CALENDAR_DAY_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "dropdown",
    id: "rotationDay",
    label: "Rotation Day*",
    placeholder: "Select the rotation day for this date",
  },
  {
    fieldKind: "dropdown",
    id: "alternateSchedule",
    label: "Alternate Schedule",
    placeholder: "Select the alternate schedule for this date",
  },
  {
    fieldKind: "switch",
    id: "reinitializeRotationDays",
    label: "Reinitialize Rotation Days from this day forward",
    placeholder:
      "If checked, this will reinitialize the rotation days from this date forward",
  },
];

const formReducer: Reducer<CalendarDayFormState, FormAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type CalendarDayEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  calendarDay: CalendarDay;
  onUpdateAfterArchiving?: () => void;
};

function CalendarDayEditModal({
  onClose,
  onUpdateAfterSaving,
  calendarDay,
}: CalendarDayEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { addModal, closeModalAll } = useModalContext();

  const [rotationDayModified, setRotationDayModified] = useState(false);
  const [alternateScheduleModified, setAlternateScheduleModified] =
    useState(false);

  const { calendarDays, rotationSchedules, rotationDays, alternateSchedules } =
    useStores();

  const { addToast } = useToastsContext();

  const { isSaving, modifyCalendarDay, reinitializeRotationDaysPreview } =
    calendarDays;

  const activeRotationSchedule =
    calendarDay && calendarDay.rotationScheduleId
      ? rotationSchedules.get(calendarDay.rotationScheduleId)
      : null;

  const init = (initialState: CalendarDayFormState) => {
    // TODO: Add logic for initializing state from district Mobx model
    return {
      ...initialState,
      rotationDay: calendarDay.rotationDayId
        ? {
            value: calendarDay.rotationDayId,
            label: calendarDay.rotationDayName,
          }
        : null,
      alternateSchedule: calendarDay.alternateScheduleId
        ? {
            value: calendarDay.alternateScheduleId,
            label: calendarDay.alternateScheduleName,
          }
        : null,
    };
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { alternateSchedule, rotationDay } = state;

    let alternateScheduleModified = false;
    let rotationDayModified = false;

    if (rotationDay && rotationDay.value !== calendarDay.rotationDayId) {
      rotationDayModified = true;
    }

    if (
      alternateSchedule &&
      alternateSchedule.value !== calendarDay.alternateScheduleId
    ) {
      alternateScheduleModified = true;
    } else {
      alternateScheduleModified = false;
    }

    setAlternateScheduleModified(alternateScheduleModified);
    setRotationDayModified(rotationDayModified);

    // We only want to enable the submit button if the rotation day or alternate schedule has been modified but not both
    if (
      (rotationDayModified || alternateScheduleModified) &&
      rotationDayModified !== alternateScheduleModified
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state, calendarDay]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);
      case "switch":
        if (id === "reinitializeRotationDays") {
          // We don't want to show the reinitialize rotation days switch if the rotation day hasn't been modified
          if (!rotationDayModified) {
            return null;
          }

          return (
            <div key={id} style={{ marginBottom: "20px" }}>
              <Switch
                id={id}
                label={field.label}
                onToggle={(value) => {
                  dispatchFormAction({
                    type: "switch",
                    fieldID: id,
                    payload: value,
                  });
                }}
                toggleValue={state[id] as boolean}
              />
            </div>
          );
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  const renderRotationDayDropdown = (id: string, field: FormField) => {
    invariant(
      activeRotationSchedule,
      "Active rotation schedule must be defined"
    );

    const activeRotationDays = rotationDays.getRotationDaysForSchedule(
      activeRotationSchedule.id
    );

    const options = activeRotationDays.map((rotationDay) => ({
      label: rotationDay.abbreviation,
      value: rotationDay.id,
    }));

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={false}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderAlternateScheduleDropdown = (id: string, field: FormField) => {
    invariant(
      activeRotationSchedule,
      "Active rotation schedule must be defined"
    );

    const activeAlternateDays =
      alternateSchedules.getAlternateDaysForRotationSchedule(
        activeRotationSchedule.id
      );

    const options = activeAlternateDays.map((alternateDay) => ({
      label: alternateDay.name,
      value: alternateDay.id,
    }));

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "rotationDay":
        return renderRotationDayDropdown(id, field);
      case "alternateSchedule":
        return renderAlternateScheduleDropdown(id, field);
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      // TBD: Run a check to ensure that the school is not being changed

      const data = {
        rotationDay:
          rotationDayModified && state.rotationDay
            ? {
                id: state.rotationDay.value,
              }
            : null,
        alternateSchedule:
          alternateScheduleModified && state.alternateSchedule
            ? { id: state.alternateSchedule.value }
            : null,
        reinitializeRotationDays: rotationDayModified
          ? state.reinitializeRotationDays
          : null,
        changeType: rotationDayModified
          ? EnumCalendarDayChangeType.RotationDay
          : EnumCalendarDayChangeType.AlternateSchedule,
      };

      if (
        rotationDayModified &&
        data.reinitializeRotationDays &&
        data.rotationDay
      ) {
        const res = await reinitializeRotationDaysPreview(
          calendarDay.id,
          data.rotationDay.id
        );

        addModal({
          content: (
            <div className="h-full">
              <ReinitializeRotationDaysPreview
                firstDate={res.firstDate}
                lastDate={res.lastDate}
                currentDate={calendarDay.date}
                changes={res.changes}
                onCancel={() => {
                  closeModalAll();
                  setIsSubmitDisabled(false);
                }}
                onSubmit={async () => {
                  await modifyCalendarDay(data, calendarDay.id);
                  closeModalAll();
                  setIsSubmitDisabled(false);
                  onUpdateAfterSaving();
                }}
              />
            </div>
          ),
          size: "lg",
        });

        console.log("Res", res);
        return;
      }

      try {
        const res = await modifyCalendarDay(data, calendarDay.id);

        console.log("Res", res);

        return onUpdateAfterSaving();
      } catch (err) {
        console.log("Error updating session", err);
      } finally {
        setIsSubmitDisabled(false);
      }
    },
    [
      isSaving,
      state,
      modifyCalendarDay,
      reinitializeRotationDaysPreview,
      onUpdateAfterSaving,
      isSubmitDisabled,
      calendarDay,
      rotationDayModified,
      alternateScheduleModified,
      addModal,
      closeModalAll,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Edit Calendar Day on{" "}
                  {DateTime.fromISO(calendarDay.date, {
                    zone: "utc",
                  }).toFormat("LLL dd, yyyy")}
                </h2>
                <p className="text-sm text-gray-500">
                  Edit the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {CALENDAR_DAY_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {/* {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )} */}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={"Save"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(CalendarDayEditModal);
