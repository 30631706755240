import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";

import styles from "./index.module.css";

type AlertProps = {
  message: string;
  errors?: string[];
  type: "success" | "warn" | "error";
  className?: string;
};

export default function Alert(props: AlertProps) {
  const { message, errors, type, className: classNameProp } = props;

  const renderIcon = () => {
    return (
      <div>
        {type === "success" && (
          <CheckCircleIcon
            className={clsx(styles.alertIcon, styles.successAlertIcon)}
            aria-hidden="true"
          />
        )}
        {type === "warn" && (
          <ExclamationTriangleIcon
            className={clsx(styles.alertIcon, styles.warningAlertIcon)}
            aria-hidden="true"
          />
        )}
        {type === "error" && (
          <XCircleIcon
            className={clsx(styles.alertIcon, styles.errorAlertIcon)}
            aria-hidden="true"
          />
        )}
      </div>
    );
  };

  const renderBackgroundTheme = () => {
    return type === "success"
      ? styles.successAlertBg
      : type === "warn"
      ? styles.warningAlertBg
      : type === "error"
      ? styles.errorAlertBg
      : "";
  };

  const renderTextTheme = () => {
    return type === "success"
      ? styles.successAlertText
      : type === "warn"
      ? styles.warningAlertText
      : type === "error"
      ? styles.errorAlertText
      : "";
  };

  return (
    <div
      className={clsx(classNameProp, "rounded-md p-4", renderBackgroundTheme())}
    >
      <div className="flex">
        <div className="flex-shrink-0">{renderIcon()}</div>
        <div className="ml-3">
          <h3 className={clsx("text-sm font-medium", renderTextTheme())}>
            {message}
          </h3>
          {errors &&
            errors.map((error, index) => {
              return (
                <div
                  key={index}
                  className={clsx("mt-2 text-sm", styles.errorAlertListText)}
                >
                  <ul className="list-disc space-y-1 pl-5">
                    <li>{error}</li>
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
