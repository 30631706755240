import { gql } from "../../__generated__/gql";

export const GetDistricts = gql(`
    query GetDistricts($where: DistrictWhereInput!, $orderBy: [DistrictOrderByInput!]) {
        districts(where: $where, orderBy: $orderBy) {
            address
            createdAt
            deletedAt
            id
            identifiers
            edlinkId
            edlinkIntegrations {
                id
                provider {
                    application
                    name
                }
            }
            locale
            name
            properties
            schools {
                id
            }
            timezone
            updatedAt
            urlId
            payments
            users {
                id
                email
                firstName
                lastName
                avatarUrl
                createdAt
                updatedAt
                districtRoles
            }
            drives {
                id
                createdAt
                district {
                    id
                }
                driveType
                user {
                    id
                }
                updatedAt
            }
            workspaceType
        }
    }
`);

export const GetDistrict = gql(`
    query GetDistrict($where: DistrictWhereUniqueInput!) {
        district(where: $where) {
            address
            createdAt
            deletedAt
            id
            identifiers
            edlinkId
            edlinkIntegrations {
                id
                provider {
                    application
                    name
                }
            }
            locale
            name
            properties
            schools {
                id
            }
            timezone
            updatedAt
            urlId
            payments
            users {
                id
                email
                firstName
                lastName
                avatarUrl
                createdAt
                updatedAt
                districtRoles
            }
            drives {
                id
                createdAt
                district {
                    id
                }
                driveType
                user {
                    id
                }
                updatedAt
            }
            workspaceType
        }
    }
`);
