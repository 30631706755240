import { computed, observable } from "mobx";
import {
  EnumEnrollmentRole,
  EnumEnrollmentState,
} from "../__generated__/graphql";
import DeletableModel from "./DeletableModel";

class Enrollment extends DeletableModel {
  @observable
  id: string;

  @observable
  classV2Id: string;

  @observable
  edlinkId: string | null;

  @observable
  endDate: Date | null;

  @observable
  identifiers: any | null;

  @observable
  primary: boolean | null;

  @observable
  role: EnumEnrollmentRole;

  @observable
  sectionId: string;

  @observable
  startDate: Date | null;

  @observable
  state: EnumEnrollmentState;

  @observable
  userId: string;

  @computed
  get fullName() {
    const user = this.store.rootStore.users.get(this.userId);
    if (!user) {
      return "";
    } else {
      return user.firstName + " " + user.lastName;
    }
  }
}

export default Enrollment;
