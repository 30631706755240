import { gql } from "../../__generated__/gql";

export const GetSectionV2 = gql(`
    query GetSectionV2($where: SectionV2WhereUniqueInput!) {
        sectionV2(where: $where) {
            classV2 {
                id
            }
            createdAt 
            deletedAt
            description 
            edlinkId 
            id
            identifiers
            locale 
            name 
            properties 
            state 
            timezone 
            updatedAt 
            urlId 
        }
    }
`);

export const GetSectionV2s = gql(`
    query GetSectionV2s($where: SectionV2WhereInput!, $orderBy: [SectionV2OrderByInput!]) {
        sectionV2s(where: $where, orderBy: $orderBy) {
            classV2 {
                id
            }
            createdAt 
            deletedAt
            description 
            edlinkId 
            id
            identifiers
            locale 
            name 
            properties 
            state 
            timezone 
            updatedAt 
            urlId 
        }
    }
`);
