import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import RotationSchedule from "../../../models/RotationSchedule";
import {
  daysOfTheWeekOptions,
  DaysOfWeek,
  DropdownOptionType,
  FormAction,
  FormField,
} from "../../../types";
import {
  rotationScheduleGradeLevelDropdownOptions,
  rotationScheduleGradesEnumArrayToDropdownValues,
} from "../../../utils/gradeLevelsMap";
import {
  EnumRotationScheduleDaysOfWeek,
  EnumRotationScheduleGradeLevels,
} from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";
import Switch from "../../Switch";

type RotationScheduleFormState = {
  name: string;
  skipNoSchoolDays: boolean;
  daysOfWeek: DaysOfWeek[];
  gradeLevels: DropdownOptionType[];
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: RotationScheduleFormState = {
  name: "",
  skipNoSchoolDays: true,
  daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
  gradeLevels: [],
};

const SESSION_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "name",
    label: "Schedule Name*",
    placeholder: "Eg. 8-day rotation",
    required: true,
  },
  {
    fieldKind: "switch",
    id: "skipNoSchoolDays",
    label: "Skip No School Days",
  },
  {
    fieldKind: "checkbox",
    id: "daysOfWeek",
    label: "Days of the Week",
  },
  // Grade levels that can view this rotation
  {
    fieldKind: "multiselect",
    id: "gradeLevels",
    label: "Grade Levels",
    placeholder: "Select grade levels",
  },
];

const formReducer: Reducer<RotationScheduleFormState, FormAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
    case "checkbox":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type RotationScheduleCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  rotationSchedule?: RotationSchedule;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function RotationScheduleCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  rotationSchedule,
  isEditing = false,
}: RotationScheduleCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { sessions, rotationSchedules, ui } = useStores();

  const { isSaving, save } = rotationSchedules;

  const init = (initialState: RotationScheduleFormState) => {
    if (isEditing) {
      invariant(
        rotationSchedule,
        "Rotation Schedule must be defined if editing"
      );

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        name: rotationSchedule.name,
        skipNoSchoolDays: rotationSchedule.skipNoSchoolDays || false,
        daysOfWeek: rotationSchedule.daysOfWeek || [],
        gradeLevels: rotationSchedule.gradeLevels
          ? rotationScheduleGradesEnumArrayToDropdownValues(
              rotationSchedule.gradeLevels
            )
          : [],
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { name } = state;

    console.log("Validate submit", state);

    if (name && name !== "") {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "switch":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Switch
              id={id}
              label={field.label}
              onToggle={(value) => {
                dispatchFormAction({
                  type: "switch",
                  fieldID: id,
                  payload: value,
                });
              }}
              toggleValue={state[id] as boolean}
            />
          </div>
        );
      case "checkbox":
        return renderCheckboxField(field);
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);

      default:
        return null;
    }
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "gradeLevels":
        return renderGradeLevelsDropdown(id, field);
      default:
        return null;
    }
  };

  const renderGradeLevelsDropdown = (id: string, field: FormField) => {
    const dropdownOptions = rotationScheduleGradeLevelDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderCheckboxField = (field: FormField) => {
    const { id, label } = field;

    switch (id) {
      case "daysOfWeek":
        return (
          <div>
            <fieldset className="mt-3 grid grid-cols-4 gap-x-8 gap-y-4">
              <legend className="text-slate11 mb-4 block text-sm font-medium">
                {label}
              </legend>
              {daysOfTheWeekOptions.map((day: DaysOfWeek) => {
                return (
                  <div key={day} className="col-span-1 flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id={day}
                        name={day}
                        checked={state.daysOfWeek.includes(day)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatchFormAction({
                              type: "checkbox",
                              fieldID: id,
                              payload: [...state.daysOfWeek, day],
                            });
                          } else {
                            dispatchFormAction({
                              type: "checkbox",
                              fieldID: id,
                              payload: state.daysOfWeek.filter(
                                (d) => d !== day
                              ),
                            });
                          }
                        }}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                    </div>
                    <div className="ml-2 text-sm">
                      <label
                        htmlFor={day}
                        className="font-medium text-gray-700"
                      >
                        {day}
                      </label>
                    </div>
                  </div>
                );
              })}
            </fieldset>
          </div>
        );
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (isEditing) {
        invariant(
          rotationSchedule,
          "Rotation schedule must be defined if editing"
        );

        // TBD: Run a check to ensure that the school is not being changed
        const updateRotationSchedule = {
          id: rotationSchedule.id,
          ...state,
          daysOfWeek: state.daysOfWeek as EnumRotationScheduleDaysOfWeek[],
          gradeLevels: state.gradeLevels.map(
            (gradeLevel) => gradeLevel.value as EnumRotationScheduleGradeLevels
          ),
        };

        try {
          const res = await save(updateRotationSchedule);

          console.log("Updated Rotation Schedule", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating Rotation Schedule", err);
        }
      } else {
        try {
          invariant(ui.activeSessionId, "Active session must be defined");
          const activeSession = sessions.getByUrlParam(ui.activeSessionId);
          invariant(activeSession, "Active session must be defined");
          const newRotationSchedule = {
            ...state,
            daysOfWeek: state.daysOfWeek as EnumRotationScheduleDaysOfWeek[],
            session: {
              id: activeSession.id,
            },
            gradeLevels: state.gradeLevels.map(
              (gradeLevel) =>
                gradeLevel.value as EnumRotationScheduleGradeLevels
            ),
          };
          const res = await save(newRotationSchedule);
          console.log("Created Rotation Schedule", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating Rotation Schedule", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      rotationSchedule,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeSessionId,
      sessions,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} Rotation Schedule
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {SESSION_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          {!isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                // TBD: Add logic to create a new rotation schedule that is a default Monday-Friday schedule (5 day rotation) with no skipped days and no alternate schedule days
              }}
              disabled={isSaving}
              buttonText="Create M-F Schedule"
              rounded="medium"
            />
          )}
          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(RotationScheduleCreateAndEditModal);
