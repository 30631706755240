import { gql } from "../../__generated__/gql";

export const CreateSession = gql(`
    mutation createSession($data: SessionCreateInput!) {
        createSession(data: $data) {
            createdAt 
            deletedAt
            district {
                id
            }
            edlinkId
            endDate
            id
            identifiers
            name
            properties
            school {
                id
            }
            sessionType
            state
            startDate 
            updatedAt
            urlId
        }
    }
`);

export const UpdateSession = gql(`
    mutation updateSession($data: SessionUpdateInput!, $where: SessionWhereUniqueInput!) {
        updateSession(data: $data, where: $where) {
            createdAt 
            deletedAt
            district {
                id
            }
            edlinkId
            endDate
            id
            identifiers
            name
            properties
            school {
                id
            }
            sessionType
            state
            startDate 
            updatedAt
            urlId
        }
    }
`);
