import { gql } from "../../__generated__/gql";

export const LoginWithEmailId = gql(`
  mutation LoginWithEmailId($credentials: Credentials!) {
    loginWithEmail(credentials: $credentials) {
      user {
        id
        email
        firstName
        lastName
        username
        roles
        userRole
        isOnboarded
        avatarUrl
        integrations
      }
      accessToken
    }
  }
`);

export const LoginWithEdlink = gql(`
  mutation LoginWithEdlinkAdminPortal($credentials: EdlinkCredentials!) {
    loginWithEdlinkAdminPortal(credentials: $credentials) {
      user {
        id
        email
        firstName
        lastName
        username
        roles
        userRole
        isOnboarded
        avatarUrl
        integrations
      }
      accessToken
    }
  }
`);

export const IsEdlinkUser = gql(`
  mutation IsEdlinkUser($email: String!) {
    isEdlinkUser(email: $email)
  }
`);

// export const LoginAlaynaPortal = gql(`
//   mutation LoginAlaynaPortal($credentials: Credentials!) {
//     loginAlaynaPortal(credentials: $credentials) {
//       user {
//         id
//         email
//         firstName
//         lastName
//         username
//         roles
//         userRole
//         isOnboarded
//         avatarUrl
//         integrations
//       }
//       accessToken
//     }
//   }
// `);

export const SignUpAlaynaPortal = gql(`
  mutation SignUpAlaynaPortal($credentials: Credentials!) {
    signUpAlaynaPortal(credentials: $credentials) {
      user {
        id
        email
        firstName
        lastName
        roles
        userRole
        isOnboarded
        avatarUrl
        integrations
      }
      accessToken
    }
  }
`);

export const SendOnboardingEmail = gql(`
  mutation sendOnboardingInvite($userId: String!) {
    sendOnboardingInvite(userId: $userId)
  }
`);
