import { gql } from "../../__generated__/gql";

export const CreateDepartment = gql(`
    mutation createDepartment($data: DepartmentCreateInput!) {
        createDepartment(data: $data) {
            createdAt
            deletedAt
            description
            district {
                id
            }
            edlinkId 
            id
            identifiers 
            name 
            properties
            schools {
                id
            }
            subjects {
                id
            } 
            updatedAt 
            urlId 
            users {
                id
            }
        }
    }
`);

export const UpdateDepartment = gql(`
    mutation updateDepartment($data: DepartmentUpdateInput!, $where: DepartmentWhereUniqueInput!) {
        updateDepartment(data: $data, where: $where) {
            createdAt
            deletedAt
            description
            district {
                id
            }
            edlinkId 
            id
            identifiers 
            name 
            properties
            schools {
                id
            }
            subjects {
                id
            } 
            updatedAt 
            urlId 
            users {
                id
            }
        }
    }
`);
