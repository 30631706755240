import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import School from "../../../models/School";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import {
  schoolGradeLevelDropdownOptions,
  schoolGradesEnumArrayToDropdownValues,
} from "../../../utils/gradeLevelsMap";
import { generateRandomString } from "../../../utils/RandomString";
import { EnumSchoolGradeLevels } from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";

type SchoolFormState = {
  name: string;
  gradeLevels: DropdownOptionType[];
  [key: string]: string | boolean | any[];
};

const initialState: SchoolFormState = {
  name: "",
  gradeLevels: [],
};

const SCHOOL_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "name",
    label: "School Name",
    placeholder: "Eg. Spring Field Elementary",
    required: true,
  },
  {
    fieldKind: "multiselect",
    id: "gradeLevels",
    label: "Grade Levels",
    placeholder: "Select grade levels",
  },
];

const formReducer: Reducer<SchoolFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type SchoolCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  school?: School;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function SchoolCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  school,
  isEditing = false,
}: SchoolCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { districts, schools, ui } = useStores();

  const { isSaving, save } = schools;

  const init = (initialState: SchoolFormState) => {
    if (isEditing) {
      invariant(school, "School must be defined if editing");

      console.log("school", school);
      console.log(
        "school grade levels",
        schoolGradesEnumArrayToDropdownValues(school.gradeLevels)
      );

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        name: school.name,
        gradeLevels: schoolGradesEnumArrayToDropdownValues(school.gradeLevels),
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { name } = state;

    console.log("Validate submit", state);

    if (name && name.length > 0) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);

      default:
        return null;
    }
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "gradeLevels":
        return renderGradeLevelsDropdown(id, field);
      default:
        return null;
    }
  };

  const renderGradeLevelsDropdown = (id: string, field: FormField) => {
    const dropdownOptions = schoolGradeLevelDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (isEditing) {
        invariant(school, "School must be defined if editing");

        const updatedSchool = {
          id: school.id,
          ...state,
          gradeLevels: state.gradeLevels.map(
            (gradeLevel) => gradeLevel.value as EnumSchoolGradeLevels
          ),
        };

        console.log("Updated school", updatedSchool);

        try {
          const res = await save(updatedSchool);

          console.log("Updated school", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating school", err);
        }
      } else {
        try {
          invariant(ui.activeDistrictId, "Active district must be defined");

          const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

          invariant(activeDistrict, "Active district must be defined");

          const newSchool = {
            ...state,
            gradeLevels: state.gradeLevels.map(
              (gradeLevel) => gradeLevel.value as EnumSchoolGradeLevels
            ),
            district: {
              id: activeDistrict.id,
            },
            urlId: generateRandomString(),
          };

          console.log("New school", newSchool);

          const res = await save(newSchool);
          console.log("Created school", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating school", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      school,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeDistrictId,
      districts,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} School
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {SCHOOL_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(SchoolCreateAndEditModal);
