import { gql } from "../../__generated__/gql";

export const GetAuthUser = gql(/* Graphql */ `
    query GetAuthUser($where: UserWhereUniqueInput!) {
        user(where: $where) {
            id
            email
            firstName
            lastName
            username
            avatarUrl
            userRole
            isOnboarded
            createdAt
            authProvider
            updatedAt
            district {
                id
                urlId
            }
            integrations
        }
    }
`);
