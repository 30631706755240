// import { faker } from "@faker-js/faker";

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export const ActionTypes = Object.freeze({
  ADD_OPTION_TO_COLUMN: "add_option_to_column",
  ADD_ROW: "add_row",
  UPDATE_COLUMN_TYPE: "update_column_type",
  UPDATE_COLUMN_HEADER: "update_column_header",
  UPDATE_CELL: "update_cell",
  ADD_COLUMN_TO_LEFT: "add_column_to_left",
  ADD_COLUMN_TO_RIGHT: "add_column_to_right",
  DELETE_COLUMN: "delete_column",
  ENABLE_RESET: "enable_reset",
  DELETE_ROW: "delete_row",

  // FIXED COLUMN METHODS
  UPDATE_TITLE: "update_title",
  UPDATE_DATES: "update_dates",
  UPDATE_STATUS: "update_status",

  SELECT_STANDARD: "select_standard",
  REMOVE_STANDARD: "remove_standard",

  SELECT_LESSON: "select_lesson",
  REMOVE_LESSON: "remove_lesson",
  ADD_NEW_LESSON: "add_new_lesson",
});

export const DataTypes = Object.freeze({
  // USERS CAN ADD THESE
  NUMBER: "number",
  TEXT: "text",
  SELECT: "select",
  // THESE ARE DEFAULT AND CANNOT BE MODIFIED
  TITLE: "title",
  DATE: "date",
  TIME: "time",
  STATUS: "status",
  BLOCKS: "blocks",
  ROW_NUMBER: "row_number",
  DELETE_ROW: "delete_row",
});

export const Constants = Object.freeze({
  DELETE_ROW_ID: "99999999998",
  ADD_COLUMN_ID: "99999999999",
  ROW_NUMBER_COLUMN_ID: "100000000000",
});

export const DEFAULT_COLUMNS = [
  {
    id: Constants.ROW_NUMBER_COLUMN_ID,
    width: 50,
    label: "#",
    disableResizing: true,
    dataType: DataTypes.ROW_NUMBER,
  },
  {
    id: "title",
    label: "Title",
    accessor: "title",
    minWidth: 250,
    dataType: DataTypes.TITLE,
    options: [],
  },
  {
    id: "dates",
    label: "Dates",
    accessor: "dates",
    minWidth: 250,
    dataType: DataTypes.DATE,
    options: [],
  },
  {
    id: "standards",
    label: "Standards",
    accessor: "standards",
    minWidth: 250,
    dataType: DataTypes.STANDARDS,
    options: [],
  },
  {
    id: "lessons",
    label: "Lessons",
    accessor: "lessons",
    minWidth: 250,
    dataType: DataTypes.LESSONS,
    options: [],
  },
  {
    id: "status",
    label: "Status",
    accessor: "status",
    minWidth: 250,
    dataType: DataTypes.STATUS,
    options: [],
  },
];

export const ROTATION_DAY_COLUMNS = [
  {
    id: Constants.ROW_NUMBER_COLUMN_ID,
    width: 50,
    label: "#",
    disableResizing: true,
    dataType: DataTypes.ROW_NUMBER,
  },
  {
    id: "abbreviation",
    label: "Abbreviation",
    accessor: "abbreviation",
    minWidth: 250,
    dataType: DataTypes.TEXT,
    options: [],
  },
  {
    id: "description",
    label: "Description",
    accessor: "description",
    minWidth: 250,
    dataType: DataTypes.TEXT,
    options: [],
  },
  {
    id: "dayIndex",
    label: "Day Index",
    accessor: "dayIndex",
    minWidth: 250,
    dataType: DataTypes.NUMBER,
    options: [],
  },
  {
    id: Constants.DELETE_ROW_ID,
    width: 20,
    label: "",
    disableResizing: true,
    dataType: DataTypes.DELETE_ROW,
  },
];

export const BLOCK_SCHEDULE_COLUMNS = [
  {
    id: "rotationDay",
    label: "Rotation Day",
    accessor: "rotationDay",
    minWidth: 250,
    dataType: DataTypes.TEXT,
    disabled: true,
  },
  {
    id: "startTime",
    label: "Start Time",
    accessor: "startTime",
    minWidth: 250,
    dataType: DataTypes.TIME,
  },
  {
    id: "endTime",
    label: "End Time",
    accessor: "endTime",
    minWidth: 250,
    dataType: DataTypes.TIME,
  },
  {
    id: Constants.DELETE_ROW_ID,
    width: 20,
    label: "",
    disableResizing: true,
    dataType: DataTypes.DELETE_ROW,
  },
];

export const ALTERNATE_SCHEDULE_COLUMNS = [
  {
    id: "block",
    label: "Block",
    accessor: "block",
    minWidth: 250,
    dataType: DataTypes.TEXT,
    disabled: true,
  },
  {
    id: "startTime",
    label: "Start Time",
    accessor: "startTime",
    minWidth: 250,
    dataType: DataTypes.TIME,
  },
  {
    id: "endTime",
    label: "End Time",
    accessor: "endTime",
    minWidth: 250,
    dataType: DataTypes.TIME,
  },
  {
    id: Constants.DELETE_ROW_ID,
    width: 20,
    label: "",
    disableResizing: true,
    dataType: DataTypes.DELETE_ROW,
  },
];

export const SECTION_TIMEBLOCKING_COLUMNS = [
  {
    id: "rotationDay",
    label: "Rotation Day",
    accessor: "rotationDay",
    minWidth: 250,
    dataType: DataTypes.TEXT,
    disabled: true,
  },
  {
    id: "blockId",
    label: "Block",
    accessor: "blockId",
    minWidth: 250,
    dataType: DataTypes.BLOCKS,
    options: [],
    disabled: true,
  },
  {
    id: "startTime",
    label: "Start Time",
    accessor: "startTime",
    minWidth: 250,
    dataType: DataTypes.TIME,
  },
  {
    id: "endTime",
    label: "End Time",
    accessor: "endTime",
    minWidth: 250,
    dataType: DataTypes.TIME,
  },
  {
    id: Constants.DELETE_ROW_ID,
    width: 20,
    label: "",
    disableResizing: true,
    dataType: DataTypes.DELETE_ROW,
  },
];
