import React from "react";

interface TransitionProps {
  children: React.ReactNode;
}

const Transition: React.FC<TransitionProps> = ({ children }) => {
  return <span className="animate-simpleTransition">{children}</span>;
};

export default Transition;
