import { gql } from "../../__generated__/gql";

export const GetUsers = gql(`
    query GetUsers($where: UserWhereInput!, $orderBy: [UserOrderByInput!], $take: Float, $skip: Float) {
        users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
            avatarUrl
            createdAt
            deletedAt
            district {
                id
                name
                payments
            }
            districtRoles
            edlinkId
            email
            firstName
            gradeLevels
            id 
            identifiers
            isOnboarded
            lastName
            middleName
            schools {
                id
                name
            }
            updatedAt
            username 
        }
    }
`);

export const GetUsersMeta = gql(`
    query GetUsersMeta(
        $where: UserWhereInput!
    ) {
        _usersMeta(where: $where) {
            count
        }
    }
`);
