import React from "react";
import { useModalContext } from "../../../contexts/modals";
import ButtonLarge from "../../ButtonLarge";

interface Props {
  // Define the props for your component here
  name: string;
  onSubmit: (assignSeat: boolean) => void;
}

const ActivateUserModal: React.FC<Props> = (props) => {
  // Add your component logic here
  const { name, onSubmit } = props;

  const [assignSeat, setAssignSeat] = React.useState(true);

  const { closeModalAll } = useModalContext();

  return (
    // Add your JSX code here
    <div className="py-3">
      {/* Description */}
      <p className="text-sm text-gray-500">
        Are you sure you want to activate {name}?
      </p>

      {/* Assign Seat checkbox */}
      <div className="mt-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="text-blue9 focus:ring-blue9 h-4 w-4 rounded border-gray-300"
            checked={assignSeat}
            onChange={() => {
              setAssignSeat(!assignSeat);
            }}
          />
          <span className="ml-2 text-sm text-gray-900">
            Assign Paid License
          </span>
        </label>
      </div>

      {/* Submit button */}
      <div className="mt-6 flex justify-end">
        <ButtonLarge
          type="button"
          theme="constructive"
          buttonText="Submit"
          rounded="medium"
          onClick={() => {
            onSubmit(assignSeat);
            closeModalAll();
          }}
        />
      </div>
    </div>
  );
};

export default ActivateUserModal;
