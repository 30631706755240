import clsx from "clsx";
import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  totalCount: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  totalCount,
}) => {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing{" "}
          <span className="font-medium">
            {(currentPage - 1) * pageSize + 1}
          </span>{" "}
          to{" "}
          <span className="font-medium">
            {Math.min(currentPage * pageSize, totalCount)}
          </span>{" "}
          of <span className="font-medium">{totalCount}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          className={clsx(
            currentPage <= 1
              ? "cursor-not-allowed bg-gray-50"
              : "bg-white hover:bg-gray-50",
            "relative inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus-visible:outline-offset-0"
          )}
          disabled={currentPage <= 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </button>
        <button
          className={clsx(
            currentPage >= totalPages
              ? "cursor-not-allowed bg-gray-600"
              : "bg-black hover:bg-gray-700",
            "relative ml-3 inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300  focus-visible:outline-offset-0"
          )}
          disabled={currentPage >= totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
