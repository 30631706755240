import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import ListOfClasses from "../components/Classes/ListOfClasses";
import ClassCreateAndEditModal from "../components/CRUD/CreateAndEdit/Class";
import PageLayout from "../components/PageLayout";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Classes() {
  const [showCreateClassModal, setShowCreateClassModal] = useState(false);
  const { userId = "" } = useParams();

  const { districts, ui, schools } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId, activeSchoolId } = ui;

  if (!activeDistrictId || !activeSchoolId) return null;

  console.log(activeDistrictId, activeSchoolId);

  const activeDistrict = districts.getByUrlParam(activeDistrictId);
  const activeSchool = schools.getByUrlParam(activeSchoolId);

  console.log(activeDistrict, activeSchool);

  if (!activeSchool || !activeDistrict) return null;

  const schoolClasses = activeSchool.activeClasses;

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateClassModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div className="flex">
            <h2 className="text-xl font-semibold">Classes</h2>
            {/* <SchoolClassesViewDropdown>
              <Tooltip content="Change section" dir="top" asChild={false}>
                <button className="border-slate7 ml-4 flex items-center justify-center rounded-lg border-[1px] border-solid p-1 px-2 shadow-sm">
                  <div className="ml-2 text-sm font-medium">{school.name}</div>
                  <span className="w-1" />
                  <ChevronDownIcon className="h-5 w-5" />
                </button>
              </Tooltip>
            </SchoolClassesViewDropdown> */}
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfClasses key={schoolClasses.length} classes={schoolClasses} />
        </div>
      </PageLayout>
      {showCreateClassModal && (
        <ClassCreateAndEditModal
          onClose={() => setShowCreateClassModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateClassModal(false);

            addToast("Class created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Classes);
