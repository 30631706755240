// import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React from "react";

import { Pencil1Icon } from "@radix-ui/react-icons";
import { observer } from "mobx-react";
import { useModalContext } from "../../contexts/modals";
import { useToastsContext } from "../../contexts/toasts";
import useStores from "../../hooks/useStores";
import DropdownMenu from "../DropdownMenu";
import DropdownList from "../DropdownMenu/DropdownList";

type DropdownMenuProps = {
  children: React.ReactNode;
  date: Date;
  onEditCalendarDay: () => void;
};

const CalendarDayDropdownMenu = ({
  children: triggerComponent,
  date,
  onEditCalendarDay,
}: DropdownMenuProps) => {
  const stores = useStores();

  //   const { planner } = stores;

  //   const { isBumping, bumpForward, bumpBackward, resetRotationCycle } = planner;

  const { addToast } = useToastsContext();

  const { addModal, closeModalAll } = useModalContext();

  //   const handleBumpForward = useCallback(async () => {
  //     if (isBumping) {
  //       return;
  //     }

  //     const descriptionDate = DateTime.fromJSDate(date).toFormat("EEE, MMM dd");

  //     addModal({
  //       title: "Bump Lessons Forward",
  //       description: <div>Bump all lessons forward from {descriptionDate}?</div>,
  //       alignCenter: false,
  //       onSubmit: async () => {
  //         closeModalAll();

  //         // Convert date to yyyy-MM-dd format
  //         const formattedDate = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

  //         const res = await bumpForward(formattedDate);

  //         if (res) {
  //           addToast("Successfully bumped forward", {
  //             type: "success",
  //           });
  //         } else {
  //           addToast("Failed to bump forward", {
  //             type: "error",
  //           });
  //         }
  //       },
  //       onClose: () => {
  //         closeModalAll();
  //       },
  //       submitText: "Submit",
  //       size: "sm",
  //       destructive: false,
  //     });
  //   }, [isBumping, date]);

  //   const handleBumpBackward = useCallback(async () => {
  //     if (isBumping) {
  //       return;
  //     }

  //     const descriptionDate = DateTime.fromJSDate(date).toFormat("EEE, MMM dd");

  //     addModal({
  //       title: "Bump Lessons Backward",
  //       description: <div>Bump all lessons backward from {descriptionDate}?</div>,
  //       alignCenter: false,
  //       onSubmit: async () => {
  //         closeModalAll();
  //         // Convert date to yyyy-MM-dd format
  //         const formattedDate = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

  //         const res = await bumpBackward(formattedDate);

  //         if (res) {
  //           addToast("Successfully bumped backward", {
  //             type: "success",
  //           });
  //         } else {
  //           addToast("Failed to bump backward", {
  //             type: "error",
  //           });
  //         }
  //       },
  //       onClose: () => {
  //         closeModalAll();
  //       },
  //       submitText: "Submit",
  //       size: "sm",
  //       destructive: false,
  //     });
  //   }, [isBumping, date]);

  //   const handleResetCycle = () => {
  //     if (isBumping) {
  //       return;
  //     }

  //     const descriptionDate = DateTime.fromJSDate(date).toFormat("EEE, MMM dd");

  //     addModal({
  //       title: "Reset rotation cycle",
  //       description: <div>Reset rotation cycle from {descriptionDate}?</div>,
  //       alignCenter: false,
  //       onSubmit: async () => {
  //         // Convert date to yyyy-MM-dd format

  //         closeModalAll();

  //         const formattedDate = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

  //         const res = await resetRotationCycle(formattedDate);

  //         if (res) {
  //           addToast("Successfully reset rotation cycle.", {
  //             type: "success",
  //           });
  //         } else {
  //           addToast("Failed to reset rotation cycle.", {
  //             type: "error",
  //           });
  //         }
  //       },
  //       onClose: () => {
  //         closeModalAll();
  //       },
  //       submitText: "Submit",
  //       size: "sm",
  //       destructive: true,
  //     });
  //   };

  //   const resetCycleItems =
  //     scheduleType === EnumPlannerRevisionScheduleType.CycleDays ||
  //     scheduleType === EnumPlannerRevisionScheduleType.CycleWeeks
  //       ? ([
  //           {
  //             type: "divider",
  //           },

  //           {
  //             type: "button",
  //             text: "Reset Cycle",
  //             icon: <ReloadIcon />,
  //             onClick: () => handleResetCycle(),
  //             destructive: true,
  //           },
  //         ] as DropdownItemType[])
  //       : [];

  return (
    <DropdownMenu width={250} triggerComponent={triggerComponent}>
      <DropdownList
        items={[
          {
            type: "button",
            text: "Edit Calendar Day",
            icon: <Pencil1Icon />,
            onClick: () => onEditCalendarDay(),
          },
          //   ...resetCycleItems,
        ]}
      />
    </DropdownMenu>
  );
};

export default observer(CalendarDayDropdownMenu);
