import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import PageLayout from "../components/PageLayout";
import PageTabs from "../components/Tabs/PageTabs";
import useStores from "../hooks/useStores";
import { PageTab } from "../types";
import { classRoute } from "../utils/routeHelper";

function Class() {
  const { classId = "" } = useParams();

  const { sections, ui, classes, schools, districts, enrollments, calendars } =
    useStores();
  const [isLoading, setIsLoading] = useState(true);

  const currentClass = classes.getByUrlParam(classId);

  const { tab = "lessons" } = useParams();

  const navigate = useNavigate();

  // Set active district in UI store
  useEffect(() => {
    if (currentClass) {
      ui.setActiveClassId(currentClass.urlId);
    }

    // if (currentSchool) {
    //   ui.setActiveSchoolId(currentSchool.urlId);
    // }

    // if (currentDistrict) {
    //   ui.setActiveDistrictId(currentDistrict.urlId);
    // }

    setIsLoading(false);
    return () => {
      ui.setActiveClassId(undefined);
    };
  }, [
    currentClass,
    ui,
    // currentSchool, currentDistrict
  ]);

  // Fetch all school related data
  useEffect(() => {
    if (currentClass) {
      sections.fetchClassSections(currentClass.id);
      enrollments.fetchEnrollmentsForClass(currentClass.id);
      calendars.fetchCalendarsForClass(currentClass.id);
    }
  }, [currentClass]);

  if (!currentClass) {
    return <div>Class not found</div>;
  }

  const courseHeader = (
    <div className="flex items-center">
      {/* <div
        className=" mr-1 flex items-center justify-center rounded-lg p-1 shadow-sm"
        style={{
          color: courseColor,
          background: courseColor
            ? colorsMap50[colorsMap500Flipped[courseColor]]
            : "var(--slate2)",
        }}
      >
        <IconComponent className="h-6 w-6" />
      </div> */}
      <div className="ml-2 text-xl font-semibold">{currentClass.name}</div>
    </div>
  );

  const courseTabs: PageTab[] = [
    {
      name: "Lessons",
      onClick: () => {
        navigate(classRoute(currentClass, "lessons"));
      },
      current: (currentTab) => currentTab === "lessons",
    },
    {
      name: "Units",
      onClick: () => {
        navigate(classRoute(currentClass, "units"));
      },
      current: (currentTab) => currentTab === "units",
    },
    {
      name: "Resources",
      onClick: () => {
        navigate(classRoute(currentClass, "resources"));
      },
      current: (currentTab) => currentTab === "resources",
    },
    {
      name: "Enrollments",
      onClick: () => {
        navigate(classRoute(currentClass, "enrollments"));
      },
      current: (currentTab) => currentTab === "enrollments",
    },
    {
      name: "Calendar",
      onClick: () => {
        navigate(classRoute(currentClass, "calendar"));
      },
      current: (currentTab) => currentTab === "enrollments",
    },
  ];

  if (isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  return (
    <PageLayout
      left={courseHeader}
      title={tab === "lessons" ? "Lessons" : "Units"}
      right={null}
      fullWidth={true}
    >
      {/* Render the Course component here */}
      <div className="px-20 py-2 pt-6">
        <PageTabs
          tabs={courseTabs}
          currentTab={tab}
          // activeTabControls={
          //   tab === "lessons"
          //     ? renderCalendarRight
          //     : tab === "assets"
          //     ? renderResourcesRight
          //     : null
          // }
        />
      </div>
    </PageLayout>
  );

  // return <Outlet />;
}

export default observer(Class);
