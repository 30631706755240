import { computed, observable } from "mobx";
import { EnumSchoolGradeLevels } from "../__generated__/graphql";
import ClassV2 from "./ClassV2";
import DeletableModel from "./DeletableModel";

class School extends DeletableModel {
  @observable
  id: string;

  @observable
  address: any | null;

  @observable
  courseIds: string[];

  @observable
  classIds: string[];

  @observable
  districtId: string;

  @observable
  edlinkId: string | null;

  @observable
  gradeLevels: EnumSchoolGradeLevels[];

  @observable
  identifiers: any | null;

  @observable
  locale: string | null;

  @observable
  name: string;

  @observable
  properties: any | null;

  @observable
  timezone: string | null;

  @observable
  urlId: string;

  @computed
  get courseCount(): number {
    return this.courseIds.length;
  }

  @computed
  get classCount(): number {
    return this.classIds.length;
  }

  @computed
  get activeClasses(): ClassV2[] {
    const { classes } = this.store.rootStore;

    return classes.getClassesForSchool(this.id);
  }
}

export default School;
