import { computed, observable } from "mobx";
import DeletableModel from "./DeletableModel";

class Subject extends DeletableModel {
  @observable
  id: string;

  @observable
  cedsCode: string | null;

  @observable
  code: string | null;

  @observable
  departmentId: string;

  @observable
  description: string | null;

  @observable
  districtId: string;

  @observable
  edlinkId: string | null;

  @observable
  identifiers: any | null;

  @observable
  name: string;

  @observable
  properties: any | null;

  @computed
  get departmentName(): string {
    const { departments } = this.store.rootStore;

    const fetchDepartment = departments.get(this.departmentId);

    return fetchDepartment ? fetchDepartment.name : "-";
  }
}

export default Subject;
