import { ApolloClient } from "@apollo/client";
import AbilitiesStore from "./AbilitiesStore";
import AlternateScheduleStore from "./AlternateScheduleStore";
import AuthStore from "./AuthStore";
import BlockScheduleStore from "./BlockScheduleStore";
import BlocksStore from "./BlocksStore";
import CalendarDayStore from "./CalendarDayStore";
import CalendarEventsStore from "./CalendarEventsStore";
import CalendarScheduleStore from "./CalendarScheduleStore";
import CalendarStore from "./CalendarStore";
import ClassesStore from "./ClassesStore";
import CoursesStore from "./CoursesStore";
import DepartmentsStore from "./DepartmentsStore";
import DistrictsStore from "./DistrictsStore";
import EnrollmentStore from "./EnrollmentStore";
import EventsStore from "./EventsStore";
import RotationDayStore from "./RotationDayStore";
import RotationScheduleStore from "./RotationScheduleStore";
import SchoolsStore from "./SchoolsStore";
import SectionsStore from "./SectionsStore";
import SessionsStore from "./SessionsStore";
import SubjectsStore from "./SubjectsStore";
import UiStore from "./UiStore";
import UsersStore from "./UsersStore";

export default class RootStore {
  abilities: AbilitiesStore;
  auth: AuthStore;
  alternateSchedules: AlternateScheduleStore;
  blocks: BlocksStore;
  blockSchedules: BlockScheduleStore;
  calendarDays: CalendarDayStore;
  calendarEvents: CalendarEventsStore;
  calendarSchedules: CalendarScheduleStore;
  classes: ClassesStore;
  courses: CoursesStore;
  departments: DepartmentsStore;
  districts: DistrictsStore;
  enrollments: EnrollmentStore;
  events: EventsStore;
  calendars: CalendarStore;
  rotationDays: RotationDayStore;
  rotationSchedules: RotationScheduleStore;
  schools: SchoolsStore;
  sections: SectionsStore;
  sessions: SessionsStore;
  subjects: SubjectsStore;
  users: UsersStore;
  ui: UiStore;

  constructor(apolloClient: ApolloClient<any>) {
    this.auth = new AuthStore(this, apolloClient);
    this.abilities = new AbilitiesStore(this, apolloClient);
    this.alternateSchedules = new AlternateScheduleStore(this, apolloClient);
    this.blocks = new BlocksStore(this, apolloClient);
    this.blockSchedules = new BlockScheduleStore(this, apolloClient);
    this.calendarDays = new CalendarDayStore(this, apolloClient);
    this.calendarEvents = new CalendarEventsStore(this, apolloClient);
    this.calendarSchedules = new CalendarScheduleStore(this, apolloClient);
    this.classes = new ClassesStore(this, apolloClient);
    this.courses = new CoursesStore(this, apolloClient);
    this.departments = new DepartmentsStore(this, apolloClient);
    this.districts = new DistrictsStore(this, apolloClient);
    this.enrollments = new EnrollmentStore(this, apolloClient);
    this.events = new EventsStore(this, apolloClient);
    this.calendars = new CalendarStore(this, apolloClient);
    this.rotationDays = new RotationDayStore(this, apolloClient);
    this.rotationSchedules = new RotationScheduleStore(this, apolloClient);
    this.schools = new SchoolsStore(this, apolloClient);
    this.sections = new SectionsStore(this, apolloClient);
    this.sessions = new SessionsStore(this, apolloClient);
    this.subjects = new SubjectsStore(this, apolloClient);
    this.users = new UsersStore(this, apolloClient);
    this.ui = new UiStore();
  }

  async logout() {
    this.abilities.clear();
    this.alternateSchedules.clear();
    this.blocks.clear();
    this.blockSchedules.clear();
    this.calendarDays.clear();
    this.calendarEvents.clear();
    this.calendarSchedules.clear();
    this.calendars.clear();
    this.classes.clear();
    this.courses.clear();
    this.departments.clear();
    this.districts.clear();
    this.enrollments.clear();
    this.events.clear();
    this.rotationDays.clear();
    this.rotationSchedules.clear();
    this.sections.clear();
    this.sessions.clear();
    this.schools.clear();
    this.subjects.clear();
    this.users.clear();
    this.ui.clear();
  }
}
