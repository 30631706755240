import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateRotationSchedule,
  UpdateRotationSchedule,
} from "../graphql/scheduling/scheduling.mutations";
import {
  GetRotationSchedule,
  GetRotationSchedules,
} from "../graphql/scheduling/scheduling.queries";
import RotationSchedule from "../models/RotationSchedule";
import {
  RotationScheduleCreateInput,
  RotationScheduleUpdateInput,
  RotationScheduleWhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class RotationScheduleStore extends BaseStore<RotationSchedule> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, RotationSchedule, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetch = async (id: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetRotationSchedule,
          variables: {
            where: {
              id,
            },
          },
        })
        .then((res) => {
          const rotationSchedule = res.data.rotationSchedule;

          invariant(rotationSchedule, "Rotation Schedule must exist");

          invariant(
            rotationSchedule.session,
            "Rotation Schedule must have a session"
          );

          // Fetch the blocks for this rotation schedule
          this.rootStore.blocks.fetchRotationScheduleBlocks(
            rotationSchedule.id
          );

          this.rootStore.rotationDays.fetchRotationScheduleDays(
            rotationSchedule.id
          );

          this.rootStore.alternateSchedules.fetchRotationAlternateSchedules(
            rotationSchedule.id
          );

          this.rootStore.calendars.fetchSessionRotationCalendars(
            rotationSchedule.session.id
          );

          const sanitizeRotationSchedule = {
            calendarDayIds: rotationSchedule.calendarDays.map((day) => day.id),
            createdAt: rotationSchedule.createdAt,
            deletedAt: rotationSchedule.deletedAt,
            id: rotationSchedule.id,
            name: rotationSchedule.name,
            rotationDayIds: rotationSchedule.rotationDays.map((day) => day.id),
            sessionId: rotationSchedule.session.id,
            skipNoSchoolDays: rotationSchedule.skipNoSchoolDays,
            daysOfWeek: rotationSchedule.daysOfWeek,
            gradeLevels: rotationSchedule.gradeLevels,
          };

          this.add(sanitizeRotationSchedule);
          resolve(true);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
        });
    });
  };

  @action
  fetchSessionRotationSchedules = (sessionId: string) => {
    this.isLoading = true;

    const session = this.rootStore.sessions.get(sessionId);

    invariant(session, "Session must exist");

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetRotationSchedules,
          variables: {
            where: {
              session: {
                id: sessionId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const rotationSchedules = res.data.rotationSchedules;

          if (!rotationSchedules) {
            reject(false);
          }

          // We set the default rotation schedule to the first one
          if (
            !session.activeRotationScheduleId &&
            rotationSchedules.length > 0
          ) {
            session.setActiveRotationScheduleId(rotationSchedules[0].id);
          }

          runInAction("Populate rotationSchedules", () => {
            rotationSchedules.forEach((rotationSchedule) => {
              console.log(rotationSchedule);

              invariant(
                rotationSchedule.session,
                "Rotation Schedule must have a session"
              );

              // Fetch the blocks for this rotation schedule
              this.rootStore.blocks.fetchRotationScheduleBlocks(
                rotationSchedule.id
              );

              this.rootStore.rotationDays.fetchRotationScheduleDays(
                rotationSchedule.id
              );

              this.rootStore.alternateSchedules.fetchRotationAlternateSchedules(
                rotationSchedule.id
              );

              this.rootStore.calendars.fetchSessionRotationCalendars(
                rotationSchedule.session.id
              );

              const sanitizeRotationSchedule = {
                calendarDayIds: rotationSchedule.calendarDays.map(
                  (day) => day.id
                ),
                createdAt: rotationSchedule.createdAt,
                deletedAt: rotationSchedule.deletedAt,
                id: rotationSchedule.id,
                name: rotationSchedule.name,
                rotationDayIds: rotationSchedule.rotationDays.map(
                  (day) => day.id
                ),
                sessionId: rotationSchedule.session.id,
                skipNoSchoolDays: rotationSchedule.skipNoSchoolDays,
                daysOfWeek: rotationSchedule.daysOfWeek,
                gradeLevels: rotationSchedule.gradeLevels,
              };

              this.add(sanitizeRotationSchedule);
            });
          });
        })
        .catch((e) => {
          console.log("error", e);
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<RotationSchedule>): Promise<RotationSchedule> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as RotationScheduleCreateInput);
      } else {
        return this.update(
          rest as RotationScheduleUpdateInput,
          { id } as RotationScheduleWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: RotationScheduleCreateInput): Promise<RotationSchedule> {
    const res = await this.apolloClient.mutate({
      mutation: CreateRotationSchedule,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createRotationScheduleWithCalendar) {
      throw Error("Failed to create rotation schedule.");
    }

    const rotationSchedule = res.data.createRotationScheduleWithCalendar;

    invariant(
      rotationSchedule.session,
      "Rotation Schedule must have a session"
    );

    const sanitizeRotationSchedule = {
      calendarDayIds: rotationSchedule.calendarDays.map((day) => day.id),
      createdAt: rotationSchedule.createdAt,
      deletedAt: rotationSchedule.deletedAt,
      id: rotationSchedule.id,
      name: rotationSchedule.name,
      rotationDayIds: rotationSchedule.rotationDays.map((day) => day.id),
      sessionId: rotationSchedule.session.id,
      skipNoSchoolDays: rotationSchedule.skipNoSchoolDays,
      daysOfWeek: rotationSchedule.daysOfWeek,
      gradeLevels: rotationSchedule.gradeLevels,
    };

    // TO DO - fetch the calendars for this session again since we just added a new one
    this.rootStore.calendars.fetchSessionRotationCalendars(
      rotationSchedule.session.id
    );

    return this.add(sanitizeRotationSchedule);
  }

  async update(
    data: RotationScheduleUpdateInput,
    where: RotationScheduleWhereUniqueInput
  ): Promise<RotationSchedule> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateRotationSchedule,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateRotationSchedule) {
      throw Error("Failed to update rotation schedule.");
    }

    const rotationSchedule = res.data.updateRotationSchedule;

    invariant(
      rotationSchedule.session,
      "Rotation Schedule must have a session"
    );

    const sanitizeRotationSchedule = {
      calendarDayIds: rotationSchedule.calendarDays.map((day) => day.id),
      createdAt: rotationSchedule.createdAt,
      deletedAt: rotationSchedule.deletedAt,
      id: rotationSchedule.id,
      name: rotationSchedule.name,
      rotationDayIds: rotationSchedule.rotationDays.map((day) => day.id),
      sessionId: rotationSchedule.session.id,
      skipNoSchoolDays: rotationSchedule.skipNoSchoolDays,
      daysOfWeek: rotationSchedule.daysOfWeek,
      gradeLevels: rotationSchedule.gradeLevels,
    };

    return this.add(sanitizeRotationSchedule);
  }

  getRotationSchedulesForSession = (sessionId: string): RotationSchedule[] => {
    console.log("get rotation schedules for Session Id", sessionId);

    return this.sortedData.filter(
      (schedule) => schedule.sessionId === sessionId && !schedule.deletedAt
    );
  };
}
