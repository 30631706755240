import * as DropdownMenuRadix from "@radix-ui/react-dropdown-menu";
import React from "react";

type DropdownMenuWrapperProps = {
  triggerComponent: React.ReactNode;
  asChild?: boolean;
  sideOffset?: number;
  width?: number;
  fullWidth?: boolean;
  disabled?: boolean;
} & DropdownMenuRadix.DropdownMenuProps;

const DropdownMenu: React.FC<DropdownMenuWrapperProps> = ({
  triggerComponent,
  children,
  asChild = true,
  sideOffset = 5,
  width = 220,
  fullWidth = false,
  disabled = false,
  ...rest
}) => {
  return (
    <DropdownMenuRadix.Root {...rest}>
      <DropdownMenuRadix.Trigger
        asChild={asChild}
        style={{
          width: fullWidth ? "100%" : "auto",
        }}
        disabled={disabled}
      >
        {triggerComponent}
      </DropdownMenuRadix.Trigger>

      <DropdownMenuRadix.Portal className="z-[500]">
        <DropdownMenuRadix.Content
          className="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade shadow-dropdown-shadow z-[500] flex min-w-[220px] flex-col rounded-md bg-white will-change-[opacity,transform]"
          sideOffset={sideOffset}
          collisionPadding={20}
          style={{
            width,
          }}
        >
          {children}
        </DropdownMenuRadix.Content>
      </DropdownMenuRadix.Portal>
    </DropdownMenuRadix.Root>
  );
};

export default DropdownMenu;
