import { TrashIcon } from "@radix-ui/react-icons";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { ActionTypes, DataTypes } from "../utils";
import BlocksCell from "./BlockCell";
import DateCell from "./DateCell";
import NumberCell from "./NumberCell";
import SelectCell from "./SelectCell";
import TextCell from "./TextCell";
import TimeCell from "./TimeCell";
import TitleCell from "./TitleCell";

function Cell(props) {
  const {
    value: initialValue,
    row: { index },
    column: { id, dataType, options, disabled },
    dataDispatch,
    data,
  } = props;

  function getCellElement() {
    switch (dataType) {
      case DataTypes.TEXT:
        return (
          <TextCell
            initialValue={initialValue}
            rowIndex={index}
            columnId={id}
            dataDispatch={dataDispatch}
            disabled={disabled}
          />
        );
      case DataTypes.NUMBER:
        return (
          <NumberCell
            initialValue={initialValue}
            rowIndex={index}
            columnId={id}
            dataDispatch={dataDispatch}
          />
        );
      case DataTypes.SELECT:
        return (
          <SelectCell
            initialValue={initialValue}
            options={options}
            rowIndex={index}
            columnId={id}
            dataDispatch={dataDispatch}
          />
        );
      case DataTypes.TITLE:
        const url = data[index].url;

        return (
          <TitleCell
            initialValue={initialValue}
            rowIndex={index}
            columnId={id}
            dataDispatch={dataDispatch}
            url={url}
          />
        );
      case DataTypes.DATE:
        const dateResource = data[index].unitMobx;
        const dates = toJS(dateResource.unitDates);

        // const min = state;

        return (
          <DateCell
            initialValue={dates}
            rowIndex={index}
            columnId={id}
            dataDispatch={dataDispatch}
            min={undefined}
            max={undefined}
          />
        );

      case DataTypes.ROW_NUMBER:
        return (
          <div className="h-full w-full p-2">
            <div className="text-slate12 font-medium">{index + 1}.</div>
          </div>
        );
      case DataTypes.DELETE_ROW:
        return (
          <div className="h-full w-full p-2">
            <div className="text-slate12 font-medium">
              <button
                className="h-6 w-6"
                onClick={() => {
                  dataDispatch({
                    type: ActionTypes.DELETE_ROW,
                    rowIndex: index,
                  });
                }}
              >
                <TrashIcon className="text-red10 h-6 w-6" />
              </button>
            </div>
          </div>
        );
      case DataTypes.TIME:
        return (
          <TimeCell
            initialValue={initialValue}
            rowIndex={index}
            columnId={id}
            dataDispatch={dataDispatch}
          />
        );
      case DataTypes.BLOCKS:
        const blockDropdownOptions = data[index].blockDropdownOptions;

        return (
          <BlocksCell
            id={"block"}
            dropdownTitle="Block"
            loading={false}
            options={blockDropdownOptions}
            selectedValues={initialValue ? [initialValue] : []}
            onSelect={(blocks) => {
              dataDispatch({
                type: ActionTypes.UPDATE_CELL,
                columnId: id,
                rowIndex: index,
                value: blocks.id,
              });
            }}
            multiselect={false}
            onRemove={() => {
              dataDispatch({
                type: ActionTypes.UPDATE_CELL,
                columnId: id,
                rowIndex: index,
                value: undefined,
              });
            }}
          />
        );
      default:
        return <span></span>;
    }
  }

  return getCellElement();
}

export default observer(Cell);
