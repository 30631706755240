import invariant from "invariant";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import Event from "../../../models/Event";
import { DashboardBreakpoint } from "../../../types";
import { eventTypeToStringMap } from "../../../utils/schedulingEnums";
import { EnumEventEventType } from "../../../__generated__/graphql";
import Button from "../../Button";
import RotationEventCreateAndEditModal from "../../CRUD/CreateAndEdit/Event";

type RotationEventItemProps = {
  breakpoint: DashboardBreakpoint;
  event: Event;
};

function RotationEventItem({ breakpoint, event }: RotationEventItemProps) {
  const [showEditRotationEventModal, setShowEditRotationEventModal] =
    useState(false);

  const { addToast } = useToastsContext();

  //   const { districts, sessions, ui } = useStores();

  //   const { activeSessionId, activeDistrictId } = ui;

  //   if (!activeSessionId || !activeDistrictId) {
  //     return null;
  //   }

  //   const district = districts.getByUrlParam(activeDistrictId);

  //   const session = sessions.getByUrlParam(activeSessionId);

  //   if (!district || !session) {
  //     return null;
  //   }

  const renderEventTypeBadge = (eventType: EnumEventEventType) => {
    switch (eventType) {
      case EnumEventEventType.FirstDay:
        return (
          <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700">
            {eventTypeToStringMap[eventType]}
          </span>
        );
      case EnumEventEventType.LastDay:
        return (
          <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700">
            {eventTypeToStringMap[eventType]}
          </span>
        );
      case EnumEventEventType.Holiday:
        return (
          <span className="inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800">
            {eventTypeToStringMap[eventType]}
          </span>
        );
      case EnumEventEventType.NoSchool:
        return (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
            {eventTypeToStringMap[eventType]}
          </span>
        );
      case EnumEventEventType.Event:
        return (
          <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
            {eventTypeToStringMap[eventType]}
          </span>
        );
      case EnumEventEventType.Period:
        return (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
            {eventTypeToStringMap[eventType]}
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center rounded-md bg-pink-100 px-2 py-1 text-xs font-medium text-pink-800">
            {eventTypeToStringMap[eventType]}
          </span>
        );
    }
  };

  const renderRotationEventItemLg = () => {
    const { eventType, allDay, date, startTime, endTime } = event;

    invariant(eventType, "Event type must be defined");

    const dateCaseSatisfied =
      eventType === EnumEventEventType.FirstDay ||
      eventType === EnumEventEventType.LastDay ||
      eventType === EnumEventEventType.Holiday ||
      eventType === EnumEventEventType.NoSchool;

    const allDayEventCaseSatisfied =
      (eventType === EnumEventEventType.Event ||
        eventType === EnumEventEventType.Period ||
        eventType === EnumEventEventType.Occasion) &&
      allDay;

    const timeCaseSatisfied =
      (eventType === EnumEventEventType.Event ||
        eventType === EnumEventEventType.Period ||
        eventType === EnumEventEventType.Occasion) &&
      !allDay;

    return (
      <tr key={event.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <span className="mr-3">{event.title}</span>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {renderEventTypeBadge(event.eventType)}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {event.allDay ? "Yes" : "No"}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {(dateCaseSatisfied || allDayEventCaseSatisfied) && event.date
            ? DateTime.fromISO(event.date, {
                zone: "utc",
              }).toFormat("LLL dd, yyyy")
            : event.startTime
            ? DateTime.fromISO(event.startTime, {
                zone: event.timezone || "utc",
              }).toFormat("LLL dd, yyyy hh:mm a")
            : null}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {!dateCaseSatisfied && !allDayEventCaseSatisfied && event.endTime
            ? DateTime.fromISO(event.endTime, {
                zone: event.timezone || "utc",
              }).toFormat("LLL dd, yyyy hh:mm a")
            : null}
        </td>
        <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
          <Button
            onClick={() => setShowEditRotationEventModal(true)}
            type="button"
            theme="editor"
            buttonText="Edit"
            padding="medium"
            width="48"
          />
        </td>
      </tr>
    );
  };

  // TBD
  const renderRotationEventItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditRotationEventModal && (
        <RotationEventCreateAndEditModal
          isEditing={true}
          event={event}
          calendarId={event.calendarId}
          onClose={() => setShowEditRotationEventModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditRotationEventModal(false);

            addToast("Event updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg"
        ? renderRotationEventItemLg()
        : renderRotationEventItemSm()}
    </React.Fragment>
  );
}

export default observer(RotationEventItem);
