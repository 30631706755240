import { computed, observable } from "mobx";
import DeletableModel from "./DeletableModel";

class BlockSchedule extends DeletableModel {
  @observable
  alternateScheduleId: string | null;

  @observable
  blockId: string;

  @observable
  endTime: string;

  @observable
  rotationDayId: string | null;

  @observable
  startTime: string;

  @computed
  get blockName(): string {
    const { blocks } = this.store.rootStore;

    const block = blocks.get(this.blockId);

    if (!block) {
      return "";
    }

    return block.abbreviation;
  }

  @computed
  get blockColor(): string | null {
    const { blocks } = this.store.rootStore;

    const block = blocks.get(this.blockId);

    if (!block) {
      return "";
    }

    return block.color;
  }

  @computed
  get rotationDayName(): string | null {
    const { rotationDays } = this.store.rootStore;

    if (!this.rotationDayId) {
      return null;
    }

    const rotationDay = rotationDays.get(this.rotationDayId);

    if (!rotationDay) {
      return null;
    }

    return rotationDay.abbreviation;
  }
}

export default BlockSchedule;
