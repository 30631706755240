import { XMarkIcon } from "@heroicons/react/20/solid";
import * as Dialog from "@radix-ui/react-dialog";

import React from "react";
import Button from "../Button";

type DialogProps = {
  actionType: "delete" | "edit" | "create" | "confirm";
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  actionText: string;
  children?: React.ReactNode;
  disabled?: boolean;
  trackingName?: string;
};

export default function ConfirmationDialog({
  open,
  onClose,
  onSubmit,
  title,
  actionText,
  children,
  actionType = "confirm",
  disabled = false,
  trackingName,
}: DialogProps) {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-[10001]" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] z-[10001] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="text-slate12 m-0 text-[17px] font-medium">
            {title}
          </Dialog.Title>
          <Dialog.Description className="text-slate11 mt-[10px] mb-5 text-[15px] leading-normal">
            {actionText}
          </Dialog.Description>
          {children}
          <div className="mt-[25px] flex justify-end">
            <Dialog.Close asChild>
              {actionType === "delete" ? (
                <Button
                  theme="destructive"
                  type="button"
                  onClick={() => {
                    onSubmit();
                    // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                    setTimeout(
                      () => (document.body.style.pointerEvents = ""),
                      50
                    );
                  }}
                  buttonText={"Delete"}
                  disabled={disabled}
                  trackingName={trackingName}
                />
              ) : actionType === "edit" || actionType === "create" ? (
                <Button
                  theme="constructive"
                  type="button"
                  onClick={() => {
                    onSubmit();
                    // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                    setTimeout(
                      () => (document.body.style.pointerEvents = ""),
                      50
                    );
                  }}
                  buttonText={"Save"}
                  disabled={disabled}
                  trackingName={trackingName}
                />
              ) : (
                <Button
                  theme="primary"
                  type="button"
                  onClick={() => {
                    onSubmit();
                    // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                    setTimeout(
                      () => (document.body.style.pointerEvents = ""),
                      50
                    );
                  }}
                  buttonText={"Confirm"}
                  disabled={disabled}
                  trackingName={trackingName}
                />
              )}
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
              onClick={() => {
                onClose();
                // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                setTimeout(() => (document.body.style.pointerEvents = ""), 50);
              }}
            >
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
