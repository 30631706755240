import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import RotationScheduleCreateAndEditModal from "../components/CRUD/CreateAndEdit/RotationSchedule";
import PageLayout from "../components/PageLayout";
import ListOfRotationSchedules from "../components/RotationSchedules/ListOfRotationSchedules";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function RotationSchedules() {
  const [showCreateRotationScheduleModal, setShowCreateRotationScheduleModal] =
    useState(false);

  const { ui, districts, rotationSchedules, sessions } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId, activeSessionId } = ui;

  if (!activeDistrictId || !activeSessionId) return null;

  // const activeDistrict = districts.getByUrlParam(activeDistrictId);

  // const activeSession = sessions.getByUrlParam(activeSessionId);

  // if (!activeDistrict || !activeSession) return null;

  const sessionRotationSchedules =
    rotationSchedules.getRotationSchedulesForSession(activeSessionId);

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateRotationScheduleModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Rotation Cycle</h2>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfRotationSchedules
            key={sessionRotationSchedules.length}
            rotationSchedules={sessionRotationSchedules}
          />
        </div>
      </PageLayout>
      {showCreateRotationScheduleModal && (
        <RotationScheduleCreateAndEditModal
          onClose={() => setShowCreateRotationScheduleModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateRotationScheduleModal(false);

            addToast("Rotation Schedule created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(RotationSchedules);
