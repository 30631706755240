import { SizeIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { Link } from "react-router-dom";
import Tooltip from "../../Tooltip";
import { ActionTypes } from "../utils";

export default function TitleCell({
  initialValue,
  columnId,
  rowIndex,
  dataDispatch,
  url,
}) {
  const [value, setValue] = useState({ value: initialValue, update: false });

  function onChange(e) {
    setValue({ value: e.target.value, update: false });
  }

  function onBlur(e) {
    setValue((old) => ({ value: old.value, update: true }));
  }

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({
        type: ActionTypes.UPDATE_TITLE,
        columnId,
        rowIndex,
        value: value.value,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.update, columnId, rowIndex]);

  return (
    <div className="z-1 relative h-full  w-full">
      <ContentEditable
        html={(value.value && value.value.toString()) || ""}
        onChange={onChange}
        onBlur={onBlur}
        className={clsx(
          "title-input",
          "decoration-slate8 underline underline-offset-1"
        )}
      />
      <Tooltip content="Click to open in editor" dir="top">
        <Link
          to={url}
          className={clsx(
            "open-button",
            "text-slate11 border-slate8 hover:bg-slate2 z-10 m-1 items-center rounded-lg border-[1px] border-solid bg-white py-1 px-2 shadow",
            "max-h-[36px] min-h-[36px]"
          )}
        >
          <SizeIcon className="text-slate11 mr-1 h-5 w-5" />
          Open
        </Link>
      </Tooltip>
    </div>
  );
}
