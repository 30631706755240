import { gql } from "../../__generated__/gql";

export const CreateEnrollment = gql(`
    mutation CreateEnrollment($data: EnrollmentCreateInput!) {
        createEnrollment(data: $data) {
            classV2 {
                id
            }
            createdAt 
            deletedAt 
            edlinkId 
            endDate 
            id 
            identifiers 
            primary 
            properties 
            role 
            section {
                id 
            }
            startDate 
            state 
            updatedAt 
            user {
                id
            }
        }
    }
`);

export const UpdateEnrollment = gql(`
    mutation UpdateEnrollment($data: EnrollmentUpdateInput!, $where: EnrollmentWhereUniqueInput!) {
        updateEnrollment(data: $data, where: $where) {
            classV2 {
                id
            }
            createdAt 
            deletedAt 
            edlinkId 
            endDate 
            id 
            identifiers 
            primary 
            properties 
            role 
            section {
                id 
            }
            startDate 
            state 
            updatedAt 
            user {
                id
            }
        }
    }
`);
