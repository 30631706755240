import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateSectionV2,
  UpdateSectionV2,
} from "../graphql/sectionV2/sectionV2.mutations";
import {
  GetSectionV2,
  GetSectionV2s,
} from "../graphql/sectionV2/sectionV2.queries";
import SectionV2 from "../models/SectionV2";
import {
  SectionV2CreateInput,
  SectionV2UpdateInput,
  SectionV2WhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class SectionsStore extends BaseStore<SectionV2> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, SectionV2, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetch = async (id: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetSectionV2,
          variables: {
            where: {
              id,
            },
          },
        })
        .then((res) => {
          const sectionV2 = res.data.sectionV2;

          invariant(sectionV2, "Section must exist");
          invariant(sectionV2.classV2, "Section must have a class");
          invariant(sectionV2.state, "Section must have a state");

          // Check if the ClassV2 is already in the store and fetch it if not
          const fetchSectionClass = this.rootStore.classes.get(
            sectionV2.classV2.id
          );

          if (!fetchSectionClass) {
            this.rootStore.classes.fetch(sectionV2.classV2.id);
          }

          const sanitizeSection = {
            classV2Id: sectionV2.classV2.id,
            createdAt: sectionV2.createdAt,
            deletedAt: sectionV2.deletedAt,
            description: sectionV2.description,
            edlinkId: sectionV2.edlinkId,
            id: sectionV2.id,
            identifiers: sectionV2.identifiers,
            locale: sectionV2.locale,
            name: sectionV2.name,
            properties: sectionV2.properties,
            state: sectionV2.state,
            timezone: sectionV2.timezone,
            updatedAt: sectionV2.updatedAt,
            urlId: sectionV2.urlId,
          };

          this.add(sanitizeSection);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchClassSections = (classId: string) => {
    this.isLoading = true;

    const { ui } = this.rootStore;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetSectionV2s,
          variables: {
            where: {
              classV2: {
                id: classId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const sections = res.data.sectionV2s;

          console.log("Fetched sections", sections);

          if (!sections) {
            reject(false);
          }

          runInAction("Populate sections", () => {
            sections.forEach((sectionV2, index) => {
              console.log(sectionV2);

              invariant(sectionV2.classV2, "Section must have a class");
              invariant(sectionV2.state, "Section must have a state");

              // This is the first section, so set it as the active section
              if (index === 0) {
                console.log("Setting active section", sectionV2.urlId);
                ui.setActiveSectionId(sectionV2.urlId);
              }

              const sanitizeSection = {
                classV2Id: sectionV2.classV2.id,
                createdAt: sectionV2.createdAt,
                deletedAt: sectionV2.deletedAt,
                description: sectionV2.description,
                edlinkId: sectionV2.edlinkId,
                id: sectionV2.id,
                identifiers: sectionV2.identifiers,
                locale: sectionV2.locale,
                name: sectionV2.name,
                properties: sectionV2.properties,
                state: sectionV2.state,
                timezone: sectionV2.timezone,
                updatedAt: sectionV2.updatedAt,
                urlId: sectionV2.urlId,
              };

              this.add(sanitizeSection);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<SectionV2>): Promise<SectionV2> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as SectionV2CreateInput);
      } else {
        return this.update(
          rest as SectionV2UpdateInput,
          { id } as SectionV2WhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: SectionV2CreateInput): Promise<SectionV2> {
    const res = await this.apolloClient.mutate({
      mutation: CreateSectionV2,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createSectionForClass) {
      throw Error("Failed to create district.");
    }

    const sectionV2 = res.data.createSectionForClass;

    invariant(sectionV2.classV2, "Section must have a class");
    invariant(sectionV2.state, "Section must have a state");

    const sanitizeSection = {
      classV2Id: sectionV2.classV2.id,
      createdAt: sectionV2.createdAt,
      deletedAt: sectionV2.deletedAt,
      description: sectionV2.description,
      edlinkId: sectionV2.edlinkId,
      id: sectionV2.id,
      identifiers: sectionV2.identifiers,
      locale: sectionV2.locale,
      name: sectionV2.name,
      properties: sectionV2.properties,
      state: sectionV2.state,
      timezone: sectionV2.timezone,
      updatedAt: sectionV2.updatedAt,
      urlId: sectionV2.urlId,
    };

    return this.add(sanitizeSection);
  }

  async update(
    data: SectionV2UpdateInput,
    where: SectionV2WhereUniqueInput
  ): Promise<SectionV2> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateSectionV2,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateSectionV2) {
      throw Error("Failed to update section.");
    }

    const sectionV2 = res.data.updateSectionV2;

    invariant(sectionV2.classV2, "Section must have a class");
    invariant(sectionV2.state, "Section must have a state");

    const sanitizeSection = {
      classV2Id: sectionV2.classV2.id,
      createdAt: sectionV2.createdAt,
      deletedAt: sectionV2.deletedAt,
      description: sectionV2.description,
      edlinkId: sectionV2.edlinkId,
      id: sectionV2.id,
      identifiers: sectionV2.identifiers,
      locale: sectionV2.locale,
      name: sectionV2.name,
      properties: sectionV2.properties,
      state: sectionV2.state,
      timezone: sectionV2.timezone,
      updatedAt: sectionV2.updatedAt,
      urlId: sectionV2.urlId,
    };

    return this.add(sanitizeSection);
  }

  getSectionsForClass = (classId: string): SectionV2[] => {
    return this.sortedData.filter(
      (section) => section.classV2Id === classId && !section.deletedAt
    );
  };

  getByUrlParam = (urlId: string): SectionV2 | undefined => {
    return this.sortedData.find(
      (section) => section.urlId === urlId && !section.deletedAt
    );
  };
}
