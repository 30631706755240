import { ChevronDownIcon } from "@radix-ui/react-icons";
import { observer } from "mobx-react";
import React, { useState } from "react";
import ListOfBlocks from "../components/Blocks/ListOfBlocks";
import Button from "../components/Button";
import BlockCreateAndEditModal from "../components/CRUD/CreateAndEdit/Block";
import RotationsDropdown from "../components/Dropdown/RotationsDropdown";
import PageLayout from "../components/PageLayout";
import Tooltip from "../components/Tooltip";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Blocks() {
  const [showCreateBlockModal, setShowCreateBlockModal] = useState(false);

  const { ui, districts, blocks, sessions } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId, activeSessionId } = ui;

  if (!activeDistrictId || !activeSessionId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  const activeSession = sessions.getByUrlParam(activeSessionId);

  if (!activeDistrict || !activeSession) return null;

  const activeRotationSchedule = activeSession.activeRotationSchedule;

  if (!activeRotationSchedule) return null;

  const scheduleBlocks = blocks.getBlocksForRotationSchedule(
    activeRotationSchedule.id
  );

  console.log("scheduleBlocks", scheduleBlocks);

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateBlockModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div className="flex items-center">
            <h2 className="text-xl font-semibold">Blocks</h2>
            {/* Rotations Dropdown */}
            <RotationsDropdown>
              <Tooltip content="Change section" dir="top" asChild={false}>
                <button className="border-slate7 ml-4 flex items-center justify-center rounded-lg border-[1px] border-solid p-1 px-2 shadow-sm">
                  {/* Name */}
                  <div className="ml-2 text-sm font-medium">
                    {activeRotationSchedule.name}
                  </div>
                  <span className="w-1" />
                  <ChevronDownIcon className="h-5 w-5" />
                </button>
              </Tooltip>
            </RotationsDropdown>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfBlocks key={scheduleBlocks.length} blocks={scheduleBlocks} />
        </div>
      </PageLayout>
      {showCreateBlockModal && (
        <BlockCreateAndEditModal
          onClose={() => setShowCreateBlockModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateBlockModal(false);

            addToast("Block created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Blocks);
