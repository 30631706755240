import AlternateSchedule from "../models/AlternateSchedule";
import Block from "../models/Block";
import ClassV2 from "../models/ClassV2";
import CourseV2 from "../models/CourseV2";
import Session from "../models/Session";

export function homeRoute(): string {
  return "/";
}

export function districtsRoute(): string {
  return "/districts";
}

export function schoolsRoute(): string {
  return "/schools";
}

export function usersRoute(): string {
  return "/users";
}

export function coursesRoute(): string {
  return "/courses";
}

export function classesRoute(): string {
  return "/classes";
}

export function sessionsRoute(): string {
  return "/sessions";
}

// Class related URLs
export function classRoute(classV2: ClassV2, tab?: string): string {
  if (tab) {
    return `/class/${classV2.urlId}/${tab}`;
  }
  return `/class/${classV2.urlId}`;
}

// Course related URLs
export function courseRoute(course: CourseV2, tab?: string): string {
  if (tab) {
    return `/course/${course.urlId}/${tab}`;
  }
  return `/course/${course.urlId}`;
}

export function userRoute(userId: string, tab?: string): string {
  if (tab) {
    return `/user/${userId}/${tab}`;
  }
  return `/user/${userId}`;
}

export function sessionRoute(session: Session, tab?: string): string {
  if (tab) {
    return `/session/${session.urlId}/${tab}`;
  }
  return `/session/${session.urlId}`;
}

// export function paymentsRoute(): string {
//   return "/payments";
// }

// export function analyticsRoute(): string {
//   return "/analytics";
// }

// export function districtRoute(district: District, tab?: string): string {
//   if (tab) {
//     return `/district/${district.urlId}/${tab}`;
//   }

//   return `/district/${district.urlId}/schools`;
// }

// export function districtSchedulingRoute(
//   district: District,
//   session: Session,
//   tab?: string
// ): string {
//   if (tab) {
//     return `/district/${district.urlId}/session/${session.urlId}/${tab}`;
//   }

//   return `/district/${district.urlId}/session/${session.urlId}/calendar`;
// }

export function scheduleRotationDaysRoute(
  sessionId: string,
  rotationScheduleId: string
): string {
  return `/session/${sessionId}/rotations/${rotationScheduleId}`;
}

// export function districtSchoolRoute(
//   district: District,
//   school: School,
//   tab?: string
// ): string {
//   if (tab) {
//     return `/district/${district.urlId}/school/${school.urlId}/${tab}`;
//   }

//   return `/district/${district.urlId}/school/${school.urlId}/classes`;
// }

// export function districtSchoolClassRoute(
//   district: District,
//   school: School,
//   classV2: ClassV2,
//   tab?: string
// ): string {
//   if (tab) {
//     return `/district/${district.urlId}/school/${school.urlId}/class/${classV2.urlId}/${tab}`;
//   }

//   return `/district/${district.urlId}/school/${school.urlId}/class/${classV2.urlId}/sections`;
// }

// export function userRoute(user: DistrictUser, tab?: string): string {
//   if (tab) {
//     return `/user/${user.id}/${tab}`;
//   }

//   return `/user/${user.id}/home`;
// }

export function scheduleBlockRoute(
  // district: District,
  session: Session,
  block: Block
): string {
  return `/session/${session.urlId}/blocks/${block.id}`;
}

export function scheduleAlternateDayRoute(
  session: Session,
  alternateSchedule: AlternateSchedule
): string {
  return `/session/${session.urlId}/alternate-schedules/${alternateSchedule.id}`;
}

export function scheduleClassTimeblockingRoute(
  // district: District,
  session: Session,
  classV2: ClassV2
): string {
  return `session/${session.urlId}/class-schedules/${classV2.urlId}`;
}
