import {
  DropdownAction,
  DropdownActionContext,
  DropdownItemButton,
  DropdownRadioGroup,
  DropdownRadioItem,
  DropdownSubmenu,
  Optional,
  PopoverAction,
  PopoverActionContext,
  PopoverItemButton,
} from "../types";

import { v4 as uuidv4 } from "uuid";

function getPropertyFromDropdownContext<T>(
  value: T | ((context: DropdownActionContext) => T),
  context: DropdownActionContext
): T {
  if (typeof value === "function") {
    return (value as (context: DropdownActionContext) => T)(context);
  } else {
    return value;
  }
}

export function createDropdownAction(
  definition: Optional<DropdownAction, "id">
): DropdownAction {
  return {
    ...definition,
    onSelect: definition.onSelect
      ? (context: DropdownActionContext) => {
          //   if (definition.trackingName) {
          //     mixpanel.track(definition.trackingName, {
          //       componentType: "dropdown",
          //     });
          //   }

          definition.onSelect?.(context);
        }
      : undefined,
    id: uuidv4(),
  };
}

// Convert the action to a DropdownItemButton/DropdownSubmenu/DropdownRadioGroup/DropdownRadioItem

export function actionToDropdownItem(
  action: DropdownAction,
  context: DropdownActionContext
):
  | DropdownItemButton
  | DropdownSubmenu
  | DropdownRadioGroup
  | DropdownRadioItem {
  // Convert actions to dropdown item of type button or submenu depending on the presence of children.

  const show =
    action.show === undefined
      ? true
      : getPropertyFromDropdownContext(action.show, context);

  const value =
    typeof action.value === "string"
      ? action.value
      : getPropertyFromDropdownContext(action.value, context);

  if (action.type === "submenu" && action.children) {
    const submenuItems = action.children.map((childAction) =>
      actionToDropdownItem(childAction, context)
    );

    return {
      type: "submenu",
      text: action.text,
      icon: action.icon,
      items: submenuItems.filter((item) => item !== null),
      show: submenuItems.length > 0 && show,
    } as DropdownSubmenu;
  } else if (action.type === "radio-group" && action.children) {
    const radioItems = action.children.map((childAction) =>
      actionToDropdownItem(childAction, context)
    ) as DropdownRadioItem[];

    return {
      type: "radio-group",
      text: action.text,
      items: radioItems.filter((item) => item !== null),
      value,
      onValueChange: (value: string) => {
        action.onValueChange?.(context, value);
      },
      itemIndicator: action.itemIndicator,
    } as DropdownRadioGroup;
  } else if (action.type === "radio-item") {
    return {
      type: "radio-item",
      text: action.text,
      value: action.value,
      show,
      icon: action.icon,
    } as DropdownRadioItem;
  } else {
    return {
      type: "button",
      text: action.text,
      icon: action.icon,
      onClick: (e) => {
        action.onSelect?.(context);
      },
      destructive: action.destructive,
      show,
    } as DropdownItemButton;
  }
}

function getPropertyFromPopoverContext<T>(
  value: T | ((context: PopoverActionContext) => T),
  context: PopoverActionContext
): T {
  if (typeof value === "function") {
    return (value as (context: PopoverActionContext) => T)(context);
  } else {
    return value;
  }
}

export function createPopoverAction(
  definition: Optional<PopoverAction, "id">
): PopoverAction {
  return {
    ...definition,
    onSelect: definition.onSelect
      ? (context: PopoverActionContext) => {
          //   if (definition.trackingName) {
          //     mixpanel.track(definition.trackingName, {
          //       componentType: "popover",
          //     });
          //   }

          definition.onSelect?.(context);
        }
      : undefined,
    id: uuidv4(),
  };
}

// Convert an action a PopoveritemButton

export function actionToPopoverItem(
  action: PopoverAction,
  context: PopoverActionContext
): PopoverItemButton {
  const show =
    action.show === undefined
      ? true
      : getPropertyFromPopoverContext(action.show, context);

  return {
    type: "button",
    text: action.text,
    description: action.description,
    icon: action.icon,
    iconColor: action.iconColor,
    onClick: (e) => {
      action.onSelect?.(context);
    },
    show,
  } as PopoverItemButton;
}
