import clsx from "clsx";
import { Link } from "react-router-dom";
import { PageTab } from "../../types";

type PageTabsProps = {
  currentTab: string;
  tabs: PageTab[];
  activeTabControls?: React.ReactNode;
};

export default function PageTabs({
  currentTab,
  tabs,
  activeTabControls,
}: PageTabsProps) {
  return (
    <div className="flex w-full justify-between">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="border-slate5 focus:border-slate7 focus:ring-slate6 block w-full rounded-md"
          defaultValue={
            tabs.find((tab) => tab.current(currentTab))?.name || undefined
          }
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden w-full items-center sm:flex">
        <div className="border-slate5 flex w-full items-center justify-between">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab: PageTab) => {
              const tabStyle =
                "relative group inline-flex items-center border-b-2 py-4 px-1 text-[15px] font-semibold";

              const activeTabStyle = "";
              const inactiveTabStyle =
                "border-transparent text-slate10 hover:border-slate8 hover:text-slate11";

              if (tab.to) {
                return (
                  <Link
                    key={tab.name}
                    to={tab.to}
                    className={clsx(
                      tab.current(currentTab)
                        ? activeTabStyle
                        : inactiveTabStyle,
                      tabStyle
                    )}
                    aria-current={tab.current(currentTab) ? "page" : undefined}
                  >
                    {tab.icon && (
                      <tab.icon
                        className={clsx(
                          tab.current(currentTab)
                            ? "text-slate12"
                            : "text-slate10 group-hover:text-slate11",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                    )}
                    <span>{tab.name}</span>
                  </Link>
                );
              }

              return (
                <button
                  key={tab.name}
                  onClick={tab.onClick}
                  className={clsx(
                    tab.current(currentTab)
                      ? "bg-gray-900 text-gray-100"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-300 hover:text-gray-900",
                    "my-1 rounded-lg px-3 py-2 text-sm font-medium"
                  )}
                  aria-current={tab.current(currentTab) ? "page" : undefined}
                >
                  {tab.icon && (
                    <tab.icon
                      className={clsx(
                        tab.current(currentTab)
                          ? "text-slate12"
                          : "text-slate10 group-hover:text-slate11",
                        "-ml-0.5 mr-2 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                  )}
                  <span>{tab.name}</span>
                  {/* {tab.current(currentTab) && (
                    <motion.div
                      className={clsx(
                        "bg-slate12 absolute -bottom-0.5 left-0 z-10 h-[3px] w-full rounded-sm"
                      )}
                      custom={{
                        initial: {
                          transform: "translateY(-50%)",
                        },
                        animate: {
                          transform: "translateY(-50%)",
                        },
                      }}
                      layoutId="underline"
                    />
                  )} */}
                </button>
              );
            })}
          </nav>
          {/* ActiveTabControls */}
          {activeTabControls}
        </div>
      </div>
    </div>
  );
}
