import {
  AdjustmentsVerticalIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
  PaintBrushIcon,
  PlayIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DownloadIcon,
  FileIcon,
  TokensIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
// import mixpanel from "mixpanel-browser";
import logo from "../../assets/logos/logo-no-text.png";
import styles from "./index.module.css";

interface ButtonLargeProps {
  theme:
    | "primary"
    | "secondary"
    | "tertiary"
    | "destructive"
    | "constructive"
    | "editor";
  icon?: string;
  type: "button" | "submit" | "reset" | undefined;
  buttonText?: string;
  onClick?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: string;
  showSpinner?: boolean;
  className?: string;
  alignLeft?: boolean;
  iconPosition?: "left" | "right";
  rounded?: "full" | "medium" | "none";
  trackingName?: string;
}

export default function ButtonLarge(props: ButtonLargeProps) {
  const {
    icon,
    buttonText = "",
    onClick,
    disabled,
    fullWidth,
    width,
    type,
    theme = "primary",
    className: classNameProp,
    alignLeft,
    rounded = "full",
    iconPosition = "left",
    trackingName,
  } = props;

  // Write switch function for icon later on if needed (e.g. create, plus, delete, etc.);
  // Primary button = Accent color background and border and hover effect
  // Secondary button = No background but border and hover effect
  // Tertiary button = No background and no border and no hover effect (e.g. in app link)
  // Destructive button = Red background and border and hover effect
  // Constructive button = Green background and border and hover effect

  const renderIcon = () => {
    switch (icon) {
      case "chevron-left":
        return <ChevronLeftIcon className={styles.buttonIconLg} />;
      case "comments":
        return (
          <ChatBubbleBottomCenterTextIcon className={styles.buttonIconLg} />
        );
      case "options":
        return <EllipsisHorizontalIcon className={styles.buttonIconLg} />;
      case "play":
        return <PlayIcon className={styles.buttonIconLg} />;
      case "plus":
        return <PlusIcon className={styles.buttonIconLg} />;
      case "adjustments":
        return <AdjustmentsVerticalIcon className={styles.buttonIconLg} />;
      case "paint-brush":
        return <PaintBrushIcon className={styles.buttonIconLg} />;
      case "file":
        return <FileIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />;
      case "templates":
        return <TokensIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />;
      case "import":
        return (
          <DownloadIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />
        );
      case "arrow-left":
        return (
          <ArrowLeftIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />
        );
      case "arrow-right":
        return (
          <ArrowRightIcon className={clsx(styles.buttonIconLg, "h-5 w-5 ")} />
        );
      case "logo":
        return <img src={logo} alt="logo" className="h-6 w-6" />;
      default:
        return null;
    }
  };

  const renderTheme = () => {
    switch (theme) {
      case "primary":
        return styles.buttonPrimary;
      case "secondary":
        return styles.buttonSecondary;
      case "tertiary":
        return styles.buttonTertiary;
      case "destructive":
        return styles.buttonDestructive;
      case "constructive":
        return styles.buttonConstructive;
      case "editor":
        return styles.buttonEditor;
      default:
        return styles.buttonPrimary;
    }
  };

  return (
    <button
      onClick={() => {
        if (onClick) {
          // if (trackingName) {
          //   mixpanel.track(trackingName, {
          //     componentType: "button",
          //   });
          // }
          onClick();
        }
      }}
      disabled={disabled}
      type={type}
      className={clsx(
        styles.buttonLarge,
        "flex items-center text-sm font-semibold text-white",
        // fullW,
        renderTheme(),
        theme === "editor" ? "" : "rounded-full",
        classNameProp ? classNameProp : "",
        disabled ? styles.disabled : "",
        alignLeft ? "justify-start" : "justify-center"
      )}
      style={{
        width: fullWidth ? "100%" : width ? `${width}px` : "auto",
        borderRadius:
          rounded === "full"
            ? "9999px"
            : rounded === "medium" || theme === "editor"
            ? "8px"
            : "24px",
      }}
    >
      {icon && iconPosition === "left" && <span>{renderIcon()}</span>}
      {icon && buttonText && iconPosition === "left" && (
        <span className="w-2" />
      )}
      {buttonText && <span>{buttonText}</span>}
      {icon && buttonText && iconPosition === "right" && (
        <span className="w-1" />
      )}
      {icon && iconPosition === "right" && <span>{renderIcon()}</span>}
    </button>
  );
}
