import update from "immutability-helper";
import { useEffect, useMemo, useReducer, useState } from "react";
import "./style.css";
import Table from "./Table";
import { ActionTypes, ROTATION_DAY_COLUMNS } from "./utils";

function reducer(state, action) {
  // Action will return the rowIndex and the columnId

  switch (action.type) {
    case ActionTypes.UPDATE_STATE_FROM_PROPS:
      return {
        ...state,
        columns: action.columns,
        data: action.data,
      };
    case ActionTypes.ADD_ROW:
      // create the new rotation day and add it to the table
      if (state.shouldAddNewRow) {
        return;
      }

      state.setShouldAddNewRow(true);

      return update(state, {
        skipReset: { $set: true },
      });

    case ActionTypes.DELETE_ROW:
      // delete the rotation day for the given index and remove it from the table

      if (state.deleteRowIndex !== null) {
        return;
      }

      state.setDeleteRowIndex(action.rowIndex);

      return update(state, {
        skipReset: { $set: true },
      });

    // const newData = [...state.data];
    // newData.splice(action.rowIndex, 1);
    // return update(state, {
    //   data: { $set: newData },
    //   skipReset: { $set: true },
    // });

    case ActionTypes.UPDATE_CELL:
      // state.updateRotationDay(action.rowIndex, action.columnId, action.value);

      if (state.updateRowData !== null) {
        return;
      }

      state.setUpdateRowData({
        rowIndex: action.rowIndex,
        columnId: action.columnId,
        value: action.value,
      });

      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.rowIndex]: { [action.columnId]: { $set: action.value } },
        },
      });

    case ActionTypes.ENABLE_RESET:
      return update(state, { skipReset: { $set: true } });
    default:
      return state;
  }
}

function RotationDayTable(props) {
  const {
    rotationDays,
    addNewRotationDay,
    updateRotationDay,
    removeRotationDay,
  } = props;

  const [shouldAddNewRow, setShouldAddNewRow] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [updateRowData, setUpdateRowData] = useState(null);

  // We must construct the initial state from the units prop

  const initialState = useMemo(() => {
    // Columns are constructed with fixed (type) as well as variable properties (e.g. options)
    // The fixed columns are: Title, Dates, Status, Standards, and Lessons

    const columns = [
      ...ROTATION_DAY_COLUMNS,
      // Variable columns must be constructed from the course unitAttributes (JSON) property
    ];

    return {
      columns,
      data: rotationDays,
      skipReset: false,
      addNewRotationDay,
      removeRotationDay,
      updateRotationDay,
      shouldAddNewRow,
      setShouldAddNewRow,
      deleteRowIndex,
      setDeleteRowIndex,
      updateRowData,
      setUpdateRowData,
    };

    // Variable columns must be constructed from the course unitAttributes (JSON) property
  }, [
    rotationDays,
    addNewRotationDay,
    removeRotationDay,
    updateRotationDay,
    shouldAddNewRow,
    setShouldAddNewRow,
    deleteRowIndex,
    setDeleteRowIndex,
    updateRowData,
    setUpdateRowData,
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);

  // Use effect for adding a new row
  useEffect(() => {
    if (shouldAddNewRow) {
      addNewRotationDay();
      setShouldAddNewRow(false);
    }
  }, [shouldAddNewRow, addNewRotationDay]);

  // Use effect for deleting a row
  useEffect(() => {
    if (deleteRowIndex !== null) {
      removeRotationDay(deleteRowIndex);
      setDeleteRowIndex(null);
    }
  }, [deleteRowIndex, removeRotationDay]);

  // Use effect for updating a row
  useEffect(() => {
    if (updateRowData !== null) {
      updateRotationDay(
        updateRowData.rowIndex,
        updateRowData.columnId,
        updateRowData.value
      );
      setUpdateRowData(null);
    }
  }, [updateRowData, updateRotationDay]);

  useEffect(() => {
    dispatch({ type: ActionTypes.ENABLE_RESET });
  }, [state.data, state.columns]);

  useEffect(() => {
    const columns = [
      ...ROTATION_DAY_COLUMNS,
      // Variable columns must be constructed from the course unitAttributes (JSON) property
    ];

    // Data is constructed from the units prop

    const data = rotationDays.map((rotationDay) => {
      // Status is constructed from the unit's status property

      // Date should be constructed from schedule start and end date
      return {
        id: rotationDay.id,
        abbreviation: rotationDay.abbreviation,
        description: rotationDay.description,
        dayIndex: rotationDay.dayIndex,
      };
    });

    dispatch({
      type: ActionTypes.UPDATE_STATE_FROM_PROPS,
      columns,
      data,
      addNewRotationDay,
      removeRotationDay,
    });
  }, [rotationDays, addNewRotationDay, removeRotationDay]);

  return (
    <div
      className="overflow-hidden"
      style={{
        width: "100%",
        height: "100%",
        padding: 10,
      }}
    >
      <Table
        columns={state.columns}
        data={state.data}
        dispatch={dispatch}
        skipReset={state.skipReset}
      />
    </div>
  );
}

export default RotationDayTable;
