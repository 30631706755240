import { observer } from "mobx-react";
import { useEffect, useReducer } from "react";
import Block from "../../models/Block";
import BlockSchedule from "../../models/BlockSchedule";
import AlternateScheduleEditableTable from "../EditableTable/AlternateSchedule";

export type AlternateScheduleFormEntity = {
  blockId: string;
  blockName: string;
  id?: string;
  startTime?: string;
  endTime?: string;
};

type AlternateScheduleFormState = {
  alternateSchedules: AlternateScheduleFormEntity[];
};

const initialState: AlternateScheduleFormState = {
  alternateSchedules: [],
};

const formReducer = (state: AlternateScheduleFormState, action: any) => {
  switch (action.type) {
    case "update_alternate_schedule":
      const {
        alternateScheduleIndex,
        alternateScheduleKey,
        alternateScheduleValue,
      } = action.payload;

      console.log("alternateScheduleIndex", alternateScheduleIndex);
      console.log("alternateScheduleKey", alternateScheduleKey);
      console.log("alternateScheduleValue", alternateScheduleValue);

      return {
        ...state,
        alternateSchedules: state.alternateSchedules.map(
          (alternateSchedule, index) => {
            if (index === alternateScheduleIndex) {
              return {
                ...alternateSchedule,
                [alternateScheduleKey]: alternateScheduleValue,
              };
            }

            return alternateSchedule;
          }
        ),
      };

    default:
      return state;
  }
};

function AlternateSchedules({
  activeBlockSchedules,
  setModifiedAlternateSchedules,
  blocks,
}: {
  activeBlockSchedules: BlockSchedule[];
  setModifiedAlternateSchedules: React.Dispatch<
    React.SetStateAction<AlternateScheduleFormEntity[]>
  >;
  blocks: Block[];
}) {
  const init = (initialState: AlternateScheduleFormState) => {
    // We loop over all the rotation days since there should be on row per rotation day

    const alternateSchedules: AlternateScheduleFormEntity[] = blocks.map(
      (block) => {
        // Check if there is a block schedule for this rotation day
        const findAlternateDayBlockSchedule = activeBlockSchedules.find(
          (alternateSchedule) => alternateSchedule.blockId === block.id
        );

        if (!findAlternateDayBlockSchedule) {
          return {
            id: undefined,
            blockId: block.id,
            blockName: block.abbreviation,
            startTime: undefined,
            endTime: undefined,
          };
        } else {
          return {
            id: findAlternateDayBlockSchedule.id,
            blockId: block.id,
            blockName: block.abbreviation,
            startTime: findAlternateDayBlockSchedule.startTime,
            endTime: findAlternateDayBlockSchedule.endTime,
          };
        }
      }
    );

    return {
      alternateSchedules,
    };
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  useEffect(() => {
    setModifiedAlternateSchedules(state.alternateSchedules);
  }, [state, setModifiedAlternateSchedules]);

  return (
    <div>
      <AlternateScheduleEditableTable
        alternateSchedules={state.alternateSchedules}
        updateAlternateSchedule={(
          rowIndex: number,
          rowKey: string,
          rowValue: any
        ) => {
          dispatchFormAction({
            type: "update_alternate_schedule",
            payload: {
              alternateScheduleIndex: rowIndex,
              alternateScheduleKey: rowKey,
              alternateScheduleValue: rowValue,
            },
          });
        }}
      />
    </div>
  );
}

export default observer(AlternateSchedules);
