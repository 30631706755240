import clsx from "clsx";
import React, { useMemo } from "react";
import {
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";

import { PlusIcon } from "@radix-ui/react-icons";
import Cell from "./cells/Cell";
import Header from "./header/Header";
import { ActionTypes } from "./utils";

const defaultColumn = {
  minWidth: 50,
  width: 150,
  maxWidth: 400,
  Cell: Cell,
  Header: Header,
  sortType: "alphanumericFalsyLast",
};

export default function Table({
  columns,
  data,
  dispatch: dataDispatch,
  skipReset,
  showNewRow = true,
  blocks,
}) {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return isNaN(rowA.values[columnId])
          ? rowA.values[columnId].localeCompare(rowB.values[columnId])
          : rowA.values[columnId] - rowB.values[columnId];
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
      sortTypes,
      blocks,
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy
  );

  const RenderRow = React.useCallback(
    (row) => {
      prepareRow(row);
      return (
        <div className="tr" {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <div {...cell.getCellProps()} className="td">
              {cell.render("Cell")}
            </div>
          ))}
        </div>
      );
    },
    [prepareRow]
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  const getCurrentSortByColumnTitle = () => {
    const sortByColumn = columns.find(
      (column) => column.id === state.sortBy[0]?.id
    );

    return sortByColumn
      ? sortByColumn.label +
          (state.sortBy[0] && state.sortBy[0]["desc"] ? "↓" : "↑")
      : "";
  };

  return (
    <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
      {state.sortBy.length > 0 && (
        <p className="mb-4">
          Current sort by column: {getCurrentSortByColumnTitle()}
        </p>
      )}
      <div
        {...getTableProps()}
        className={clsx("table", isTableResizing() && "noselect")}
      >
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => column.render("Header"))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()}>
          {rows.map((row) => {
            return RenderRow(row);
          })}
          {showNewRow ? (
            <div
              className="tr add-row text-slate10 text-[15px] font-medium"
              onClick={() => dataDispatch({ type: ActionTypes.ADD_ROW })}
            >
              <span className="mr-2">
                <PlusIcon className="text-slate10 h-6 w-6" />
              </span>
              New
            </div>
          ) : (
            <div className="tr add-row-dummy text-slate10 text-[15px] font-medium" />
          )}
        </div>
      </div>
    </div>
  );
}
