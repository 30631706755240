import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import PageLayout from "../components/PageLayout";
import PageTabs from "../components/Tabs/PageTabs";
import useStores from "../hooks/useStores";
import { PageTab } from "../types";
import { courseRoute } from "../utils/routeHelper";

function Course() {
  const { courseId = "" } = useParams();

  const { sections, ui, courses, enrollments, calendars, classes } =
    useStores();
  const [isLoading, setIsLoading] = useState(true);

  const currentCourse = courses.getByUrlParam(courseId);

  const { tab = "lessons" } = useParams();

  const navigate = useNavigate();

  // Set active district in UI store
  useEffect(() => {
    if (currentCourse) {
      ui.setActiveCourseId(currentCourse.urlId);
    }

    // if (currentSchool) {
    //   ui.setActiveSchoolId(currentSchool.urlId);
    // }

    // if (currentDistrict) {
    //   ui.setActiveDistrictId(currentDistrict.urlId);
    // }

    setIsLoading(false);
    return () => {
      ui.setActiveCourseId(undefined);
    };
  }, [
    currentCourse,
    ui,
    // currentSchool, currentDistrict
  ]);

  // Fetch all school related data
  useEffect(() => {
    if (currentCourse) {
      // sections.fetchClassSections(currentCourse.id);
      // enrollments.fetchEnrollmentsForClass(currentCourse.id);
      classes.getClassesForCourse(currentCourse.id);
    }
  }, [currentCourse]);

  if (!currentCourse) {
    return <div>Course not found</div>;
  }

  const courseHeader = (
    <div className="flex items-center">
      {/* <div
        className=" mr-1 flex items-center justify-center rounded-lg p-1 shadow-sm"
        style={{
          color: courseColor,
          background: courseColor
            ? colorsMap50[colorsMap500Flipped[courseColor]]
            : "var(--slate2)",
        }}
      >
        <IconComponent className="h-6 w-6" />
      </div> */}
      <div className="ml-2 text-xl font-semibold">{currentCourse.name}</div>
    </div>
  );

  const courseTabs: PageTab[] = [
    {
      name: "Lessons",
      onClick: () => {
        navigate(courseRoute(currentCourse, "lessons"));
      },
      current: (currentTab) => currentTab === "lessons",
    },
    {
      name: "Units",
      onClick: () => {
        navigate(courseRoute(currentCourse, "units"));
      },
      current: (currentTab) => currentTab === "units",
    },
    {
      name: "Resources",
      onClick: () => {
        navigate(courseRoute(currentCourse, "resources"));
      },
      current: (currentTab) => currentTab === "resources",
    },
    {
      name: "Classes",
      onClick: () => {
        navigate(courseRoute(currentCourse, "classes"));
      },
      current: (currentTab) => currentTab === "enrollments",
    },
  ];

  if (isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  return (
    <PageLayout
      left={courseHeader}
      title={tab === "lessons" ? "Lessons" : "Units"}
      right={null}
      fullWidth={true}
    >
      {/* Render the Course component here */}
      <div className="px-20 py-2 pt-6">
        <PageTabs
          tabs={courseTabs}
          currentTab={tab}
          // activeTabControls={
          //   tab === "lessons"
          //     ? renderCalendarRight
          //     : tab === "assets"
          //     ? renderResourcesRight
          //     : null
          // }
        />
      </div>
    </PageLayout>
  );

  // return <Outlet />;
}

export default observer(Course);
