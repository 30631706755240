import { observable } from "mobx";
import { EnumEventEventType } from "../__generated__/graphql";
import DeletableModel from "./DeletableModel";

class Event extends DeletableModel {
  @observable
  allDay: boolean | null;

  @observable
  id: string;

  @observable
  calendarId: string;

  @observable
  calendarDayId: string | null;

  @observable
  calendarScheduleId: string | null;

  @observable
  date: string | null;

  @observable
  description: string | null;

  @observable
  edlinkId: string | null;

  @observable
  endTime: string | null;

  @observable
  eventType: EnumEventEventType;

  @observable
  startTime: string | null;

  @observable
  timezone: string | null;

  @observable
  title: string;

  @observable
  urlId: string;

  @observable
  recurring: { [key: string]: any } | null;
}

export default Event;
