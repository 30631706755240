import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import AlternateSchedule from "../../../models/AlternateSchedule";
import { DashboardBreakpoint } from "../../../types";
import { scheduleAlternateDayRoute } from "../../../utils/routeHelper";
import Button from "../../Button";
import AlternateScheduleCreateAndEditModal from "../../CRUD/CreateAndEdit/AlternateSchedule";
import Link from "../../Link";

type AlternateDayItemProps = {
  breakpoint: DashboardBreakpoint;
  alternateDay: AlternateSchedule;
};

function AlternateDayItem({ breakpoint, alternateDay }: AlternateDayItemProps) {
  const [showEditAlternateDayModal, setShowEditAlternateDayModal] =
    useState(false);

  const { addToast } = useToastsContext();

  const { districts, sessions, ui, rotationDays } = useStores();

  const { activeSessionId, activeDistrictId } = ui;

  if (!activeSessionId || !activeDistrictId) {
    return null;
  }

  const district = districts.getByUrlParam(activeDistrictId);

  const session = sessions.getByUrlParam(activeSessionId);

  if (!district || !session) {
    return null;
  }

  const renderAlternateDayItemLg = () => {
    return (
      <tr key={alternateDay.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <Link
            to={scheduleAlternateDayRoute(
              // district,
              session,
              alternateDay
            )}
            className="mr-3 hover:underline"
          >
            {alternateDay.name}
          </Link>
        </td>

        <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
          <Button
            onClick={() => setShowEditAlternateDayModal(true)}
            type="button"
            theme="editor"
            buttonText="Edit"
            padding="medium"
            width="48"
          />
        </td>
      </tr>
    );
  };

  // TBD
  const renderAlternateDayItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditAlternateDayModal && (
        <AlternateScheduleCreateAndEditModal
          isEditing={true}
          alternateSchedule={alternateDay}
          onClose={() => setShowEditAlternateDayModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditAlternateDayModal(false);

            addToast("Alternate Schedule updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg"
        ? renderAlternateDayItemLg()
        : renderAlternateDayItemSm()}
    </React.Fragment>
  );
}

export default observer(AlternateDayItem);
