import {
  Eventcalendar,
  MbscSelectedDateChangeEvent,
  momentTimezone,
  setOptions,
} from "@mobiscroll/react";
import { EventcalendarBase } from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import { MbscCalendarDayData } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-day";
import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { colorsMap200, colorsMap600 } from "../../colors-list";
import { useModalContext } from "../../contexts/modals";
import useStores from "../../hooks/useStores";
import Calendar from "../../models/Calendar";
import CalendarEvent from "../../models/CalendarEvent";
import { CalendarView } from "../../types";
import CustomDay from "./CustomDay";
import "./index.css";

momentTimezone.moment = moment;

const hexToRgbA = (hex: string, alpha: number) => {
  let c: any;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");

    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = "0x" + c.join("");

    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    );
  }
  throw new Error("Bad Hex");
};

setOptions({
  theme: "ios",
  themeVariant: "light",
});

type CalendarProps = {
  onSelectDateChange: (
    args: MbscSelectedDateChangeEvent,
    inst: EventcalendarBase
  ) => void;
  selectedDate: Date;
  mobiscrollView: any;
  min: Date;
  max: Date;
  calendarView: CalendarView;
  calendarTimezone: string;
  eventsCalendarId: string; // This is the calendar id for the events
  rotationCalendarId: string; // This is the calendar id for the Rotation calendar days
  editCalendarDay: boolean;
  includeRotationCalendarEvents?: boolean;
  // CRUD Methods
  onEditCalendarDay?: (id: string) => void;
  onEditEvent?: (id: string) => void;
  onEditBlock?: (id: string) => void;
  onEventDragEnd?: (event: any, inst: any) => void;
};

const EventsCalendar = ({
  selectedDate,
  onSelectDateChange,
  mobiscrollView,
  min,
  max,
  calendarView,
  calendarTimezone,
  eventsCalendarId,
  rotationCalendarId,
  editCalendarDay,
  onEditCalendarDay,
  includeRotationCalendarEvents = false,
  onEditEvent,
  ...props
}: CalendarProps) => {
  const stores = useStores();

  const { calendars, calendarEvents } = stores;

  const navigate = useNavigate();

  const { addModal } = useModalContext();

  const orderMyEvents = useCallback((event: any) => {
    // return event.accepted ? 1 : -1;
    switch (event.eventType) {
      case "special-event":
        return 3;
      case "rotation-day":
        return 4;
      case "event":
        return 2;
      case "block":
        return 1;
      default:
        return -1;
    }
  }, []);

  const renderScheduleEvent = useCallback((data: any) => {
    const backgroundColor =
      data.color === "white" || data.color === "black"
        ? data.color
        : colorsMap200[data.color];
    const textColor =
      data.color === "white"
        ? "black"
        : data.color === "black"
        ? "white"
        : colorsMap600[data.color];

    if (data.allDay) {
      return (
        <div
          className=""
          style={{
            backgroundColor,
          }}
        >
          <div
            className="md-custom-event-allday-title"
            style={{
              color: textColor,
            }}
          >
            {data.title}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={clsx(
            "md-custom-event-cont group m-1",
            "md-custom-event-cont",
            "cursor-pointer"
          )}
          style={{
            backgroundColor,
          }}
          onClick={() => {
            console.log("Event data", data);
            if (data.original.eventType === "event") {
              onEditEvent && onEditEvent(data.id);
            }
          }}
        >
          <div className={clsx("md-custom-event-wrapper")}>
            <div className="md-custom-event-details h-[90%]">
              <div className="flex justify-between">
                <div>
                  <div
                    className={clsx("pt-1", "font-medium")}
                    style={{
                      color: textColor,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {data.title}
                  </div>
                  <div
                    className="font-medium"
                    style={{
                      color: textColor,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {data.start}{" "}
                    {data.end && !data.allDay ? ` - ${data.end}` : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }, []);

  const eventsCalendar: Calendar | undefined = calendars.get(eventsCalendarId);

  if (!eventsCalendar) {
    return null;
  }

  const eventsToDisplay: CalendarEvent[] =
    eventsCalendar.calendarEventsForDisplay;

  // const calendarData = useMemo(() => {
  //   const events: any[] = [];

  //   calendarEvents.getEventsForCalendar(eventsCalendarId).forEach((event) => {
  //     events.push(toJS(event.toGQLAttributes()));

  //     if (includeRotationCalendarEvents) {
  //       console.log(
  //         "Rotation calendar events",
  //         calendarEvents.getEventsForCalendar(rotationCalendarId)
  //       );

  //       calendarEvents
  //         .getEventsForCalendar(rotationCalendarId)
  //         .forEach((event) => {
  //           events.push(toJS(event.toGQLAttributes()));
  //         });
  //     }
  //   });

  //   return events;
  // }, [
  //   calendarEvents,
  //   rotationCalendarId,
  //   eventsCalendarId,
  //   includeRotationCalendarEvents,
  // ]);

  console.log("Events to display", eventsToDisplay.length);

  const data = eventsToDisplay.map((event) => {
    const jsObject = event.toGQLAttributes();

    const updatedObj = {
      ...jsObject,
    };

    return toJS(updatedObj);
  });

  console.log("All Events being displayed", data);

  return (
    <Eventcalendar
      render={true}
      renderScheduleEvent={renderScheduleEvent}
      onSelectDateChange={onSelectDateChange}
      selectedDate={selectedDate}
      view={mobiscrollView}
      data={data}
      min={min}
      max={max}
      dataTimezone={"utc"}
      displayTimezone={calendarTimezone}
      timezonePlugin={momentTimezone}
      dragToMove={true}
      renderDay={(day: MbscCalendarDayData) => (
        <CustomDay
          day={day}
          mobiscrollView={mobiscrollView}
          calendarView={calendarView}
          calendarId={rotationCalendarId}
          editCalendarDay={editCalendarDay}
          onEditCalendarDay={onEditCalendarDay}
        />
      )}
      onEventDragEnd={function (event, inst) {
        if (props.onEventDragEnd) props.onEventDragEnd(event, inst);
      }}
      eventOrder={orderMyEvents}
    />
  );
};

export default observer(EventsCalendar);
