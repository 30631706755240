import React, { useMemo } from "react";
import { sessionCalendarViewToggle } from "../../actions/scheduling";
import useDropdownContext from "../../hooks/useDropdownContext";
import DropdownMenu from "../DropdownMenu";
import DropdownList from "../DropdownMenu/DropdownList";

type DropdownMenuProps = {
  children: React.ReactNode;
};

const SessionCalendarViewDropdown = ({
  children: triggerComponent,
}: DropdownMenuProps) => {
  const context = useDropdownContext();

  const actions = useMemo(() => {
    return [sessionCalendarViewToggle];
  }, [context]);

  return (
    <DropdownMenu asChild={false} triggerComponent={triggerComponent}>
      <DropdownList actions={actions} />
    </DropdownMenu>
  );
};

export default SessionCalendarViewDropdown;
