import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import RotationSchedule from "../../../models/RotationSchedule";
import { DashboardBreakpoint } from "../../../types";
import { scheduleRotationDaysRoute } from "../../../utils/routeHelper";
import Button from "../../Button";
import RotationScheduleCreateAndEditModal from "../../CRUD/CreateAndEdit/RotationSchedule";
import Link from "../../Link";

type RotationScheduleItemProps = {
  breakpoint: DashboardBreakpoint;
  rotationSchedule: RotationSchedule;
};

function RotationScheduleItem({
  breakpoint,
  rotationSchedule,
}: RotationScheduleItemProps) {
  const [showEditRotationScheduleModal, setShowEditRotationScheduleModal] =
    useState(false);

  const { addToast } = useToastsContext();

  const { districts, sessions, ui, rotationDays } = useStores();

  useEffect(() => {
    if (rotationSchedule) {
      rotationDays.getRotationDaysForSchedule(rotationSchedule.id);
    }
  }, [rotationDays, rotationSchedule]);

  const { activeSessionId, activeDistrictId } = ui;

  if (!activeSessionId || !activeDistrictId) {
    return null;
  }

  const district = districts.getByUrlParam(activeDistrictId);

  const session = sessions.getByUrlParam(activeSessionId);

  if (!district || !session) {
    return null;
  }

  const renderRotationScheduleItemLg = () => (
    <tr key={session.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <Link
          to={scheduleRotationDaysRoute(
            // district,
            session.id,
            rotationSchedule.id
          )}
          className="mr-3 hover:underline"
        >
          {rotationSchedule.name}
        </Link>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {rotationSchedule.rotationDaysCount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {rotationSchedule.calendarDaysCount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {rotationSchedule.skipNoSchoolDays ? "Yes" : "No"}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditRotationScheduleModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderSchoolItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditRotationScheduleModal && (
        <RotationScheduleCreateAndEditModal
          isEditing={true}
          rotationSchedule={rotationSchedule}
          onClose={() => setShowEditRotationScheduleModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditRotationScheduleModal(false);

            addToast("Rotation Schedule updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg"
        ? renderRotationScheduleItemLg()
        : renderSchoolItemSm()}
    </React.Fragment>
  );
}

export default observer(RotationScheduleItem);
