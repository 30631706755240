import { useApolloClient } from "@apollo/client";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import invariant from "invariant";
import { observer } from "mobx-react";
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Switch from "react-switch";
import Button from "../components/Button";
import PeopleCreateAndEditModal from "../components/CRUD/CreateAndEdit/People";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import PageLayout from "../components/PageLayout";
import Pagination from "../components/Pagination";
import ActivateBulkUsersModal from "../components/Users/Modals/ActivateBulkUsersModal";
import ActivateUserModal from "../components/Users/Modals/ActivateUserModal";
import ChangeBulkLicensesModal from "../components/Users/Modals/ChangeBulkLicensesModal";
import { useModalContext } from "../contexts/modals";
import { useToastsContext } from "../contexts/toasts";
import {
  ChangeUserLicense,
  ChangeUserLicensesBulk,
  MakeUserActive,
  MakeUsersActiveBulk,
} from "../graphql/districts/districts.mutations";
import { GetUsers, GetUsersMeta } from "../graphql/users/users.queries";
import useStores from "../hooks/useStores";
import District from "../models/District";
import {
  EnumUserDistrictRoles,
  SortOrder,
  User,
} from "../__generated__/graphql";

const userCategoryOptions = [
  {
    id: "active-users",
    name: "Active Users",
  },
  {
    id: "all-users",
    name: "User Directory",
  },
];

function DistrictUsers() {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const { ui, districts, users: usersStore } = useStores();
  const { addToast } = useToastsContext();
  const { addModal } = useModalContext();
  const { activeDistrictId } = ui;
  const [currentUserCategory, setCurrentUserCategory] =
    useState("active-users");
  const [users, setUsers] = useState<User[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = Number(process.env.REACT_APP_PAGE_SIZE || 100);
  const [isFetchingCount, setIsFetchingCount] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [error, setError] = useState<any>(null);
  const apolloClient = useApolloClient();
  const checkbox = useRef<any>();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [reloadKey, setReloadKey] = useState(0);
  const [editUserId, setEditUserId] = useState<string | undefined>(undefined);

  const activeDistrict: District | undefined = activeDistrictId
    ? districts.getByUrlParam(activeDistrictId)
    : undefined;

  const editUser = useMemo(() => {
    if (!usersStore.sortedData || !editUserId) return undefined;

    const user = usersStore.sortedData.find((user) => user.id === editUserId);

    return user;
  }, [usersStore.sortedData, editUserId]);

  console.log("Edit user", editUser);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedUsers.length > 0 && selectedUsers.length < users.length;
    setChecked(selectedUsers.length === users.length);
    setIndeterminate(isIndeterminate);

    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedUsers, users, checkbox]);

  useEffect(() => {
    if (!activeDistrictId || !activeDistrict) return;

    fetchTotalCount();
    fetchUsers();
  }, [activeDistrictId, activeDistrict, currentPage, currentUserCategory]);

  // If filter changes, reset page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [currentUserCategory]);

  function toggleAll() {
    setSelectedUsers(checked || indeterminate ? [] : users);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function removeAllSelections() {
    setSelectedUsers([]);
    setChecked(false);
    setIndeterminate(false);
  }

  const renderAddButtons = () => {
    return (
      <Button
        type="button"
        theme="primary"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateUserModal(true)}
      />
    );
  };

  const groupedUsersByAlphabet: {
    initial: string;
    users: User[];
  }[] = useMemo(() => {
    const groupedUsers: {
      initial: string;
      users: User[];
    }[] = [];

    const sortedUsers = [...users].sort((a, b) => {
      const aLastname = a.lastName || "";
      const bLastname = b.lastName || "";

      return aLastname.localeCompare(bLastname);
    });

    sortedUsers.forEach((user) => {
      const userLastName = user.lastName || "";

      const initial = userLastName
        ? userLastName[0].toUpperCase()
        : "Unassigned";

      const existingGroup = groupedUsers.find((group: any) => {
        return group.initial === initial;
      });

      if (existingGroup) {
        existingGroup.users.push(user);
      } else {
        groupedUsers.push({
          initial,
          users: [user],
        });
      }
    });

    return groupedUsers;
  }, [users, reloadKey]);

  const fetchTotalCount = async () => {
    if (!activeDistrict) return;

    setIsFetchingCount(true);

    try {
      const userCount = await apolloClient.query({
        query: GetUsersMeta,
        variables: {
          where: {
            district: {
              id: activeDistrict.id,
            },
            isOnboarded:
              currentUserCategory === "active-users"
                ? { equals: true }
                : undefined,
            deletedAt: {
              equals: null,
            },
            districtRoles: {
              hasSome: [
                EnumUserDistrictRoles.Teacher,
                EnumUserDistrictRoles.DistrictAdministrator,
                EnumUserDistrictRoles.Designer,
                EnumUserDistrictRoles.Administrator,
                EnumUserDistrictRoles.Staff,
                EnumUserDistrictRoles.Aide,
                EnumUserDistrictRoles.Member,
                EnumUserDistrictRoles.Ta,
              ],
            },
          },
        },
      });

      if (!userCount.data || !userCount.data._usersMeta) {
        addToast("There was an error fetching users.", {
          type: "error",
        });
        return;
      }

      setTotalPages(Math.ceil(userCount.data._usersMeta.count / pageSize));
      setTotalCount(userCount.data._usersMeta.count);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching users.", {
        type: "error",
      });
    } finally {
      setIsFetchingCount(false);
    }
  };

  const fetchUsers = async () => {
    if (!activeDistrict) return;

    setIsFetchingUsers(true);

    try {
      const users = await apolloClient.query({
        query: GetUsers,
        variables: {
          where: {
            district: {
              id: activeDistrict.id,
            },
            isOnboarded:
              currentUserCategory === "active-users"
                ? { equals: true }
                : undefined,
            deletedAt: {
              equals: null,
            },
            districtRoles: {
              hasSome: [
                EnumUserDistrictRoles.Teacher,
                EnumUserDistrictRoles.DistrictAdministrator,
                EnumUserDistrictRoles.Designer,
                EnumUserDistrictRoles.Administrator,
                EnumUserDistrictRoles.Staff,
                EnumUserDistrictRoles.Aide,
                EnumUserDistrictRoles.Member,
                EnumUserDistrictRoles.Ta,
              ],
            },
          },
          orderBy: [{ lastName: SortOrder.Asc }],
          take: pageSize,
          skip: (currentPage - 1) * pageSize,
        },
      });

      if (!users.data || !users.data.users) {
        addToast("There was an error fetching users.", {
          type: "error",
        });
        return;
      }

      setUsers(users.data.users as User[]);
      setReloadKey(reloadKey + 1);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching users.", {
        type: "error",
      });
    } finally {
      setIsFetchingUsers(false);
    }
  };

  const makeUserActive = async (userId: string, assignSeat: boolean) => {
    if (!activeDistrict) return;

    try {
      await apolloClient.mutate({
        mutation: MakeUserActive,
        variables: {
          makeUserActiveInput: {
            districtId: activeDistrict.id,
            userId,
            assignSeat,
          },
        },
      });

      addToast("User activated successfully.", {
        type: "success",
      });

      // Refresh users
      fetchUsers();

      if (assignSeat) {
        // Refresh district object
        districts.fetchDistrict(activeDistrict.id);
      }
    } catch (e: any) {
      console.log(e);
      addToast(e.message || "There was an error activating the user.", {
        type: "error",
      });
    }
  };

  const changeUserLicense = async (userId: string, assignSeat: boolean) => {
    if (!activeDistrict) return;

    try {
      await apolloClient.mutate({
        mutation: ChangeUserLicense,
        variables: {
          userLicenseActiveInput: {
            districtId: activeDistrict.id,
            userId,
            assignSeat,
          },
        },
      });

      addToast("User activated successfully.", {
        type: "success",
      });

      // Refresh users
      fetchUsers();
      districts.fetchDistrict(activeDistrict.id);
    } catch (e: any) {
      console.log(e);
      addToast(e.message || "There was an error activating the user.", {
        type: "error",
      });
    }
  };

  const changeUserLicensesBulk = async (assignSeat: boolean) => {
    if (!activeDistrict) return;

    try {
      await apolloClient.mutate({
        mutation: ChangeUserLicensesBulk,
        variables: {
          bulkLicenseActiveInput: {
            districtId: activeDistrict.id,
            users: selectedUsers.map((user) => user.id),
            assignSeat,
          },
        },
      });

      addToast("User activated successfully.", {
        type: "success",
      });
      removeAllSelections();
      // Refresh users
      fetchUsers();
      districts.fetchDistrict(activeDistrict.id);
    } catch (e: any) {
      console.log(e);
      addToast(e.message || "There was an error activating the user.", {
        type: "error",
      });
    }
  };

  const makeUsersActiveBulk = async (assignSeat: boolean) => {
    if (!activeDistrict) return;

    try {
      await apolloClient.mutate({
        mutation: MakeUsersActiveBulk,
        variables: {
          bulkUserActiveInput: {
            districtId: activeDistrict.id,
            users: selectedUsers.map((user) => user.id),
            assignSeat,
          },
        },
      });

      addToast("User activated successfully.", {
        type: "success",
      });
      removeAllSelections();
      // Refresh users
      fetchUsers();
      districts.fetchDistrict(activeDistrict.id);
    } catch (e: any) {
      console.log(e);
      addToast(e.message || "There was an error activating the user.", {
        type: "error",
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const renderEmptyState = () => {
    if (currentUserCategory === "active-users") {
      return (
        <div className="flex flex-col items-center">
          <UserPlusIcon className="text-slate11 h-6 w-6" />

          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No active Users.
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by inviting users from your user directory.
          </p>
          <div className="mt-6 flex flex-col items-center space-y-4">
            <Button
              type="button"
              theme="primary"
              className="mr-3"
              icon="search"
              buttonText="Browse Directory"
              padding="medium"
              rounded="medium"
              onClick={() => setCurrentUserCategory("all-users")}
            />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderActiveSeats = () => {
    if (
      !activeDistrict ||
      !activeDistrict.licensedUsers ||
      !activeDistrict.numberOfSeats
    )
      return <div />;

    return (
      <div className="text-slate12 flex justify-between text-sm">
        <div className="font-medium">
          <span className="font-semibold">
            {activeDistrict.licensedUsers.length} /{" "}
            {activeDistrict.numberOfSeats}
          </span>{" "}
          Licenses Assigned
        </div>
        <div className="flex"></div>
      </div>
    );
  };

  const renderUsersTable = () => {
    return (
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              {selectedUsers.length > 0 && (
                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-gray-50 sm:left-12">
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={() => {
                      if (currentUserCategory === "active-users") {
                        addModal({
                          title: "Assign Licenses",
                          content: (
                            <ChangeBulkLicensesModal
                              numberOfUsers={selectedUsers.length.toString()}
                              onSubmit={() => {
                                changeUserLicensesBulk(true);
                              }}
                              assigning={true}
                            />
                          ),
                          size: "md",
                        });
                      } else {
                        addModal({
                          title: "Activate Users",
                          content: (
                            <ActivateBulkUsersModal
                              numberOfUsers={selectedUsers.length.toString()}
                              onSubmit={(assignSeat) => {
                                makeUsersActiveBulk(assignSeat);
                              }}
                            />
                          ),
                          size: "md",
                        });
                      }
                    }}
                  >
                    {currentUserCategory === "active-users"
                      ? "Assign Licenses"
                      : "Activate users"}
                  </button>
                  {currentUserCategory === "active-users" && (
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      onClick={() => {
                        addModal({
                          title: "Remove Licenses",
                          content: (
                            <ChangeBulkLicensesModal
                              numberOfUsers={selectedUsers.length.toString()}
                              onSubmit={() => {
                                changeUserLicensesBulk(false);
                              }}
                              assigning={false}
                            />
                          ),
                          size: "md",
                        });
                      }}
                    >
                      {"Remove Licenses"}
                    </button>
                  )}
                </div>
              )}
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="text-blue9 focus:ring-blue9 absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Role(s)
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {currentUserCategory === "active-users"
                          ? "Paid License"
                          : "Active"}
                      </th>

                      {currentUserCategory === "active-users" && (
                        <th scope="col" className="relative py-3.5 ">
                          <span className="sr-only">Edit </span>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {/* Render users */}
                    {groupedUsersByAlphabet.map((group) => (
                      <Fragment key={group.initial}>
                        <tr className="border-t border-gray-200">
                          <th
                            colSpan={7}
                            scope="colgroup"
                            className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          >
                            {group.initial}
                          </th>
                        </tr>
                        {group.users.map((user, index) => {
                          // Calculate if paid user or not
                          let paid = false;

                          if (
                            (activeDistrict?.licensedUsers || []).includes(
                              user.id
                            )
                          ) {
                            paid = true;
                          }

                          return (
                            <tr key={index}>
                              <td className="relative px-7 sm:w-12 sm:px-6">
                                {selectedUsers.includes(user) && (
                                  <div className="bg-blue9 absolute inset-y-0 left-0 w-0.5" />
                                )}
                                <input
                                  type="checkbox"
                                  className="text-blue9 focus:ring-blue9 absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300"
                                  value={user.email}
                                  checked={selectedUsers.includes(user)}
                                  onChange={(e) =>
                                    setSelectedUsers(
                                      e.target.checked
                                        ? [...selectedUsers, user]
                                        : selectedUsers.filter(
                                            (p) => p !== user
                                          )
                                    )
                                  }
                                />
                              </td>
                              <td
                                className="cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 hover:underline sm:pl-6"
                                // onClick={() => setViewUserId(user.id)}
                              >
                                {user.firstName || "undefined"} {user.lastName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.email}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.districtRoles
                                  ? user.districtRoles.join(", ")
                                  : "-"}
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {currentUserCategory === "active-users" ? (
                                  <Switch
                                    onChange={() => {
                                      if (
                                        (activeDistrict?.licensedUsers || [])
                                          .length >=
                                          (activeDistrict?.numberOfSeats ||
                                            0) &&
                                        !paid
                                      ) {
                                        addToast(
                                          "You have reached your seat limit. Please upgrade your subscription to add more seats.",
                                          {
                                            type: "error",
                                          }
                                        );
                                        return;
                                      }

                                      changeUserLicense(user.id, !paid);
                                    }}
                                    checked={paid}
                                  />
                                ) : user.isOnboarded ? (
                                  "Yes"
                                ) : (
                                  <Button
                                    type="button"
                                    theme="constructive"
                                    className="mr-3"
                                    buttonText="Activate"
                                    disabled={selectedUsers.length > 0}
                                    padding="medium"
                                    rounded="medium"
                                    onClick={() => {
                                      console.log("Activate user");

                                      addModal({
                                        title: "Activate User",
                                        content: (
                                          <ActivateUserModal
                                            name={
                                              user.firstName +
                                              " " +
                                              user.lastName
                                            }
                                            onSubmit={(assignSeat) => {
                                              makeUserActive(
                                                user.id,
                                                assignSeat
                                              );
                                            }}
                                          />
                                        ),
                                        size: "md",
                                      });
                                    }}
                                  />
                                )}
                              </td>
                              {currentUserCategory === "active-users" && (
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                                  <Button
                                    type="button"
                                    theme="secondary"
                                    buttonText="Edit"
                                    rounded="medium"
                                    onClick={() => {
                                      setEditUserId(user.id);

                                      invariant(
                                        user.district,
                                        "User must have a district"
                                      );
                                      invariant(
                                        user.schools,
                                        "User must have schools"
                                      );
                                      invariant(
                                        user.firstName,
                                        "User must have a first name"
                                      );

                                      const sanitizeUser = {
                                        avatarUrl: user.avatarUrl,
                                        createdAt: user.createdAt,
                                        deletedAt: user.deletedAt,
                                        districtId: user.district.id,
                                        districtRoles: user.districtRoles || [],
                                        edlinkId: user.edlinkId,
                                        email: user.email,
                                        firstName: user.firstName,
                                        gradeLevels: user.gradeLevels,
                                        id: user.id,
                                        identifiers: user.identifiers,
                                        lastName:
                                          user.lastName || user.firstName || "",
                                        middleName: user.middleName,
                                        schools: user.schools,
                                        updatedAt: user.updatedAt,
                                        username: user.username,
                                      };

                                      usersStore.add(sanitizeUser);
                                    }}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
                {/* Pagination Component */}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  pageSize={pageSize}
                  totalCount={totalCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Users</h2>
          </div>
        }
        right={<div>{renderAddButtons()}</div>}
      >
        {!activeDistrict || isFetchingCount || isFetchingUsers ? (
          <div className="mt-36 flex w-full items-center justify-center">
            <Spinner color="black" size={32} />
          </div>
        ) : (
          <div className="w-full space-y-12 overflow-x-hidden px-16 py-8">
            {/* Tabs to switch between Active users & User directory */}
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap ">
                  <div className="m:leading-7 bg-slate2 order-last flex w-full space-x-1 rounded-md text-sm font-semibold leading-6 sm:order-none sm:w-auto">
                    {userCategoryOptions.map((item: any) => (
                      <button
                        key={item.id}
                        className={
                          currentUserCategory === item.id
                            ? "border-slate4 rounded-md border border-solid bg-black px-2 py-1 text-white shadow"
                            : "bg-slate2 rounded-md px-2  py-1 text-gray-700"
                        }
                        onClick={() => {
                          setCurrentUserCategory(item.id);
                        }}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {renderActiveSeats()}
            </div>

            {users.length === 0 ? renderEmptyState() : renderUsersTable()}
          </div>
        )}
      </PageLayout>
      {showCreateUserModal && (
        <PeopleCreateAndEditModal
          onClose={() => setShowCreateUserModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateUserModal(false);

            fetchUsers();

            addToast("User created successfully", {
              type: "success",
            });
          }}
        />
      )}
      {editUserId && editUser && (
        <PeopleCreateAndEditModal
          isEditing={true}
          user={editUser}
          onClose={() => setEditUserId(undefined)}
          onUpdateAfterSaving={() => {
            setEditUserId(undefined);

            fetchUsers();

            addToast("User updated successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(DistrictUsers);
