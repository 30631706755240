import { BuildingOffice2Icon } from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import { ExitIcon } from "@radix-ui/react-icons";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import useStores from "../../../hooks/useStores";
import { homeRoute, schoolsRoute } from "../../../utils/routeHelper";
import SchoolLogo from "../../Avatars/SchoolLogo";
import MainSidebar from "../MainSidebar";
import SidebarTab from "../SidebarTab";

function AppSidebar() {
  const stores = useStores();

  const { ui, auth, schools, districts } = stores;

  const { user } = auth;

  const { activeDistrictId, activeSchoolId } = ui;

  const navigate = useNavigate();

  if (!activeDistrictId) return null;

  // const activeSchool = schools.getByUrlParam(activeSchoolId);
  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  if (!activeDistrict) return null;

  return (
    <MainSidebar className="group">
      {/* Render the planner switching dropdown */}
      {/* <div className="ml-6 mt-2 flex h-12 shrink-0 items-center">
        <img className="mr-3 h-8" src={logo} alt="Alayna Logo" />
      </div> */}
      <div className="flex items-center px-4 py-6">
        {/* Wrap the planner logo */}
        <div
          className="pr-[12px]"
          data-intercom-target="sample_planner_button2"
        >
          <SchoolLogo
            schoolData={{
              initial: activeDistrict.name[0],
              id: activeDistrict.id,
              // color: activeSchool.color,
            }}
            size={28}
          />
        </div>
        {/* Render the planner name with truncation */}
        <div
          className="truncate text-[15px] font-semibold"
          data-intercom-target="sample_planner_button3"
        >
          {activeDistrict.name}
        </div>
        {/* Render extra spacing on the right since we will have the toggle button of width 32 and height 32 px */}
        <div className="w-8" />
      </div>
      {/* <SchoolClassesViewDropdown>
        <button
          data-intercom-target="sample_planner_button"
          className={clsx(
            "bg-slate hover:bg-slate5 focus-visible:ring-blue7 m-2 flex items-center rounded-md px-4 py-6 focus-visible:ring focus-visible:ring-opacity-50"
          )}
          style={{
            height: "40px",
          }}
        >
          <div
            className="pr-[12px]"
            data-intercom-target="sample_planner_button2"
          >
            <SchoolLogo
              schoolData={{
                initial: activeDistrict.name[0],
                id: activeDistrict.id,
                // color: activeSchool.color,
              }}
              size={28}
            />
          </div>
          <div
            className="truncate text-[15px] font-semibold"
            data-intercom-target="sample_planner_button3"
          >
            {activeDistrict.name}
          </div>
          <div className="w-8" />
        </button>
      </SchoolClassesViewDropdown> */}

      {/* Render the main tabs */}
      <div className="m-2 mt-4 flex flex-col">
        <SidebarTab to={homeRoute()} icon={<UsersIcon />} text="Users" />
        <SidebarTab
          to={schoolsRoute()}
          icon={<BuildingOffice2Icon />}
          text="Schools"
        />
      </div>

      <div
        className="mt-auto flex w-full cursor-pointer flex-col p-3"
        onClick={(e) => {
          navigate("/logout");
        }}
      >
        <div
          // className={clsx(
          //   "bg-slate focus-visible:ring-blue7 m-2 mt-auto flex items-center rounded-md px-4 py-6 focus-visible:ring focus-visible:ring-opacity-50"
          // )}
          // style={{
          //   height: "40px",
          // }}
          className="text-slate11 mb-2 flex items-center px-4 text-xs font-semibold"
        >
          {/* Wrap the planner logo */}
          {/* Render the planner name with truncation */}
          <ExitIcon className="text-red9 mr-2" />
          Log out
        </div>
      </div>
    </MainSidebar>
  );
}

export default observer(AppSidebar);
