// Use this as the model that will populate the calendar (MbscEvent)

import { observable } from "mobx";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

export type CalendarEventSource =
  | "special-event"
  | "event"
  | "block"
  | "rotation-day"
  | "alternate-day"
  | "course";

class CalendarEvent extends PersistModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  eventType: CalendarEventSource;

  @Attribute
  @observable
  title: string;

  @Attribute
  @observable
  start: string;

  @Attribute
  @observable
  end: string;

  @Attribute
  @observable
  color: string;

  @Attribute
  @observable
  subTitle?: string | null; // For e.g. Course name

  @Attribute
  @observable
  calendarId: string;

  @Attribute
  @observable
  formattedDate?: string | null; // Date in yyyy-mm-dd format

  @Attribute
  @observable
  allDay?: boolean | null;

  @Attribute
  @observable
  description?: string | null;

  @Attribute
  @observable
  courseId?: string | null;

  @Attribute
  @observable
  sectionId?: string | null;

  @Attribute
  @observable
  editable: boolean;

  @Attribute
  @observable
  recurring: { [key: string]: any } | null;
}

export default CalendarEvent;
