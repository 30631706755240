import { gql } from "../../__generated__/gql";

export const GetSchools = gql(`
    query GetSchools($where: SchoolWhereInput!,  $orderBy: [SchoolOrderByInput!]) {
        schools(where: $where, orderBy: $orderBy) {
            address 
            createdAt
            classes {
                id
            }
            courses {
                id
            }
            deletedAt
            district {
                id
            }
            edlinkId
            gradeLevels
            id
            identifiers
            locale
            name
            properties
            timezone
            updatedAt
            urlId
        }
    }
`);
