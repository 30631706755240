// import { Select } from "@mobiscroll/react";
import Select from "react-select";

import { DropdownOptionType } from "../../types";

export type DropdownProps = {
  id: string;
  label?: string;
  value: DropdownOptionType | DropdownOptionType[] | null;
  onChange: (val: any) => void;
  data: DropdownOptionType[];
  multiSelect?: boolean;
  showSearch?: boolean;
  customFilterFn?: () => void; // Use this to filter the data in the dropdown
};

export default function Dropdown(props: DropdownProps) {
  const { id, label, value, onChange, data, multiSelect } = props;

  return (
    <div>
      {label && (
        <label
          htmlFor={props.id}
          className="text-slate11 block text-sm font-medium"
        >
          {label}
        </label>
      )}
      <div className="mt-2">
        <Select
          isMulti={multiSelect}
          id={id}
          value={value}
          onChange={onChange}
          options={data}
          closeMenuOnSelect={!multiSelect}
        />
      </div>
    </div>
  );
}
