import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import { SendOnboardingEmail } from "../graphql/auth/auth.mutations";
import {
  CreateDistrictUserAdminPortal,
  CreateUser,
  UpdateUser,
} from "../graphql/users/users.mutations";
import { GetUsers } from "../graphql/users/users.queries";
import DistrictUser from "../models/DistrictUser";
import {
  DistrictUpdateInput,
  DistrictWhereUniqueInput,
  SortOrder,
  UserCreateInput,
  UserUpdateInput,
  UserWhereUniqueInput,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class UsersStore extends BaseStore<DistrictUser> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, DistrictUser, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchDistrictUsers = (districtId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetUsers,
          variables: {
            where: {
              district: {
                id: districtId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const districtUsers = res.data.users;

          console.log(districtUsers);

          if (!districtUsers) {
            reject(false);
          }

          runInAction("Populate District Users", () => {
            districtUsers.forEach((user) => {
              invariant(user.district, "Subject must have a district");
              invariant(user.schools, "Subject must have schools");
              invariant(user.firstName, "User must have a first name");
              // invariant(user.lastName, "User must have a last name");

              const sanitizeUser = {
                avatarUrl: user.avatarUrl,
                createdAt: user.createdAt,
                deletedAt: user.deletedAt,
                districtId: user.district.id,
                districtRoles: user.districtRoles || [],
                edlinkId: user.edlinkId,
                email: user.email,
                firstName: user.firstName,
                gradeLevels: user.gradeLevels,
                id: user.id,
                identifiers: user.identifiers,
                lastName: user.lastName || user.firstName || "",
                middleName: user.middleName,
                schools: user.schools.map((school) => school.id),
                updatedAt: user.updatedAt,
                username: user.username,
              };

              this.add(sanitizeUser);
            });
          });
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<DistrictUser>): Promise<DistrictUser> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.createDistrictUser(rest as UserCreateInput);
      } else {
        return this.update(
          rest as UserUpdateInput,
          { id } as UserWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  sendOnboardingEmail = async (userId: string) => {
    const res = await this.apolloClient.mutate({
      mutation: SendOnboardingEmail,
      variables: {
        userId,
      },
    });

    if (!res.data || !res.data.sendOnboardingInvite) {
      throw Error("Failed to send onboarding email.");
    }

    return res.data.sendOnboardingInvite;
  };

  @action
  async createDistrictUser(data: UserCreateInput): Promise<DistrictUser> {
    const res = await this.apolloClient.mutate({
      mutation: CreateDistrictUserAdminPortal,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createDistrictUserAdminPortal) {
      throw Error("Failed to create subject.");
    }

    const user = res.data.createDistrictUserAdminPortal;

    invariant(user.district, "Subject must have a district");
    invariant(user.schools, "Subject must have schools");
    invariant(user.firstName, "User must have a first name");
    invariant(user.lastName, "User must have a last name");

    const sanitizeUser = {
      avatarUrl: user.avatarUrl,
      createdAt: user.createdAt,
      deletedAt: user.deletedAt,
      districtId: user.district.id,
      districtRoles: user.districtRoles || [],
      edlinkId: user.edlinkId,
      email: user.email,
      firstName: user.firstName,
      gradeLevels: user.gradeLevels,
      id: user.id,
      identifiers: user.identifiers,
      lastName: user.lastName,
      middleName: user.middleName,
      schools: user.schools.map((school) => school.id),
      updatedAt: user.updatedAt,
      username: user.username,
    };

    return this.add(sanitizeUser);
  }

  @action
  async create(data: UserCreateInput): Promise<DistrictUser> {
    const res = await this.apolloClient.mutate({
      mutation: CreateUser,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createUser) {
      throw Error("Failed to create subject.");
    }

    const user = res.data.createUser;

    invariant(user.district, "Subject must have a district");
    invariant(user.schools, "Subject must have schools");
    invariant(user.firstName, "User must have a first name");
    invariant(user.lastName, "User must have a last name");

    const sanitizeUser = {
      avatarUrl: user.avatarUrl,
      createdAt: user.createdAt,
      deletedAt: user.deletedAt,
      districtId: user.district.id,
      districtRoles: user.districtRoles || [],
      edlinkId: user.edlinkId,
      email: user.email,
      firstName: user.firstName,
      gradeLevels: user.gradeLevels,
      id: user.id,
      identifiers: user.identifiers,
      lastName: user.lastName,
      middleName: user.middleName,
      schools: user.schools.map((school) => school.id),
      updatedAt: user.updatedAt,
      username: user.username,
    };

    return this.add(sanitizeUser);
  }

  async update(
    data: DistrictUpdateInput,
    where: DistrictWhereUniqueInput
  ): Promise<DistrictUser> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateUser,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateUser) {
      throw Error("Failed to update user.");
    }

    const user = res.data.updateUser;

    invariant(user.district, "Subject must have a district");
    invariant(user.schools, "Subject must have schools");
    invariant(user.firstName, "User must have a first name");
    invariant(user.lastName, "User must have a last name");

    const sanitizeUser = {
      avatarUrl: user.avatarUrl,
      createdAt: user.createdAt,
      deletedAt: user.deletedAt,
      districtId: user.district.id,
      districtRoles: user.districtRoles || [],
      edlinkId: user.edlinkId,
      email: user.email,
      firstName: user.firstName,
      gradeLevels: user.gradeLevels,
      id: user.id,
      identifiers: user.identifiers,
      lastName: user.lastName,
      middleName: user.middleName,
      schools: user.schools.map((school) => school.id),
      updatedAt: user.updatedAt,
      username: user.username,
    };

    return this.add(sanitizeUser);
  }

  getUsersForDistrict = (districtId: string): DistrictUser[] => {
    const users: any[] = this.sortedData.filter(
      (user) => user.districtId === districtId && !user.deletedAt
    );
    console.log(users);
    return users;
  };

  getUsersForSchool = (schoolId: string): DistrictUser[] => {
    return this.sortedData.filter(
      (user) => user.schools.includes(schoolId) && !user.deletedAt
    );
  };
}
