import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import ClassV2 from "../../../models/ClassV2";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import {
  classStateEnumToDropdownOptions,
  classStateEnumToSelectedOption,
} from "../../../utils/classAndSectionEnums";
import {
  classGradesEnumArrayToDropdownValues,
  courseGradeLevelDropdownOptions,
} from "../../../utils/gradeLevelsMap";
import { generateRandomString } from "../../../utils/RandomString";
import {
  classSubjectCodesDropdownOptions,
  classSubjectCodesEnumArrayToDropdownValues,
} from "../../../utils/subjectCodesMap";
import {
  EnumClassV2GradeLevels,
  EnumClassV2State,
  EnumClassV2SubjectCodes,
} from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";

type ClassFormState = {
  name: string;
  description: string;
  gradeLevels: DropdownOptionType[];
  subjectCodes: DropdownOptionType[];
  state: DropdownOptionType | null;
  course: DropdownOptionType | null;
  school: DropdownOptionType | null;
  sessions: DropdownOptionType[] | null;
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: ClassFormState = {
  name: "",
  description: "",
  gradeLevels: [],
  subjectCodes: [],
  state: null,
  course: null,
  school: null,
  sessions: null,
};

const CLASS_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "name",
    label: "Class Name",
    placeholder: "",
    required: true,
  },
  {
    fieldKind: "textarea",
    id: "name",
    label: "Description",
    placeholder: "",
    required: true,
  },
  {
    fieldKind: "multiselect",
    id: "gradeLevels",
    label: "Grade Levels",
    placeholder: "Select the grade level of the course",
  },
  {
    fieldKind: "multiselect",
    id: "subjectCodes",
    label: "Categories",
    placeholder: "Select the subject codes of the course",
  },
  {
    fieldKind: "dropdown",
    id: "state",
    label: "State (required)",
    placeholder: "Select the state of the class",
  },
  {
    fieldKind: "dropdown",
    id: "school",
    label: "School (required)",
    placeholder: "Select the school for this class",
  },
  {
    fieldKind: "multiselect",
    id: "sessions",
    label: "Sessions (required)",
    placeholder: "",
  },
  {
    fieldKind: "dropdown",
    id: "course",
    label: "Course (optional)",
    placeholder: "",
  },
];

const formReducer: Reducer<ClassFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
      if (action.fieldID === "school" && action.payload !== state.school) {
        // We must reset the course and the session
        return {
          ...state,
          [action.fieldID]: action.payload,
          course: null,
          sessions: null,
        };
      }

      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type ClassCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  classV2?: ClassV2;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function SessionCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  classV2,
  isEditing = false,
}: ClassCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { classes, courses, districts, schools, sessions, ui } = useStores();

  const { isSaving, save } = classes;

  const { getSessionsForSchool } = sessions;

  const { getCoursesForSchool } = courses;

  const init = (initialState: ClassFormState) => {
    if (isEditing) {
      invariant(classV2, "ClassV2 must be defined if editing");

      const sns = classV2.sessionIds.map((sessionId) => {
        const s = sessions.get(sessionId);

        invariant(s, "School must be defined");

        return {
          label: s.name,
          value: s.id,
        };
      });

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        name: classV2.name,
        description: classV2.description || "",
        gradeLevels: classGradesEnumArrayToDropdownValues(classV2.gradeLevels),
        subjectCodes: classSubjectCodesEnumArrayToDropdownValues(
          classV2.subjectCodes
        ),
        course: classV2.courseId
          ? {
              label: classV2.courseName || "undefined",
              value: classV2.courseId,
            }
          : null,
        sessions: sns,
        state: classStateEnumToSelectedOption(classV2.state),
        school: {
          label: classV2.schoolName,
          value: classV2.schoolId,
        },
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { name, state: classState, sessions } = state;

    console.log("Validate submit", state);

    if (name && name !== "" && classState && sessions && sessions.length > 0) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);
      default:
        return null;
    }
  };

  const renderStateDropdown = (id: string, field: FormField) => {
    const options = classStateEnumToDropdownOptions();

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderSchoolDropdown = (id: string, field: FormField) => {
    invariant(ui.activeDistrictId, "Active district must be defined");

    const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

    invariant(activeDistrict, "Active district must be defined");

    const schoolsForDistrict = schools.getSchoolsForDistrict(activeDistrict.id);

    const options = schoolsForDistrict.map((school) => ({
      label: school.name,
      value: school.id,
    }));

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderSessionsDropdown = (id: string, field: FormField) => {
    invariant(ui.activeDistrictId, "Active district must be defined");

    const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

    invariant(activeDistrict, "Active district must be defined");

    const activeSchoolId = state.school ? state.school.value : null;

    if (!activeSchoolId) {
      return null;
    }

    const activeSchool = schools.get(activeSchoolId);

    invariant(activeSchool, "Active school must be defined");

    let sessions = getSessionsForSchool(activeDistrict.id, activeSchool.id);

    const options = sessions.map((session) => ({
      label: session.name,
      value: session.id,
    }));

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderGradeLevelsDropdown = (id: string, field: FormField) => {
    const dropdownOptions = courseGradeLevelDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderSubjectCodesDropdown = (id: string, field: FormField) => {
    const dropdownOptions = classSubjectCodesDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderCourseDropdown = (id: string, field: FormField) => {
    invariant(ui.activeDistrictId, "Active district must be defined");

    const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

    invariant(activeDistrict, "Active district must be defined");

    const activeSchoolId = state.school ? state.school.value : null;

    if (!activeSchoolId) {
      return null;
    }

    const activeSchool = schools.get(activeSchoolId);

    invariant(activeSchool, "Active school must be defined");

    const courses = getCoursesForSchool(activeDistrict.id, activeSchool.id);

    const options = courses.map((course) => ({
      label: course.name,
      value: course.id,
    }));

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={false}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "gradeLevels":
        return renderGradeLevelsDropdown(id, field);
      case "subjectCodes":
        return renderSubjectCodesDropdown(id, field);
      case "state":
        return renderStateDropdown(id, field);
      case "sessions":
        return renderSessionsDropdown(id, field);
      case "school":
        return renderSchoolDropdown(id, field);
      case "course":
        return renderCourseDropdown(id, field);
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (
        !state.school ||
        !state.state ||
        !state.sessions ||
        state.sessions.length === 0
      ) {
        return false;
      }

      if (isEditing) {
        invariant(classV2, "Course must be defined if editing");

        const sessionIdsToConnect: DropdownOptionType[] = state.sessions
          ? state.sessions.filter((session) => {
              return !classV2.sessionIds.includes(session.value);
            })
          : [];

        const sessionIdsToDisconnect: string[] = state.sessions
          ? classV2.sessionIds.filter((sessionId) => {
              return !state
                .sessions!.map((session) => session.value)
                .includes(sessionId);
            })
          : classV2.sessionIds || [];

        const updateClassV2 = {
          id: classV2.id,
          ...state,
          description: state.description || null,
          gradeLevels: state.gradeLevels.map(
            (gradeLevel) => gradeLevel.value as EnumClassV2GradeLevels
          ),
          subjectCodes: state.subjectCodes.map(
            (subjectCode) => subjectCode.value as EnumClassV2SubjectCodes
          ),
          course: state.course ? { id: state.course.value } : undefined,
          sessions: {
            connect: sessionIdsToConnect.map((session) => ({
              id: session.value,
            })),
            disconnect: sessionIdsToDisconnect.map((sessionId) => ({
              id: sessionId,
            })),
          },
          state: state.state.value as EnumClassV2State,
          school: {
            id: state.school.value,
          },
        };

        try {
          const res = await save(updateClassV2);

          console.log("Updated session", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating session", err);
        }
      } else {
        try {
          invariant(ui.activeDistrictId, "Active district must be defined");

          const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

          invariant(activeDistrict, "Active district must be defined");

          const newClass = {
            ...state,
            description: state.description || null,
            gradeLevels: state.gradeLevels.map(
              (gradeLevel) => gradeLevel.value as EnumClassV2GradeLevels
            ),
            subjectCodes: state.subjectCodes.map(
              (subjectCode) => subjectCode.value as EnumClassV2SubjectCodes
            ),
            urlId: generateRandomString(),
            course: state.course ? { id: state.course.value } : undefined,
            sessions: {
              connect: (state.sessions || []).map((session) => ({
                id: session.value,
              })),
            },
            state: state.state.value as EnumClassV2State,
            school: {
              id: state.school.value,
            },
          };

          const res = await save(newClass);
          console.log("Created session", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating session", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      classV2,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeDistrictId,
      districts,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} Class
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {CLASS_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(SessionCreateAndEditModal);
