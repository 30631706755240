import { observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import ResponsiveLayout from "../components/Layout";
import ResponsiveLayout from "../components/ResponsiveLayout";
import useStores from "../hooks/useStores";

function Authenticated() {
  const {
    courses,
    departments,
    districts,
    ui,
    auth,
    schools,
    sessions,
    subjects,
    users,
  } = useStores();

  const { validSession, isLoading, user, isInitialized } = auth;

  const navigate = useNavigate();

  // First entry point for all authenticated routes. We can check if the user is authenticated and if the user has completed onboarding here.
  useEffect(() => {
    if (isInitialized && !validSession) {
      navigate("/login");
      return;
    }

    // Disabled temporarily when testing onboarding
    // if (isInitialized && validSession && user && !user.isOnboarded) {
    //   navigate("/onboarding");
    //   return;
    // }

    if (user && user.district && user.district.id && user.district.urlId) {
      const districtId = user.district.id;
      const districtUrlId = user.district.urlId;
      ui.setActiveDistrictId(districtUrlId);
      districts.fetchDistrict(districtId);
      schools.fetchDistrictSchools(districtId);
      sessions.fetchDistrictSessions(districtId);
      courses.fetchDistrictCourses(districtId);
      departments.fetchDistrictDepartments(districtId);
      subjects.fetchDistrictSubjects(districtId);
      users.fetchDistrictUsers(districtId);
    }

    // We want to fetch all the user data only once when the user is authenticated and has completed onboarding.
  }, [isInitialized, validSession, user]);

  // TO-DO: Replace Spinner with a skeleton loader
  if (!isInitialized || !user || isLoading) {
    return (
      <div className="h-full w-full">
        <div className="flex h-full w-full items-center justify-center">
          {/* <Spinner color="black" size={24} /> */}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      {/* Replace title with current planner name */}
      <ResponsiveLayout title={"Alayna — Admin Dashboard"} />
    </div>
  );
}

export default observer(Authenticated);
