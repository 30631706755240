import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

export type ToastType = {
  id: string;
  createdAt: string;
  message: string;
  type: "warning" | "error" | "info" | "success" | "loading";
  timeout?: number;
  recurring?: number;
  action?: {
    text: string;
    onClick: React.MouseEventHandler<HTMLSpanElement>;
  };
};

type ToastOptions = {
  type: ToastType["type"];
  timeout?: ToastType["timeout"];
  action?: ToastType["action"];
};

export type ToastContextValue = {
  toasts: Map<string, ToastType>;
  addToast: (message: string, options: ToastOptions) => void;
  removeToast: (id: string) => void;
  getToastsInOrder: () => ToastType[];
  mostRecentToastId: string;
};

export const ToastContext = React.createContext<ToastContextValue>(
  {} as ToastContextValue
);

export type ToastContextProps = {
  value?: Partial<ToastContextValue>;
  children: React.ReactNode;
};

export const useToastsContext = () => useContext(ToastContext);

export const ToastProvider = ({ value, children }: ToastContextProps) => {
  const [toasts, setToasts] = React.useState<Map<string, ToastType>>(
    value?.toasts || new Map()
  );
  const [mostRecentToastId, setMostRecentToastId] = React.useState<string>("");

  const addToast = (message: string, options: ToastOptions) => {
    if (!message) {
      return;
    }
    const mostRecentToast = toasts.get(mostRecentToastId);

    if (mostRecentToast?.message === message) {
      // Update the toast with mostRecuentToastId and save it to toastsMap
      setToasts((toasts) => {
        const newToasts = new Map(toasts);
        newToasts.set(mostRecentToastId, {
          ...mostRecentToast,
          recurring: mostRecentToast.recurring
            ? ++mostRecentToast.recurring
            : 1,
        });
        return newToasts;
      });

      return mostRecentToastId;
    }

    const id = uuidv4();
    const createdAt = new Date().toISOString();
    toasts.set(id, {
      id,
      message,
      createdAt,
      type: options.type,
      timeout: options.timeout,
      action: options.action,
    });
    setMostRecentToastId(id);
    return id;
  };

  const removeToast = (id: string) => {
    setToasts((toasts) => {
      const newToasts = new Map(toasts);
      newToasts.delete(id);
      return newToasts;
    });
  };

  const getToastsInOrder = () => {
    return Array.from(toasts.values()).sort((a, b) =>
      a.createdAt.localeCompare(b.createdAt)
    );
  };

  return (
    <ToastContext.Provider
      value={{
        toasts,
        addToast,
        removeToast,
        getToastsInOrder,
        mostRecentToastId,
        ...value,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
