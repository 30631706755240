import { Datepicker, momentTimezone } from "@mobiscroll/react";
import invariant from "invariant";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import Event from "../../../models/Event";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import { generateRandomString } from "../../../utils/RandomString";
import {
  eventTypeEnumToDropdownOptions,
  eventTypeEnumToSelectedOption,
} from "../../../utils/schedulingEnums";
import { EnumEventEventType } from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";
import Switch from "../../Switch";

// setup Mobiscroll Moment plugin
momentTimezone.moment = moment;

type EventFormState = {
  title: string;
  description: string;
  allDay: boolean;
  startTime: Date | null;
  endTime: Date | null;
  date: Date | null;
  eventType: DropdownOptionType | null;
  recurring: { [key: string]: any };
  [key: string]:
    | string
    | boolean
    | any[]
    | null
    | Date
    | DropdownOptionType
    | { [key: string]: any };
};

const initialState: EventFormState = {
  title: "",
  description: "",
  allDay: false,
  date: null,
  startTime: null,
  endTime: null,
  eventType: null,
  recurring: {
    enabled: false,
    repeat: "weekly",
    interval: 1,
    count: 1,
    weeksSelected: [], // to parse
    stopCondition: "never", // to parse
    monthRotationCondition: "day", // to parse
    weekDays: "SU",
    pos: 1,
    day: 1,
    until: new Date().toISOString().split("T")[0],
  },
};

const recurringTypes: any[] = [
  {
    label: "daily",
    value: "daily",
  },
  {
    label: "weekly",
    value: "weekly",
  },
  {
    label: "monthly",
    value: "monthly",
  },
];

const weeklyRotationOptions: any[] = [
  {
    label: "Sunday",
    value: "SU",
  },
  {
    label: "Monday",
    value: "MO",
  },
  {
    label: "Tuesday",
    value: "TU",
  },
  {
    label: "Wednesday",
    value: "WE",
  },
  {
    label: "Thursday",
    value: "TH",
  },
  {
    label: "Friday",
    value: "FR",
  },
  {
    label: "Saturday",
    value: "SA",
  },
];

const monthlyRotationWeeks: any[] = [
  {
    label: "First",
    value: 1,
  },
  {
    label: "Second",
    value: 2,
  },
  {
    label: "Third",
    value: 3,
  },
  {
    label: "Fourth",
    value: 4,
  },
  {
    label: "Last",
    value: -1,
  },
];

const monthDays: any[] = [];

for (let i = 1; i <= 31; i++) {
  monthDays.push({
    label: i.toString(),
    value: i,
  });
}

const EVENT_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "dropdown",
    id: "eventType",
    label: "Event Type*",
    placeholder: "Select the type of the event",
  },
  {
    fieldKind: "text",
    id: "title",
    label: "Title*",
    placeholder: "",
    required: true,
  },
  {
    fieldKind: "textarea",
    id: "description",
    label: "Description",
    placeholder: "",
    required: false,
  },
  {
    fieldKind: "switch",
    id: "allDay",
    label: "All Day*",
    placeholder: "",
    required: false,
  },
  {
    fieldKind: "datepicker",
    id: "startTime",
    label: "Start*",
    placeholder: "Select the start time of the event",
  },
  {
    fieldKind: "datepicker",
    id: "endTime",
    label: "End*",
    placeholder: "Select the end time of the event",
  },
  {
    fieldKind: "datepicker",
    id: "date",
    label: "Date*",
    placeholder: "Select the date of the event",
  },
  {
    fieldKind: "recurring",
    id: "recurring",
    label: "Recurring",
    placeholder: "",
  },
];

const formReducer: Reducer<EventFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    case "recurring":
    default:
      return state;
  }
};

type EventCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  event?: Event;
  calendarId: string;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function EventCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  event,
  calendarId,
  isEditing = false,
}: EventCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { calendars, sessions, events, ui } = useStores();

  const { addToast } = useToastsContext();

  const { isSaving, save } = events;

  const activeCalendar = calendars.get(calendarId);

  const calendarTimezone = activeCalendar?.timezone || "UTC";

  const getDatetimeInTimezone = useCallback(
    (datetime: Date): string | null => {
      // Step 1: Convert the user-selected date to DateTime object in calendar timezone
      let timezoneTime = DateTime.fromObject(
        {
          year: datetime.getFullYear(),
          month: datetime.getMonth() + 1, // JS month is 0 indexed, but DateTime's is not.
          day: datetime.getDate(), // Get the day of the month, not the day of the week.
          hour: datetime.getHours(),
          minute: datetime.getMinutes(),
        },
        {
          zone: calendarTimezone,
        }
      );

      // Step 2: Convert the calendarTimezone DateTime to UTC format for storage
      const utcTime = timezoneTime.toUTC();

      // Return the formatted UTC time as a string
      return utcTime.toISO();
    },
    [calendarTimezone]
  );

  const getDatetimeInLocalTimezone = useCallback(
    (datetime: string): Date | null => {
      // We are receiving the datetime as a UTC ISO string
      // We want to construct the target timezone Date object but for the local timezone (not the calendar timezone)

      // Step 1: Convert the UTC ISO string to DateTime object in UTC timezone
      const utcDateTime = DateTime.fromISO(datetime, {
        zone: calendarTimezone,
      });

      // Step 2: Copy the date and time from the UTC DateTime object to the local DateTime object
      const timezoneTime = DateTime.fromObject(
        {
          year: utcDateTime.year,
          month: utcDateTime.month,
          day: utcDateTime.day,
          hour: utcDateTime.hour,
          minute: utcDateTime.minute,
        },
        {
          zone: "local",
        }
      );

      return timezoneTime.toJSDate();
    },
    [calendarTimezone]
  );

  const getDateInLocalTimezone = (isoDatetime: string): Date | null => {
    // Split the ISO string into date and time
    const [date] = isoDatetime.split("T");

    const parts = date.split("-");

    // Initialize a Date object with the date part
    const dateOnly = new Date(
      Number(parts[0]),
      Number(parts[1]) - 1,
      Number(parts[2])
    );

    return dateOnly;
  };

  const replaceTimeWithZeros = (isoString: string): string => {
    try {
      const dateOnly = isoString.split("T")[0]; // Extract the date part
      return dateOnly + "T00:00:00.000Z"; // Append zeros for time and 'Z' for UTC timezone
    } catch (error) {
      // If there's an error (invalid ISO string), return the original string
      return isoString;
    }
  };

  const verifyConversionToTimezone = useCallback(
    (utcISOString: string): string | null => {
      try {
        const utcDateTime = DateTime.fromISO(utcISOString, { zone: "utc" });

        // Step 2: Convert the DateTime object to the target timezone
        const timezoneDateTime = utcDateTime.setZone(calendarTimezone);

        // Return the formatted time in the target timezone as a string
        return timezoneDateTime.toISO();
      } catch (error) {
        // If there's an error in parsing or converting, return false
        return null;
      }
    },
    [calendarTimezone]
  );

  const initializeRecurring = (recurringCopy: any) => {
    if (!recurringCopy) {
      return undefined;
    }
    // Create a copy of the modified object to preserve the original data
    const recurring = { ...recurringCopy };

    // Restore the deleted properties based on the original 'repeat' value
    if (recurring.repeat === "daily") {
      recurring.weeksSelected = [];
      recurring.monthRotationCondition = "day";
      recurring.weekDays = "SU";
      recurring.pos = 1;
      recurring.day = 1;
    } else if (recurring.repeat === "weekly") {
      // Convert 'weekDays' back to 'weeksSelected'
      if (recurring.weekDays) {
        recurring.weeksSelected = recurring.weekDays
          .split(",")
          .map((day: DropdownOptionType) => ({
            value: day,
          }));
      }
      delete recurring.monthRotationCondition;
      delete recurring.day;
      delete recurring.pos;
    } else if (recurring.repeat === "monthly") {
      recurring.weeksSelected = [];
      if (recurring.weekDays) {
        delete recurring.day;
      } else {
        delete recurring.pos;
      }
      recurring.monthRotationCondition = recurring.weekDays
        ? "day"
        : "position";
    }

    // Restore the deleted properties based on the original 'stopCondition' value
    if (recurring.stopCondition === "never") {
      recurring.count = 0;
      recurring.until = new Date().toISOString().split("T")[0];
    } else if (recurring.stopCondition === "after") {
      recurring.until = new Date().toISOString().split("T")[0];
    } else if (recurring.stopCondition === "enddate") {
      recurring.count = 1;
    }

    // Restore the deleted properties 'stopCondition' and 'enabled'
    recurring.stopCondition = recurring.count
      ? recurring.count === 0
        ? "never"
        : "after"
      : "enddate";
    recurring.enabled = true;

    return recurring;
  };

  const init = (initialState: EventFormState) => {
    if (isEditing) {
      invariant(event, "Event must be defined if editing");

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        title: event.title,
        description: event.description || "",
        allDay: event.allDay || false,
        startTime: event.startTime
          ? getDatetimeInLocalTimezone(event.startTime)
          : null,
        endTime: event.endTime
          ? getDatetimeInLocalTimezone(event.endTime)
          : null,
        date: event.date ? getDateInLocalTimezone(event.date) : null,
        eventType: eventTypeEnumToSelectedOption(event.eventType),
        recurring: initializeRecurring(event.recurring),
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { title, eventType, startTime, endTime, date, allDay } = state;

    invariant(ui.activeSessionId, "Active session must be defined");

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    invariant(activeSession, "Active session must be defined");

    console.log("Validate submit", state);

    invariant(activeSession.startDate, "Active session must have start date");
    invariant(activeSession.endDate, "Active session must have end date");

    if (!eventType || !title) {
      setIsSubmitDisabled(true);
      return;
    }

    const dateCaseSatisfied =
      eventType.value === EnumEventEventType.FirstDay ||
      eventType.value === EnumEventEventType.LastDay ||
      eventType.value === EnumEventEventType.Holiday ||
      eventType.value === EnumEventEventType.NoSchool;

    const allDayEventCaseSatisfied =
      (eventType.value === EnumEventEventType.Event ||
        eventType.value === EnumEventEventType.Period ||
        eventType.value === EnumEventEventType.Occasion) &&
      allDay;

    const timeCaseSatisfied =
      (eventType.value === EnumEventEventType.Event ||
        eventType.value === EnumEventEventType.Period ||
        eventType.value === EnumEventEventType.Occasion) &&
      !allDay;

    // if recurring event and stop condition is enddate, the enddate must be after the start date
    if (
      state.recurring.enabled &&
      state.recurring.stopCondition === "enddate"
    ) {
      if (
        state.recurring.until <
        (state.startDate || state.startTime || new Date())
      ) {
        setIsSubmitDisabled(true);
        return;
      }
    }

    if (dateCaseSatisfied || allDayEventCaseSatisfied) {
      // Check if date is valid (We must check that it occurs within the session)
      if (!date) {
        setIsSubmitDisabled(true);
        return;
      }

      if (
        date.getTime() < new Date(activeSession.startDate).getTime() ||
        date.getTime() > new Date(activeSession.endDate).getTime()
      ) {
        setIsSubmitDisabled(true);
        return;
      }

      setIsSubmitDisabled(false);
    } else if (timeCaseSatisfied) {
      // We must check that the start and end time are valid by checking that they occur within the session and that the start time is before the end time
      if (!startTime || !endTime) {
        setIsSubmitDisabled(true);
        return;
      }

      if (
        startTime.getTime() < new Date(activeSession.startDate).getTime() ||
        startTime.getTime() > new Date(activeSession.endDate).getTime()
      ) {
        setIsSubmitDisabled(true);
        return;
      }

      if (
        endTime.getTime() < new Date(activeSession.startDate).getTime() ||
        endTime.getTime() > new Date(activeSession.endDate).getTime()
      ) {
        setIsSubmitDisabled(true);
        return;
      }

      if (startTime.getTime() > endTime.getTime()) {
        setIsSubmitDisabled(true);
        return;
      }

      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state, ui.activeSessionId, sessions]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const parseRecurring = (recurring: { [key: string]: any }) => {
    if (!recurring) {
      return undefined;
    }

    const recurringCopy = { ...recurring };
    // if daily recurring, delete weeksSelected, monthRotationCondition, weekDays, pos, day
    if (recurring.repeat === "daily") {
      delete recurringCopy.weeksSelected;
      delete recurringCopy.monthRotationCondition;
      delete recurringCopy.weekDays;
      delete recurringCopy.pos;
      delete recurringCopy.day;
    }

    // if weekly recurring, delete monthRotationCondition, day, pos
    if (recurring.repeat === "weekly") {
      delete recurringCopy.monthRotationCondition;
      delete recurringCopy.day;
      delete recurringCopy.pos;
    }
    // if weekly convert weeksSelected to weekdays string eg. ["SU", "MO"] => "SU,MO"
    if (recurring.repeat === "weekly") {
      console.log(recurringCopy);
      recurringCopy.weekDays = recurring.weeksSelected
        .map((day: DropdownOptionType) => day.value)
        .join(",");
      if (recurringCopy.weekDays === "") {
        delete recurringCopy.weekDays;
      }
      delete recurringCopy.weeksSelected;
    }

    // if monthly recurring, delete weeksSelected and then delete either day or pos
    if (recurring.repeat === "monthly") {
      delete recurringCopy.weeksSelected;
      if (recurring.monthRotationCondition === "day") {
        delete recurringCopy.weekDays;
        delete recurringCopy.pos;
      } else {
        delete recurringCopy.day;
      }
      delete recurringCopy.monthRotationCondition;
    }

    // if stop condition is never, delete count and until
    if (recurring.stopCondition === "never") {
      delete recurringCopy.count;
      delete recurringCopy.until;
    }

    // if stop condition is after, delete until
    if (recurring.stopCondition === "after") {
      delete recurringCopy.until;
    }

    // if stop condition is enddate, delete count
    if (recurring.stopCondition === "enddate") {
      delete recurringCopy.count;
    }

    delete recurringCopy.stopCondition;
    delete recurringCopy.enabled;

    return recurringCopy;
  };

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    const { eventType } = state;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);
      case "datepicker":
        return renderDatepicker(id, field);
      case "switch":
        if (!eventType) return null;

        if (
          eventType.value === EnumEventEventType.FirstDay ||
          eventType.value === EnumEventEventType.LastDay ||
          eventType.value === EnumEventEventType.Holiday ||
          eventType.value === EnumEventEventType.NoSchool
        ) {
          return null;
        }
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Switch
              id={id}
              label={field.label}
              onToggle={(value) => {
                dispatchFormAction({
                  type: "switch",
                  fieldID: id,
                  payload: value,
                });
              }}
              toggleValue={state[id] as boolean}
            />
          </div>
        );
      case "recurring":
        if (!eventType) return null;

        if (
          eventType.value === EnumEventEventType.FirstDay ||
          eventType.value === EnumEventEventType.LastDay ||
          eventType.value === EnumEventEventType.Holiday ||
          eventType.value === EnumEventEventType.NoSchool
        ) {
          return null;
        }

        const recurringValue = state["recurring"] as { [key: string]: any };
        const variableDay = monthlyRotationWeeks.find((week) => {
          return week.value === recurringValue.pos;
        });
        const fixedDay = monthDays.find(
          (day) => day.value === recurringValue.day
        );
        const weekDay = weeklyRotationOptions.find((week) => {
          return week.value === recurringValue.weekDays;
        });

        return (
          <div style={{ marginBottom: "20px" }}>
            <Switch
              id={id}
              label={"Recurring*"}
              onToggle={(value) => {
                dispatchFormAction({
                  type: "switch",
                  fieldID: id,
                  payload: {
                    ...recurringValue,
                    enabled: value,
                  },
                });
              }}
              toggleValue={recurringValue.enabled as boolean}
            />
            {recurringValue.enabled && (
              <div className="my-6 space-y-6">
                <div key={id} style={{ marginBottom: "20px" }}>
                  <Dropdown
                    id={id}
                    label={"Type*"}
                    data={recurringTypes}
                    value={{
                      label: recurringValue.repeat,
                      value: recurringValue.repeat,
                    }}
                    onChange={(value) => {
                      console.log("Dropdown onChange called", value);
                      dispatchFormAction({
                        type: "dropdown",
                        fieldID: id,
                        payload: {
                          ...recurringValue,
                          repeat: value.value,
                        },
                      });
                    }}
                  />
                </div>
                <div>
                  <Input
                    label="Interval*"
                    // name={"Interval"}
                    onChange={(value) => {
                      dispatchFormAction({
                        type: "text",
                        fieldID: id,
                        payload: {
                          ...recurringValue,
                          interval: Number(value),
                        },
                      });
                    }}
                    type="Number"
                    value={recurringValue.interval}
                    multiLine={false}
                  />
                </div>
                {recurringValue.repeat === "weekly" && (
                  <div>
                    <Dropdown
                      label="Repeat only on these days of the week (optional)"
                      id="repeat-weekly-days"
                      multiSelect={true}
                      value={recurringValue.weeksSelected}
                      onChange={(val) => {
                        console.log(val);
                        dispatchFormAction({
                          type: "dropdown",
                          fieldID: id,
                          payload: {
                            ...recurringValue,
                            weeksSelected: val,
                          },
                        });
                      }}
                      data={weeklyRotationOptions}
                    />
                  </div>
                )}
                {recurringValue.repeat === "monthly" && (
                  <div>
                    <fieldset
                      className="space-y-3"
                      onChange={(e: any) => {
                        if (e.target.type === "radio") {
                          dispatchFormAction({
                            type: "dropdown",
                            fieldID: id,
                            payload: {
                              ...recurringValue,
                              monthRotationCondition: e.target.value,
                            },
                          });
                        }
                      }}
                    >
                      <legend>Repeat on the</legend>
                      <div className="space-x-3">
                        <input
                          type="radio"
                          id={"field"}
                          name="month"
                          value="day"
                          checked={
                            recurringValue.monthRotationCondition === "day"
                          }
                        />
                        <label>Fixed Day</label>
                      </div>
                      <div>
                        <Dropdown
                          id="repeat-monthly-days"
                          value={fixedDay}
                          onChange={(val) => {
                            console.log(val);
                            dispatchFormAction({
                              type: "dropdown",
                              fieldID: id,
                              payload: {
                                ...recurringValue,
                                day: val.value,
                                monthRotationCondition: "day",
                              },
                            });
                          }}
                          data={monthDays}
                        />
                      </div>
                      <div className="space-x-3">
                        <input
                          type="radio"
                          id="field"
                          name="month"
                          value="pos"
                          checked={
                            recurringValue.monthRotationCondition === "pos"
                          }
                        />
                        <label>Variable Day</label>
                      </div>
                      <div>
                        <div>
                          <Dropdown
                            id="repeat-monthly-pos"
                            value={variableDay}
                            onChange={(val) => {
                              console.log(val);
                              dispatchFormAction({
                                type: "dropdown",
                                fieldID: id,
                                payload: {
                                  ...recurringValue,
                                  pos: val.value,
                                  monthRotationCondition: "pos",
                                },
                              });
                            }}
                            data={monthlyRotationWeeks}
                          />
                          <Dropdown
                            id="repeat-monthly-weekdays"
                            value={weekDay}
                            onChange={(val) => {
                              console.log(val);
                              dispatchFormAction({
                                type: "dropdown",
                                fieldID: id,
                                payload: {
                                  ...recurringValue,
                                  weekDays: val.value,
                                  monthRotationCondition: "pos",
                                },
                              });
                            }}
                            data={weeklyRotationOptions}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}
                <fieldset
                  className="space-y-3"
                  onChange={(e: any) => {
                    if (e.target.type === "radio") {
                      dispatchFormAction({
                        type: "dropdown",
                        fieldID: id,
                        payload: {
                          ...recurringValue,
                          stopCondition: e.target.value,
                        },
                      });
                    }
                  }}
                >
                  <label>Stop Condition</label>
                  <div className="space-x-3">
                    <input
                      type="radio"
                      id="huey"
                      name="stop"
                      value="never"
                      checked={recurringValue.stopCondition === "never"}
                    />
                    <label>Never</label>
                  </div>
                  <div className="space-x-3">
                    <input
                      type="radio"
                      id="dewey"
                      name="stop"
                      value="after"
                      checked={recurringValue.stopCondition === "after"}
                    />
                    <label>After number of recurrences</label>
                  </div>
                  {recurringValue.stopCondition === "after" && (
                    <div>
                      <Input
                        label="Number of recurrences"
                        type="Number"
                        // name={"Interval"}
                        onChange={(value) => {
                          dispatchFormAction({
                            type: "text",
                            fieldID: id,
                            payload: {
                              ...recurringValue,
                              count: Number(value),
                            },
                          });
                        }}
                        value={recurringValue.count}
                        multiLine={false}
                      />
                    </div>
                  )}
                  <div className="space-x-3">
                    <input
                      type="radio"
                      id="louie"
                      name="stop"
                      value="enddate"
                      checked={recurringValue.stopCondition === "enddate"}
                    />
                    <label>End date</label>
                  </div>
                  {recurringValue.stopCondition === "enddate" && (
                    <Datepicker
                      controls={["date"]}
                      timezonePlugin={momentTimezone}
                      touchUi={true}
                      theme="ios"
                      value={recurringValue.until}
                      themeVariant="light"
                      inputProps={{
                        placeholder: field.placeholder,
                      }}
                      onChange={(event: any) => {
                        const date = new Date(event.value);
                        const roundOffDate = roundSeconds(date);
                        dispatchFormAction({
                          type: "datepicker",
                          fieldID: id,
                          payload: {
                            ...recurringValue,
                            until: roundOffDate,
                          },
                        });
                      }}
                      responsive={{
                        xsmall: {
                          controls: ["date"],
                          display: "bottom",
                          touchUi: true,
                        },
                        medium: {
                          controls: ["date"],
                          display: "anchored",
                          touchUi: false,
                        },
                      }}
                    />
                  )}
                </fieldset>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  /**
   * @description Round time to nearest seconds
   */
  const roundSeconds = (time: Date) => {
    time.setMinutes(time.getMinutes() + Math.round(time.getSeconds() / 60));
    time.setSeconds(0, 0);
    return time;
  };

  const renderDatepicker = (id: string, field: FormField) => {
    const { eventType, allDay } = state;

    if (!eventType) return null;

    switch (id) {
      case "startTime":
        if (
          eventType.value === EnumEventEventType.FirstDay ||
          eventType.value === EnumEventEventType.LastDay ||
          eventType.value === EnumEventEventType.Holiday ||
          eventType.value === EnumEventEventType.NoSchool ||
          allDay
        ) {
          return null;
        }

        return (
          <div className="flex-grow">
            <label
              htmlFor="start-time"
              className="text-slate11 block text-sm font-medium"
            >
              {field.label}
            </label>
            <div className="mt-1">
              <Datepicker
                controls={["date", "time"]}
                touchUi={true}
                theme="ios"
                value={state[id]}
                themeVariant="light"
                inputProps={{
                  placeholder: field.placeholder,
                }}
                onChange={(event: any) => {
                  const date = new Date(event.value);
                  const roundOffDate = roundSeconds(date);
                  dispatchFormAction({
                    type: "datepicker",
                    fieldID: id,
                    payload: roundOffDate,
                  });
                }}
                responsive={{
                  xsmall: {
                    controls: ["date", "time"],
                    display: "bottom",
                    touchUi: true,
                  },
                  medium: {
                    controls: ["date", "time"],
                    display: "anchored",
                    touchUi: false,
                  },
                }}
              />
            </div>
          </div>
        );
      case "endTime":
        if (
          eventType.value === EnumEventEventType.FirstDay ||
          eventType.value === EnumEventEventType.LastDay ||
          eventType.value === EnumEventEventType.Holiday ||
          eventType.value === EnumEventEventType.NoSchool ||
          allDay
        ) {
          return null;
        }

        return (
          <div className="flex-grow">
            <label
              htmlFor="end-time"
              className="text-slate11 block text-sm font-medium"
            >
              {field.label}
            </label>
            <div className="mt-1">
              <Datepicker
                controls={["date", "time"]}
                touchUi={true}
                theme="ios"
                value={state[id]}
                themeVariant="light"
                inputProps={{
                  placeholder: field.placeholder,
                }}
                onChange={(event: any) => {
                  const date = new Date(event.value);
                  const roundOffDate = roundSeconds(date);
                  dispatchFormAction({
                    type: "datepicker",
                    fieldID: id,
                    payload: roundOffDate,
                  });
                }}
                responsive={{
                  xsmall: {
                    controls: ["date", "time"],
                    display: "bottom",
                    touchUi: true,
                  },
                  medium: {
                    controls: ["date", "time"],
                    display: "anchored",
                    touchUi: false,
                  },
                }}
              />
            </div>
          </div>
        );
      case "date":
        console.log("Date", state[id]?.toDateString());

        if (
          eventType.value === EnumEventEventType.FirstDay ||
          eventType.value === EnumEventEventType.LastDay ||
          eventType.value === EnumEventEventType.Holiday ||
          eventType.value === EnumEventEventType.NoSchool ||
          allDay
        ) {
          return (
            <div className="flex-grow">
              <label
                htmlFor="date"
                className="text-slate11 block text-sm font-medium"
              >
                {field.label}
              </label>
              <div className="mt-1">
                <Datepicker
                  controls={["date"]}
                  timezonePlugin={momentTimezone}
                  touchUi={true}
                  theme="ios"
                  value={state[id]}
                  themeVariant="light"
                  inputProps={{
                    placeholder: field.placeholder,
                  }}
                  onChange={(event: any) => {
                    const date = new Date(event.value);
                    const roundOffDate = roundSeconds(date);
                    dispatchFormAction({
                      type: "datepicker",
                      fieldID: id,
                      payload: roundOffDate,
                    });
                  }}
                  responsive={{
                    xsmall: {
                      controls: ["date"],
                      display: "bottom",
                      touchUi: true,
                    },
                    medium: {
                      controls: ["date"],
                      display: "anchored",
                      touchUi: false,
                    },
                  }}
                />
              </div>
            </div>
          );
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  const renderEventTypeDropdown = (id: string, field: FormField) => {
    let options = eventTypeEnumToDropdownOptions();

    invariant(activeCalendar, "Active calendar must be defined");

    // We must filter out the first day, last day if they already exist
    const firstDayEventExists = !!activeCalendar.firstDayEvent;
    const lastDayEventExists = !!activeCalendar.lastDayEvent;

    if (firstDayEventExists) {
      options = options.filter(
        (option) => option.value !== EnumEventEventType.FirstDay
      );
    }

    if (lastDayEventExists) {
      options = options.filter(
        (option) => option.value !== EnumEventEventType.LastDay
      );
    }

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "eventType":
        return renderEventTypeDropdown(id, field);
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      const { title, description, eventType, allDay } = state;

      if (!title || !eventType) {
        return;
      }

      const dateCaseSatisfied =
        eventType.value === EnumEventEventType.FirstDay ||
        eventType.value === EnumEventEventType.LastDay ||
        eventType.value === EnumEventEventType.Holiday ||
        eventType.value === EnumEventEventType.NoSchool;

      const allDayEventCaseSatisfied =
        (eventType.value === EnumEventEventType.Event ||
          eventType.value === EnumEventEventType.Period ||
          eventType.value === EnumEventEventType.Occasion) &&
        allDay;

      const timeCaseSatisfied =
        (eventType.value === EnumEventEventType.Event ||
          eventType.value === EnumEventEventType.Period ||
          eventType.value === EnumEventEventType.Occasion) &&
        !allDay;

      if (isEditing) {
        invariant(event, "Session must be defined if editing");

        const updateEvent = {
          id: event.id,
          ...state,
          title,
          description: description || null,
          eventType: eventType.value as EnumEventEventType,
          calendar: { id: calendarId },
          startTime:
            timeCaseSatisfied && state.startTime
              ? getDatetimeInTimezone(state.startTime)
              : null,
          endTime:
            timeCaseSatisfied && state.endTime
              ? getDatetimeInTimezone(state.endTime)
              : null,
          timezone: timeCaseSatisfied ? calendarTimezone : null,
          date:
            (dateCaseSatisfied || allDayEventCaseSatisfied) && state.date
              ? replaceTimeWithZeros(state.date.toISOString())
              : null,
          allDay: allDayEventCaseSatisfied ? true : false,
          recurring: parseRecurring(state.recurring),
        };

        try {
          const res = await save(updateEvent);

          console.log("Updated session", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating session", err);
        }
      } else {
        try {
          console.log("Start time", state.startTime);
          console.log("End time", state.endTime);

          const newEvent = {
            ...state,
            title,
            description: description || null,
            eventType: eventType.value as EnumEventEventType,
            urlId: generateRandomString(),
            calendar: { id: calendarId },
            startTime:
              timeCaseSatisfied && state.startTime
                ? getDatetimeInTimezone(state.startTime)
                : null,
            endTime:
              timeCaseSatisfied && state.endTime
                ? getDatetimeInTimezone(state.endTime)
                : null,
            timezone: timeCaseSatisfied ? calendarTimezone : null,
            date:
              (dateCaseSatisfied || allDayEventCaseSatisfied) && state.date
                ? replaceTimeWithZeros(state.date.toISOString())
                : null,
            allDay: allDayEventCaseSatisfied ? true : false,
            recurring: parseRecurring(state.recurring),
          };

          const res = await save(newEvent);
          console.log("Created event", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating event", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      event,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      calendarId,
      calendarTimezone,
      getDatetimeInTimezone,
      // verifyConversionToTimezone,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} Event
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {EVENT_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(EventCreateAndEditModal);
