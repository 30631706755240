import { gql } from "../../__generated__/gql";

export const CreateSchool = gql(`
    mutation createSchool($data: SchoolCreateInput!) {
        createSchool(data: $data) {
            address 
            createdAt
            classes {
                id
            }
            courses {
                id
            }
            deletedAt
            district {
                id
            }
            edlinkId
            gradeLevels
            id
            identifiers
            locale
            name
            properties
            timezone
            updatedAt
            urlId
        }
    }
`);

export const UpdateSchool = gql(`
    mutation updateSchool($data: SchoolUpdateInput!, $where: SchoolWhereUniqueInput!) {
        updateSchool(data: $data, where: $where) {
            address 
            createdAt
            classes {
                id
            }
            courses {
                id
            }
            deletedAt
            district {
                id
            }
            edlinkId
            gradeLevels
            id
            identifiers
            locale
            name
            properties
            timezone
            updatedAt
            urlId
        }
    }
`);
