import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import Session from "../../../models/Session";
import { DashboardBreakpoint } from "../../../types";
// import { districtSchedulingRoute } from "../../../utils/routeHelper";
import { sessionRoute } from "../../../utils/routeHelper";
import {
  sessionStateToStringMap,
  sessionTypeToStringMap,
} from "../../../utils/schedulingEnums";
import { EnumSessionState } from "../../../__generated__/graphql";
import Button from "../../Button";
import SessionCreateAndEditModal from "../../CRUD/CreateAndEdit/Session";
import Link from "../../Link";
import Tooltip from "../../Tooltip";

type SessionItemsProps = {
  breakpoint: DashboardBreakpoint;
  session: Session;
};

function SessionItem({ breakpoint, session }: SessionItemsProps) {
  const [showEditSessionModal, setShowEditSessionModal] = useState(false);

  const { addToast } = useToastsContext();

  const { districts, ui } = useStores();

  const { activeDistrictId } = ui;

  const sessionJS = toJS(session);

  const [isMissingSessionDates, setIsMissingSessionDates] = useState(false);

  useEffect(() => {
    if (!sessionJS.startDate || !sessionJS.endDate) {
      setIsMissingSessionDates(true);
    }
  }, [session, sessionJS]);

  if (!activeDistrictId) {
    return null;
  }

  const district = districts.getByUrlParam(activeDistrictId);

  if (!district) {
    return null;
  }

  const renderSessionStateBadge = (sessionState: EnumSessionState) => {
    switch (sessionState) {
      case EnumSessionState.Active:
        return (
          <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
            {sessionStateToStringMap[sessionState]}
          </span>
        );
      case EnumSessionState.Completed:
        return (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
            {sessionStateToStringMap[sessionState]}
          </span>
        );
      case EnumSessionState.Upcoming:
        return (
          <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
            {sessionStateToStringMap[sessionState]}
          </span>
        );
      default:
        return null;
    }
  };

  const renderSessionItemLg = () => (
    <tr key={session.id}>
      <td className="flex items-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {isMissingSessionDates ? (
          <span className="mr-3 py-1 text-sm">{session.name}</span>
        ) : (
          <Link
            to={sessionRoute(session, "calendar")}
            className="mr-3 py-1 hover:underline"
          >
            {session.name}
          </Link>
        )}
        {/* Render the state badge */}
        {renderSessionStateBadge(session.state)}
        {/* Render session missing start and end date warning! */}
        {isMissingSessionDates && (
          <div className="ml-4 inline-flex h-full items-center">
            <Tooltip content={"Session is missing start or end date"}>
              <ExclamationCircleIcon
                className="h-6 w-6 text-red-500"
                aria-hidden="true"
              />
            </Tooltip>
          </div>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {sessionTypeToStringMap[session.sessionType]}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {sessionJS.startDate
          ? DateTime.fromISO(sessionJS.startDate, {
              zone: "utc",
            }).toFormat("LLL dd, yyyy")
          : ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {sessionJS.endDate
          ? DateTime.fromISO(sessionJS.endDate, {
              zone: "utc",
            }).toFormat("LLL dd, yyyy")
          : ""}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditSessionModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderSchoolItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditSessionModal && (
        <SessionCreateAndEditModal
          isEditing={true}
          session={session}
          onClose={() => setShowEditSessionModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditSessionModal(false);

            addToast("Session updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderSessionItemLg() : renderSchoolItemSm()}
    </React.Fragment>
  );
}

export default observer(SessionItem);
