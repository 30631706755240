import React from "react";
import Session from "../../../models/Session";
import { DashboardBreakpoint } from "../../../types";
import SessionItem from "../SessionItem";

type ListOfSessionsProps = {
  sessions: Session[];
};

function ListOfSessions({ sessions }: ListOfSessionsProps) {
  const renderSessionsLg = () => {
    return (
      <div className="hidden sm:block">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Start
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        End
                      </th>
                      <th scope="col" className="relative py-3.5 ">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {renderSessionsList("lg")}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSessionsList = (breakpoint: DashboardBreakpoint) => {
    return sessions.map((session) => {
      return (
        <SessionItem
          key={session.id}
          session={session}
          breakpoint={breakpoint}
        />
      );
    });
  };

  return <React.Fragment>{renderSessionsLg()}</React.Fragment>;
}

export default ListOfSessions;
