/**

LocalStorage is a container class for localStorage that ensures safe
usage when localStorage is not accessible.
*/
class LocalStorage {
  storageInterface: typeof localStorage | InMemoryStorage;
  public constructor() {
    try {
      localStorage.setItem("check", "check");
      localStorage.removeItem("check");
      this.storageInterface = localStorage;
    } catch (_error) {
      this.storageInterface = new InMemoryStorage();
    }
  }

  /**
    
    Save a value in storage. To optimize, this method will delete the
    value if it is undefined.
    @param identifier The key to save under.
    @param data The value to save
    */
  public save<T>(identifier: string, data: T) {
    try {
      if (data === undefined) {
        this.delete(identifier);
      } else {
        this.storageInterface.setItem(identifier, JSON.stringify(data));
      }
    } catch (_error) {
      // Disregard errors
    }
  }
  /**
    
    Retrieve a value from storage.
    @param identifier The key to retrieve.
    @param defaultValue The fallback value if the key doesn't exist.
    @returns The value or undefined if it doesn't exist.
    */
  public retrieve(identifier: string, defaultValue?: any) {
    try {
      const data = this.storageInterface.getItem(identifier);
      if (typeof data === "string") {
        return JSON.parse(data);
      }
    } catch (_error) {
      // Disregard errors
    }

    return defaultValue;
  }

  /**
    
    Delete a value from storage.
    @param identifier The key to delete.
    */
  public delete(identifier: string) {
    try {
      this.storageInterface.removeItem(identifier);
    } catch (_error) {
      // Disregard errors
    }
  }
}
class InMemoryStorage {
  private storageData: { [key: string]: string | undefined } = {};

  getItem(key: string) {
    return this.storageData[key] || null;
  }

  setItem(key: string, value: any) {
    return (this.storageData[key] = String(value));
  }

  removeItem(key: string) {
    return delete this.storageData[key];
  }

  clear() {
    return (this.storageData = {});
  }
}

export default new LocalStorage();
