import { useEffect, useRef } from "react";

/**
 * A utility to simplify the process of adding and removing event listeners
 * within React components.
 *
 * @param eventTitle The event to listen for.
 * @param eventHandler The function to execute when the event occurs.
 * @param target The target element to attach the event listener to.
 * @param listenerOptions Options for the event listener.
 */
export default function useEventHook<T extends EventListener>(
  eventTitle: string,
  eventHandler: T,
  target: Window | Node = window,
  listenerOptions: AddEventListenerOptions = {}
) {
  const handlerRef = useRef<T>();
  const { capture, passive, once } = listenerOptions;

  useEffect(() => {
    handlerRef.current = eventHandler;
  }, [eventHandler]);

  useEffect(() => {
    const supported = target && target.addEventListener;
    if (!supported) {
      return;
    }

    const eventListener: EventListener = (event) => handlerRef.current?.(event);

    const opts = { capture, passive, once };
    target.addEventListener(eventTitle, eventListener, opts);
    return () => target.removeEventListener(eventTitle, eventListener, opts);
  }, [eventTitle, target, capture, passive, once]);
}
