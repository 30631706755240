import { DetailedReactHTMLElement } from "react";
import { Location, NavigateFunction } from "react-router-dom";
import { ModalContextValue } from "./contexts/modals";
import { ToastContextValue } from "./contexts/toasts";
import RootStore from "./stores/RootStore";

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type PartialWithId<T> = Partial<T> & { id: string };

// DROPDOWN MENU ACTIONS

export type ActionContext = {
  activeSessionId?: string;
  activeDistrictId?: string;
  activeSchoolId?: string;
  activeClassId?: string;
  activeSectionId?: string;
  userId: string | undefined;
  // orgId: string | undefined;
  navigate?: NavigateFunction;
  location: Location;
  stores: RootStore;
  event?: Event;
  modalContext: ModalContextValue;
  toastsContext: ToastContextValue;
};

export type DropdownActionContext = ActionContext & {
  menuType: "dropdown";
};

export type PopoverActionContext = ActionContext & {
  menuType: "popover";
};

export type DropdownAction = {
  type: string;
  id: string;
  text: string;
  trackingName?: string;
  icon?:
    | React.FC
    | React.ReactElement
    | DetailedReactHTMLElement<{ className: string }, HTMLElement>;
  onSelect?: (context: DropdownActionContext) => void;
  show?: (context: DropdownActionContext) => boolean;
  destructive?: boolean;
  children?: DropdownAction[];
  // For RadioGroup menus
  value?: string | ((context: DropdownActionContext) => string);
  onValueChange?: (context: DropdownActionContext, value: string) => void;
  itemIndicator?: "checkbox" | "check" | "radio";
};

export type DropdownItemButton = {
  type: "button";
  text: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  destructive?: boolean;
  show?: boolean;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
};

export type DropdownRadioGroup = {
  type: "radio-group";
  value: string;
  onValueChange: (value: string) => void;
  items: DropdownRadioItem[];
  itemIndicator: "checkbox" | "check" | "radio";
  show?: boolean;
};

export type DropdownRadioItem = {
  type: "radio-item";
  text: React.ReactNode;
  show?: boolean;
  disabled?: boolean;
  value: string;
  icon?: React.ReactElement;
};

export type DropdownLinkInternal = {
  type: "route";
  text: React.ReactNode;
  to: string;
  show?: boolean;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
};

export type DropdownLinkExternal = {
  type: "link";
  text: React.ReactNode;
  href: string;
  show?: boolean;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
};

export type DropdownSubmenu = {
  type: "submenu";
  text: React.ReactNode;
  items: DropdownItemType[];
  show?: boolean;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
};

export type DropdownDivider = {
  type: "divider";
  show?: boolean;
};

export type DropdownHeading = {
  type: "heading";
  text: React.ReactNode;
  show?: boolean;
};

export type DropdownItemType =
  | DropdownItemButton
  | DropdownLinkInternal
  | DropdownLinkExternal
  | DropdownSubmenu
  | DropdownDivider
  | DropdownHeading
  | DropdownRadioGroup
  | DropdownRadioItem;

export type PopoverAction = {
  type: string;
  id: string;
  text: string;
  description?: string;
  trackingName?: string;
  icon?: React.FC | React.ReactElement;
  iconColor?: string;
  onSelect?: (context: PopoverActionContext) => void;
  show?: (context: PopoverActionContext) => boolean;
};

export type PopoverItemButton = {
  type: "button";
  text: React.ReactNode;
  description?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  show?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
  iconColor?: string;
};

export type PopoverItemType = PopoverItemButton;

export type DropdownOptionType = {
  label: string;
  value: string;
};

export type FormAction = {
  type:
    | "text"
    | "textarea"
    | "switch"
    | "dropdown"
    | "multiselect"
    | "datepicker"
    | "checkbox"
    | "colorpicker"
    | "users"
    | "recurring";
  fieldID: string;
  payload: any;
};

export type FieldKind =
  | "text"
  | "textarea"
  | "dropdown"
  | "switch"
  | "multiselect"
  | "datepicker"
  | "checkbox"
  | "radio"
  | "colorpicker"
  | "users"
  | "recurring";

export type FormField = {
  fieldKind: FieldKind;
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
};

export type DashboardBreakpoint = "sm" | "lg";

export type PageTab = {
  name: string;
  to?: string;
  onClick?: () => void;
  current: (currentTab: string) => boolean;
  icon?: React.ComponentType<React.ComponentProps<"svg">>;
};

export type DaysOfWeek =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export const possibleDaysOfWeek: DaysOfWeek[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const daysOfTheWeekOptions: DaysOfWeek[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export type CalendarView = "day" | "week" | "month" | "agenda";
