import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateEnrollment,
  UpdateEnrollment,
} from "../graphql/enrollments/enrollments.mutations";
import {
  GetEnrollment,
  GetEnrollments,
} from "../graphql/enrollments/enrollments.queries";
import Enrollment from "../models/Enrollment";
import {
  EnrollmentCreateInput,
  EnrollmentUpdateInput,
  EnrollmentWhereUniqueInput,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class EnrollmentStore extends BaseStore<Enrollment> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Enrollment, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetch = async (id: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetEnrollment,
          variables: {
            where: {
              id,
            },
          },
        })
        .then((res) => {
          const enrollment = res.data.enrollment;

          invariant(enrollment, "Enrollment must exist");
          invariant(enrollment.classV2, "Enrollment must have a class");
          invariant(enrollment.state, "Enrollment must have a state");
          invariant(enrollment.section, "Enrollment must have a section");
          invariant(enrollment.user, "Enrollment must have a user");

          // Check if the ClassV2 is already in the store and fetch it if not
          const fetchEnrollmentClass = this.rootStore.classes.get(
            enrollment.classV2.id
          );

          if (!fetchEnrollmentClass) {
            this.rootStore.classes.fetch(enrollment.classV2.id);
          }

          const fetchEnnrollmentSection = this.rootStore.sections.get(
            enrollment.section.id
          );

          if (!fetchEnnrollmentSection) {
            this.rootStore.sections.fetch(enrollment.section.id);
          }

          const sanitizeEnrollment = {
            classV2Id: enrollment.classV2.id,
            edlinkId: enrollment.edlinkId,
            endDate: enrollment.endDate,
            id: enrollment.id,
            identifiers: enrollment.identifiers,
            primary: enrollment.primary,
            role: enrollment.role || undefined,
            sectionId: enrollment.section.id,
            startDate: enrollment.startDate,
            state: enrollment.state,
            userId: enrollment.user.id,
            // fullName:
            //   enrollment.user.firstName + " " + enrollment.user.lastName,
          };

          this.add(sanitizeEnrollment);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchEnrollmentsForSection = (sectionId: string) => {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetEnrollments,
          variables: {
            where: {
              section: {
                id: sectionId,
              },
            },
          },
        })
        .then((res) => {
          const enrollments = res.data.enrollments;
          console.log("Fetched enrollments", enrollments);
          if (!enrollments) {
            reject(false);
          }
          runInAction("Populate enrollments", () => {
            enrollments.forEach((enrollment: any) => {
              invariant(enrollment.classV2, "Enrollment must have a class");
              invariant(enrollment.state, "Enrollment must have a state");
              invariant(enrollment.section, "Enrollment must have a section");
              invariant(enrollment.user, "Enrollment must have a user");

              const sanitizeEnrollment = {
                classV2Id: enrollment.classV2.id,
                edlinkId: enrollment.edlinkId,
                endDate: enrollment.endDate,
                id: enrollment.id,
                identifiers: enrollment.identifiers,
                primary: enrollment.primary,
                role: enrollment.role || undefined,
                sectionId: enrollment.section.id,
                startDate: enrollment.startDate,
                state: enrollment.state,
                userId: enrollment.user.id,
                // fullName:
                //   enrollment.user.firstName + " " + enrollment.user.lastName,
              };
              this.add(sanitizeEnrollment);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchEnrollmentsForClass = (id: string) => {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetEnrollments,
          variables: {
            where: {
              classV2: {
                id,
              },
            },
          },
        })
        .then((res) => {
          const enrollments = res.data.enrollments;
          console.log("Fetched enrollments", enrollments);
          if (!enrollments) {
            reject(false);
          }
          runInAction("Populate enrollments", () => {
            enrollments.forEach((enrollment: any) => {
              invariant(enrollment.classV2, "Enrollment must have a class");
              invariant(enrollment.state, "Enrollment must have a state");
              invariant(enrollment.section, "Enrollment must have a section");
              invariant(enrollment.user, "Enrollment must have a user");

              const sanitizeEnrollment = {
                classV2Id: enrollment.classV2.id,
                edlinkId: enrollment.edlinkId,
                endDate: enrollment.endDate,
                id: enrollment.id,
                identifiers: enrollment.identifiers,
                primary: enrollment.primary,
                role: enrollment.role || undefined,
                sectionId: enrollment.section.id,
                startDate: enrollment.startDate,
                state: enrollment.state,
                userId: enrollment.user.id,
                // fullName:
                //   enrollment.user.firstName + " " + enrollment.user.lastName,
              };
              this.add(sanitizeEnrollment);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<Enrollment>): Promise<Enrollment> => {
    const { newlyCreated, id, ...rest } = args;
    this.isSaving = true;
    try {
      if (!id || newlyCreated) {
        return this.create(rest as EnrollmentCreateInput);
      } else {
        return this.update(
          rest as EnrollmentUpdateInput,
          { id } as EnrollmentWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: EnrollmentCreateInput): Promise<Enrollment> {
    const res = await this.apolloClient.mutate({
      mutation: CreateEnrollment,
      variables: {
        data,
      },
    });
    if (!res.data || !res.data.createEnrollment) {
      throw Error("Failed to create enrollment.");
    }
    const enrollment = res.data.createEnrollment;
    invariant(enrollment.classV2, "Enrollment must have a class");
    invariant(enrollment.state, "Enrollment must have a state");
    invariant(enrollment.section, "Enrollment must have a section");
    invariant(enrollment.user, "Enrollment must have a user");
    const sanitizeEnrollment = {
      classV2Id: enrollment.classV2.id,
      edlinkId: enrollment.edlinkId,
      endDate: enrollment.endDate,
      id: enrollment.id,
      identifiers: enrollment.identifiers,
      primary: enrollment.primary,
      role: enrollment.role || undefined,
      sectionId: enrollment.section.id,
      startDate: enrollment.startDate,
      state: enrollment.state,
      userId: enrollment.user.id,
    };
    return this.add(sanitizeEnrollment);
  }

  async update(
    data: EnrollmentUpdateInput,
    where: EnrollmentWhereUniqueInput
  ): Promise<Enrollment> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateEnrollment,
      variables: {
        data,
        where,
      },
    });
    if (!res.data || !res.data.updateEnrollment) {
      throw Error("Failed to update enrollment.");
    }
    const enrollment = res.data.updateEnrollment;
    invariant(enrollment.classV2, "Enrollment must have a class");
    invariant(enrollment.state, "Enrollment must have a state");
    invariant(enrollment.section, "Enrollment must have a section");
    invariant(enrollment.user, "Enrollment must have a user");
    const sanitizeEnrollment = {
      classV2Id: enrollment.classV2.id,
      edlinkId: enrollment.edlinkId,
      endDate: enrollment.endDate,
      id: enrollment.id,
      identifiers: enrollment.identifiers,
      primary: enrollment.primary,
      role: enrollment.role || undefined,
      sectionId: enrollment.section.id,
      startDate: enrollment.startDate,
      state: enrollment.state,
      userId: enrollment.user.id,
    };
    return this.add(sanitizeEnrollment);
  }

  getEnrollmentsForClass = (classId: string): Enrollment[] => {
    return this.sortedData.filter(
      (enrollment) => enrollment.classV2Id === classId && !enrollment.deletedAt
    );
  };

  getEnrollmentsForSection = (sectionId: string): Enrollment[] => {
    return this.sortedData.filter(
      (enrollment) =>
        enrollment.sectionId === sectionId && !enrollment.deletedAt
    );
  };

  getEnrollments = (): Enrollment[] => {
    return this.sortedData.filter((enrollment) => !enrollment.deletedAt);
  };
}
