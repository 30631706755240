import { observer } from "mobx-react";
import React from "react";
import { useParams } from "react-router-dom";
import SectionTimeblocking from "../components/CRUD/CreateAndEdit/SectionTimeblocking";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Classes() {
  const { classes } = useStores();

  const { classId } = useParams();

  const { addToast } = useToastsContext();

  const activeClass = classId ? classes.getByUrlParam(classId) : null;

  // We need to split the classes into assigned and unassigned
  // Assigned classes will have a calendar entity
  // Unassigned classes will not have a calendar entity

  if (!activeClass) return null;

  return (
    <React.Fragment>
      {/* <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">
              Scheduling for {activeClass.name}
            </h2>
          </div>
        }
        right={null}
      > */}
      <div className="w-full px-20 py-2 pt-4">
        <SectionTimeblocking classV2={activeClass} />
      </div>
      {/* </PageLayout> */}
    </React.Fragment>
  );
}

export default observer(Classes);
