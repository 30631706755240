import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { DateTime } from "luxon";
import { action, observable, runInAction } from "mobx";
import {
  ModifyCalendarDay,
  ReinitializeRotationDaysPreview,
} from "../graphql/scheduling/scheduling.mutations";
import { GetCalendarDays } from "../graphql/scheduling/scheduling.queries";
import CalendarDay from "../models/CalendarDay";
import { UpdateCalendarDayInput } from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

const constructDatetimeInTimezone = (
  dateString: string, // dateString format should be "yyyy-MM-dd"
  timeString: string, // timeString format should be "HH:mm"
  calendarTimezone: string
): string | null => {
  // Step 1: Convert the dateString to DateTime object
  const [year, month, day] = dateString.split("-").map(Number);

  // Parse hours and minutes from the timeString
  const [hour, minute] = timeString.split(":").map(Number);

  // Create the DateTime object in UTC and then adjust the timezone
  let datetime = DateTime.fromObject(
    { year, month, day, hour, minute },
    { zone: calendarTimezone }
  );

  // Step 2: Convert the timezone adjusted DateTime back to UTC format for storage
  const utcTime = datetime.toUTC();

  // Return the formatted UTC time as a string
  return utcTime.toISO();
};

export default class CalendarDayStore extends BaseStore<CalendarDay> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, CalendarDay, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchCalendarDays = (calendarId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetCalendarDays,
          variables: {
            where: {
              calendar: {
                id: calendarId,
              },
              deletedAt: null,
            },
          },
        })
        .then((res) => {
          const calendarDays = res.data.calendarDays;

          if (!calendarDays) {
            reject(false);
          }

          runInAction("Populate calendar days", () => {
            calendarDays.forEach((day) => {
              invariant(day.calendar, "Calendar Day must have a calendar");

              invariant(
                day.rotationSchedule,
                "Calendar Day must have a rotation schedule"
              );

              invariant(
                day.rotationDay,
                "Calendar Day must have a rotation day"
              );

              const calendarId = day.calendar.id;

              const formattedDate = day.date.split("T")[0];

              if (day.alternateSchedule) {
                // We add the calendar day as an event to the calendarEvents store
                this.rootStore.calendarEvents.add({
                  id: day.id,
                  title: day.alternateSchedule.name,
                  eventType: "alternate-day",
                  start: day.date,
                  allDay: true,
                  calendarId,
                  color: "white",
                  formattedDate,
                });

                // We will have to add the alternate day block schedules instead of
              }

              // We add the block schedules as events to the calendarEvents store
              const blockSchedules = day.alternateSchedule
                ? day.alternateSchedule.blockSchedules
                : day.rotationDay.blockSchedules;

              blockSchedules.forEach((blockSchedule) => {
                invariant(
                  blockSchedule.block,
                  "Block Schedule must have a block"
                );

                invariant(
                  day.calendar,
                  "Block Schedule must have a calendar day"
                );

                // Construct the start and end time for the block schedule
                // blockSchedule.startTime is in (HH:MM) format
                // blockSchedule.endTime is in (HH:MM) format
                // Use day.date to construct the start and end time

                this.rootStore.calendarEvents.add({
                  id: blockSchedule.id + "-" + day.id,
                  title:
                    blockSchedule.block.description ||
                    blockSchedule.block.abbreviation,
                  color: blockSchedule.block.color || "pink",
                  start:
                    constructDatetimeInTimezone(
                      formattedDate,
                      blockSchedule.startTime,
                      day.calendar.timezone || "utc"
                    ) || "",
                  end:
                    constructDatetimeInTimezone(
                      formattedDate,
                      blockSchedule.endTime,
                      day.calendar.timezone || "utc"
                    ) || "",
                  formattedDate,
                  allDay: false,
                  eventType: "block",
                  calendarId,
                  editable: true,
                });
              });

              const sanitizeCalendarDay = {
                alternateScheduleId: day.alternateSchedule
                  ? day.alternateSchedule.id
                  : null,
                calendarId,
                createdAt: day.createdAt,
                date: day.date,
                id: day.id,
                rotationDayId: day.rotationDay.id,
                rotationScheduleId: day.rotationSchedule.id,
                updatedAt: day.updatedAt,
              };

              this.add(sanitizeCalendarDay);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  reinitializeRotationDaysPreview = (
    calendarDayId: string,
    rotationDayId: string
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ReinitializeRotationDaysPreview,
          variables: {
            calendarDay: {
              id: calendarDayId,
            },
            rotationDay: {
              id: rotationDayId,
            },
          },
        })
        .then((res) => {
          if (!res.data || !res.data.reinitializeRotationDayPreview) {
            throw Error("Failed to reinitialize rotation day preview.");
          }

          resolve(res.data.reinitializeRotationDayPreview);
        })
        .catch((e) => {
          console.log("Error", e);
          reject(false);
        });
    });
  };

  @action
  modifyCalendarDay = (
    data: UpdateCalendarDayInput,
    calendarDayId: string
  ): Promise<CalendarDay> => {
    this.isSaving = true;

    console.log("Calendar Day Id", calendarDayId);

    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ModifyCalendarDay,
          variables: {
            calendarDay: {
              id: calendarDayId,
            },
            data,
          },
        })
        .then((res) => {
          if (!res.data || !res.data.modifyCalendarDay) {
            throw Error("Failed to update alternate day schedules.");
          }

          const day = res.data.modifyCalendarDay;

          invariant(day.calendar, "Calendar Day must have a calendar");

          invariant(
            day.rotationSchedule,
            "Calendar Day must have a rotation schedule"
          );

          invariant(day.rotationDay, "Calendar Day must have a rotation day");

          const calendarId = day.calendar.id;

          // We need to clear and re-fetch a lot of the new data
          // The easiest way to do this is to re-fetch the entire calendar
          if (data.reinitializeRotationDays) {
            // Remove this calendarId data from calendarDays, events, calendarEvents
            this.rootStore.events.removeEvents(calendarId);
            this.rootStore.calendarEvents.removeCalendarEvents(calendarId);
            this.removeCalendarDays(calendarId);

            // Now re-fetch the calendar
            this.rootStore.calendars.fetch(calendarId);
          } else {
            // We only need to clear current calendar day events from the events & calendarEvents Store
            this.rootStore.events.removeEventsForCalendarDay(day.id);
            this.rootStore.calendarEvents.removeCalendarEventsForCalendarDay(
              day.id
            );
          }

          const formattedDate = day.date.split("T")[0];

          // We add the calendar day as an event to the calendarEvents store
          this.rootStore.calendarEvents.add({
            id: day.id,
            title: day.rotationDay.abbreviation,
            start: day.date,
            end: day.date,
            allDay: true,
            calendarId,
            eventType: "rotation-day",
            color: "white",
            formattedDate,
            editable: false,
          });

          if (day.alternateSchedule) {
            // We add the calendar day as an event to the calendarEvents store
            this.rootStore.calendarEvents.add({
              id: day.id,
              title: day.alternateSchedule.name,
              eventType: "alternate-day",
              start: day.date,
              allDay: true,
              calendarId,
              color: "white",
              formattedDate,
            });

            // We will have to add the alternate day block schedules instead of
          }

          // We add the block schedules as events to the calendarEvents store
          const blockSchedules = day.alternateSchedule
            ? day.alternateSchedule.blockSchedules
            : day.rotationDay.blockSchedules;

          blockSchedules.forEach((blockSchedule) => {
            invariant(blockSchedule.block, "Block Schedule must have a block");

            invariant(day.calendar, "Block Schedule must have a calendar day");

            // Construct the start and end time for the block schedule
            // blockSchedule.startTime is in (HH:MM) format
            // blockSchedule.endTime is in (HH:MM) format
            // Use day.date to construct the start and end time

            this.rootStore.calendarEvents.add({
              id: blockSchedule.id + "-" + day.id,
              title:
                blockSchedule.block.description ||
                blockSchedule.block.abbreviation,
              color: blockSchedule.block.color || "pink",
              start:
                constructDatetimeInTimezone(
                  formattedDate,
                  blockSchedule.startTime,
                  day.calendar.timezone || "utc"
                ) || "",
              end:
                constructDatetimeInTimezone(
                  formattedDate,
                  blockSchedule.endTime,
                  day.calendar.timezone || "utc"
                ) || "",
              formattedDate,
              allDay: false,
              eventType: "block",
              calendarId,
              editable: true,
            });
          });

          const sanitizeCalendarDay = {
            alternateScheduleId: day.alternateSchedule
              ? day.alternateSchedule.id
              : null,
            calendarId,
            createdAt: day.createdAt,
            date: day.date,
            id: day.id,
            rotationDayId: day.rotationDay.id,
            rotationScheduleId: day.rotationSchedule.id,
            updatedAt: day.updatedAt,
          };

          resolve(this.add(sanitizeCalendarDay));
        })
        .catch((e) => {
          console.log("Error", e);
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isSaving = false;
          });
        });
    });
  };

  getDayForDate = (
    calendarId: string,
    date: string
  ): CalendarDay | undefined => {
    return this.sortedData.find(
      (day) => day.calendarId === calendarId && day.date.startsWith(date)
    );
  };

  @action
  getCalendarDays = (calendarId: string): CalendarDay[] => {
    return this.sortedData.filter(
      (day) => day.calendarId === calendarId && !day.deletedAt
    );
  };

  @action
  removeCalendarDays = (calendarId: string) => {
    this.sortedData
      .filter((day) => day.calendarId === calendarId)
      .forEach((day) => {
        this.remove(day.id);
      });
  };
}
