import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import ClassV2 from "../../../models/ClassV2";
import { DashboardBreakpoint } from "../../../types";
import { classStateToStringMap } from "../../../utils/classAndSectionEnums";
// import { districtSchoolClassRoute } from "../../../utils/routeHelper";
import { classRoute } from "../../../utils/routeHelper";
import { EnumClassV2State } from "../../../__generated__/graphql";
import Button from "../../Button";
import ClassCreateAndEditModal from "../../CRUD/CreateAndEdit/Class";
import Link from "../../Link";

type ClassItemsProps = {
  breakpoint: DashboardBreakpoint;
  classV2: ClassV2;
};

function ClassItem({ breakpoint, classV2 }: ClassItemsProps) {
  const [showEditClassModal, setShowEditClassModal] = useState(false);

  const { addToast } = useToastsContext();

  const { districts, schools } = useStores();

  if (!classV2) return null;

  const school = schools.get(classV2.schoolId);

  if (!school) return null;

  const district = districts.get(school.districtId);

  if (!district) return null;

  const renderClassStateBadge = (sessionState: EnumClassV2State) => {
    switch (sessionState) {
      case EnumClassV2State.Active:
        return (
          <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Completed:
        return (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Upcoming:
        return (
          <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Inactive:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-800">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Archived:
        return (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-gray-800">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Template:
        return (
          <span className="inline-flex items-center rounded-md bg-pink-100 px-2 py-1 text-xs font-medium text-gray-800">
            {classStateToStringMap[sessionState]}
          </span>
        );

      default:
        return null;
    }
  };

  const renderClassItemLg = () => (
    <tr key={classV2.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <Link to={classRoute(classV2, "lessons")} className="hover:underline">
          {classV2.name}
        </Link>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {renderClassStateBadge(classV2.state)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {classV2.sectionsCount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {classV2.sessionNames}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {classV2.courseName}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditClassModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderClassItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditClassModal && (
        <ClassCreateAndEditModal
          isEditing={true}
          classV2={classV2}
          onClose={() => setShowEditClassModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditClassModal(false);

            addToast("Class updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderClassItemLg() : renderClassItemSm()}
    </React.Fragment>
  );
}

export default observer(ClassItem);
