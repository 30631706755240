import { computed, observable } from "mobx";
import {
  EnumCalendarCalendarType,
  EnumRotationScheduleDaysOfWeek,
  EnumRotationScheduleGradeLevels,
} from "../__generated__/graphql";
import Block from "./Block";
import Calendar from "./Calendar";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";
import RotationDay from "./RotationDay";

class RotationSchedule extends DeletableModel {
  @observable
  id: string;

  @observable
  calendarDayIds: string[];

  @Attribute
  @observable
  name: string;

  @observable
  rotationDayIds: string[];

  @observable
  sessionId: string;

  @Attribute
  @observable
  skipNoSchoolDays: boolean | null;

  @Attribute
  @observable
  daysOfWeek: EnumRotationScheduleDaysOfWeek[] | null;

  @Attribute
  @observable
  gradeLevels: EnumRotationScheduleGradeLevels[] | null;

  @computed
  get rotationDaysCount(): number {
    return this.rotationDayIds.length;
  }

  @computed
  get calendarDaysCount(): number {
    return this.calendarDayIds.length;
  }

  @computed
  get activeCalendar(): Calendar | null {
    const { calendars } = this.store.rootStore;

    const scheduleCalendars = calendars.getCalendarsForRotationSchedule(
      this.id
    );

    // Find the session default calendar
    const sessionActiveCalendar = scheduleCalendars.find(
      (calendar: Calendar) => {
        return (
          calendar.calendarType === EnumCalendarCalendarType.SessionDefault
        );
      }
    );

    return sessionActiveCalendar || null;
  }

  @computed
  get rotationDays(): RotationDay[] {
    const { rotationDays } = this.store.rootStore;

    return rotationDays.getRotationDaysForSchedule(this.id);
  }

  @computed
  get blocks(): Block[] {
    const { blocks } = this.store.rootStore;

    return blocks.getBlocksForRotationSchedule(this.id);
  }
}

export default RotationSchedule;
