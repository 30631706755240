import { gql } from "../../__generated__/gql";

export const GetEnrollments = gql(`
    query GetEnrollments($where: EnrollmentWhereInput!) {
        enrollments(where: $where) {
            classV2 {
                id
            }
            createdAt 
            deletedAt 
            edlinkId 
            endDate 
            id 
            identifiers 
            primary 
            properties 
            role 
            section {
                id 
            }
            startDate 
            state 
            updatedAt 
            user {
                id
                firstName
                lastName
            }
        }
    }
`);

export const GetEnrollment = gql(`
    query GetEnrollment($where: EnrollmentWhereUniqueInput!) {
        enrollment(where: $where) {
            classV2 {
                id
            }
            createdAt 
            deletedAt 
            edlinkId 
            endDate 
            id 
            identifiers 
            primary 
            properties 
            role 
            section {
                id 
            }
            startDate 
            state 
            updatedAt 
            user {
                id
                firstName
                lastName
            }
        }
    }
`);
