import { computed, observable } from "mobx";
import DeletableModel from "./DeletableModel";

class CalendarSchedule extends DeletableModel {
  @observable
  id: string;

  @observable
  blockScheduleId: string | null;

  @observable
  calendarId: string;

  @observable
  endTime: string | null;

  @observable
  startTime: string | null;

  @observable
  rotationDayId: string | null;

  @computed
  get blockId(): string | null {
    if (!this.blockScheduleId) {
      return null;
    }

    const { blockSchedules } = this.store.rootStore;

    const blockSchedule = blockSchedules.get(this.blockScheduleId);

    if (!blockSchedule) {
      return null;
    }

    return blockSchedule.blockId;
  }

  @computed
  get blockName(): string | null {
    if (!this.blockScheduleId) {
      return null;
    }

    const { blockSchedules } = this.store.rootStore;

    const blockSchedule = blockSchedules.get(this.blockScheduleId);

    if (!blockSchedule) {
      return null;
    }

    return blockSchedule.blockName;
  }

  // @computed
  // get rotationDayId(): string | null {
  //   if (!this.blockScheduleId) {
  //     return null;
  //   }

  //   const { blockSchedules } = this.store.rootStore;

  //   const blockSchedule = blockSchedules.get(this.blockScheduleId);

  //   if (!blockSchedule) {
  //     return null;
  //   }

  //   return blockSchedule.rotationDayId;
  // }

  @computed
  get rotationDayName(): string | null {
    if (!this.blockScheduleId) {
      return null;
    }

    const { blockSchedules } = this.store.rootStore;

    const blockSchedule = blockSchedules.get(this.blockScheduleId);

    if (!blockSchedule) {
      return null;
    }

    return blockSchedule.rotationDayName;
  }
}

export default CalendarSchedule;
