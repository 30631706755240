import { gql } from "../../__generated__/gql";

export const GetSubjects = gql(`
    query GetSubjects($where: SubjectWhereInput!, $orderBy: [SubjectOrderByInput!]) {
        subjects(where: $where, orderBy: $orderBy) {
            cedsCode 
            code 
            createdAt
            department {
                id
            }
            deletedAt
            description
            district {
                id
            } 
            edlinkId 
            id 
            identifiers 
            name 
            properties 
            updatedAt 
        }
    }
`);
