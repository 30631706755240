import clsx from "clsx";

type BadgeProps = {
  id: string;
  label: string;
  color: string;
  dot?: boolean;
  onRemove?: (id: string) => void;
  onClick?: (id: string) => void;
  size?: "sm" | "lg";
  className?: string;
};

export default function Badge({
  id,
  label,
  color,
  dot = false,
  onRemove,
  onClick,
  size = "lg",
  className,
}: BadgeProps) {
  const commonStyle =
    "inline-flex items-center rounded-full font-medium py-0.5";
  const smStyle = "text-xs  px-2.5";
  const lgStyle = "text-sm px-3";

  const allDivs = (
    <div className="hidden">
      <div className="bg-red-100 text-red-800 " />
      <div className="bg-red-200 text-red-400" />
      <div className="bg-red-500 text-red-500" />
      <div className="bg-yellow-100 text-yellow-800" />
      <div className="bg-yellow-200 text-yellow-400" />
      <div className="bg-yellow-500 text-yellow-500" />
      <div className="bg-green-100  text-green-800" />
      <div className="bg-green-200 text-green-400 " />
      <div className="bg-green-500 text-green-500" />
      <div className="bg-blue-100  text-blue-800" />
      <div className="bg-blue-200 text-blue-400" />
      <div className="bg-blue-500 text-blue-500" />
      <div className="bg-indigo-100  text-indigo-800" />
      <div className="bg-indigo-200 text-indigo-400" />
      <div className="bg-indigo-500 text-indigo-500" />
      <div className="bg-purple-100  text-purple-800" />
      <div className="bg-purple-200 text-purple-400" />
      <div className="bg-purple-500 text-purple-500" />
      <div className="bg-pink-100 text-pink-800" />
      <div className="bg-pink-200 text-pink-400" />
      <div className="bg-pink-500 text-pink-500" />
      <div className="bg-indigo-100  text-indigo-800" />
      <div className="bg-indigo-200 text-indigo-400" />
      <div className="bg-indigo-500 text-indigo-500" />
      <div className="bg-orange-100  text-orange-800" />
      <div className="bg-orange-200 text-orange-400" />
      <div className="bg-orange-500 text-orange-500" />
      <div className="bg-fuchsia-100  text-fuchsia-800" />
      <div className="bg-fuchsia-200 text-fuchsia-400" />
      <div className="bg-fuchsia-500 text-fuchsia-500" />
      <div className="bg-lime-100 text-lime-800" />
      <div className="bg-lime-400 text-lime-400" />
      <div className="bg-lime-500 text-lime-500" />
      <div className="bg-emerald-100  text-emerald-800" />
      <div className="bg-emerald-200 text-emerald-400" />
      <div className="bg-emerald-500 text-emerald-500" />
      <div className="bg-teal-100  text-teal-800" />
      <div className="bg-teal-200 text-teal-400" />
      <div className="bg-teal-500 text-teal-500" />
      <div className="bg-cyan-100  text-cyan-800" />
      <div className="bg-cyan-200 text-cyan-400" />
      <div className="bg-cyan-500 text-cyan-500" />
      <div className="bg-sky-100  text-sky-800" />
      <div className="bg-sky-200 text-sky-400" />
      <div className="bg-sky-500 text-sky-500" />
      <div className="bg-violet-100  text-violet-800" />
      <div className="bg-violet-200 text-violet-400" />
      <div className="bg-violet-500 text-violet-500" />
      <div className="bg-rose-100  text-rose-800" />
      <div className="bg-rose-200 text-rose-400" />
      <div className="bg-rose-500 text-rose-500" />
      <div className="bg-amber-100  text-amber-800" />
      <div className="bg-amber-200 text-amber-400" />
      <div className="bg-amber-500 text-amber-500" />
      <div className="bg-gray-200 text-gray-400" />
      <div className="bg-gray-500 text-gray-500" />
    </div>
  );

  return (
    <span
      key={id}
      className={clsx(
        commonStyle,
        size === "sm" ? smStyle : lgStyle,
        "bg-" + color + "-100 text-" + color + "-800",
        className
      )}
    >
      {allDivs}
      {/* Dot */}
      {dot && (
        <svg
          className={clsx("-ml-0.5 mr-1.5 h-2 w-2", "text-" + color + "-400")}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
      )}
      {/* Label */}
      {label}
      {/* if onRemove then render close button */}
      {onRemove && (
        <button
          type="button"
          className={clsx(
            "ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full  focus:text-white focus:outline-none",
            "text-" +
              color +
              "-400 hover:text-" +
              color +
              "-500 focus:bg-" +
              color +
              "-500"
          )}
        >
          <span className="sr-only">Remove large option</span>
          <svg
            className="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      )}
    </span>
  );
}
