import clsx from "clsx";
import { observer } from "mobx-react";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useRoutes } from "react-router-dom";
import useStores from "../../hooks/useStores";
import { Spinner } from "../LoadingIndicators/Spinner";
import AppSidebar from "../Sidebar/AppSidebar";
// import ClassSidebar from "../Sidebar/ClassSidebar";
// import DistrictSidebar from "../Sidebar/DistrictSidebar";
// import SchedulingSidebar from "../Sidebar/SchedulingSidebar";
import SchedulingSidebar from "../Sidebar/SchedulingSidebar";
import styles from "./index.module.css";
import Transition from "./Transition";

type Props = {
  title?: string;
};

function ResponsiveLayout({ title }: Props) {
  const stores = useStores();

  const location = useLocation();

  const { auth, ui } = stores;

  const { user } = auth;

  const showSidebar =
    auth.validSession &&
    user &&
    location.pathname !== "/onboarding" &&
    !location.pathname.startsWith("/resource") &&
    !location.pathname.startsWith("/standards");

  const routes = useRoutes([
    // {
    //   path: "/district/:districtId/school/:schoolId/class/:classId/*",
    //   element: <ClassSidebar />,
    // },
    {
      path: "/session/*",
      element: <SchedulingSidebar />,
    },
    // {
    //   path: "/district/:districtId/*",
    //   element: <DistrictSidebar />,
    // },
    {
      path: "*",
      element: <AppSidebar />,
    },
  ]);

  const sidebar = showSidebar ? <Transition>{routes}</Transition> : undefined;

  const collapsed = ui.mainSidebarCollapsed || !showSidebar;

  return (
    <div className={clsx(styles.Container)}>
      <Helmet>
        <title>{title ? title : process.env.DEFAULT_APP_TITLE}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* Main Sidebar */}
      {sidebar}
      <main
        className={clsx(
          styles.Content,
          ui.mainSidebarResizing ? styles.Is_Resizing : "",
          sidebar ? styles.Has_Sidebar : "",
          collapsed ? styles.Is_Collapsed : ""
        )}
        style={{
          marginLeft: collapsed ? 0 : ui.mainSidebarWidth,
        }}
      >
        <Suspense
          fallback={
            <div className="flex h-screen w-full items-center justify-center">
              <div className="flex flex-col items-center">
                <Spinner size={32} color={"black"} />
              </div>
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
}

export default observer(ResponsiveLayout);
