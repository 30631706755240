import { useModalContext } from "../../contexts/modals";
import Modal from "./Modal";

export const Modals = () => {
  const { modals } = useModalContext();

  return (
    <>
      {modals &&
        Array.from(modals.keys()).map((modalId) => {
          const modal = modals.get(modalId);

          if (!modal) {
            return null;
          }

          if (!modal.content) {
            return (
              <Modal
                key={modalId}
                title={modal.title}
                description={modal.description}
                open={modal.open}
                alignCenter={modal.alignCenter}
                onClose={() => {
                  modal.onClose();
                  // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                  setTimeout(
                    () => (document.body.style.pointerEvents = ""),
                    50
                  );
                }}
                onSubmit={modal.onSubmit}
                submitText={modal.submitText}
                destructive={modal.destructive}
                size={modal.size}
              />
            );
          }

          return (
            <Modal
              key={modalId}
              title={modal.title}
              description={modal.description}
              open={modal.open}
              alignCenter={modal.alignCenter}
              onClose={() => {
                modal.onClose();
                // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                setTimeout(() => (document.body.style.pointerEvents = ""), 50);
              }}
              onSubmit={modal.onSubmit}
              submitText={modal.submitText}
              destructive={modal.destructive}
              size={modal.size}
            >
              {modal.content}
            </Modal>
          );
        })}
    </>
  );
};

export default Modals;
