import { useToastsContext } from "../../contexts/toasts";
import { Toast } from "./Toast";

export const Toasts = () => {
  const { getToastsInOrder, removeToast } = useToastsContext();

  return (
    <ol className="pointer-events-none fixed top-2 right-4 z-[1000]">
      {getToastsInOrder &&
        getToastsInOrder().map((toast) => (
          <Toast
            key={toast.id}
            toast={toast}
            onClose={() => removeToast(toast.id)}
          />
        ))}
    </ol>
  );
};
