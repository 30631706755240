import { Datepicker } from "@mobiscroll/react";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { ActionTypes } from "../utils";

export default function TimeCell({
  initialValue,
  columnId,
  rowIndex,
  dataDispatch,
  min,
  max,
}) {
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const { styles, attributes } = usePopper(selectRef, selectPop, {
    placement: "bottom-start",
    strategy: "fixed",
  });
  const [value, setValue] = useState({ value: initialValue, update: false });

  useEffect(() => {
    // We may need to convert the initial value from a string (HH:MM) to a date
    // Use the default date of today. we set the time in ISO format e.g  2020-05-20T12:30:00

    console.log("Initialize value time cell", initialValue);

    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({
        type: ActionTypes.UPDATE_CELL,
        columnId,
        rowIndex,
        value: value.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, columnId, rowIndex]);

  function handleTimeChange(newTime) {
    setValue({ value: newTime, update: false });
  }

  function onClose() {
    setShowSelect(false);
    setValue((old) => ({
      value: old.value,
      update: true,
    }));
  }

  return (
    <>
      <div
        ref={setSelectRef}
        className={clsx(
          "cell-padding d-flex text-slate10 flex-1 cursor-pointer items-start text-[15px] font-medium",
          "hover:bg-slate3 h-full cursor-pointer "
        )}
        onClick={() => setShowSelect(true)}
      >
        {!value.value || value.value === "" ? (
          "Empty"
        ) : (
          <span className="text-slate12 flex items-center font-medium">
            {DateTime.fromISO(value.value).toFormat("hh:mm a")}{" "}
          </span>
        )}
      </div>
      {showSelect && <div className="overlay" onClick={() => onClose()} />}
      {showSelect &&
        createPortal(
          <div
            className="border-radius-md z-100 bg-white"
            ref={setSelectPop}
            {...attributes.popper}
            style={{
              ...styles.popper,
              zIndex: 4,
              minWidth: 200,
              maxWidth: 320,
              maxHeight: 400,
              overflow: "auto",
              boxShadow:
                "rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px",
            }}
          >
            <Datepicker
              controls={["time"]}
              display="inline"
              showInput={false}
              anchor={selectRef}
              value={value.value}
              isOpen={showSelect}
              onClose={onClose}
              onChange={(args, inst) => {
                handleTimeChange(args.value);
              }}
              themeVariant="light"
              timeFormat="h:mm A"
              selectMultiple={false}
              returnFormat="iso8601"
            />
          </div>,
          document.querySelector("#popper-portal")
        )}
    </>
  );
}
