import { computed, observable } from "mobx";
import {
  EnumCalendarCalendarType,
  EnumEventEventType,
} from "../__generated__/graphql";
import CalendarEvent from "./CalendarEvent";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";
import Event from "./Event";
import RotationSchedule from "./RotationSchedule";
import Session from "./Session";

class Calendar extends DeletableModel {
  @observable
  id: string;

  @observable
  calendarType: EnumCalendarCalendarType;

  @observable
  classV2Id: string | null;

  @observable
  districtId: string | null;

  @observable
  edlinkId: string | null;

  @Attribute
  @observable
  rotationScheduleId: string | null;

  @Attribute
  @observable
  sectionId: string | null;

  @observable
  sessionId: string | null;

  @Attribute
  @observable
  timezone: string | null;

  @observable
  urlId: string;

  @observable
  userId: string | null;

  @computed
  get hasFirstAndLastEvents(): Boolean {
    const { events } = this.store.rootStore;

    const calendarEvents = events.getEventsForCalendar(this.id);

    if (calendarEvents.length === 0) {
      return false;
    }

    const firstDayEvent = calendarEvents.find(
      (event: Event) => event.eventType === EnumEventEventType.FirstDay
    );

    const lastDayEvent = calendarEvents.find(
      (event: Event) => event.eventType === EnumEventEventType.LastDay
    );

    return firstDayEvent && lastDayEvent;
  }

  @computed
  get firstDayEvent(): Event | null {
    const { events } = this.store.rootStore;

    const calendarEvents = events.getEventsForCalendar(this.id);

    const firstDayEvent = calendarEvents.find(
      (event: Event) => event.eventType === EnumEventEventType.FirstDay
    );

    return firstDayEvent ? firstDayEvent : null;
  }

  @computed
  get lastDayEvent(): Event | null {
    const { events } = this.store.rootStore;

    const calendarEvents = events.getEventsForCalendar(this.id);

    const lastDayEvent = calendarEvents.find(
      (event: Event) => event.eventType === EnumEventEventType.LastDay
    );

    return lastDayEvent ? lastDayEvent : null;
  }

  @computed
  get activeEvents(): Event[] {
    const { events } = this.store.rootStore;

    const calendarEvents = events.getEventsForCalendar(this.id);

    const activeEvents = calendarEvents.filter(
      (event: Event) => !event.deletedAt
    );

    return activeEvents;
  }

  @computed
  get rotationScheduleName(): string | null {
    const { rotationSchedules } = this.store.rootStore;

    const calendarRotationSchedule = rotationSchedules.get(
      this.rotationScheduleId
    );

    return calendarRotationSchedule ? calendarRotationSchedule.name : null;
  }

  @computed
  get session(): Session | null {
    const { sessions } = this.store.rootStore;

    return sessions.get(this.sessionId);
  }

  @computed
  get rotationSchedule(): RotationSchedule | null {
    const { rotationSchedules } = this.store.rootStore;

    return rotationSchedules.get(this.rotationScheduleId);
  }

  @computed
  get calendarName(): string {
    // Depending on the Calendar type we return a different name
    const { rotationSchedules, sessions, sections, classes } =
      this.store.rootStore;

    if (this.calendarType === EnumCalendarCalendarType.SessionDefault) {
      const session = sessions.get(this.sessionId);

      const activeRotation = rotationSchedules.get(this.rotationScheduleId);

      return `${session ? session.name : ""} - ${
        activeRotation ? activeRotation.name : ""
      }`;
    } else if (this.calendarType === EnumCalendarCalendarType.SectionDefault) {
      const section = this.sectionId ? sections.get(this.sectionId) : null;

      const classV2 = section ? classes.get(section.classV2Id) : null;

      const session = sessions.get(this.sessionId);

      // Return ClassName - Section Name (Session Name)
      return `${classV2 ? classV2.name : ""} - ${
        section ? section.name : ""
      } (${session ? session.name : ""})`;
    } else {
      return "";
    }
  }

  @computed
  get calendarEventsForDisplay(): CalendarEvent[] {
    const { calendarEvents } = this.store.rootStore;

    return calendarEvents.sortedData.filter(
      (calendarEvent: CalendarEvent) => calendarEvent.calendarId === this.id
    );
  }
}

export default Calendar;
