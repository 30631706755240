// Render the Link component
// Use react-router-dom Link component

import clsx from "clsx";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styles from "./index.module.css";

interface LinkProps {
  to: string;
  children: React.ReactNode;
  openInNewTab?: boolean;
  className?: string;
}

export default function Link({
  to,
  children,
  openInNewTab = false,
  className: classNameProp,
}: LinkProps) {
  const props = {
    to,
    children,
    target: openInNewTab ? "_blank" : undefined,
    rel: openInNewTab ? "noopener noreferrer" : undefined,
    classNameProp,
  };

  // If className is passed, use it to override the default styles

  return (
    <RouterLink
      className={
        classNameProp
          ? classNameProp
          : clsx(styles.linkStyle, "text-sm font-medium")
      }
      {...props}
    />
  );
}
