import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { colorsMap200, colorsMap200Flipped } from "../../../colors-list";
import useStores from "../../../hooks/useStores";
import Block from "../../../models/Block";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import ButtonLarge from "../../ButtonLarge";
import NewPicker1 from "../../Icons/NewPicker1";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";
import Switch from "../../Switch";

type BlockFormState = {
  abbreviation: string;
  description: string;
  color: string;
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: BlockFormState = {
  abbreviation: "",
  description: "",
  color: "blue",
};

const SESSION_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "abbreviation",
    label: "Abbreviation*",
    placeholder: "",
    required: true,
  },
  {
    fieldKind: "text",
    id: "description",
    label: "Description",
    placeholder: "",
    required: false,
  },
  {
    fieldKind: "colorpicker",
    id: "color",
    label: "Color",
  },
];

const formReducer: Reducer<BlockFormState, FormAction> = (state, action) => {
  console.log("Action", action);
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
    case "checkbox":
    case "colorpicker":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type BlockCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  block?: Block;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function BlockCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  block,
  isEditing = false,
}: BlockCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { sessions, blocks, ui } = useStores();

  const { isSaving, save } = blocks;

  const init = (initialState: BlockFormState) => {
    if (isEditing) {
      invariant(block, "Block must be defined if editing");

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        abbreviation: block.abbreviation || "",
        description: block.description || "",
        color: block.color || "blue",
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { abbreviation } = state;

    console.log("Validate submit", state);

    if (abbreviation && abbreviation !== "") {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "switch":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Switch
              id={id}
              label={field.label}
              onToggle={(value) => {
                dispatchFormAction({
                  type: "switch",
                  fieldID: id,
                  payload: value,
                });
              }}
              toggleValue={state[id] as boolean}
            />
          </div>
        );

      case "colorpicker":
        return renderColorPicker(field);
      default:
        return null;
    }
  };

  const renderColorPicker = (field: FormField) => {
    const { label } = field;

    console.log("State.color", state.color);

    return (
      <div className="flex flex-col">
        <label className="text-slate11 mb-4 block text-sm font-medium">
          {label}
        </label>
        <NewPicker1
          hideColorPicker={false}
          hideIconPicker={true}
          setIcon={(icon) => {}}
          setEmoji={(emoji) => {}}
          color={colorsMap200["blue"]}
          setColor={(color) => {
            console.log("Color", color);
            console.log("Flipped", colorsMap200Flipped[color]);

            const flippedColor = colorsMap200Flipped[color];

            dispatchFormAction({
              type: "colorpicker",
              fieldID: field.id,
              payload: flippedColor,
            });
          }}
          triggerComponent={
            <div
              className="h-8 w-full rounded-lg border-4 border-white shadow-md"
              style={{
                backgroundColor: colorsMap200[state.color],
              }}
            />
          }
        />
      </div>
    );
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (isEditing) {
        invariant(block, "Block must be defined if editing");

        // TBD: Run a check to ensure that the school is not being changed
        const updateBlock = {
          id: block.id,
          ...state,
        };

        try {
          const res = await save(updateBlock);

          console.log("Updated Block", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating Block", err);
        }
      } else {
        try {
          invariant(ui.activeSessionId, "Active session must be defined");
          const activeSession = sessions.getByUrlParam(ui.activeSessionId);
          invariant(activeSession, "Active session must be defined");
          const activeRotationSchedule = activeSession.activeRotationSchedule;

          invariant(
            activeRotationSchedule,
            "Active rotation schedule must be defined"
          );

          const newRotationSchedule = {
            ...state,
            rotationSchedule: {
              id: activeRotationSchedule.id,
            },
          };
          const res = await save(newRotationSchedule);
          console.log("Created Block", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating Block", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      block,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeSessionId,
      sessions,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} Block
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {SESSION_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(BlockCreateAndEditModal);
