import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  PopulateAlternateDaySchedules,
  PopulateBlockSchedules,
} from "../graphql/scheduling/scheduling.mutations";
import { GetBlockSchedules } from "../graphql/scheduling/scheduling.queries";
import BlockSchedule from "../models/BlockSchedule";
import {
  CreateAlternateScheduleInput,
  CreateBlockScheduleInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class BlockScheduleStore extends BaseStore<BlockSchedule> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, BlockSchedule, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchBlockSchedule = (blockId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetBlockSchedules,
          variables: {
            where: {
              block: {
                id: blockId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const blockSchedules = res.data.blockSchedules;

          if (!blockSchedules) {
            reject(false);
          }

          runInAction("Populate blocks", () => {
            blockSchedules.forEach((blockSchedule) => {
              invariant(
                blockSchedule.block,
                "Block schedule must have a block"
              );

              // We don't want block schedules that are alternate schedules
              if (blockSchedule.alternateSchedule) {
                return;
              }

              const sanitizeBlockSchedule = {
                alternateScheduleId: null,
                blockId: blockSchedule.block.id,
                createdAt: blockSchedule.createdAt,
                deletedAt: blockSchedule.deletedAt,
                endTime: blockSchedule.endTime,
                id: blockSchedule.id,
                rotationDayId: blockSchedule.rotationDay
                  ? blockSchedule.rotationDay.id
                  : null,
                startTime: blockSchedule.startTime,
                updatedAt: blockSchedule.updatedAt,
              };

              this.add(sanitizeBlockSchedule);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  fetchAlternateDayBlockSchedule = (alternateDayId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetBlockSchedules,
          variables: {
            where: {
              alternateSchedule: {
                id: alternateDayId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const blockSchedules = res.data.blockSchedules;

          if (!blockSchedules) {
            reject(false);
          }

          runInAction("Populate blocks", () => {
            blockSchedules.forEach((blockSchedule) => {
              console.log(blockSchedule);

              invariant(
                blockSchedule.block,
                "Block schedule must have a block"
              );

              invariant(
                blockSchedule.alternateSchedule,
                "Block schedule must have an alternate schedule"
              );

              const sanitizeBlockSchedule = {
                alternateScheduleId: blockSchedule.alternateSchedule.id,
                blockId: blockSchedule.block.id,
                createdAt: blockSchedule.createdAt,
                deletedAt: blockSchedule.deletedAt,
                endTime: blockSchedule.endTime,
                id: blockSchedule.id,
                rotationDayId: blockSchedule.rotationDay
                  ? blockSchedule.rotationDay.id
                  : null,
                startTime: blockSchedule.startTime,
                updatedAt: blockSchedule.updatedAt,
              };

              this.add(sanitizeBlockSchedule);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  saveBlockSchedules = (
    blockSchedules: CreateBlockScheduleInput[],
    blockId: string
  ): Promise<Boolean> => {
    this.isSaving = true;

    console.log("Block Schedules", blockSchedules);

    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: PopulateBlockSchedules,
          variables: {
            block: {
              id: blockId,
            },
            data: blockSchedules,
          },
        })
        .then((res) => {
          if (!res.data || !res.data.populateBlockSchedules) {
            throw Error("Failed to update block schedules.");
          }

          const success = res.data.populateBlockSchedules;

          if (success) {
            // Refresh the rotation days

            runInAction(() => {
              this.fetchBlockSchedule(blockId);
            });
          }

          resolve(success);
        })
        .catch((e) => {
          console.log("Error", e);
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isSaving = false;
          });
          resolve(true);
        });
    });
  };

  @action
  saveBlockSchedulesForAlternateDay = (
    blockSchedules: CreateAlternateScheduleInput[],
    alternateScheduleId: string
  ): Promise<Boolean> => {
    this.isSaving = true;

    console.log("Alternate Schedules", blockSchedules);

    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: PopulateAlternateDaySchedules,
          variables: {
            alternateSchedule: {
              id: alternateScheduleId,
            },
            data: blockSchedules,
          },
        })
        .then((res) => {
          if (!res.data || !res.data.populateAlternateDaySchedules) {
            throw Error("Failed to update alternate day schedules.");
          }

          const success = res.data.populateAlternateDaySchedules;

          if (success) {
            // Refresh the rotation days

            runInAction(() => {
              this.fetchAlternateDayBlockSchedule(alternateScheduleId);
            });
          }

          resolve(success);
        })
        .catch((e) => {
          console.log("Error", e);
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isSaving = false;
          });
          resolve(true);
        });
    });
  };

  getBlockSchedule = (blockId: string): BlockSchedule[] => {
    return this.sortedData.filter(
      (blockSchedule) =>
        blockSchedule.blockId === blockId && !blockSchedule.deletedAt
    );
  };

  getBlockScheduleForAlternateDays = (
    alternateScheduleId: string
  ): BlockSchedule[] => {
    return this.sortedData.filter(
      (blockSchedule) =>
        blockSchedule.alternateScheduleId &&
        blockSchedule.alternateScheduleId === alternateScheduleId &&
        !blockSchedule.deletedAt
    );
  };

  getBlockSchedulesForRotationDay = (
    rotationDayId: string
  ): BlockSchedule[] => {
    return this.sortedData.filter(
      (blockSchedule) =>
        blockSchedule.rotationDayId === rotationDayId &&
        !blockSchedule.deletedAt
    );
  };
}
