import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateBlock,
  UpdateBlock,
} from "../graphql/scheduling/scheduling.mutations";
import { GetBlocks } from "../graphql/scheduling/scheduling.queries";
import Block from "../models/Block";
import {
  BlockCreateInput,
  BlockUpdateInput,
  BlockWhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class BlocksStore extends BaseStore<Block> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Block, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchRotationScheduleBlocks = (rotationScheduleId: string) => {
    this.isLoading = true;

    const { fetchBlockSchedule } = this.rootStore.blockSchedules;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetBlocks,
          variables: {
            where: {
              rotationSchedule: {
                id: rotationScheduleId,
              },
            },
            orderBy: {
              abbreviation: SortOrder.Asc,
            },
          },
        })
        .then((res) => {
          const blocks = res.data.blocks;

          if (!blocks) {
            reject(false);
          }

          runInAction("Populate blocks", () => {
            blocks.forEach((block) => {
              invariant(
                block.rotationSchedule,
                "Block must have a rotation schedule"
              );

              fetchBlockSchedule(block.id);

              const sanitizeBlock = {
                abbreviation: block.abbreviation,
                color: block.color,
                createdAt: block.createdAt,
                deletedAt: block.deletedAt,
                description: block.description,
                id: block.id,
                rotationScheduleId: block.rotationSchedule.id,
                updatedAt: block.updatedAt,
              };

              this.add(sanitizeBlock);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<Block>): Promise<Block> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as BlockCreateInput);
      } else {
        return this.update(
          rest as BlockUpdateInput,
          { id } as BlockWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: BlockCreateInput): Promise<Block> {
    const res = await this.apolloClient.mutate({
      mutation: CreateBlock,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createBlock) {
      throw Error("Failed to create rotation schedule.");
    }

    const block = res.data.createBlock;

    invariant(block.rotationSchedule, "Block must have a rotation schedule");

    const sanitizeBlock = {
      abbreviation: block.abbreviation,
      color: block.color,
      createdAt: block.createdAt,
      deletedAt: block.deletedAt,
      description: block.description,
      id: block.id,
      rotationScheduleId: block.rotationSchedule.id,
      updatedAt: block.updatedAt,
    };

    return this.add(sanitizeBlock);
  }

  async update(
    data: BlockUpdateInput,
    where: BlockWhereUniqueInput
  ): Promise<Block> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateBlock,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateBlock) {
      throw Error("Failed to update rotation schedule.");
    }

    const block = res.data.updateBlock;

    invariant(block.rotationSchedule, "Block must have a rotation schedule");

    const sanitizeBlock = {
      abbreviation: block.abbreviation,
      color: block.color,
      createdAt: block.createdAt,
      deletedAt: block.deletedAt,
      description: block.description,
      id: block.id,
      rotationScheduleId: block.rotationSchedule.id,
      updatedAt: block.updatedAt,
    };

    return this.add(sanitizeBlock);
  }

  getBlocksForRotationSchedule = (rotationScheduleId: string): Block[] => {
    console.log("get Blocks for Rotation schedule Id", rotationScheduleId);

    return this.sortedData.filter(
      (schedule) =>
        schedule.rotationScheduleId === rotationScheduleId &&
        !schedule.deletedAt
    );
  };
}
