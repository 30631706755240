import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateCourseV2,
  UpdateCourseV2,
} from "../graphql/courseV2/courseV2.mutations";
import { GetCourseV2s } from "../graphql/courseV2/courseV2.queries";
import CourseV2 from "../models/CourseV2";
import {
  CourseUpdateInput,
  CourseV2CreateInput,
  CourseWhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class CoursesStore extends BaseStore<CourseV2> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, CourseV2, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchDistrictCourses = (districtId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetCourseV2s,
          variables: {
            where: {
              district: {
                id: districtId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const courses = res.data.courseV2s;

          if (!courses) {
            reject(false);
          }

          runInAction("Populate courses", () => {
            courses.forEach((course) => {
              invariant(course.district, "School must have a district");

              const sanitizeCourse = {
                code: course.code,
                createdAt: course.createdAt,
                deletedAt: course.deletedAt,
                districtId: course.district.id,
                edlinkId: course.edlinkId,
                gradeLevels: course.gradeLevels || [],
                id: course.id,
                identifiers: course.identifiers,
                name: course.name,
                properties: course.properties,
                schoolId: course.school ? course.school.id : null,
                sessionId: course.session ? course.session.id : null,
                subjectId: course.subject ? course.subject.id : null,
                subjectCodes: course.subjectCodes || [],
                updatedAt: course.updatedAt,
                urlId: course.urlId,
              };

              this.add(sanitizeCourse);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<CourseV2>): Promise<CourseV2> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as CourseV2CreateInput);
      } else {
        return this.update(
          rest as CourseUpdateInput,
          { id } as CourseWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: CourseV2CreateInput): Promise<CourseV2> {
    const res = await this.apolloClient.mutate({
      mutation: CreateCourseV2,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createCourseV2) {
      throw Error("Failed to create district.");
    }

    const course = res.data.createCourseV2;

    invariant(course.district, "CourseV2 must have a district");

    const sanitizeCourse = {
      code: course.code,
      createdAt: course.createdAt,
      deletedAt: course.deletedAt,
      districtId: course.district.id,
      edlinkId: course.edlinkId,
      gradeLevels: course.gradeLevels || [],
      id: course.id,
      identifiers: course.identifiers,
      name: course.name,
      properties: course.properties,
      schoolId: course.school ? course.school.id : null,
      sessionId: course.session ? course.session.id : null,
      subjectId: course.subject ? course.subject.id : null,
      subjectCodes: course.subjectCodes || [],
      updatedAt: course.updatedAt,
      urlId: course.urlId,
    };

    return this.add(sanitizeCourse);
  }

  async update(
    data: CourseUpdateInput,
    where: CourseWhereUniqueInput
  ): Promise<CourseV2> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateCourseV2,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateCourseV2) {
      throw Error("Failed to update course.");
    }

    const course = res.data.updateCourseV2;

    invariant(course.district, "CourseV2 must have a district");

    const sanitizeCourse = {
      code: course.code,
      createdAt: course.createdAt,
      deletedAt: course.deletedAt,
      districtId: course.district.id,
      edlinkId: course.edlinkId,
      gradeLevels: course.gradeLevels || [],
      id: course.id,
      identifiers: course.identifiers,
      name: course.name,
      properties: course.properties,
      schoolId: course.school ? course.school.id : null,
      sessionId: course.session ? course.session.id : null,
      subjectId: course.subject ? course.subject.id : null,
      subjectCodes: course.subjectCodes || [],
      updatedAt: course.updatedAt,
      urlId: course.urlId,
    };

    return this.add(sanitizeCourse);
  }

  getCoursesForDistrict = (districtId: string): CourseV2[] => {
    return this.sortedData.filter((school) => school.districtId === districtId);
  };

  getCoursesForSchool = (districtId: string, schoolId: string): CourseV2[] => {
    // First we filter by district
    const coursesForDistrict = this.getCoursesForDistrict(districtId);

    // Next we filter by school or null
    return coursesForDistrict.filter(
      (course) =>
        (course.schoolId === schoolId || course.schoolId === null) &&
        !course.deletedAt
    );
  };

  // get by urlId
  getByUrlParam = (urlId: string): CourseV2 | undefined => {
    return this.sortedData.find((course) => course.urlId === urlId);
  };
}
