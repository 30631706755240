import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateSchool,
  UpdateSchool,
} from "../graphql/schools/schools.mutations";
import { GetSchools } from "../graphql/schools/schools.queries";
import School from "../models/School";
import {
  SchoolCreateInput,
  SchoolUpdateInput,
  SchoolWhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class SchoolsStore extends BaseStore<School> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, School, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchDistrictSchools = (districtId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetSchools,
          variables: {
            where: {
              district: {
                id: districtId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const schools = res.data.schools;

          if (!schools) {
            reject(false);
          }

          runInAction("Populate schools", () => {
            schools.forEach((school, index) => {
              console.log(school);

              invariant(school.district, "School must have a district");

              // Use this hack to fetch school classes
              const { classes, ui } = this.rootStore;

              classes.fetchSchoolClasses(school.id);

              // Set the first school as active for the district
              if (index === 0 && !ui.activeSchoolId) {
                ui.setActiveSchoolId(school.urlId);
              }

              const sanitizeSchool = {
                address: school.address,
                createdAt: school.createdAt,
                classIds: school.classes.map((classObj) => classObj.id),
                courseIds: school.courses.map((course) => course.id),
                deletedAt: school.deletedAt,
                districtId: school.district.id,
                edlinkId: school.edlinkId,
                gradeLevels: school.gradeLevels || [],
                id: school.id,
                identifiers: school.identifiers,
                name: school.name,
                properties: school.properties,
                timezone: school.timezone,
                updatedAt: school.updatedAt,
                urlId: school.urlId,
              };

              this.add(sanitizeSchool);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<School>): Promise<School> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as SchoolCreateInput);
      } else {
        return this.update(
          rest as SchoolUpdateInput,
          { id } as SchoolWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: SchoolCreateInput): Promise<School> {
    const res = await this.apolloClient.mutate({
      mutation: CreateSchool,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createSchool) {
      throw Error("Failed to create district.");
    }

    const school = res.data.createSchool;

    invariant(school.district, "School must have a district");

    const sanitizeDistrict = {
      address: school.address,
      createdAt: school.createdAt,
      classIds: school.classes.map((classObj) => classObj.id),
      courseIds: school.courses.map((course) => course.id),
      deletedAt: school.deletedAt,
      districtId: school.district.id,
      edlinkId: school.edlinkId,
      gradeLevels: school.gradeLevels || [],
      id: school.id,
      identifiers: school.identifiers,
      name: school.name,
      properties: school.properties,
      timezone: school.timezone,
      updatedAt: school.updatedAt,
      urlId: school.urlId,
    };

    return this.add(sanitizeDistrict);
  }

  async update(
    data: SchoolUpdateInput,
    where: SchoolWhereUniqueInput
  ): Promise<School> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateSchool,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateSchool) {
      throw Error("Failed to update course.");
    }

    const school = res.data.updateSchool;

    invariant(school.district, "School must have a district");

    const sanitizeDistrict = {
      address: school.address,
      createdAt: school.createdAt,
      classIds: school.classes.map((classObj) => classObj.id),
      courseIds: school.courses.map((course) => course.id),
      deletedAt: school.deletedAt,
      districtId: school.district.id,
      edlinkId: school.edlinkId,
      gradeLevels: school.gradeLevels || [],
      id: school.id,
      identifiers: school.identifiers,
      name: school.name,
      properties: school.properties,
      timezone: school.timezone,
      updatedAt: school.updatedAt,
      urlId: school.urlId,
    };

    return this.add(sanitizeDistrict);
  }

  getSchoolsForDistrict = (districtId: string): School[] => {
    return this.sortedData.filter(
      (school) => school.districtId === districtId && !school.deletedAt
    );
  };

  getByUrlParam = (urlId: string): School | undefined => {
    return this.sortedData.find(
      (school) => urlId.endsWith(school.urlId) && !school.deletedAt
    );
  };

  getFirstAvailableSchool = (): School | undefined => {
    return this.sortedData.find((school) => !school.deletedAt);
  };

  getDropdownList = (): { value: string; label: string }[] => {
    return this.sortedData
      .filter((school) => !school.deletedAt)
      .map((school) => ({
        value: school.id,
        label: school.name,
      }));
  };
}
