import { computed, observable } from "mobx";
import { EnumUserGradeLevels } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";
import District from "./District";

export type IntegrationProperties = {
  [key: string]: string;
};

export enum IntegrationProvider {
  GOOGLE_CALENDAR = "google-calendar",
  GOOGLE_DRIVE = "google-drive",
  ONE_DRIVE = "one-drive",
  GOOGLE_CLASSROOM = "google-classroom",
  CANVAS = "canvas",
  MOODLE = "moodle",
  SCHOOLOGY = "schoology",
  BLACKBAUD = "blackbaud",
}

class User extends DeletableModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  email: string;

  @Attribute
  @observable
  username: string;

  @Attribute
  @observable
  firstName: string;

  @Attribute
  @observable
  lastName: string;

  @Attribute
  @observable
  avatarUrl: string | null;

  @Attribute
  @observable
  district: District | null;

  @Attribute
  @observable
  isOnboarded: boolean | null;

  @Attribute
  @observable
  userRole: string | null;

  @Attribute
  @observable
  gradeLevels: EnumUserGradeLevels[];

  @observable
  authProvider: string;

  @computed
  get fullName(): string {
    return this.firstName + " " + this.lastName;
  }

  @computed
  get initial(): string {
    return this.firstName[0];
  }

  @Attribute
  @observable
  integrations: { [key in IntegrationProvider]?: IntegrationProperties } | null;
}

export default User;
