import { computed, observable } from "mobx";
import DeletableModel from "./DeletableModel";

class CalendarDay extends DeletableModel {
  @observable
  id: string;

  @observable
  alternateScheduleId: string | null;

  @observable
  calendarId: string;

  @observable
  date: string;

  @observable
  edlinkId: string | null;

  @observable
  rotationDayId: string | null;

  @observable
  rotationScheduleId: string | null;

  @computed
  get rotationDayName(): string {
    const { rotationDays } = this.store.rootStore;

    const fetchRotationDay = rotationDays.get(this.rotationDayId);

    return fetchRotationDay ? fetchRotationDay.abbreviation : "-";
  }

  @computed
  get alternateScheduleName(): string {
    if (!this.alternateScheduleId) {
      return "-";
    }

    const { alternateSchedules } = this.store.rootStore;

    const fetchAlternateSchedule = alternateSchedules.get(
      this.alternateScheduleId
    );

    return fetchAlternateSchedule ? fetchAlternateSchedule.name : "-";
  }
}

export default CalendarDay;
