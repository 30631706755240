import { observer } from "mobx-react";
import React from "react";
import ListOfClassesSchedules from "../components/ClassSchedules/ListOfClassSchedules";
import PageLayout from "../components/PageLayout";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Classes() {
  const { ui, districts, classes, sessions } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId, activeSessionId } = ui;

  if (!activeDistrictId || !activeSessionId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  const activeSession = sessions.getByUrlParam(activeSessionId);

  if (!activeDistrict || !activeSession) return null;

  const sessionClasses = activeSession.classes;

  console.log("Session Classes", sessionClasses);

  // We need to split the classes into assigned and unassigned
  // Assigned classes will have a calendar entity
  // Unassigned classes will not have a calendar entity

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Class Schedules</h2>
          </div>
        }
        right={null}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfClassesSchedules
            key={sessionClasses.length}
            classes={sessionClasses}
          />
        </div>
      </PageLayout>
    </React.Fragment>
  );
}

export default observer(Classes);
