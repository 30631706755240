// This will be the default wrapper for all our pages

import clsx from "clsx";
import * as React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import Header from "./Header";
import styles from "./index.module.css";

type ContentProps = {
  children: React.ReactNode;
  header?: boolean;
  fullWidth?: boolean;
};

const Content: React.FC<ContentProps> = ({ children, header, fullWidth }) => {
  const isMobile = useIsMobile();

  const containerPadding = header ? "8px 16px" : "48px 16px";
  const containerPaddingLarge = header ? "8px 40px 60px" : "48px 40px";

  return (
    <div
      className={styles.Container}
      style={{
        padding: isMobile ? containerPadding : containerPaddingLarge,
      }}
    >
      <div
        className={clsx(
          fullWidth ? "" : styles.Content_Not_Full_Width,
          styles.Content
        )}
      >
        {children}
      </div>
    </div>
  );
};

// Now we write the main page layout component
type Props = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
  alignCenter?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
  alwaysShowTitle?: boolean;
};

export default function PageLayout({
  icon,
  title,
  left,
  right,
  alignCenter,
  fullWidth,
  children,
  alwaysShowTitle,
}: Props) {
  return (
    <div className="relative w-full">
      <Header
        title={
          icon ? (
            <>
              {icon}&nbsp;{title}
            </>
          ) : (
            title
          )
        }
        alwaysShowTitle={alwaysShowTitle}
        left={left}
        right={right}
      />
      {alignCenter ? (
        <Content fullWidth={fullWidth} header={true}>
          {children}
        </Content>
      ) : (
        children
      )}
    </div>
  );
}
