import clsx from "clsx";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import ClassV2 from "../../../models/ClassV2";
import { DashboardBreakpoint } from "../../../types";
import ClassScheduleCreateAndEditModal from "../../CRUD/CreateAndEdit/ClassSchedule";
import ClassAssignedScheduleItem from "../ClassAssignedScheduleItem";
import ClassUnassignedScheduleItem from "../ClassUnassignedScheduleItem";

type ListOfClassesSchedulesProps = {
  classes: ClassV2[];
};

function ListOfClassesSchedules({ classes }: ListOfClassesSchedulesProps) {
  const [currentTab, setCurrentTab] = useState("unassigned");
  const [unassignedCount, setUnassignedCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0);
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const [showBulkEditClassModal, setShowBulkEditClassModal] = useState(false);

  const { addToast } = useToastsContext();

  const { calendars } = useStores();

  const unassignedClasses = classes.filter(
    (classV2) => !classV2.hasClassCalendar
  );

  const assignedClasses = classes.filter(
    (classV2) => !!classV2.hasClassCalendar
  );

  // Initialize counts and selections
  useEffect(() => {
    setUnassignedCount(unassignedClasses.length);
    setAssignedCount(assignedClasses.length);

    if (unassignedClasses.length === 0) {
      setChecked(false);
      setIndeterminate(false);
      setCurrentTab("assigned");
    }
  }, [unassignedClasses, assignedClasses]);

  useEffect(() => {
    setSelectedClasses([]);
  }, [unassignedClasses.length, assignedClasses.length]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedClasses.length > 0 &&
      selectedClasses.length < unassignedClasses.length;
    setChecked(selectedClasses.length === unassignedClasses.length);
    setIndeterminate(isIndeterminate);

    if (checkbox.current !== null) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedClasses, unassignedClasses]);

  function toggleAll() {
    setSelectedClasses(
      checked || indeterminate
        ? []
        : unassignedClasses.map((classV2) => classV2.id)
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const renderClassScheduleTabs = () => {
    if (unassignedCount === 0) {
      return null;
    }
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            defaultValue={currentTab}
            onChange={(e) => setCurrentTab(e.target.value)}
          >
            <option key={"unassigned"}>Unassigned</option>
            <option key={"assigned"}>Assigned</option>
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <button
                className={clsx(
                  currentTab === "unassigned"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                )}
                onClick={() => setCurrentTab("unassigned")}
              >
                Unassigned
                <span
                  className={clsx(
                    currentTab === "unassigned"
                      ? "bg-blue-100 text-blue-600"
                      : "bg-gray-100 text-gray-900",
                    "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                  )}
                >
                  {unassignedCount}
                </span>
              </button>

              <button
                className={clsx(
                  currentTab === "assigned"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                  "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                )}
                onClick={() => setCurrentTab("assigned")}
              >
                Assigned
                <span
                  className={clsx(
                    currentTab === "assigned"
                      ? "bg-blue-100 text-blue-600"
                      : "bg-gray-100 text-gray-900",
                    "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                  )}
                >
                  {assignedCount}
                </span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const renderUnassignedListLg = () => {
    return (
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              {selectedClasses.length > 0 && (
                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={() => setShowBulkEditClassModal(true)}
                  >
                    Bulk edit
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {renderClassUnassignedList("lg")}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssignedListLg = () => {
    return (
      <div className="hidden sm:block">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Rotation
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Periods/Blocks assigned
                      </th>
                      <th scope="col" className="relative py-3.5 ">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {renderClassAssignedList("lg")}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderClassAssignedList = (breakpoint: DashboardBreakpoint) => {
    return assignedClasses.map((classV2) => {
      return (
        <ClassAssignedScheduleItem
          key={classV2.id}
          classV2={classV2}
          breakpoint={breakpoint}
        />
      );
    });
  };

  const renderClassUnassignedList = (breakpoint: DashboardBreakpoint) => {
    console.log("Unassigned Classes", unassignedClasses);

    return unassignedClasses.map((classV2) => {
      return (
        <ClassUnassignedScheduleItem
          key={classV2.id}
          classV2={classV2}
          breakpoint={breakpoint}
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
        />
      );
    });
  };

  return (
    <React.Fragment>
      <div className="flex flex-col">
        {renderClassScheduleTabs()}
        {currentTab === "unassigned"
          ? renderUnassignedListLg()
          : renderAssignedListLg()}
      </div>
      {showBulkEditClassModal && (
        <ClassScheduleCreateAndEditModal
          multiple={true}
          onClose={() => setShowBulkEditClassModal(false)}
          onSelectRotationSchedule={async (id: string) => {
            setShowBulkEditClassModal(false);

            const res = await calendars.assignClassSchedules(
              selectedClasses,
              id
            );

            if (res) {
              addToast("Schedules assigned successfully", {
                type: "success",
              });

              setTimeout(() => {
                window.location.reload();
              }, 400);
            } else {
              addToast("Failed to assign schedules", {
                type: "error",
              });
            }
          }}
        />
      )}
    </React.Fragment>
  );
}

export default ListOfClassesSchedules;
