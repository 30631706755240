import {
  EnumClassV2State,
  EnumEnrollmentRole,
  EnumEnrollmentState,
  EnumSectionV2State,
} from "../__generated__/graphql";

export const classStateToStringMap: {
  [key in EnumClassV2State]: string;
} = {
  [EnumClassV2State.Active]: "Active",
  [EnumClassV2State.Archived]: "Archived",
  [EnumClassV2State.Completed]: "Completed",
  [EnumClassV2State.Inactive]: "Inactive",
  [EnumClassV2State.Upcoming]: "Upcoming",
  [EnumClassV2State.Template]: "Template",
};

export const sectionStateToStringMap: {
  [key in EnumSectionV2State]: string;
} = {
  [EnumSectionV2State.Active]: "Active",
  [EnumSectionV2State.Archived]: "Archived",
  [EnumSectionV2State.Completed]: "Completed",
  [EnumSectionV2State.Inactive]: "Inactive",
  [EnumSectionV2State.Upcoming]: "Upcoming",
  [EnumSectionV2State.Default]: "Default",
};

export const enrollmentStateToStringMap: {
  [key in EnumEnrollmentState]: string;
} = {
  [EnumEnrollmentState.Active]: "Active",
  [EnumEnrollmentState.Dropped]: "Dropped",
  [EnumEnrollmentState.Completed]: "Completed",
  [EnumEnrollmentState.Inactive]: "Inactive",
  [EnumEnrollmentState.Upcoming]: "Upcoming",
  [EnumEnrollmentState.Pending]: "Pending",
};

export const enrollmentRoleToStringMap: {
  [key in EnumEnrollmentRole]: string;
} = {
  [EnumEnrollmentRole.Student]: "Student",
  [EnumEnrollmentRole.Teacher]: "Teacher",
  [EnumEnrollmentRole.Administrator]: "Administrator",
  [EnumEnrollmentRole.Aide]: "Aide",
  [EnumEnrollmentRole.Designer]: "Designer",
  [EnumEnrollmentRole.DistrictAdministrator]: "District Administrator",
  [EnumEnrollmentRole.Guardian]: "Guardian",
  [EnumEnrollmentRole.Member]: "Member",
  [EnumEnrollmentRole.Parent]: "Parent",
  [EnumEnrollmentRole.Staff]: "Staff",
  [EnumEnrollmentRole.Ta]: "TA",
};

export function classStateEnumToDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(classStateToStringMap).map((key) => {
    return {
      label: classStateToStringMap[key as EnumClassV2State],
      value: key,
    };
  });
}

export function classStateEnumToSelectedOption(selected: EnumClassV2State): {
  label: string;
  value: string;
} {
  return {
    label: classStateToStringMap[selected],
    value: selected,
  };
}

// above two functions but for section
export function sectionStateEnumToDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(sectionStateToStringMap).map((key) => {
    return {
      label: sectionStateToStringMap[key as EnumSectionV2State],
      value: key,
    };
  });
}

export function sectionStateEnumToSelectedOption(
  selected: EnumSectionV2State
): {
  label: string;
  value: string;
} {
  return {
    label: sectionStateToStringMap[selected],
    value: selected,
  };
}

export function enrollmentStateEnumToDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(enrollmentStateToStringMap).map((key) => {
    return {
      label: enrollmentStateToStringMap[key as EnumEnrollmentState],
      value: key,
    };
  });
}

export function enrollmentStateEnumToSelectedOption(
  selected: EnumEnrollmentState
): {
  label: string;
  value: string;
} {
  return {
    label: enrollmentStateToStringMap[selected],
    value: selected,
  };
}

export function enrollmentRoleEnumToDropdownOptions(): {
  label: string;
  value: string;
}[] {
  return Object.keys(enrollmentRoleToStringMap).map((key) => {
    return {
      label: enrollmentRoleToStringMap[key as EnumEnrollmentRole],
      value: key,
    };
  });
}

export function enrollmentRoleEnumToSelectedOption(
  selected: EnumEnrollmentRole
): {
  label: string;
  value: string;
} {
  return {
    label: enrollmentRoleToStringMap[selected],
    value: selected,
  };
}
