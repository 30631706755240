import { Datepicker } from "@mobiscroll/react";
import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import Session from "../../../models/Session";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import { generateRandomString } from "../../../utils/RandomString";
import {
  sessionStateEnumToDropdownOptions,
  sessionStateEnumToSelectedOption,
  sessionTypeEnumToDropdownOptions,
  sessionTypeEnumToSelectedOption,
} from "../../../utils/schedulingEnums";
import {
  EnumSessionSessionType,
  EnumSessionState,
} from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";

type SessionFormState = {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  state: DropdownOptionType | null;
  sessionType: DropdownOptionType | null;
  school: DropdownOptionType | null;
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: SessionFormState = {
  name: "",
  startDate: null,
  endDate: null,
  state: null,
  sessionType: null,
  school: null,
};

const SESSION_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "name",
    label: "Session Name*",
    placeholder: "Eg. Fall 2023",
    required: true,
  },
  {
    fieldKind: "datepicker",
    id: "startDate",
    label: "Start Date*",
    placeholder: "Select the start date of the session",
  },
  {
    fieldKind: "datepicker",
    id: "endDate",
    label: "End Date*",
    placeholder: "Select the end date of the session",
  },
  {
    fieldKind: "dropdown",
    id: "state",
    label: "State*",
    placeholder: "Select the current state of the session",
  },
  {
    fieldKind: "dropdown",
    id: "sessionType",
    label: "Type*",
    placeholder: "Select the type of the session",
  },
  {
    fieldKind: "dropdown",
    id: "school",
    label: "School",
    placeholder: "Select the school the session belongs to (optional)",
  },
];

const formReducer: Reducer<SessionFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type SessionCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  session?: Session;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function SessionCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  session,
  isEditing = false,
}: SessionCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { districts, schools, sessions, ui } = useStores();

  const { addToast } = useToastsContext();

  const { isSaving, save } = sessions;

  const { getSchoolsForDistrict } = schools;

  const getDateInLocalTimezone = (isoDatetime: string): Date | null => {
    // Split the ISO string into date and time
    const [date] = isoDatetime.split("T");

    const parts = date.split("-");

    // Initialize a Date object with the date part
    const dateOnly = new Date(
      Number(parts[0]),
      Number(parts[1]) - 1,
      Number(parts[2])
    );

    return dateOnly;
  };

  const init = (initialState: SessionFormState) => {
    if (isEditing) {
      invariant(session, "Session must be defined if editing");

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        name: session.name,
        startDate: session.startDate
          ? getDateInLocalTimezone(session.startDate)
          : null,
        endDate: session.endDate
          ? getDateInLocalTimezone(session.endDate)
          : null,
        state: sessionStateEnumToSelectedOption(session.state),
        sessionType: sessionTypeEnumToSelectedOption(session.sessionType),
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const {
      name,
      sessionType,
      state: sessionState,
      startDate,
      endDate,
    } = state;

    if (name && sessionType && sessionState && startDate && endDate) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);
      case "datepicker":
        return renderDatepicker(id, field);
      default:
        return null;
    }
  };

  /**
   * @description Round time to nearest seconds
   */
  const roundSeconds = (time: Date) => {
    time.setMinutes(time.getMinutes() + Math.round(time.getSeconds() / 60));
    time.setSeconds(0, 0);
    return time;
  };

  const replaceTimeWithZeros = (isoString: string): string => {
    try {
      const dateOnly = isoString.split("T")[0]; // Extract the date part
      return dateOnly + "T00:00:00.000Z"; // Append zeros for time and 'Z' for UTC timezone
    } catch (error) {
      // If there's an error (invalid ISO string), return the original string
      return isoString;
    }
  };

  const renderDatepicker = (id: string, field: FormField) => {
    switch (id) {
      case "startDate":
        return (
          <div className="flex-grow">
            <label
              htmlFor="planner-start-date"
              className="text-slate11 block text-sm font-medium"
            >
              {field.label}
            </label>
            <div className="mt-1">
              <Datepicker
                controls={["date"]}
                touchUi={true}
                theme="ios"
                value={state[id]}
                themeVariant="light"
                inputProps={{
                  placeholder: field.placeholder,
                }}
                onChange={(event: any) => {
                  const date = new Date(event.value);
                  const roundOffDate = roundSeconds(date);
                  dispatchFormAction({
                    type: "datepicker",
                    fieldID: id,
                    payload: roundOffDate,
                  });
                }}
                responsive={{
                  xsmall: {
                    controls: ["date"],
                    display: "bottom",
                    touchUi: true,
                  },
                  medium: {
                    controls: ["date"],
                    display: "anchored",
                    touchUi: false,
                  },
                }}
              />
            </div>
          </div>
        );
      case "endDate":
        return (
          <div className="flex-grow">
            <label
              htmlFor="planner-start-date"
              className="text-slate11 block text-sm font-medium"
            >
              {field.label}
            </label>
            <div className="mt-1">
              <Datepicker
                controls={["date"]}
                touchUi={true}
                theme="ios"
                value={state[id]}
                themeVariant="light"
                inputProps={{
                  placeholder: field.placeholder,
                }}
                onChange={(event: any) => {
                  const date = new Date(event.value);
                  const roundOffDate = roundSeconds(date);
                  dispatchFormAction({
                    type: "datepicker",
                    fieldID: id,
                    payload: roundOffDate,
                  });
                }}
                responsive={{
                  xsmall: {
                    controls: ["date"],
                    display: "bottom",
                    touchUi: true,
                  },
                  medium: {
                    controls: ["date"],
                    display: "anchored",
                    touchUi: false,
                  },
                }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderSchoolDropdown = (id: string, field: FormField) => {
    invariant(ui.activeDistrictId, "Active district must be defined");

    const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

    invariant(activeDistrict, "Active district must be defined");

    const schools = getSchoolsForDistrict(activeDistrict.id);

    const options = schools.map((school) => ({
      label: school.name,
      value: school.id,
    }));

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={false}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderSessionTypeDropdown = (id: string, field: FormField) => {
    const options = sessionTypeEnumToDropdownOptions();

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderStateDropdown = (id: string, field: FormField) => {
    const options = sessionStateEnumToDropdownOptions();

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "sessionType":
        return renderSessionTypeDropdown(id, field);
      case "state":
        return renderStateDropdown(id, field);
      case "school":
        return renderSchoolDropdown(id, field);
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (
        !state.state ||
        !state.sessionType ||
        !state.name ||
        !state.startDate ||
        !state.endDate
      ) {
        return;
      }

      if (state.startDate.getTime() > state.endDate.getTime()) {
        addToast("Start date cannot be after end date", {
          type: "error",
        });
        return;
      }

      if (isEditing) {
        invariant(session, "Session must be defined if editing");

        // TBD: Run a check to ensure that the school is not being changed

        const updateSession = {
          id: session.id,
          ...state,
          state: state.state.value as EnumSessionState,
          sessionType: state.sessionType.value as EnumSessionSessionType,
          urlId: generateRandomString(),
          school: state.school ? { id: state.school.value } : undefined,
          startDate: state.startDate
            ? replaceTimeWithZeros(state.startDate.toISOString())
            : undefined,
          endDate: state.endDate
            ? replaceTimeWithZeros(state.endDate.toISOString())
            : undefined,
        };

        try {
          const res = await save(updateSession);

          console.log("Updated session", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating session", err);
        }
      } else {
        try {
          invariant(ui.activeDistrictId, "Active district must be defined");

          const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

          invariant(activeDistrict, "Active district must be defined");

          const newSession = {
            ...state,
            district: {
              id: activeDistrict.id,
            },
            state: state.state.value as EnumSessionState,
            sessionType: state.sessionType.value as EnumSessionSessionType,
            urlId: generateRandomString(),
            school: state.school ? { id: state.school.value } : undefined,
            startDate: state.startDate
              ? state.startDate.toISOString()
              : undefined,
            endDate: state.endDate ? state.endDate.toISOString() : undefined,
          };

          const res = await save(newSession);
          console.log("Created session", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating session", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      session,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeDistrictId,
      districts,
      addToast,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} Session
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {SESSION_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(SessionCreateAndEditModal);
