import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import { PopulateRotationDays } from "../graphql/scheduling/scheduling.mutations";
import { GetRotationDays } from "../graphql/scheduling/scheduling.queries";
import RotationDay from "../models/RotationDay";
import { CreateRotationDayInput, SortOrder } from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class RotationDayStore extends BaseStore<RotationDay> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, RotationDay, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchRotationScheduleDays = (rotationScheduleId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetRotationDays,
          variables: {
            where: {
              rotationSchedule: {
                id: rotationScheduleId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const rotationDays = res.data.rotationDays;

          if (!rotationDays) {
            reject(false);
          }

          runInAction("Populate rotationDays", () => {
            rotationDays.forEach((rotationSchedule) => {
              console.log(rotationSchedule);

              invariant(
                rotationSchedule.rotationSchedule,
                "Rotation Schedule must have a session"
              );

              const sanitizeRotationSchedule = {
                abbreviation: rotationSchedule.abbreviation,
                createdAt: rotationSchedule.createdAt,
                dayIndex: rotationSchedule.dayIndex,
                deletedAt: rotationSchedule.deletedAt,
                description: rotationSchedule.description,
                id: rotationSchedule.id,
                rotationScheduleId: rotationSchedule.rotationSchedule.id,
                updatedAt: rotationSchedule.updatedAt,
              };

              this.add(sanitizeRotationSchedule);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  saveRotationDays = (
    rotationDays: CreateRotationDayInput[],
    rotationScheduleId: string
  ): Promise<Boolean> => {
    this.isSaving = true;

    console.log("Rotation Days", rotationDays);

    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: PopulateRotationDays,
          variables: {
            rotationSchedule: {
              id: rotationScheduleId,
            },
            data: rotationDays,
          },
        })
        .then((res) => {
          if (!res.data || !res.data.populateRotationDays) {
            throw Error("Failed to update rotation days.");
          }

          const success = res.data.populateRotationDays;

          if (success) {
            // Refresh the rotation days as well as calendar days for the rotation schedule
            runInAction(() => {
              this.fetchRotationScheduleDays(rotationScheduleId);
            });
          }

          const { calendars } = this.rootStore;

          const fetchCalendars =
            calendars.getCalendarsForRotationSchedule(rotationScheduleId);

          if (fetchCalendars.length > 0) {
            this.rootStore.calendarDays.fetchCalendarDays(fetchCalendars[0].id);
          }

          resolve(success);
        })
        .catch((e) => {
          console.log("Error", e);
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isSaving = false;
          });
          resolve(true);
        });
    });
  };

  getRotationDaysForSchedule = (rotationScheduleId: string): RotationDay[] => {
    return this.sortedData
      .filter(
        (day) => day.rotationScheduleId === rotationScheduleId && !day.deletedAt
      )
      .sort((a, b) => a.dayIndex - b.dayIndex);
  };
}
