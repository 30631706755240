import { gql } from "../../__generated__/gql";

export const CreateSectionV2 = gql(`
    mutation CreateSectionForClass($data: SectionV2CreateInput!) {
        createSectionForClass(data: $data) {
            classV2 {
                id
            }
            createdAt 
            deletedAt
            description 
            edlinkId 
            id
            identifiers
            locale 
            name 
            properties 
            state 
            timezone 
            updatedAt 
            urlId 
        }
    }
`);

export const UpdateSectionV2 = gql(`
    mutation updateSectionV2($data: SectionV2UpdateInput!, $where: SectionV2WhereUniqueInput!) {
        updateSectionV2(data: $data, where: $where) {
            classV2 {
                id
            }
            createdAt 
            deletedAt
            description 
            edlinkId 
            id
            identifiers
            locale 
            name 
            properties 
            state 
            timezone 
            updatedAt 
            urlId 
        }
    }
`);
