import update from "immutability-helper";
import { useEffect, useMemo, useReducer, useState } from "react";
import "./style.css";
import Table from "./Table";
import { ActionTypes, ALTERNATE_SCHEDULE_COLUMNS } from "./utils";

function reducer(state, action) {
  // Action will return the rowIndex and the columnId

  switch (action.type) {
    case ActionTypes.UPDATE_STATE_FROM_PROPS:
      return {
        ...state,
        columns: action.columns,
        data: action.data,
      };

    case ActionTypes.UPDATE_CELL:
      if (state.updateRowData !== null) {
        return;
      }

      console.log("UPDATE CELL CALLED", action);

      state.setUpdateRowData({
        rowIndex: action.rowIndex,
        columnId: action.columnId,
        value: action.value,
      });

      return update(state, {
        skipReset: { $set: true },
        data: {
          [action.rowIndex]: { [action.columnId]: { $set: action.value } },
        },
      });

    case ActionTypes.ENABLE_RESET:
      return update(state, { skipReset: { $set: true } });
    default:
      return state;
  }
}

function AlternateScheduleTable(props) {
  const { alternateSchedules, updateAlternateSchedule } = props;

  const [shouldAddNewRow, setShouldAddNewRow] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [updateRowData, setUpdateRowData] = useState(null);

  // We must construct the initial state from the units prop

  const initialState = useMemo(() => {
    // Columns are constructed with fixed (type) as well as variable properties (e.g. options)
    // The fixed columns are: Title, Dates, Status, Standards, and Lessons

    const columns = [
      ...ALTERNATE_SCHEDULE_COLUMNS,
      // Variable columns must be constructed from the rotationDays property
      //   ...rotationDays.map((rotationDay) => {
      //     const { id, abbreviation } = rotationDay;

      //     return [
      //       {
      //         id: id + "-start",
      //         label: abbreviation + " Start",
      //         accessor: id + "-start",
      //         minWidth: 250,
      //         dataType: DataTypes.TIME,
      //       },
      //       {
      //         id: id + "-end",
      //         label: abbreviation + " End",
      //         accessor: id + "-end",
      //         minWidth: 250,
      //         dataType: DataTypes.TIME,
      //       },
      //     ];
      //   }),
    ];

    console.log("columns", columns);

    return {
      columns,
      data: alternateSchedules,
      skipReset: false,
      updateAlternateSchedule,
      shouldAddNewRow,
      setShouldAddNewRow,
      deleteRowIndex,
      setDeleteRowIndex,
      updateRowData,
      setUpdateRowData,
    };

    // Variable columns must be constructed from the course unitAttributes (JSON) property
  }, [
    alternateSchedules,
    updateAlternateSchedule,
    shouldAddNewRow,
    setShouldAddNewRow,
    deleteRowIndex,
    setDeleteRowIndex,
    updateRowData,
    setUpdateRowData,
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);

  // Use effect for updating a row
  useEffect(() => {
    if (updateRowData !== null) {
      updateAlternateSchedule(
        updateRowData.rowIndex,
        updateRowData.columnId,
        updateRowData.value
      );
      setUpdateRowData(null);
    }
  }, [updateRowData, updateAlternateSchedule]);

  useEffect(() => {
    dispatch({ type: ActionTypes.ENABLE_RESET });
  }, [state.data, state.columns]);

  useEffect(() => {
    const columns = [
      ...ALTERNATE_SCHEDULE_COLUMNS,
      // Variable columns must be constructed from the rotationDays property
      //   ...rotationDays.map((rotationDay) => {
      //     const { id, abbreviation } = rotationDay;

      //     return [
      //       {
      //         id: id + "startTime",
      //         label: abbreviation + " Start",
      //         accessor: id + "startTime",
      //         minWidth: 250,
      //         dataType: DataTypes.TIME,
      //       },
      //       {
      //         id: id + "endTime",
      //         label: abbreviation + " End",
      //         accessor: id + "endTime",
      //         minWidth: 250,
      //         dataType: DataTypes.TIME,
      //       },
      //     ];
      //   }),
    ];

    console.log("columns", columns);

    // Data is constructed from the units prop

    console.log("Alternate Schedules", alternateSchedules);

    const data = alternateSchedules.map((schedule) => {
      // Status is constructed from the unit's status property

      // Date should be constructed from schedule start and end date
      return {
        id: schedule.id,
        block: schedule.blockName,
        startTime: schedule.startTime,
        endTime: schedule.endTime,
      };
    });

    console.log("Update state data", data);

    dispatch({
      type: ActionTypes.UPDATE_STATE_FROM_PROPS,
      columns,
      data,
    });
  }, [alternateSchedules]);

  return (
    <div
      className="overflow-hidden"
      style={{
        width: "100%",
        height: "100%",
        padding: 10,
      }}
    >
      <Table
        columns={state.columns}
        data={state.data}
        dispatch={dispatch}
        skipReset={state.skipReset}
        showNewRow={false}
      />
    </div>
  );
}

export default AlternateScheduleTable;
