import { gql } from "../../__generated__/gql";

export const CreateSubject = gql(`
    mutation CreateSubject($data: SubjectCreateInput!) {
        createSubject(data: $data) {
            cedsCode 
            code 
            createdAt
            deletedAt
            department {
                id
            }
            description 
            district {
                id
            }
            edlinkId 
            id 
            identifiers 
            name 
            properties 
            updatedAt 
        }
    }
`);

export const UpdateSubject = gql(`
    mutation UpdateSubject($data: SubjectUpdateInput!, $where: SubjectWhereUniqueInput!) {
        updateSubject(data: $data, where: $where) {
            cedsCode 
            code 
            createdAt
            deletedAt
            department {
                id
            }
            description 
            district {
                id
            }
            edlinkId 
            id 
            identifiers 
            name 
            properties 
            updatedAt 
        }
    }
`);
