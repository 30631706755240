import { XMarkIcon } from "@heroicons/react/20/solid";
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Badge from "../../BadgeDropdown/Badge";
import { ScrollArea } from "../../ScrollArea";
import Tooltip from "../../Tooltip";
import styles from "./index.module.css";

// export type TitleOption = {
//   id: string;
//   label: string;
//   tag?: string; // If we need to render a tag (for e.g. for a standard (notation)) (for a unit this can be the unit#)
//   emoji?: string; // Later use
//   url?: string; // Later use (For opening a link such as a unit)
// };

const BlocksCell = ({
  id,
  loading = false,
  options,
  selectedValues = [],
  readOnly,
  children,
  onAddNew,
  onSelect,
  onRemove,
  dropdownTitle, // Ensure singular
  popperPortalId = dropdownTitle
    ? `title-dropdown-${dropdownTitle}`
    : "title-dropdown",
  multiselect = false,
  boardView = false,
}) => {
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const addSelectRef = useRef(null);
  const focusedIndexRef = useRef(0);

  console.log("Selected values", selectedValues);
  console.log("Options", options);

  const filterSelectedOptions = useMemo(() => {
    return options.filter((option) => {
      return !!!selectedValues.find((selectedValue) => {
        return selectedValue.id === option.id;
      });
    });
  }, [options, selectedValues]);

  const [search, setSearch] = useState("");
  const [containerWidth, setContainerWidth] = useState(undefined);
  const [popoverStyle, setPopoverStyle] = useState({});

  const optionsFiltered = useMemo(() => {
    return filterSelectedOptions.filter(
      (option) =>
        option.label.toLowerCase().includes(search.toLowerCase()) ||
        (option.tag && option.tag.toLowerCase().includes(search.toLowerCase()))
    );
  }, [filterSelectedOptions, search]);

  const headerText = useMemo(() => {
    if (dropdownTitle && optionsFiltered.length > 0) {
      return `Select an option`;
    }

    if (dropdownTitle && optionsFiltered.length === 0) {
      return `No ${dropdownTitle}s found`;
    }

    return onAddNew ? "Select or create a new option" : "Select an option";
  }, [dropdownTitle, onAddNew, optionsFiltered]);

  useLayoutEffect(() => {
    if (selectRef && selectPop) {
      const selectRect = selectRef.getBoundingClientRect();
      setPopoverStyle({
        position: "fixed",
        top: selectRect.top,
        left: selectRect.left,
        zIndex: 1001,
        minWidth: 400,
        width: containerWidth || "100%",
        maxWidth: "100%",
        overflow: "hidden",
        boxShadow:
          "rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px",
      });
    }
  }, [selectRef, selectPop, containerWidth]);

  const handleAddNew = useCallback(() => {
    if (search !== "") {
      onAddNew?.(search);

      // Clear search
      setSearch("");
    }
    if (!multiselect) {
      setShowSelect(false);
    }
  }, [search]);

  function handleOptionClick(option) {
    onSelect(option);
    if (!multiselect) {
      setShowSelect(false);
    }
  }

  //   Render methods
  const renderSelectedValue = (selectedValue) => {
    console.log("Selected value", selectedValue);

    const selectedValueOption = options.find(
      (option) => option.id === selectedValue
    );

    return (
      <div
        className={clsx(
          "group relative flex h-[32px] min-h-[32px] w-full flex-1 items-center rounded-md px-2.5",
          onRemove && !readOnly ? "hover:bg-slate3" : ""
        )}
      >
        {/* Render label */}
        <div
          className={clsx(
            "text-slate12 w-full truncate text-left font-medium",
            boardView ? "text-[12px]" : "text-[15px]"
          )}
        >
          {selectedValueOption.label}
        </div>
        {
          // If value has a tag then render it as absolute positioned to the right (leave space for minus button if onRemove is passed)
          // Use the badge component for this
          selectedValueOption.tag && (
            <Badge
              id={selectedValueOption.id}
              className={clsx(
                // "absolute",
                onRemove ? "mr-8" : "mr-2"
                // cssStyle.tagBadge
              )}
              color={"sky"}
              size={"sm"}
              label={selectedValueOption.tag}
            />
          )
        }
        {onRemove && !readOnly && (
          <Tooltip content="Remove" side="top">
            <button
              className="border-slate5 absolute right-1 flex h-6 w-6 items-center justify-center rounded-md border-[1px] bg-white p-1 opacity-0 shadow group-hover:opacity-100"
              onClick={() => onRemove(selectedValue)}
              style={{
                top: "calc(50% - 0.8rem)",
              }}
            >
              <MinusIcon className="text-slate12 h-5 w-5" />
            </button>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderExistingValues = (values) => {
    return (
      <div className={clsx("flex w-full flex-col", styles.cell)}>
        {values.map((selectedValue) => renderSelectedValue(selectedValue))}
      </div>
    );
  };

  const renderSearchInput = () => {
    //
    return (
      <div className="border-b-slate5 bg-slate3 relative flex w-full border-b-[1px]">
        <input
          ref={addSelectRef}
          type="text"
          className={clsx(
            "text-slate10 bg-slate2 h-[40px] w-full border-none px-2.5 py-1 font-medium outline-none focus:border-none focus:outline-none",
            boardView ? "text-[12px]" : "text-[15px]"
          )}
          placeholder={
            dropdownTitle && onAddNew
              ? `Search or create a new ${dropdownTitle}`
              : dropdownTitle
              ? `Search ${dropdownTitle}s`
              : "Search"
          }
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoFocus={true}
        />
        {/* Render a cross button to remove search fixed to right */}
        {search && (
          <button
            className="absolute right-2 flex h-5 w-5 items-center justify-center rounded-md p-1"
            onClick={() => setSearch("")}
            style={{
              top: "calc(50% - 0.7rem)",
            }}
          >
            <XMarkIcon className="text-slate10 h-5 w-5" />
          </button>
        )}
      </div>
    );
  };

  const renderBody = () => (
    <>
      {/* First we will have a search input which is fixed to the top */}
      {renderSearchInput()}
      {/* Render a header */}
      <div className="text-slate11 slate11 flex items-center justify-between py-2 px-2.5 text-[13px] font-semibold">
        <span>{headerText}</span>
      </div>
      {/* Next we wrap the filteredOptions inside a ScrollArea */}
      {optionsFiltered.length > 0 && (
        <ScrollArea className="max-h-[320px] px-1 pb-2 ">
          <div className="flex w-full flex-col ">
            {/* Render the options values */}
            {/*  */}
            {optionsFiltered.map((option, index) => (
              <button
                className={clsx(
                  "hover:bg-slate3 relative flex h-[32px] min-h-[32px] w-full flex-1 items-center rounded-md px-2.5"
                )}
                onClick={() => handleOptionClick(option)}
                aria-current={focusedIndexRef.current === index}
                id={`option-${index}`}
              >
                {/* Render label */}
                <div
                  className={clsx(
                    "text-slate12 w-full truncate text-left font-medium",
                    boardView ? "text-[12px]" : "text-[15px]"
                  )}
                >
                  {option.label}
                </div>
                {
                  // If value has a tag then render it as absolute positioned to the right (leave space for minus button if onRemove is passed)
                  // Use the badge component for this
                  option.tag && (
                    <Badge
                      id={option.id}
                      className={"mr-2"}
                      color={"sky"}
                      size={"sm"}
                      label={option.tag}
                    />
                  )
                }
              </button>
            ))}
          </div>
        </ScrollArea>
      )}
      {/* Render create new button if there is no match with search. Match the style with option but include a + button */}
      {onAddNew && search && (
        <div className="border-slate5 h-[40px] w-full flex-1 items-center border-t-[1px] p-1">
          <button
            className={clsx(
              "hover:bg-slate3 mb-2 h-[32px] min-h-[32px] w-full flex-1 rounded-md px-2.5"
            )}
            onClick={() => handleAddNew()}
            aria-current={focusedIndexRef.current === optionsFiltered.length}
            id={`add-new-button`}
          >
            {/* Render label */}
            <div
              className={clsx(
                "text-slate10 flex w-full items-center truncate text-left font-medium",
                boardView ? "text-[12px]" : "text-[15px]"
              )}
            >
              <PlusIcon className="text-slate10 mr-1 h-4 w-4" />
              Create <span className="text-slate12 ml-1">{search}</span>
            </div>
          </button>
        </div>
      )}
    </>
  );

  const selectRefCallback = (node) => {
    if (node) {
      setContainerWidth(node.getBoundingClientRect().width);
    }
    setSelectRef(node);
  };

  return (
    <div
      id={dropdownTitle ? `title-dropdown-${dropdownTitle}` : "title-dropdown"}
      className="h-full w-full"
      ref={selectRefCallback}
    >
      <div
        onClick={() => {
          if (children) {
            setShowSelect(true);
          }
        }}
        tabIndex={0}
        // onFocus={() => setShowSelect(true)} // This is causing issues with the remove button so comment for now
        className={clsx(
          "w-full flex-1  flex-col items-center",
          readOnly ? "cursor-default" : "cursor-pointer"
        )}
        style={{
          minHeight:
            children || (selectedValues && selectedValues.length > 0) ? 32 : 0,
          display:
            children || (selectedValues && selectedValues.length > 0)
              ? "flex"
              : "none",
        }}
      >
        {children
          ? children
          : selectedValues && selectedValues.length > 0
          ? renderExistingValues(selectedValues)
          : null}
      </div>

      {!children && (!selectedValues || selectedValues.length === 0) && (
        <button
          className={clsx(
            "flex h-full w-full flex-1 items-center px-2.5 py-1",
            !readOnly && "hover:bg-slate3 cursor-pointer"
          )}
          style={{
            minHeight: 32,
          }}
          onClick={() => {
            setShowSelect(true);
          }}
          disabled={readOnly}
        >
          <div
            className={clsx(
              "text-slate10 font-medium",
              boardView ? "text-[12px]" : "text-[15px]"
            )}
          >
            {"Empty"}
          </div>
        </button>
      )}

      {showSelect && (
        <div className="overlay" onClick={() => setShowSelect(false)} />
      )}
      {showSelect &&
        createPortal(
          <div
            className="rounded-md bg-white"
            ref={setSelectPop}
            style={popoverStyle}
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                if (showSelect) {
                  setShowSelect(false);
                }
              }
            }}
          >
            {loading ? (
              <div className="flex h-[280px] w-full items-center justify-center"></div>
            ) : (
              renderBody()
            )}
          </div>,
          document.querySelector(`#popper-portal`)
        )}
    </div>
  );
};

export default BlocksCell;
