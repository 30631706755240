import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import ClassV2 from "../../../models/ClassV2";
import { DashboardBreakpoint } from "../../../types";
import Button from "../../Button";
import ClassScheduleCreateAndEditModal from "../../CRUD/CreateAndEdit/ClassSchedule";

type ClassUnassignedScheduledItemProps = {
  breakpoint: DashboardBreakpoint;
  classV2: ClassV2;
  selectedClasses: string[];
  setSelectedClasses: (selectedClasses: string[]) => void;
};

function ClassUnassignedScheduledItem({
  breakpoint,
  classV2,
  selectedClasses,
  setSelectedClasses,
}: ClassUnassignedScheduledItemProps) {
  const { calendars } = useStores();

  const [showEditClassScheduleModal, setShowEditClassScheduleModal] =
    useState(false);

  const { addToast } = useToastsContext();

  const renderClassUnassignedScheduledItemLg = () => (
    <tr
      key={classV2.id}
      className={
        selectedClasses.includes(classV2.id) ? "bg-gray-50" : undefined
      }
    >
      <td className="relative px-7 sm:w-12 sm:px-6">
        {selectedClasses.includes(classV2.id) && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          value={classV2.id}
          checked={selectedClasses.includes(classV2.id)}
          onChange={(e) =>
            setSelectedClasses(
              e.target.checked
                ? [...selectedClasses, classV2.id]
                : selectedClasses.filter((p) => p !== classV2.id)
            )
          }
        />
      </td>

      <td
        className={clsx(
          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
          selectedClasses.includes(classV2.id)
            ? "text-blue-600"
            : "text-gray-900"
        )}
      >
        {classV2.name}
      </td>

      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditClassScheduleModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderClassUnassignedScheduledItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditClassScheduleModal && (
        <ClassScheduleCreateAndEditModal
          multiple={false}
          onSelectRotationSchedule={async (id: string) => {
            setShowEditClassScheduleModal(false);
            // To do: update the class schedule
            const res = await calendars.assignClassSchedules([classV2.id], id);

            if (res) {
              addToast("Schedule assigned successfully", {
                type: "success",
              });

              setTimeout(() => {
                window.location.reload();
              }, 400);
            } else {
              addToast("Schedule assigned failed", {
                type: "error",
              });
            }
          }}
          onClose={() => setShowEditClassScheduleModal(false)}
        />
      )}
      {breakpoint === "lg"
        ? renderClassUnassignedScheduledItemLg()
        : renderClassUnassignedScheduledItemSm()}
    </React.Fragment>
  );
}

export default observer(ClassUnassignedScheduledItem);
