// Main App Sidebar Component

import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useLocation } from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";
import useStores from "../../hooks/useStores";
import { theme } from "../../utils/theme";
import Tooltip from "../Tooltip";
import styles from "./MainSidebar.module.css";

type Props = {
  children: React.ReactNode;
  className?: string;
};

function MainSidebar({ children, className }: Props) {
  const [isCollapsing, setCollapsing] = useState(false);
  const { ui } = useStores();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const width = ui.mainSidebarWidth;
  const isMainSidebarCollapsed = ui.isMainSidebarCollapsed;
  const maxWidth = theme.mainSidebarMaxWidth;
  const minWidth = theme.mainSidebarMinWidth + theme.sidebarPadding;

  const [mouseCursorOffset, setMouseCursorOffset] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const isLessThanMinWidth = width < minWidth;
  const [isClick, setIsClick] = useState(false);

  //   Handle dragging the resize drag handler
  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      // prevent default behavior
      event.preventDefault();

      // calculate absolute width based on current position of mouse cursor
      const absoluteWidth = event.pageX - mouseCursorOffset;

      // limit absolute width to maximum value
      const width = Math.min(absoluteWidth, maxWidth);

      // check if width is less than half of minimum value
      const shouldCollapse = width < minWidth / 2;

      // set navbar width based on calculated width or predetermined width
      if (shouldCollapse) {
        ui.setMainSidebarWidth(theme.mainSidebarWhenCollapsedWidth);
      } else {
        ui.setMainSidebarWidth(width);
      }
    },
    [theme, minWidth, maxWidth, ui.setMainSidebarWidth, mouseCursorOffset]
  );

  const onMouseUp = useCallback(() => {
    setIsDragging(false);

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    if (isLessThanMinWidth) {
      const shouldCollapse = width < minWidth / 2;

      if (shouldCollapse) {
        setAnimate(false);
        setCollapsing(true);
        ui.collapseMainSidebar();
      } else {
        ui.setMainSidebarWidth(minWidth);
        setAnimate(true);
      }
    } else {
      ui.setMainSidebarWidth(width);
    }
  }, [ui, isLessThanMinWidth, minWidth, width, ui.setMainSidebarWidth]);

  //   We activate the dragging when the user clicks on the resize drag handler
  const handleMouseDown = React.useCallback(
    (event: any) => {
      // event.preventDefault();
      setIsClick(true); // Set isClick to true initially

      setTimeout(() => {
        if (isClick) {
          // If isClick is still true after the timeout, it's a click event
          handleResizeHandleClick();
        }
      }, 200); // Set a short delay (e.g., 200ms) to determine if it's a click

      setMouseCursorOffset(event.pageX - width);
      setAnimate(false);
      setIsDragging(true);
    },
    [width]
  );

  useEffect(() => {
    if (animate) {
      setTimeout(() => setAnimate(false), 300);
    }
  }, [animate]);

  useEffect(() => {
    if (isCollapsing) {
      setTimeout(() => {
        ui.setMainSidebarWidth(minWidth);
        setCollapsing(false);
      }, 300);
    }
  }, [ui.setMainSidebarWidth, minWidth, isCollapsing]);

  //   We activate the dragging event listeners by checking for isDragging -> when the user clicks on the resize drag handler
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragging, onMouseMove, onMouseUp]);

  //   Handle the double click on the resize drag handler
  const handleResizeHandleClick = useCallback(() => {
    ui.setMainSidebarWidth(theme.mainSidebarWidth);
  }, [ui, theme.mainSidebarWidth]);

  useEffect(() => {
    ui.setMainSidebarResizing(isDragging);
  }, [ui, isDragging]);

  useEffect(() => {
    if (location !== previousLocation) {
      ui.hideMobileSidebar();
    }
  }, [ui, location, previousLocation]);

  type ResizeHandleProps = {
    direction?: "left" | "right";
    onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  };

  function ResizeHandler({
    direction,
    onMouseDown,
    onClick,
  }: ResizeHandleProps) {
    return (
      <Tooltip content="Drag to Resize" side="right" showArrow={true}>
        <div
          className={styles.Resize_Handler}
          dir={direction}
          onMouseDown={onMouseDown}
          onClick={onClick}
        />
      </Tooltip>
    );
  }

  const style = React.useMemo(
    () => ({
      width: `${width}px`,
    }),
    [width]
  );

  return (
    <>
      <div
        className={clsx(
          `flex flex-col`,
          className ? className : "",
          styles.Sidebar_Container,
          animate ? styles.Is_Animating : "",
          isLessThanMinWidth ? styles.Less_Than_Min_Width : "",
          ui.showMobileSidebar ? styles.Show_Mobile_Sidebar : "",
          isMainSidebarCollapsed ? styles.Is_Hidden : "",
          "border-r-[1px] border-gray-100"
        )}
        style={style}
      >
        {/* <Tooltip
          content={isMainSidebarCollapsed ? "Open Sidebar" : "Close Sidebar"}
          side="right"
        >
          <div
            className={clsx(styles.Toggle_Button, "bg-gray-100")}
            onClick={() => {
              if (isMainSidebarCollapsed) {
                ui.toggleMainSidebar();
              } else {
                setCollapsing(true);
                ui.collapseMainSidebar();
                ui.setMainSidebarWidth(theme.mainSidebarWhenCollapsedWidth);
              }
            }}
          >
            {isMainSidebarCollapsed ? (
              <ChevronDoubleRightIcon className="h-6 w-6" />
            ) : (
              <ChevronDoubleLeftIcon className="h-6 w-6" />
            )}
          </div>
        </Tooltip> */}
        {ui.showMobileSidebar && (
          <Portal>
            <div
              className={clsx(
                "animate-simpleTransition z-100 fixed inset-0",
                styles.Mobile_Background_Blur
              )}
              onClick={ui.toggleMobileSidebar}
            />
          </Portal>
        )}

        {children}

        {/* <ResizeHandler onMouseDown={handleMouseDown} /> */}
      </div>
    </>
  );
}

export default observer(MainSidebar);
