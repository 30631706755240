import { computed, observable } from "mobx";
import BlockSchedule from "./BlockSchedule";
import DeletableModel from "./DeletableModel";

class Block extends DeletableModel {
  @observable
  abbreviation: string;

  @observable
  color: string | null;

  @observable
  description: string | null;

  @observable
  rotationScheduleId: string;

  @computed
  get blockSchedules(): BlockSchedule[] {
    return this.store.rootStore.blockSchedules.getBlockSchedule(this.id);
  }

  @computed
  get scheduledOn(): string {
    const rotationDayAbbr: string[] = [];

    const { rotationDays } = this.store.rootStore;

    this.blockSchedules.forEach((blockSchedule) => {
      const rotationDayId = blockSchedule.rotationDayId;

      if (rotationDayId) {
        const rotationDay = rotationDays.get(rotationDayId);

        if (rotationDay) {
          rotationDayAbbr.push(rotationDay.abbreviation);
        }
      }
    });

    rotationDayAbbr.sort((a, b) => {
      return a.localeCompare(b);
    });

    return rotationDayAbbr.length > 0 ? rotationDayAbbr.join(", ") : "-";
  }
}

export default Block;
