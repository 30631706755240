type SchoolLogoProps = {
  schoolData: {
    id: string;
    initial: string;
    // color: string;
  };
  size: number;
  border?: boolean;
};

export default function SchoolLogo({
  schoolData,
  size,
  border,
}: SchoolLogoProps) {
  const fontSize = size / 2;
  const borderStyle = !border
    ? "border-none"
    : "border-2 border-current bg-current";

  return (
    <div
      className={`flex items-center justify-center rounded-md text-white  text-${fontSize} font-semibold ${borderStyle} `}
      style={{
        backgroundColor: "var(--slate8)",
        border: !border ? "none" : "2px solid var(--slate7)",
        width: size,
        height: size,
      }}
    >
      {schoolData.initial}
    </div>
  );
}
