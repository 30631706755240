import { gql } from "../../__generated__/gql";

export const CreateClassV2 = gql(`
    mutation createClassV2($data: ClassV2CreateInput!) {
        createClassV2(data: $data) {
            createdAt
            deletedAt
            course {
                id
            }
            description
            edlinkId
            gradeLevels
            id
            identifiers
            locale
            name
            properties
            school {
                id
            }
            sections {
                id
            }
            sessions {
                id
            }
            state 
            subjectCodes
            timezone
            updatedAt
            urlId
        }
    }
`);

export const UpdateClassV2 = gql(`
    mutation updateClassV2($data: ClassV2UpdateInput!, $where: ClassV2WhereUniqueInput!) {
        updateClassV2(data: $data, where: $where) {
            createdAt
            deletedAt
            course {
                id
            }
            description
            edlinkId
            gradeLevels
            id
            identifiers
            locale
            name
            properties
            school {
                id
            }
            sections {
                id
            }
            sessions {
                id
            }
            state 
            subjectCodes
            timezone
            updatedAt
            urlId
        }
    }
`);
