import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useModalContext } from "../../contexts/modals";
import useIsMobile from "../../hooks/useIsMobile";
import useOnUnmount from "../../hooks/useOnUnmount";
import usePrevious from "../../hooks/usePrevious";
import Button from "../Button";
import ButtonLarge from "../ButtonLarge";

type ModalProps = {
  open: boolean;
  title?: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  alignCenter?: boolean;
  onClose: () => void;
  disabled?: boolean;
  onSubmit?: () => void;
  size?: "sm" | "md" | "lg" | "full-wd";
  // Not implemented yet
  submitText?: string;
  destructive?: boolean;
};

export const Modal = ({
  open,
  title,
  children,
  alignCenter = false,
  onClose,
  disabled,
  description,
  onSubmit,
  submitText = "Submit",
  destructive = false,
  size = "full-wd",
}: ModalProps) => {
  const { numOpenModals, setNumOpenModals } = useModalContext();

  const [depth, setDepth] = React.useState(0);
  const wasOpen = usePrevious(open);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!wasOpen && open) {
      setNumOpenModals(numOpenModals + 1);
      setDepth(numOpenModals + 1);
    }

    if (wasOpen && !open) {
      setNumOpenModals(numOpenModals - 1);
      setDepth(numOpenModals - 1);
    }
  }, [wasOpen, open]);

  useOnUnmount(() => {
    if (open) {
      setNumOpenModals(numOpenModals - 1);
    }
  });

  if (!open) {
    return null;
  }

  // Dialog.Content is where all the main css styling will be applied

  // let's create the styles for different sizes and alignment
  const commonStyle = "bg-white focus:outline-none";
  const fullWdStyle =
    "data-[state=open]:animate-fullWidthModalShow absolute inset-0 px-[48px] py-[54px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]";
  const smStyle =
    "data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-w-[450px] w-[90vw] max-h-[85vh] translate-x-[-50%] translate-y-[-50%]  shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] rounded-[6px] p-[25px]";
  const mdStyle =
    "data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-w-[600px] w-[90vw] max-h-[85vh] translate-x-[-50%] translate-y-[-50%]  shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] rounded-[12px] p-[32px]";

  const lgStyle =
    "overflow-hidden data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-w-[90pvw] w-[90vw] h-[90vh] max-h-[85vh] translate-x-[-50%] translate-y-[-50%]  shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] rounded-[12px]";

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-[609]"
          onClick={() => onClose()}
        />
        <Dialog.Content
          className={clsx(
            commonStyle,
            size === "full-wd"
              ? fullWdStyle
              : size === "sm"
              ? smStyle
              : size === "md"
              ? mdStyle
              : lgStyle,
            "flex flex-col items-center overflow-scroll"
          )}
          style={{
            zIndex: 610 + depth,
            // For first modal with depth 1 we want no margin, for all others we want (depth * 12)
            marginTop: depth === 1 ? 0 : depth * 12,
          }}
        >
          {/* Content wrapper */}
          <div
            className={clsx(
              size === "full-wd" && "w-full",
              alignCenter && "flex flex-col items-center",
              "h-full w-full"
            )}
          >
            {title && (
              <Dialog.Title
                className={clsx(
                  size === "full-wd" && !isMobile
                    ? "text-3xl font-semibold"
                    : "text-slate12 m-0 mr-auto text-[17px] font-medium",
                  "text-center"
                )}
              >
                {title}
              </Dialog.Title>
            )}
            {description && (
              <Dialog.Description
                className={clsx(
                  "mt-4",
                  size === "full-wd" && !isMobile
                    ? "text-md"
                    : "text-slate11 mt-[10px] mb-5 text-[15px] leading-normal",
                  "text-center"
                )}
              >
                {description}
              </Dialog.Description>
            )}
            {/* May need to make this wrapper scrollable */}
            {children && (
              <div
                className={clsx(
                  "w-full ",
                  size === "full-wd" && !isMobile
                    ? "mt-16"
                    : size === "lg"
                    ? "m-0"
                    : "mt-4",
                  size === "lg" ? "h-full overflow-hidden" : "overflow-scroll"
                )}
              >
                {children}
              </div>
            )}

            {/* Consider adding a submit button here */}
            {onSubmit && (
              <div
                className={clsx(
                  "flex w-full justify-end",
                  size === "full-wd" ? "mt-[25px] pb-8" : "mt-4"
                )}
              >
                <Dialog.Close asChild>
                  {size === "full-wd" ? (
                    <ButtonLarge
                      onClick={() => {
                        onSubmit();
                        // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                        setTimeout(
                          () => (document.body.style.pointerEvents = ""),
                          100
                        );
                      }}
                      disabled={disabled}
                      type="button"
                      theme={destructive ? "destructive" : "primary"}
                      buttonText={submitText || "Submit"}
                      // padding="large"
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        onSubmit();
                        // Temporary fix for pointer events not working after closing a modal (Radix UI bug)
                        setTimeout(
                          () => (document.body.style.pointerEvents = ""),
                          100
                        );
                      }}
                      disabled={disabled}
                      type="button"
                      theme={destructive ? "destructive" : "primary"}
                      buttonText={submitText || "Submit"}
                      padding="large"
                    />
                  )}
                </Dialog.Close>
              </div>
            )}
          </div>

          {/* Back close button for full width */}
          {size === "full-wd" && !isMobile && (
            <Dialog.Close asChild>
              <button
                className="text-slate12 hover:text-slate11 absolute top-8 left-12 flex items-center font-semibold"
                aria-label="Close"
                disabled={disabled}
                onClick={() => onClose()}
              >
                <ChevronLeftIcon className="mr-1 h-5 w-5 font-bold" />
                Back
              </button>
            </Dialog.Close>
          )}
          {/* X mark button */}
          {(size === "md" || size === "sm" || isMobile) && (
            <Dialog.Close asChild>
              <button
                className="text-blue11 hover:bg-blue4 focus:shadow-blue7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
                disabled={disabled}
                onClick={() => onClose()}
              >
                <XMarkIcon className="h-5 w-5 text-gray-600" />
              </button>
            </Dialog.Close>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
