import invariant from "invariant";
import { action, computed, observable } from "mobx";
import {
  EnumClassV2GradeLevels,
  EnumClassV2State,
  EnumClassV2SubjectCodes,
} from "../__generated__/graphql";
import BlockSchedule from "./BlockSchedule";
import Calendar from "./Calendar";
import DeletableModel from "./DeletableModel";
import RotationDay from "./RotationDay";
import SectionV2 from "./SectionV2";
import Session from "./Session";

type SectionSchedulingDone = {
  assigned: number;
  unassigned: number;
  total: number;
};

export type BlockOption = {
  id: string;
  label: string;
  tag?: string;
  color: string;
};

type RotationBlockOptions = {
  [rotationDayId: string]: BlockOption[];
};

class ClassV2 extends DeletableModel {
  @observable
  id: string;

  @observable
  courseId: string | null;

  @observable
  description: string | null;

  @observable
  edlinkId: string | null;

  @observable
  gradeLevels: EnumClassV2GradeLevels[];

  @observable
  identifiers: any | null;

  @observable
  locale: string | null;

  @observable
  name: string;

  @observable
  properties: any | null;

  @observable
  schoolId: string;

  @observable
  sectionIds: string[];

  @observable
  sessionIds: string[];

  @observable
  subjectCodes: EnumClassV2SubjectCodes[];

  @observable
  state: EnumClassV2State;

  @observable
  timezone: string | null;

  @observable
  urlId: string;

  // Active Session ID & Active Calendar ID
  @observable
  activeSessionId: string | null = null;

  @action
  setActiveSessionId = (sessionId: string) => {
    this.activeSessionId = sessionId;
  };

  @computed
  get activeSession(): Session | null {
    const { sessions } = this.store.rootStore;

    if (!this.activeSessionId) {
      return null;
    }

    const session = sessions.get(this.activeSessionId);

    return session;
  }

  @computed
  get sections(): SectionV2[] {
    const { sections } = this.store.rootStore;

    const fetchSections = this.sectionIds.map((sectionId) =>
      sections.get(sectionId)
    );

    // Filter our any undefined sections
    return fetchSections.filter((section) => !!section) as SectionV2[];
  }

  @computed
  get sectionsCount(): number {
    return this.sectionIds.length;
  }

  @computed
  get schoolName(): string {
    const { schools } = this.store.rootStore;

    const fetchSchool = schools.get(this.schoolId);

    return fetchSchool ? fetchSchool.name : "-";
  }

  @computed
  get sessionNames(): string {
    const { sessions } = this.store.rootStore;

    return this.sessionIds
      .map((sessionId) => {
        const fetchSession = sessions.get(sessionId);

        return fetchSession ? fetchSession.name : "-";
      })
      .join(", ");
  }

  @computed
  get courseName(): string {
    if (!this.courseId) {
      return "-";
    }

    const { courses } = this.store.rootStore;

    const fetchCourse = courses.get(this.courseId);

    return fetchCourse ? fetchCourse.name : "-";
  }

  // SCHEDULING -- START

  // IMP: CHECK IF THERE IS A CALENDAR FOR THIS SESSION ONLY
  @computed
  get hasClassCalendar(): boolean {
    const { calendars, ui, sessions } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    invariant(activeSession, "Active session must exist");

    const classCalendars = calendars.getCalendarsForClass(
      this.id,
      activeSession.id
    );

    return classCalendars.length > 0;
  }

  @computed
  get getBlockOptionsForClass(): RotationBlockOptions {
    const { blockSchedules, calendars, rotationDays, sessions, ui } =
      this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    invariant(activeSession, "Active session must exist");

    const classCalendars = calendars.getCalendarsForClass(
      this.id,
      activeSession.id
    );

    if (classCalendars.length === 0) {
      return {};
    }

    const classCalendar = classCalendars[0];

    const findRotationDays = rotationDays.getRotationDaysForSchedule(
      classCalendar.rotationScheduleId
    );

    // Let's create a map of rotationDayId to blockOptions
    const rotationBlockOptions: RotationBlockOptions = {};

    findRotationDays.forEach((rotationDay: RotationDay) => {
      const findBlockSchedules = blockSchedules.getBlockSchedulesForRotationDay(
        rotationDay.id
      );

      const dropdownOptions = findBlockSchedules.map(
        (blockSchedule: BlockSchedule) => {
          return {
            id: blockSchedule.id,
            label: blockSchedule.blockName,
            color: blockSchedule.blockColor,
          };
        }
      );

      rotationBlockOptions[rotationDay.id] = dropdownOptions;
    });

    return rotationBlockOptions;
  }

  @computed
  get rotationScheduleNameForClass(): string | null {
    const { calendars, sessions, ui } = this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    invariant(activeSession, "Active session must exist");

    const classCalendars = calendars.getCalendarsForClass(
      this.id,
      activeSession.id
    );

    if (classCalendars.length === 0) {
      return null;
    }

    const classCalendar = classCalendars[0];

    return classCalendar.rotationScheduleName;
  }

  @computed
  get sectionSchedulingDone(): SectionSchedulingDone {
    const { sessions, sections, calendars, calendarSchedules, ui } =
      this.store.rootStore;

    const activeSession = sessions.getByUrlParam(ui.activeSessionId);

    invariant(activeSession, "Active session must exist");

    const classSections = sections.getSectionsForClass(this.id);

    const classCalendars = calendars.getCalendarsForClass(
      this.id,
      activeSession.id
    );

    // We probably haven't created a Default section in our backend
    if (classCalendars.length === 0) {
      return {
        assigned: 0,
        unassigned: 0,
        total: 0,
      };
    }

    let assignedCount = 0;
    let unassignedCount = 0;

    classSections.forEach((section: SectionV2) => {
      //
      const findCalendar = classCalendars.find(
        (calendar: Calendar) =>
          calendar.sectionId === section.id && !calendar.deletedAt
      );

      // IF we found the calendar, we must check for calendar schedules for this calendar ID
      if (findCalendar) {
        const findCalendarSchedules =
          calendarSchedules.getCalendarsSchedulesForCalendar(findCalendar.id);

        if (findCalendarSchedules.length > 0) {
          assignedCount++;
        } else {
          unassignedCount++;
        }
      } else {
        unassignedCount++;
      }
    });

    return {
      assigned: assignedCount,
      unassigned: unassignedCount,
      total: assignedCount + unassignedCount,
    };
  }

  // SCHEDULING -- END
}

export default ClassV2;
