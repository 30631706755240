// DropdownMenu/Content.tsx

import React from "react";
import { actionToDropdownItem } from "../../actions";
import useDropdown from "../../hooks/useDropdownContext";
import {
  DropdownAction,
  DropdownActionContext,
  DropdownDivider,
  DropdownHeading,
  DropdownItemType,
} from "../../types";
import { ScrollArea } from "../ScrollArea";
import DropdownItem from "./DropdownItem";

// We can instead using both items or actions passed in to render the components
type DropdownListProps = {
  items?: DropdownItemType[];
  actions?: (DropdownAction | DropdownDivider | DropdownHeading)[];
  context?: DropdownActionContext;
};

const DropdownList: React.FC<DropdownListProps> = ({
  items,
  actions,
  context,
}) => {
  const dropdownContext = useDropdown();

  const itemList: DropdownItemType[] = actions
    ? actions.map((action, index) => {
        if (action.type === "divider" || action.type === "heading") {
          return { ...action } as DropdownItemType;
        } else {
          return actionToDropdownItem(
            action as DropdownAction,
            context || dropdownContext
          ) as DropdownItemType;
        }
      })
    : items || [];

  const showList = itemList.filter((item) => item.show !== false);

  return (
    <ScrollArea className="max-h-[280px] p-[5px]">
      {showList.map((item, index) => (
        <DropdownItem key={index} item={item} />
      ))}
    </ScrollArea>
  );
};

export default DropdownList;
