import { observable } from "mobx";
import DeletableModel from "./DeletableModel";

class RotationDay extends DeletableModel {
  @observable
  id: string;

  @observable
  abbreviation: string;

  @observable
  dayIndex: number;

  @observable
  description: string | null;

  @observable
  rotationScheduleId: string;
}

export default RotationDay;
