import { observer } from "mobx-react";
import { useEffect, useReducer } from "react";
import RotationDay from "../../models/RotationDay";
import RotationDaysEditableTable from "../EditableTable/RotationDay.jsx";

export type RotationDayFormEntity = {
  id?: string;
  dayIndex?: string;
  abbreviation?: string;
  description?: string;
};

type RotationDayFormState = {
  rotationDays: RotationDayFormEntity[];
};

const initialState: RotationDayFormState = {
  rotationDays: [],
};

const formReducer = (state: RotationDayFormState, action: any) => {
  switch (action.type) {
    case "add_rotation_day":
      return {
        ...state,
        rotationDays: [
          ...state.rotationDays,
          {
            dayIndex: (state.rotationDays.length + 1).toString(),
            abbreviation: "",
            description: "",
          },
        ],
      };
    case "update_rotation_day":
      const { rotationDayIndex, rotationDayKey, rotationDayValue } =
        action.payload;

      return {
        ...state,
        rotationDays: state.rotationDays.map((rotationDay, index) => {
          if (index === rotationDayIndex) {
            return {
              ...rotationDay,
              [rotationDayKey]: rotationDayValue,
            };
          }

          return rotationDay;
        }),
      };

    case "remove_rotation_day":
      const { rotationDayIndex: rotationDayIndexToRemove } = action.payload;

      return {
        ...state,
        rotationDays: state.rotationDays.filter(
          (rotationDay, index) => index !== rotationDayIndexToRemove
        ),
      };

    default:
      return state;
  }
};

function RotationDays({
  activeRotationScheduleDays,
  setModifiedRotationDays,
}: {
  activeRotationScheduleDays: RotationDay[];
  setModifiedRotationDays: React.Dispatch<
    React.SetStateAction<RotationDayFormEntity[]>
  >;
}) {
  const init = (initialState: RotationDayFormState) => {
    const rotationDays: RotationDayFormEntity[] =
      activeRotationScheduleDays.map((rotationDay) => {
        return {
          id: rotationDay.id || undefined,
          dayIndex: rotationDay.dayIndex.toString() || undefined,
          abbreviation: rotationDay.abbreviation || undefined,
          description: rotationDay.description || undefined,
        };
      });

    return {
      rotationDays,
    };
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  useEffect(() => {
    setModifiedRotationDays(state.rotationDays);
  }, [state, setModifiedRotationDays]);

  return (
    <div>
      <RotationDaysEditableTable
        rotationDays={state.rotationDays}
        addNewRotationDay={() => {
          dispatchFormAction({ type: "add_rotation_day" });
        }}
        removeRotationDay={(rowIndex: number) => {
          dispatchFormAction({
            type: "remove_rotation_day",
            payload: {
              rotationDayIndex: rowIndex,
            },
          });
        }}
        updateRotationDay={(
          rowIndex: number,
          rowKey: string,
          rowValue: any
        ) => {
          dispatchFormAction({
            type: "update_rotation_day",
            payload: {
              rotationDayIndex: rowIndex,
              rotationDayKey: rowKey,
              rotationDayValue: rowValue,
            },
          });
        }}
      />
    </div>
  );
}

export default observer(RotationDays);
