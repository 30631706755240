import clsx from "clsx";
import styles from "./index.module.css";

type InputProps = {
  label?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: (val: string) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  multiLine?: boolean;
  name?: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  readOnly?: boolean;
};

export default function Input(props: InputProps) {
  const {
    label,
    placeholder,
    type,
    value,
    onChange,
    onBlur,
    error,
    multiLine,
    name,
    id,
    className,
    disabled,
    required,
    autoComplete,
    autoFocus,
    maxLength,
    minLength,
    pattern,
    readOnly,
  } = props;

  const commonProps = {
    value,
    onChange: (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    onBlur,
    name,
    id,
    placeholder,
    className: clsx(
      styles.inputStyle,
      "mt-2 rounded-md border px-4 py-2 text-sm",
      className,
      {
        "border-red-500 dark:border-red-500": error,
      }
    ),
    disabled,
    required,
    autoComplete,
    autoFocus,
    maxLength,
    minLength,
    pattern,
    readOnly,
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label
          htmlFor={id}
          className={clsx(styles.label, "block text-sm font-medium")}
        >
          {label}
        </label>
      )}
      {multiLine ? (
        <textarea {...commonProps} rows={4} cols={50} />
      ) : (
        <input type={type} {...commonProps} />
      )}
      {error && (
        <span className="mt-1 text-xs text-red-500 dark:text-red-500">
          {error}
        </span>
      )}
    </div>
  );
}
