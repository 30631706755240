// Use this to store and manage main calendar data

import { ApolloClient } from "@apollo/client";
import { action, observable } from "mobx";
import CalendarEvent from "../models/CalendarEvent";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class CalendarEventsStore extends BaseStore<CalendarEvent> {
  @observable
  sessionCalendarViewDate: Date | null = null;

  @observable
  sharedCalendarViewDate: Date | null = null;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, CalendarEvent, apolloClient);
  }

  @action
  setSessionCalendarCurrentDate = (date: Date | null) => {
    this.sessionCalendarViewDate = date;
  };

  @action
  setSharedCalendarCurrentDate = (date: Date | null) => {
    this.sharedCalendarViewDate = date;
  };

  @action
  getEventsForCalendar(calendarId: string) {
    return this.sortedData.filter((event) => {
      return event.calendarId === calendarId;
    });
  }

  @action
  removeCalendarEventsForCalendarDay = (calendarDayId: string) => {
    this.sortedData
      .filter((event) => event.id.includes(calendarDayId))
      .forEach((event) => {
        this.remove(event.id);
      });
  };

  @action
  removeCalendarEvents = (calendarId: string) => {
    this.sortedData
      .filter((event) => event.calendarId === calendarId)
      .forEach((event) => {
        this.remove(event.id);
      });
  };
}
