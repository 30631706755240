import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import School from "../../../models/School";
import { DashboardBreakpoint } from "../../../types";
import { schoolGradesEnumArrayToStringArray } from "../../../utils/gradeLevelsMap";
// import { districtSchoolRoute } from "../../../utils/routeHelper";
import Button from "../../Button";
import SchoolCreateAndEditModal from "../../CRUD/CreateAndEdit/School";

type SchoolItemProps = {
  breakpoint: DashboardBreakpoint;
  school: School;
};

function SchoolItem({ breakpoint, school }: SchoolItemProps) {
  const [showEditSchoolModal, setShowEditSchoolModal] = useState(false);

  const { addToast } = useToastsContext();

  const { districts } = useStores();

  const district = districts.get(school.districtId);

  if (!district) return null;

  const renderSchoolItemLg = () => (
    <tr key={school.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {/* <Link
          to={districtSchoolRoute(district, school)}
          className="hover:underline"
        > */}
        {school.name}
        {/* </Link> */}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {schoolGradesEnumArrayToStringArray(school.gradeLevels).join(", ")}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {school.courseCount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {school.classCount}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditSchoolModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderSchoolItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditSchoolModal && (
        <SchoolCreateAndEditModal
          isEditing={true}
          school={school}
          onClose={() => setShowEditSchoolModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditSchoolModal(false);

            addToast("School updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderSchoolItemLg() : renderSchoolItemSm()}
    </React.Fragment>
  );
}

export default observer(SchoolItem);
