import {
  CalendarIcon,
  DropdownMenuIcon,
  Link2Icon,
  TextAlignLeftIcon,
  TextIcon,
} from "@radix-ui/react-icons";
// import TextIcon from "../img/Text";
import { CheckCircleIcon, HashtagIcon } from "@heroicons/react/24/outline";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { DataTypes } from "../utils";

export default function DataTypeIcon({ dataType }) {
  function getPropertyIcon(dataType) {
    switch (dataType) {
      case DataTypes.NUMBER:
        return <HashtagIcon className="text-slate10 h-5 w-5" />;
      case DataTypes.TEXT:
        return <TextAlignLeftIcon className="text-slate10 h-5 w-5" />;
      case DataTypes.SELECT:
        return <DropdownMenuIcon className="text-slate10 h-5 w-5" />;
      case DataTypes.TITLE:
        return <TextIcon className="text-slate10 h-5 w-5" />;
      case DataTypes.DATE:
        return <CalendarIcon className="text-slate10 h-5 w-5" />;
      case DataTypes.STANDARDS:
        return <ChevronUpDownIcon className="text-slate10 h-5 w-5" />;
      case DataTypes.LESSONS:
        return <Link2Icon className="text-slate10 h-5 w-5" />;
      case DataTypes.STATUS:
        return <CheckCircleIcon className="text-slate10 h-5 w-5" />;
      default:
        return null;
    }
  }

  return getPropertyIcon(dataType);
}
