import { HashtagIcon } from "@heroicons/react/24/outline";

export default function RowNumberHeader({ getHeaderProps }) {
  return (
    <div {...getHeaderProps()} className="th noselect d-inline-block">
      <div className="th-content d-flex justify-content-center bg-slate2">
        <HashtagIcon className="text-slate11 h-5 w-5" />
      </div>
    </div>
  );
}
