import { observer } from "mobx-react";
import { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import useStores from "../hooks/useStores";
import { PageTab } from "../types";

function Session() {
  const { sessionId = "" } = useParams();

  const {
    sections,
    ui,
    classes,
    schools,
    districts,
    enrollments,
    calendars,
    sessions,
    auth,
  } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  // const currentClass = classes.get(classId);

  const { tab = "calendar" } = useParams();

  const navigate = useNavigate();

  // Set active district in UI store
  // useEffect(() => {
  //   // if (currentClass) {
  //   //   ui.setActiveClassId(currentClass.id);
  //   // }

  //   // if (currentSchool) {
  //   //   ui.setActiveSchoolId(currentSchool.urlId);
  //   // }

  //   // if (currentDistrict) {
  //   //   ui.setActiveDistrictId(currentDistrict.urlId);
  //   // }

  //   setIsLoading(false);
  //   return () => {
  //     ui.setActiveClassId(undefined);
  //   };
  // }, [
  //   currentClass,
  //   ui,
  //   // currentSchool, currentDistrict
  // ]);

  // Fetch all school related data
  // useEffect(() => {
  //   if (currentClass) {
  //     sections.fetchClassSections(currentClass.id);
  //     enrollments.fetchEnrollmentsForClass(currentClass.id);
  //     calendars.fetchCalendarsForClass(currentClass.id);
  //   }
  // }, [currentClass]);

  const activeSession = sessions.getByUrlParam(sessionId);

  if (!activeSession) {
    return <div>Session not found</div>;
  }

  const courseHeader = (
    <div className="flex items-center">
      {/* <div
        className=" mr-1 flex items-center justify-center rounded-lg p-1 shadow-sm"
        style={{
          color: courseColor,
          background: courseColor
            ? colorsMap50[colorsMap500Flipped[courseColor]]
            : "var(--slate2)",
        }}
      >
        <IconComponent className="h-6 w-6" />
      </div> */}
      <div className="ml-2 text-xl font-semibold">{activeSession.name}</div>
    </div>
  );

  const courseTabs: PageTab[] = [
    // {
    //   name: "Calendar",
    //   onClick: () => {
    //     navigate(sessionRoute(sessionId, "lessons"));
    //   },
    //   current: (currentTab) => currentTab === "classes",
    // },
    // {
    //   name: "Rotation Cycle",
    //   onClick: () => {
    //     navigate(sessionRoute(sessionId, "units"));
    //   },
    //   current: (currentTab) => currentTab === "drive",
    // },
    // {
    //   name: "Reports",
    //   onClick: () => {
    //     navigate(sessionRoute(sessionId, "resources"));
    //   },
    //   current: (currentTab) => currentTab === "reports",
    // },
    // {
    //   name: "Enrollments",
    //   onClick: () => {
    //     navigate(classRoute(classId, "enrollments"));
    //   },
    //   current: (currentTab) => currentTab === "enrollments",
    // },
    // {
    //   name: "Calendar",
    //   onClick: () => {
    //     navigate(classRoute(classId, "calendar"));
    //   },
    //   current: (currentTab) => currentTab === "enrollments",
    // },
  ];

  if (isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  // return (
  //   <PageLayout left={courseHeader} title={tab} right={null} fullWidth={true}>
  //     {/* Render the Course component here */}
  //     <div className="px-20 py-2 pt-6">
  //       <PageTabs
  //         tabs={courseTabs}
  //         currentTab={tab}
  //         // activeTabControls={
  //         //   tab === "lessons"
  //         //     ? renderCalendarRight
  //         //     : tab === "assets"
  //         //     ? renderResourcesRight
  //         //     : null
  //         // }
  //       />
  //     </div>
  //   </PageLayout>
  // );

  return <Outlet />;
}

export default observer(Session);
