import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { ChevronLeftIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import invariant from "invariant";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import ClassV2, { BlockOption } from "../../../models/ClassV2";
import { DropdownOptionType } from "../../../types";
import { sessionRoute } from "../../../utils/routeHelper";
import { CreateCalendarScheduleInput } from "../../../__generated__/graphql";
import Button from "../../Button";
import ConfirmationDialog from "../../ConfirmationDialog";
import Dropdown from "../../Dropdown";
import SectionTimeblockingTable from "../../EditableTable/SectionTimeblocking";

export type SectionTimeblockFormEntity = {
  id?: string;
  rotationDayId: string;
  rotationDayName: string;
  blockId?: string;
  startTime?: string;
  endTime?: string;
  blockDropdownOptions?: BlockOption[];
};

type SectionTimeblockFormState = {
  sectionTimeblocks: SectionTimeblockFormEntity[];
};

const initialState: SectionTimeblockFormState = {
  sectionTimeblocks: [],
};

const formReducer = (state: SectionTimeblockFormState, action: any) => {
  switch (action.type) {
    case "set_section_timeblocks":
      return {
        ...state,
        sectionTimeblocks: action.payload,
      };
    case "update_section_timeblock":
      const {
        sectionTimeblockIndex,
        sectionTimeblockKey,
        sectionTimeblockValue,
      } = action.payload;

      console.log("sectionTimeblockIndex", sectionTimeblockIndex);
      console.log("sectionTimeblockKey", sectionTimeblockKey);
      console.log("sectionTimeblockValue", sectionTimeblockValue);

      return {
        ...state,
        sectionTimeblocks: state.sectionTimeblocks.map(
          (sectionTimeblock, index) => {
            if (index === sectionTimeblockIndex) {
              return {
                ...sectionTimeblock,
                [sectionTimeblockKey]: sectionTimeblockValue,
              };
            }

            return sectionTimeblock;
          }
        ),
      };

    default:
      return state;
  }
};

type SectionTimeblockingCreateAndEditProps = {
  classV2: ClassV2;
};

function SectionTimeblocking({
  classV2,
}: SectionTimeblockingCreateAndEditProps) {
  const [currentStep, setCurrentStep] = useState(2);
  const [selectedSection, setSelectedSection] = useState<string | undefined>();
  const [selectedBlock, setSelectedBlock] = useState<DropdownOptionType | null>(
    null
  );
  const [showPopulateConfirmationDialog, setShowPopulateConfirmationDialog] =
    useState(false);

  const [calendarScheduleErrors, setCalendarScheduleErrors] = useState<
    string[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMultiStep, setIsMultistep] = useState(true);

  const navigate = useNavigate();

  const { addToast } = useToastsContext();

  const {
    blockSchedules,
    calendarSchedules,
    districts,
    rotationSchedules,
    sessions,
    sections,
    ui,
  } = useStores();

  const { activeSessionId, activeDistrictId } = ui;

  const blockOptions = classV2.getBlockOptionsForClass;

  useEffect(() => {
    if (classV2.sectionsCount > 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(2);
      // Initialize the selectedSection to the first section
      setIsMultistep(false);
      console.log("classV2.sections", classV2.sections);
      if (!classV2.sections[0]) {
        return;
      }
      invariant(classV2.sections[0], "At least one section must be defined");
      setSelectedSection(classV2.sections[0].id);
    }
  }, [classV2.sectionsCount, classV2.sections]);

  useEffect(() => {
    if (!selectedSection) return;

    // We need to get the section's timeblocks from the calendarSchedules store
    const activeSection = sections.get(selectedSection);

    // To-do: handle case where selectedSection is section-not-created
    if (!activeSection) return;

    const calendarSchedules = activeSection.calendarSchedules;

    console.log(
      "Calendar Schedules",
      calendarSchedules.map((cs) => toJS(cs))
    );

    invariant(
      activeSection.rotationScheduleId,
      "activeSection.rotationScheduleId must be defined"
    );

    // Next we need all the rotation days from the rotationDays store
    const activeRotationSchedule = rotationSchedules.get(
      activeSection.rotationScheduleId
    );

    invariant(activeRotationSchedule, "activeRotationSchedule must be defined");

    const rotationDays = activeRotationSchedule.rotationDays;

    invariant(
      rotationDays,
      "rotationDays must be defined for activeRotationSchedule"
    );

    // Now we loop over the rotationDays to construct the sectionTimeblocks
    const sectionTimeblocks: SectionTimeblockFormEntity[] = [];

    console.log("rotationDays", rotationDays);

    rotationDays.forEach((rotationDay) => {
      // We need to check if there is a calendarSchedule for this rotationDay
      const calendarSchedule = calendarSchedules.find(
        (calendarSchedule) => calendarSchedule.rotationDayId === rotationDay.id
      );

      if (!calendarSchedule) {
        sectionTimeblocks.push({
          id: undefined,
          rotationDayId: rotationDay.id,
          rotationDayName: rotationDay.abbreviation,
          blockId: undefined,
          startTime: undefined,
          endTime: undefined,
          blockDropdownOptions: blockOptions[rotationDay.id],
        });
      } else {
        console.log("calendarSchedule", calendarSchedule);

        sectionTimeblocks.push({
          id: calendarSchedule.id,
          rotationDayId: rotationDay.id,
          rotationDayName: rotationDay.abbreviation,
          blockId: calendarSchedule.blockScheduleId || undefined,
          startTime: calendarSchedule.startTime || undefined,
          endTime: calendarSchedule.endTime || undefined,
          blockDropdownOptions: blockOptions[rotationDay.id],
        });
      }
    });

    console.log("init sectionTimeblocks", sectionTimeblocks);

    dispatchFormAction({
      type: "set_section_timeblocks",
      payload: sectionTimeblocks,
    });
  }, [selectedSection, rotationSchedules, sections, blockOptions]);

  const [state, dispatchFormAction] = useReducer(formReducer, initialState);

  useEffect(() => {
    console.log("State", state);
  }, [state]);

  const populateFromBlock = useCallback(() => {
    if (!selectedBlock || !selectedBlock.value) {
      return;
    }

    const blockSchedulesForSelectedBlock = blockSchedules.getBlockSchedule(
      selectedBlock.value
    );

    console.log(
      "blockSchedulesForSelectedBlock",
      blockSchedulesForSelectedBlock
    );

    //
    const modifiedSectionTimeblocks = state.sectionTimeblocks.map(
      (sectionTimeblock: SectionTimeblockFormEntity) => {
        // Find the blockSchedule for this rotationDay

        const blockSchedule = blockSchedulesForSelectedBlock.find(
          (blockSchedule) =>
            blockSchedule.rotationDayId === sectionTimeblock.rotationDayId
        );

        if (!blockSchedule) {
          return sectionTimeblock;
        }

        console.log("blockSchedule", blockSchedule);

        return {
          ...sectionTimeblock,
          blockId: blockSchedule.id,
          startTime: undefined,
          endTime: undefined,
        };
      }
    );

    dispatchFormAction({
      type: "set_section_timeblocks",
      payload: modifiedSectionTimeblocks,
    });

    setShowPopulateConfirmationDialog(false);
    setSelectedBlock(null);
  }, [selectedBlock, state, blockSchedules]);

  const submitValidationForCalendarSchedules = (): string[] => {
    // We loop over all the calendar schedules and check for errors
    // Here is the schema for the calendar schedule input

    // id?: string;
    // rotationDayId: string;
    // blockId?: string;
    // startTime?: string;
    // endTime?: string;

    // We need to check for the following errors:
    // 1. For each rotation day -
    // Case 1. either both start and end time are filled out
    // Case 2. blockId is filled out
    // all 3 are filled out
    // all 3 are not filled out (we do not use this calendar schedule in the mutation)
    // 2. For each rotation day, if the start & end time are given the start time is before the end time

    let counter = 0;

    // Keep track of the errors
    const errors = [];

    // Loop over all the block schedules
    for (const calendarSchedule of state.sectionTimeblocks) {
      // If the start time is filled out but the end time is not
      if (calendarSchedule.startTime && !calendarSchedule.endTime) {
        errors.push(`Row ${counter + 1}: Please fill out the end time.`);
      }

      // If the end time is filled out but the start time is not
      if (calendarSchedule.endTime && !calendarSchedule.startTime) {
        errors.push(`Row ${counter + 1}: Please fill out the start time.`);
      }

      // If the start time is filled out but the end time is not
      if (calendarSchedule.startTime && calendarSchedule.endTime) {
        // If the start time is after the end time
        // The time is in HH:MM format so must convert to date to compare
        const startTime = new Date(`1970-01-01T${calendarSchedule.startTime}`);
        const endTime = new Date(`1970-01-01T${calendarSchedule.endTime}`);

        if (startTime > endTime) {
          errors.push(
            `Row ${counter + 1}: The start time must be before the end time.`
          );
        }
      }

      counter++;
    }

    setCalendarScheduleErrors(errors);

    return errors;
  };

  type Step = { title: string; subtitle: string };

  const headers: { [step: string]: Step } = {
    step1: {
      title: "Sections",
      subtitle: "",
    },
    step2: {
      title: "Assign Periods/Blocks",
      subtitle: "Select a block or enter a custom time.",
    },
  };

  const renderSteps = () => (
    <ol className="flex items-center space-x-3">
      {Object.values(headers).map((_, index: number) => (
        <li
          className={`h-2 w-16 rounded-sm ${
            index === currentStep - 1 ? "bg-black" : "bg-gray-300"
          }`}
        />
      ))}
    </ol>
  );

  //   Next

  //   Step 1 will be a table of sections with status of scheduling done, and a button to assign periods/blocks (which will take you to step 2)
  const renderStep1 = () => {
    return (
      <div className="hidden sm:block">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Section Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Periods/Blocks Assigned
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Blocks
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Days
                      </th>

                      <th scope="col" className="relative py-3.5 ">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {classV2.sections.map((section) => {
                      return (
                        <tr key={section.id}>
                          <td
                            onClick={() => {
                              setSelectedSection(section.id);
                              setCurrentStep(2);
                            }}
                            className={clsx(
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6",
                              "cursor-pointer hover:underline"
                            )}
                          >
                            {section.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {!section.schedulingDone ? (
                              <XCircleIcon
                                className="h-6 w-6 text-red-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <CheckCircleIcon
                                className="h-6 w-6 text-green-400"
                                aria-hidden="true"
                              />
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {section.blockOccurences
                              ? section.blockOccurences.join(", ")
                              : "-"}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {section.dayOccurences
                              ? section.dayOccurences.join(", ")
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInfoStep2 = () => {
    return (
      <div className="mt-4 rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              You can use the start and end time to override the default block
              time for a rotation day.
            </p>
          </div>
        </div>
      </div>
    );
  };

  //   Step 2 will be the actual form to assign periods/blocks to the section selected in step 1 -> Submitting this form will update the section's periods/blocks
  const renderStep2 = () => {
    if (!selectedSection) return null;

    // We need to get the section's timeblocks from the calendarSchedules store
    const activeSection = sections.get(selectedSection);

    // To-do: handle case where selectedSection is section-not-created
    if (!activeSection) return null;

    invariant(
      activeSection.rotationScheduleId,
      "activeSection.rotationScheduleId must be defined"
    );

    // Next we need all the rotation days from the rotationDays store
    const activeRotationSchedule = rotationSchedules.get(
      activeSection.rotationScheduleId
    );

    invariant(activeRotationSchedule, "activeRotationSchedule must be defined");

    const rotationDays = activeRotationSchedule.rotationDays;

    invariant(
      rotationDays,
      "rotationDays must be defined for activeRotationSchedule"
    );

    const blockDropdownOptions = activeRotationSchedule.blocks.map((block) => ({
      label: block.abbreviation,
      value: block.id,
    }));

    return (
      <div className="flex w-full flex-col">
        <div className="mt-4 flex flex-col">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Auto-schedule from Block
          </h2>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
            Select a block to auto-populate the schedule.
          </p>

          <div className="mt-4 flex items-center">
            <div className="mr-4" style={{ minWidth: 240 }}>
              <Dropdown
                id={"auto-schedule-block"}
                multiSelect={false}
                data={blockDropdownOptions}
                value={selectedBlock}
                onChange={(value) => {
                  setSelectedBlock(value);
                }}
              />
            </div>
            <Button
              type="button"
              theme="primary"
              onClick={() => {
                setShowPopulateConfirmationDialog(true);
              }}
              buttonText="Populate"
              rounded="medium"
              disabled={!selectedBlock}
            />
          </div>
        </div>

        <div className="mt-8">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Schedule
          </h2>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
            Select a block or enter a custom time.
          </p>

          <div className="mt-4">
            <SectionTimeblockingTable
              sectionTimeblocks={state.sectionTimeblocks}
              updateSectionTimeblock={(
                rowIndex: number,
                rowKey: string,
                rowValue: any
              ) => {
                dispatchFormAction({
                  type: "update_section_timeblock",
                  payload: {
                    sectionTimeblockIndex: rowIndex,
                    sectionTimeblockKey: rowKey,
                    sectionTimeblockValue: rowValue,
                  },
                });
              }}
            />
          </div>
          {renderInfoStep2()}
          {/* Render errors */}
          {calendarScheduleErrors.length > 0 && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    There were {calendarScheduleErrors.length} errors with your
                    submission
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      {calendarScheduleErrors.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="col-span-6 flex w-full justify-between py-8"
            aria-hidden="true"
          >
            <div />
            <div className="flex items-center space-x-4">
              {isMultiStep && (
                <Button
                  type="button"
                  theme="secondary"
                  buttonText="Previous"
                  onClick={() => {
                    setSelectedSection(undefined);
                    setCurrentStep(1);
                  }}
                  rounded="medium"
                />
              )}
              <Button
                disabled={isSubmitting}
                onClick={async () => {
                  setIsSubmitting(true);

                  const errors = submitValidationForCalendarSchedules();

                  if (errors.length > 0) {
                    setIsSubmitting(false);
                    return;
                  }

                  const calendarSchedulesInput: CreateCalendarScheduleInput[] =
                    [];

                  // We need to loop over the sectionTimeblocks and create the calendarSchedules
                  state.sectionTimeblocks.forEach(
                    (calendarSchedule: SectionTimeblockFormEntity) => {
                      console.log("Input entity", calendarSchedule);

                      // If the blockId is not defined, we do not create a calendarSchedule
                      if (
                        !calendarSchedule.blockId &&
                        !calendarSchedule.startTime &&
                        !calendarSchedule.endTime
                      ) {
                        return;
                      }

                      calendarSchedulesInput.push({
                        id: calendarSchedule.id,
                        blockSchedule: calendarSchedule.blockId
                          ? {
                              id: calendarSchedule.blockId,
                            }
                          : undefined,
                        rotationDay: {
                          id: calendarSchedule.rotationDayId,
                        },
                        startTime: calendarSchedule.startTime,
                        endTime: calendarSchedule.endTime,
                      });
                    }
                  );

                  console.log("calendarSchedulesInput", calendarSchedulesInput);

                  invariant(
                    ui.activeSessionId,
                    "ui.activeSessionId must be defined"
                  );

                  const activeSession = sessions.getByUrlParam(
                    ui.activeSessionId
                  );

                  invariant(
                    activeSession,
                    "activeSession must be defined for ui.activeSessionId"
                  );

                  try {
                    const response =
                      await calendarSchedules.saveCalendarSchedules(
                        calendarSchedulesInput,
                        activeSession.id,
                        activeSection.id
                      );
                    if (response) {
                      addToast("Blocks/Periods saved successfully.", {
                        type: "success",
                      });
                    } else {
                      addToast("Error saving Blocks/Periods.", {
                        type: "error",
                      });
                    }
                  } catch (e) {
                    addToast("Error saving Blocks/Periods.", {
                      type: "error",
                    });
                  } finally {
                    setIsSubmitting(false);
                  }
                }}
                type="button"
                theme="primary"
                buttonText="Save"
                rounded="medium"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!activeSessionId || !activeDistrictId) {
    return null;
  }

  const district = districts.getByUrlParam(activeDistrictId);

  const session = sessions.getByUrlParam(activeSessionId);

  if (!district || !session) {
    return null;
  }

  return (
    <div className="mt-4 flex flex-col">
      <button
        className="text-slate12 hover:text-slate11 flex items-center font-semibold"
        aria-label="Close"
        // disabled={disabled}
        onClick={() => navigate(sessionRoute(session, "class-schedules"))}
      >
        <ChevronLeftIcon className="mr-1 h-5 w-5 font-bold" />
        Back to Class Schedules
      </button>

      <div
        className={clsx(
          classV2.sectionsCount > 1 && "border-b-2 border-gray-200",
          "mb-8 flex  w-full flex-col items-center space-y-6 py-8"
        )}
      >
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold">
            {headers[`step${currentStep}`].title}
          </h1>
        </div>

        {classV2.sectionsCount > 1 ? renderSteps() : null}
      </div>

      {currentStep === 1 ? renderStep1() : renderStep2()}

      {showPopulateConfirmationDialog && (
        <ConfirmationDialog
          open={true}
          disabled={false}
          title="Auto-populate from Block"
          actionText="Populating from a block will override any existing entries. Are you sure you want to continue?"
          onSubmit={() => {
            populateFromBlock();
          }}
          onClose={() => {
            setShowPopulateConfirmationDialog(false);
          }}
          actionType="confirm"
        />
      )}
    </div>
  );
}

export default observer(SectionTimeblocking);
