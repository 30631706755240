import clsx from "clsx";
import React, { Attributes } from "react";
import { Link, useMatch } from "react-router-dom";

type SidebarTabProps = {
  to: string;
  text: string;
  icon?: React.FC<{ className?: string }> | React.ReactElement;
  iconColor?: string;
  exact?: boolean;
  onClick?: () => void;
};

function SidebarTab({
  to,
  text,
  icon,
  exact,
  iconColor,
  onClick,
}: SidebarTabProps) {
  const isActive = useMatch(to);

  const iconElement = React.isValidElement(icon)
    ? React.cloneElement(icon, { className: "h-[20px] w-[20px]" } as Attributes)
    : icon
    ? React.createElement(icon, { className: "h-[20px] w-[20px]" })
    : null;

  // console.log("onClick", onClick);

  const renderSidebarLinkContent = () => {
    return (
      <div
        className={clsx(
          "group flex items-center rounded-lg px-4 py-4",
          isActive ? "bg-slate4" : " hover:bg-slate3"
        )}
        style={{
          height: "34px",
          marginBottom: 2,
        }}
      >
        {/* Icon wrapper */}
        <div
          className={clsx(
            "pr-[12px]",
            isActive
              ? "text-slate12"
              : iconColor
              ? `text-[${iconColor}] hover:text-slate12`
              : "text-slate11 hover:text-slate12"
          )}
          style={{
            // isActive ? "var(--blue11)" :
            color: iconColor
              ? iconColor
              : isActive
              ? "var(--slate12)"
              : "var(--slate11)",
          }}
        >
          {iconElement}
        </div>
        {/* Text */}
        <div
          className={clsx(
            "truncate text-sm",
            isActive
              ? //? "text-blue11 font-semibold"
                "text-slate12 font-semibold"
              : "text-slate11 hover:text-slate12 font-medium"
          )}
        >
          {text}
        </div>
      </div>
    );
  };

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className="focus-visible:ring-blue7 rounded-lg focus-visible:ring focus-visible:ring-opacity-50"
      >
        {renderSidebarLinkContent()}
      </button>
    );
  }

  return (
    <Link
      to={to}
      className="focus-visible:ring-blue7 rounded-lg focus-visible:ring focus-visible:ring-opacity-50"
    >
      {renderSidebarLinkContent()}
    </Link>
  );
}

export default SidebarTab;
