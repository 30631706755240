import React from "react";
import { useModalContext } from "../../../contexts/modals";
import ButtonLarge from "../../ButtonLarge";

interface Props {
  // Define the props for your component here
  numberOfUsers: string;
  assigning: boolean;
  onSubmit: () => void;
}

const ChangeBulkLicensesModal: React.FC<Props> = (props) => {
  // Add your component logic here
  const { numberOfUsers, assigning, onSubmit } = props;

  const { closeModalAll } = useModalContext();

  return (
    // Add your JSX code here
    <div className="py-3">
      {/* Description */}
      <p className="text-sm text-gray-500">
        {assigning
          ? `Are you sure you want to assign paid licenses to ${numberOfUsers} selected users?`
          : `Are you sure you want to unassign paid licenses to ${numberOfUsers} selected users?`}
      </p>

      {/* Submit button */}
      <div className="mt-6 flex justify-end">
        <ButtonLarge
          type="button"
          theme="constructive"
          buttonText="Submit"
          rounded="medium"
          onClick={() => {
            onSubmit();
            closeModalAll();
          }}
        />
      </div>
    </div>
  );
};

export default ChangeBulkLicensesModal;
