import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import SessionCreateAndEditModal from "../components/CRUD/CreateAndEdit/Session";
import PageLayout from "../components/PageLayout";
import ListOfSessions from "../components/Sessions/ListOfSessions";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Scheduling() {
  const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);

  const { ui, sessions, districts } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId } = ui;

  if (!activeDistrictId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  if (!activeDistrict) return null;

  const districtSessions = sessions.getSessionsForDistrict(activeDistrict.id);

  console.log(districtSessions);

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateSessionModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Sessions</h2>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfSessions
            key={districtSessions.length}
            sessions={districtSessions}
          />
        </div>
      </PageLayout>
      {showCreateSessionModal && (
        <SessionCreateAndEditModal
          onClose={() => setShowCreateSessionModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateSessionModal(false);

            addToast("Session created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Scheduling);
