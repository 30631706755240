// import all the individual exports from icon-imports.js
import { ReactElement } from "react";
import {
  AbacusIcon,
  AboutphoneIcon,
  AccessibilityIcon,
  AcIcon,
  ActivityIcon,
  AddcircleIcon,
  AddrectangleIcon,
  AddtoIcon,
  AgendaIcon,
  AirplaneIcon,
  AirplanemodeIcon,
  AirplanemodeoffIcon,
  AlarmaddIcon,
  AlarmcheckIcon,
  AlarmdeleteIcon,
  AlarmIcon,
  AlarmminusIcon,
  AmbulanceIcon,
  AnnouncementIcon,
  AppsaddIcon,
  AppsIcon,
  AppsremoveIcon,
  Archive01Icon,
  Archive1Icon,
  ArchiveaddIcon,
  ArchiveclearIcon,
  ArchivedoneIcon,
  ArchivedownloadIcon,
  ArchivefavouriteIcon,
  ArchiveIcon,
  ArchiveremoveIcon,
  ArchivesearchIcon,
  ArchiveuploadIcon,
  AssignmentIcon,
  AtmIcon,
  AttachmentIcon,
  AudiobookIcon,
  AutoIcon,
  AutorotationIcon,
  BackpackIcon,
  BadgepercentIcon,
  Bag01Icon,
  BagaddIcon,
  BagcheckIcon,
  BagfavouriteIcon,
  BagIcon,
  BagminusIcon,
  BagremoveIcon,
  BankIcon,
  Barchart01Icon,
  BarchartIcon,
  BarcodeIcon,
  BasketballIcon,
  BatteryalertIcon,
  BatterychargingfullIcon,
  BatterychargingIcon,
  BatteryhorizontalchargingIcon,
  BatteryhorizontalfullIcon,
  BatteryhorizontalIcon,
  BatteryhorizontallowbetIcon,
  BatteryhorizontalsavingmodeIcon,
  BatteryIcon,
  BatterylowbetIcon,
  BatterysavingmodeIcon,
  BellschoolIcon,
  BillIcon,
  BlackboardIcon,
  Blood01Icon,
  BloodIcon,
  BluetoothconnectedIcon,
  BluetoothIcon,
  BluetoothoffIcon,
  Board1Icon,
  BoardIcon,
  Book01Icon,
  Book02Icon,
  Book03Icon,
  Book04Icon,
  Bookadd1Icon,
  BookaddIcon,
  BookcheckIcon,
  Bookdownload1Icon,
  BookdownloadIcon,
  BookfavouriteIcon,
  Bookhelp1Icon,
  BookhelpIcon,
  BookIcon,
  Bookinfo1Icon,
  BookinfoIcon,
  BookmarkIcon,
  Bookminus1Icon,
  BookminusIcon,
  Bookremove1Icon,
  BookremoveIcon,
  Booksearch1Icon,
  BooksearchIcon,
  BooksIcon,
  Bookupload1Icon,
  BookuploadIcon,
  BoxaddIcon,
  BoxarrowdownIcon,
  BoxarrowupIcon,
  BoxcancelIcon,
  BoxdoneIcon,
  BoxlocationIcon,
  BoxloveIcon,
  BoxminusIcon,
  BoxsearchIcon,
  BoxtimeIcon,
  Briefcase01Icon,
  Briefcase02Icon,
  Briefcase03Icon,
  Briefcase04Icon,
  BriefcaseIcon,
  BrightnessIcon,
  BroadcastIcon,
  BrowserIcon,
  BusIcon,
  Calculator1Icon,
  CalculatorIcon,
  Calendar01Icon,
  CalendaraddIcon,
  CalendarcheckIcon,
  CalendardeleteIcon,
  CalendarIcon,
  CalendarminusIcon,
  CallblockIcon,
  CallhospitalIcon,
  CallIcon,
  Callin01Icon,
  CallingIcon,
  CallinIcon,
  CallloveIcon,
  Callout01Icon,
  CalloutIcon,
  CalluserIcon,
  CallvoicemailIcon,
  Camera01Icon,
  CameraIcon,
  CameraoffIcon,
  Camerasilent01Icon,
  CamerasilentIcon,
  CameraswitchIcon,
  Cameravideo01Icon,
  CameravideoIcon,
  CameravideosilentIcon,
  CamIcon,
  CanvasIcon,
  Capsule01Icon,
  CapsuleIcon,
  CardaddIcon,
  CardarrowdownIcon,
  CardarrowleftIcon,
  CardarrowrightIcon,
  CardarrowupIcon,
  CardcheckIcon,
  CardIcon,
  CardiogramIcon,
  CardminusIcon,
  CardremoveIcon,
  CarduserIcon,
  CardwithdrawIcon,
  CargoIcon,
  CargoshipIcon,
  Cart01Icon,
  Cart02Icon,
  CartaddIcon,
  CartarrowdownIcon,
  CartarrowupIcon,
  CartcheckIcon,
  CartesianIcon,
  CartIcon,
  CartminusIcon,
  CartremoveIcon,
  CastIcon,
  CdburningIcon,
  Chartarrowdown01Icon,
  ChartarrowdownIcon,
  ChartarrowdownrectangleIcon,
  Chartarrowup01Icon,
  ChartarrowupIcon,
  ChartarrowuprectangleIcon,
  Chartbar01Icon,
  ChartbarIcon,
  ChartIcon,
  ChartlineIcon,
  ChartnotificationIcon,
  Chartpie01Icon,
  ChartpieIcon,
  Chartwave01Icon,
  ChartwaveIcon,
  ChartwaverectangleIcon,
  Chat01Icon,
  Chat02Icon,
  ChatacceptIcon,
  ChatarrowdownIcon,
  ChatarrowupIcon,
  ChatblockIcon,
  ChatclockIcon,
  ChatcloseIcon,
  ChatfavouriteIcon,
  ChatIcon,
  ChatinformationIcon,
  ChatlockIcon,
  ChatloveIcon,
  ChatnegativeIcon,
  Chatnotification1Icon,
  ChatnotificationIcon,
  ChatplusIcon,
  ChatsadIcon,
  ChatsearchIcon,
  ChatsilentIcon,
  ChatsmileIcon,
  ChattingIcon,
  ChatuserIcon,
  CheckcircleIcon,
  CheckmarkcircleIcon,
  CheckmarkrectangleIcon,
  CheckrectangleIcon,
  Chemistry01Icon,
  Chemistry02Icon,
  ChemistryIcon,
  ClapperboardIcon,
  ClearcircleIcon,
  ClearrectangleIcon,
  ClipboardactivityIcon,
  ClipboardIcon,
  Clockcircle1Icon,
  // ClockcircleIcon,
  Clockrectangle1Icon,
  ClockrectangleIcon,
  CloudflashIcon,
  CloudflashrainIcon,
  CloudhalfmoonflashIcon,
  CloudhalfmoonflashrainIcon,
  CloudhalfmoonIcon,
  CloudhalfmoonrainhighIcon,
  CloudhalfmoonrainlowIcon,
  CloudhalfmoonrainmidIcon,
  CloudhalfmoonsnowIcon,
  CloudhalfmoonwindrainIcon,
  CloudIcon,
  CloudmoonflashIcon,
  CloudmoonflashrainIcon,
  CloudmoonIcon,
  CloudmoonrainhighIcon,
  CloudmoonrainlowIcon,
  CloudmoonrainmidIcon,
  CloudmoonsnowIcon,
  CloudmoonwindrainIcon,
  CloudrainhighIcon,
  CloudrainlowIcon,
  CloudrainmidIcon,
  CloudsIcon,
  CloudsnowIcon,
  CloudsunflashIcon,
  CloudsunflashrainIcon,
  CloudsunIcon,
  CloudsunrainhighIcon,
  CloudsunrainlowIcon,
  CloudsunrainmidIcon,
  CloudsunsnowIcon,
  CloudsunwindrainIcon,
  CloudwindIcon,
  CloudwindrainIcon,
  CoindollarIcon,
  CoineuroIcon,
  CoinIcon,
  CoinpoundIcon,
  CoinyenIcon,
  CollectionIcon,
  ColorinversionIcon,
  CompasIcon,
  CompassIcon,
  ComputerIcon,
  CopycheckIcon,
  CopyIcon,
  CpuIcon,
  CubeIcon,
  CubestackIcon,
  DarkmodeIcon,
  DealIcon,
  DeliveryairplaneIcon,
  DeliverycancelIcon,
  DeliveryconveyorIcon,
  DeliverydoneIcon,
  DeliveryIcon,
  DeliveryparachuteIcon,
  DeliverytimeIcon,
  DepositIcon,
  DeskIcon,
  Destination01Icon,
  Destination02Icon,
  Destination03Icon,
  DestinationIcon,
  DevicesIcon,
  DiamondIcon,
  DigitalhealthIcon,
  Diploma1Icon,
  DiplomaIcon,
  DiskIcon,
  DislikeIcon,
  DividecircleIcon,
  DividerectangleIcon,
  DnaIcon,
  DollarIcon,
  DonatecoinIcon,
  DonateIcon,
  DonationIcon,
  DonotdisturbIcon,
  DoorIcon,
  EarningsIcon,
  EarphoneIcon,
  EditIcon,
  EditrectangleIcon,
  ElearningIcon,
  EqualitycircleIcon,
  EqualityrectangleIcon,
  Equalizer01Icon,
  EqualizerIcon,
  EuroIcon,
  ExamIcon,
  Eye01Icon,
  EyedisableIcon,
  EyedropperIcon,
  EyeIcon,
  EyescannerIcon,
  FastdeliveryIcon,
  FasttimeIcon,
  FavouriteIcon,
  FeatherIcon,
  FeedrectangleIcon,
  File01Icon,
  File02Icon,
  File03Icon,
  Fileaccept01Icon,
  FileacceptIcon,
  Fileadd01Icon,
  FileaddIcon,
  FiledocIcon,
  Filedownload01Icon,
  FiledownloadIcon,
  Filefavourite01Icon,
  FilefavouriteIcon,
  FileIcon,
  FilejpgIcon,
  Fileminus01Icon,
  FileminusIcon,
  FilemusicIcon,
  FilepdfIcon,
  FilepngIcon,
  FilereloadIcon,
  Fileremove01Icon,
  FileremoveIcon,
  Files01Icon,
  Filesearch01Icon,
  FilesearchIcon,
  FilesharingIcon,
  FilesIcon,
  Fileupload01Icon,
  FileuploadIcon,
  FilevideoIcon,
  Film01Icon,
  Film02Icon,
  Film03Icon,
  FilmIcon,
  Filter01Icon,
  Filter02Icon,
  Filter03Icon,
  Filter04Icon,
  Filter05Icon,
  Filter06Icon,
  Filter07Icon,
  FilterIcon,
  FingerprintIcon,
  Flag01Icon,
  // FlagIcon,
  FlashautoIcon,
  FlashIcon,
  FlashlightIcon,
  FlashoffIcon,
  FlaskIcon,
  FloppydiskIcon,
  FocusIcon,
  Folder01Icon,
  Folder02Icon,
  Folder03Icon,
  Folderaccept01Icon,
  FolderacceptIcon,
  Folderadd01Icon,
  FolderaddIcon,
  Folderdownload01Icon,
  FolderdownloadIcon,
  Folderfavourite01Icon,
  FolderfavouriteIcon,
  FolderIcon,
  Folderminus01Icon,
  FolderminusIcon,
  FolderreloadIcon,
  Folderremove01Icon,
  FolderremoveIcon,
  Foldersearch01Icon,
  FoldersearchIcon,
  FoldershareIcon,
  Folderupload01Icon,
  FolderuploadIcon,
  FountainpenIcon,
  GiftIcon,
  GivebloodIcon,
  GivepillIcon,
  GlassbrokenIcon,
  GlassesIcon,
  GlobeIcon,
  GoldIcon,
  Gps01Icon,
  GpsIcon,
  GpsoffIcon,
  GraduationcapIcon,
  GraphiceqIcon,
  HalfmoonIcon,
  HalfmoonphaseIcon,
  HangerIcon,
  HartarrowdownIcon,
  HartarrowupIcon,
  HarthistogramIcon,
  Headphones1Icon,
  HeadphonesIcon,
  HeadphonessilentIcon,
  HealthcareIcon,
  // HeartIcon,
  Help01Icon,
  Help02Icon,
  HelpIcon,
  HelppolygonIcon,
  Home01Icon,
  Home02Icon,
  Home03Icon,
  Home04Icon,
  Homeadd01Icon,
  HomeaddIcon,
  Homearrowdown01Icon,
  HomearrowdownIcon,
  Homearrowup01Icon,
  Homecelcius01Icon,
  HomecelciusIcon,
  Homecheck01Icon,
  HomecheckIcon,
  Homecloud01Icon,
  HomecloudIcon,
  Homedownload01Icon,
  HomedownloadIcon,
  Homeeye01Icon,
  HomeeyeIcon,
  Homefavourite01Icon,
  HomefavouriteIcon,
  Homefilter01Icon,
  HomefilterIcon,
  Homeflash01Icon,
  HomeflashIcon,
  Homefluid01Icon,
  HomefluidIcon,
  Homegraph01Icon,
  HomegraphIcon,
  HomeIcon,
  Homeinformation01Icon,
  HomeinformationIcon,
  Homelink01Icon,
  HomelinkIcon,
  Homelocation01Icon,
  HomelocationIcon,
  Homelock01Icon,
  HomelockIcon,
  Homemail01Icon,
  HomemailIcon,
  Homeminus01Icon,
  HomeminusIcon,
  Homenotification01Icon,
  HomenotificationIcon,
  Homepower01Icon,
  HomepowerIcon,
  Homeremove01Icon,
  HomeremoveIcon,
  Homesetting01Icon,
  HomesettingIcon,
  Homeshare01Icon,
  HomeshareIcon,
  Homeshield01Icon,
  HomeshieldIcon,
  Homeupload01Icon,
  HomeuploadIcon,
  Homewarning01Icon,
  HomewarningIcon,
  Homewave01Icon,
  Homewave1Icon,
  HomewaveIcon,
  Homewifi01Icon,
  HomewifiIcon,
  // HoneyIcon,
  Hospital01Icon,
  HospitalbedIcon,
  HospitalIcon,
  HotspotIcon,
  HotspotoffIcon,
  HourglassendIcon,
  HourglassIcon,
  HourglassstartIcon,
  HumidityIcon,
  IdcardIcon,
  IdeaIcon,
  Image01Icon,
  ImageaddIcon,
  ImagecircleIcon,
  ImagedownloadIcon,
  ImageeditIcon,
  ImagefavouriteIcon,
  ImageIcon,
  ImageminusIcon,
  ImagerectangleIcon,
  ImageremoveIcon,
  ImagetickIcon,
  ImageuploadIcon,
  InformationcircleIcon,
  InformationIcon,
  InformationpolygonIcon,
  InformationrectangleIcon,
  InjectionIcon,
  InteractiveIcon,
  Invoice01Icon,
  InvoiceIcon,
  IpodIcon,
  LaptopIcon,
  LastquartermoonIcon,
  LayersIcon,
  LibraryIcon,
  LifebuoyIcon,
  LightmodeIcon,
  LikeIcon,
  Linechart01Icon,
  Linechart02Icon,
  LinechartIcon,
  ListviewcircleIcon,
  ListviewrectangleIcon,
  Loading01Icon,
  LoadingIcon,
  Location01Icon,
  Location02Icon,
  Location03Icon,
  LocationaddIcon,
  LocationhospitalIcon,
  LocationIcon,
  LocationminusIcon,
  LocationremoveIcon,
  LocationshareIcon,
  LocationuserIcon,
  Lock01Icon,
  LockersIcon,
  LockIcon,
  Login01Icon,
  LoginIcon,
  Logout01Icon,
  LogoutIcon,
  LoudspeakerIcon,
  LoveIcon,
  MailarrowdownIcon,
  MailarrowleftIcon,
  MailarrowrightIcon,
  MailarrowupIcon,
  MailblockIcon,
  MailboxIcon,
  MaildelayIcon,
  MailfailIcon,
  MailfastIcon,
  MailfavouriteIcon,
  MailIcon,
  MailnegativeIcon,
  MailnotificationIcon,
  MailplusIcon,
  MailsilentIcon,
  Map01Icon,
  Map02Icon,
  Map03Icon,
  Mapgpsnavigation01Icon,
  Mapgpsnavigation03Icon,
  Mapgpsnavigation04Icon,
  MapgpsnavigationIcon,
  MapIcon,
  Maplocation01Icon,
  Maplocation02Icon,
  Maplocation03Icon,
  Maplocation04Icon,
  Maplocation05Icon,
  Maplocation06Icon,
  Maplocation07Icon,
  MaplocationIcon,
  Mappeople01Icon,
  MappeopleIcon,
  Mappin01Icon,
  Mappin02Icon,
  Mappin03Icon,
  MappinIcon,
  MaskIcon,
  MastercardIcon,
  MathsIcon,
  MatrixIcon,
  Medal1Icon,
  MedalIcon,
  MedicalbookIcon,
  MedicalinformationIcon,
  MedicalserviceIcon,
  MicIcon,
  MicmuteIcon,
  MicrophoneIcon,
  MicrophonemuteIcon,
  MicroscopeIcon,
  Minus01Icon,
  MinuscircleIcon,
  MinusIcon,
  MinusrectangleIcon,
  MobiledataIcon,
  MobiledataoffIcon,
  MobileIcon,
  MobilepaymentIcon,
  Money01Icon,
  Money02Icon,
  MoneyaddIcon,
  MoneyarrowdownIcon,
  MoneyarrowleftIcon,
  MoneyarrowrightIcon,
  MoneyarrowupIcon,
  Moneybagcoin1Icon,
  MoneybagcoinIcon,
  MoneybagdollarIcon,
  MoneybageuroIcon,
  MoneybagIcon,
  MoneybagpoundIcon,
  MoneybagyenIcon,
  MoneycheckIcon,
  MoneycoinIcon,
  MoneydollarIcon,
  MoneyeuroIcon,
  MoneyfavouriteIcon,
  MoneyIcon,
  MoneyincomeIcon,
  MoneyminusIcon,
  MoneypoundIcon,
  Moneyprofit01Icon,
  MoneyprofitIcon,
  MoneyremoveIcon,
  MoneyuserIcon,
  MoneywithdrawIcon,
  MoneyyenIcon,
  MonitorIcon,
  MorehorizontalIcon,
  MoreverticalIcon,
  MouseIcon,
  MultiplycircleIcon,
  MultiplyrectangleIcon,
  Music01Icon,
  Music02Icon,
  Music03Icon,
  Music04Icon,
  Music05Icon,
  MusicIcon,
  MusicoffIcon,
  NavigationIcon,
  Next01Icon,
  NextIcon,
  NibIcon,
  NightmodeIcon,
  Notebook1Icon,
  NotebookIcon,
  NotepadIcon,
  Notes01Icon,
  NotesaddIcon,
  NotescheckIcon,
  NotesdeleteIcon,
  NotesdownloadIcon,
  Notesedit01Icon,
  NoteseditaddIcon,
  NoteseditcheckIcon,
  NoteseditdeleteIcon,
  NoteseditdownloadIcon,
  NoteseditfavouriteIcon,
  NoteseditIcon,
  NoteseditminusIcon,
  NoteseditsearchIcon,
  NotesedituploadIcon,
  NotesfavouriteIcon,
  NotesIcon,
  NotesremoveIcon,
  NotessearchIcon,
  NotesuploadIcon,
  Notification01Icon,
  Notification02Icon,
  Notification03Icon,
  Notification04Icon,
  Notification05Icon,
  NotificationcircleIcon,
  NotificationIcon,
  NotificationoffIcon,
  NotificationrectangleIcon,
  Notificationringing1Icon,
  NotificationringingIcon,
  Notificationsilent1Icon,
  NotificationsilentIcon,
  OnlinelearningIcon,
  OpenbookIcon,
  Openbox01Icon,
  OpenboxaddIcon,
  OpenboxarrowdownIcon,
  OpenboxarrowupIcon,
  OpenboxdoneIcon,
  OpenboxIcon,
  OpenboxloveIcon,
  OpenboxminusIcon,
  OpenboxremoveIcon,
  OpenboxsearchIcon,
  Package01Icon,
  Package02Icon,
  Packagebox01Icon,
  Packagebox02Icon,
  Packagebox03Icon,
  Packagebox04Icon,
  Packagebox05Icon,
  Packagebox06Icon,
  Packagebox07Icon,
  PackageboxIcon,
  PackageIcon,
  PackagingaddIcon,
  PackagingarrowdownIcon,
  PackagingarrowupIcon,
  PackagingcancelIcon,
  PackagingdoneIcon,
  PackagingIcon,
  PackaginglocationIcon,
  PackagingloveIcon,
  PackagingminusIcon,
  PackagingsearchIcon,
  PackagingtimeIcon,
  PaintboardIcon,
  PaintbucketIcon,
  ParcelIcon,
  PauseIcon,
  PencilbookIcon,
  PencilIcon,
  PencilpaperIcon,
  PenIcon,
  People01Icon,
  PeopleIcon,
  PhonelockIcon,
  PhysicsIcon,
  PiechartIcon,
  Pills01Icon,
  PillsIcon,
  PilltabletIcon,
  Pin01Icon,
  Pin02Icon,
  PinIcon,
  PlasterIcon,
  PlayaddIcon,
  PlayfavouriteIcon,
  PlayIcon,
  Playlist01Icon,
  PlaylistIcon,
  Playremove01Icon,
  PlayremoveIcon,
  PluginIcon,
  Plus01Icon,
  PluscircleIcon,
  PlusIcon,
  PlusrectangleIcon,
  PoundIcon,
  PowercircleIcon,
  PowerIcon,
  PowerrectangleIcon,
  PresentationchartIcon,
  PresentationIcon,
  PressIcon,
  Previous01Icon,
  PreviousIcon,
  Profit01Icon,
  Profit02Icon,
  ProfitIcon,
  ProjectorIcon,
  Protractor1Icon,
  ProtractorIcon,
  PyramidIcon,
  QrscanIcon,
  QueueIcon,
  QuizIcon,
  RankIcon,
  ReceiptIcon,
  RecordIcon,
  RemoteIcon,
  Remove01Icon,
  Remove02Icon,
  RemovecircleIcon,
  RemoveIcon,
  RemoverectangleIcon,
  RepeatIcon,
  ReplayIcon,
  ReplyrectangleIcon,
  ReportIcon,
  RestartIcon,
  Return01Icon,
  ReturnIcon,
  RewardIcon,
  Road01Icon,
  RoadIcon,
  Roadlocation01Icon,
  RoadlocationIcon,
  Roadpin01Icon,
  RoadpinIcon,
  RotatelockIcon,
  RulerIcon,
  SadcircleIcon,
  SadrectangleIcon,
  Safebox01Icon,
  SafeboxIcon,
  SchoolIcon,
  ScreenrotateIcon,
  ScreenshotIcon,
  SdstorageIcon,
  Search01Icon,
  Search02Icon,
  Search03Icon,
  SearchminusIcon,
  SearchplusIcon,
  SearchremoveIcon,
  SecurityIcon,
  SendIcon,
  SensorIcon,
  SentfastIcon,
  SettingIcon,
  ShareIcon,
  SharerectangleIcon,
  ShieldcheckIcon,
  ShieldIcon,
  ShieldwarningIcon,
  ShippingIcon,
  ShoppingbagaddIcon,
  ShoppingbagcheckIcon,
  ShoppingbagIcon,
  ShoppingbagloveIcon,
  ShoppingbagminusIcon,
  ShoppingbagremoveIcon,
  Shoppingbasket01Icon,
  Shoppingbasket02Icon,
  Shoppingbasket03Icon,
  ShoppingbasketaddIcon,
  ShoppingbasketarrowdownIcon,
  ShoppingbasketarrowoutIcon,
  ShoppingbasketcheckIcon,
  ShoppingbasketIcon,
  ShoppingbasketloveIcon,
  ShoppingbasketminusIcon,
  ShoppingbasketremoveIcon,
  ShuffleIcon,
  ShutdownIcon,
  SignalcellulerbadIcon,
  SignalcellulerIcon,
  Signalcellulernointernet1Icon,
  SignalcellulernointernetIcon,
  SignalcelluleroffIcon,
  SignalIcon,
  Signpost01Icon,
  Signpost02Icon,
  Signpost03Icon,
  Signpost04Icon,
  SignpostIcon,
  SimcardIcon,
  SmartkeyIcon,
  SmartlightIcon,
  SmartlockIcon,
  SmartphoneIcon,
  SmartphonelearningIcon,
  SmartplugIcon,
  SmarttvIcon,
  SmartwatchIcon,
  // SmileellipseIcon,
  SmilerectangleIcon,
  SnowIcon,
  SolarpanelIcon,
  Song01Icon,
  SongIcon,
  Sortarrowdown01Icon,
  SortarrowdownIcon,
  Sortarrowup01Icon,
  SortarrowupIcon,
  SortcircleIcon,
  SortrectangleIcon,
  SpeakerdisableIcon,
  SpeakerIcon,
  SpeakerminusIcon,
  SpeakermuteIcon,
  SpeakerplusIcon,
  SpeedtestIcon,
  SquarerootIcon,
  StandbymodeIcon,
  StarIcon,
  StationeryIcon,
  StethoscopeIcon,
  StickerIcon,
  StopIcon,
  StopwatchaddIcon,
  StopwatchcheckIcon,
  StopwatchdeleteIcon,
  StopwatchfastIcon,
  StopwatchIcon,
  StopwatchminusIcon,
  StorageIcon,
  Store01Icon,
  Store02Icon,
  Store03Icon,
  StoreIcon,
  StudentcardIcon,
  StudentIcon,
  StudentsIcon,
  SunriseIcon,
  SunsetIcon,
  SwitchIcon,
  SyrupIcon,
  TablelampIcon,
  TabletIcon,
  Tag01Icon,
  TagIcon,
  TargetIcon,
  Task01Icon,
  TaskaddIcon,
  TaskcheckIcon,
  TaskdeleteIcon,
  TaskdownloadIcon,
  TaskfavouriteIcon,
  TaskIcon,
  TaskminusIcon,
  TasksearchIcon,
  TaskuploadIcon,
  Teacher01Icon,
  TeacherIcon,
  TelescopeIcon,
  TemperaturecoldIcon,
  TemperatureminusIcon,
  TemperatureplusIcon,
  Testtube1Icon,
  TesttubeIcon,
  ThermometerIcon,
  ThermostatIcon,
  TickdoubleIcon,
  TicketIcon,
  TicketpercentIcon,
  TickIcon,
  Time10Icon,
  Time24Icon,
  Time60Icon,
  TimeaddIcon,
  TimecheckIcon,
  TimedeleteIcon,
  TimefastIcon,
  TimeforwardIcon,
  TimehalfpastIcon,
  TimeminusIcon,
  TimeoclockIcon,
  TimequarterIcon,
  TimequarterpastIcon,
  ToothIcon,
  TornadoIcon,
  TrainingIcon,
  TransferIcon,
  TransformIcon,
  TransfusionIcon,
  Trash01Icon,
  TrashIcon,
  Trolley01Icon,
  TrolleyIcon,
  TrophyIcon,
  Turn01Icon,
  TurnIcon,
  TurnleftIcon,
  TurnrightIcon,
  UmbrellaIcon,
  Unlock01Icon,
  UnlockIcon,
  UsbIcon,
  UsercircleIcon,
  UserIcon,
  UserrectangleIcon,
  Uturn01Icon,
  UturnIcon,
  VibratemodeIcon,
  VideoaddIcon,
  VideocallIcon,
  VideofavouriteIcon,
  VideoIcon,
  VideominusIcon,
  VideooffIcon,
  VideoremoveIcon,
  VideotickIcon,
  ViewIcon,
  ViewoffIcon,
  ViewprivacyIcon,
  VoicemailIcon,
  VoucherIcon,
  Vynil01Icon,
  VynilIcon,
  Wallet01Icon,
  Walletadd01Icon,
  WalletaddIcon,
  Walletarrowdown01Icon,
  WalletarrowdownIcon,
  Walletarrowleft01Icon,
  WalletarrowleftIcon,
  Walletarrowright01Icon,
  WalletarrowrightIcon,
  Walletarrowup01Icon,
  WalletarrowupIcon,
  Walletcheck01Icon,
  WalletcheckIcon,
  WalletIcon,
  Walletminus01Icon,
  WalletminusIcon,
  Walletremove01Icon,
  WalletremoveIcon,
  Walletuser01Icon,
  WalletuserIcon,
  WarehouseIcon,
  WarningerrorIcon,
  WarningIcon,
  WarningpolygonIcon,
  Watch01Icon,
  WatchIcon,
  WaveIcon,
  WavesIcon,
  Wheelchair01Icon,
  WheelchairIcon,
  WhistleIcon,
  WidgetsIcon,
  WifiIcon,
  WifioffIcon,
  WifisignalbadIcon,
  WifisignalIcon,
  WifisignalnointernetIcon,
  WifisignaloffIcon,
  WindhighIcon,
  WindlowIcon,
  WindmidIcon,
  WirelesschargingIcon,
  WithdrawIcon,
  YenIcon,
  ZoomIcon,
  ZoominIcon,
  ZoomoutIcon,
  ZoomtextIcon,
} from "./icon-imports";

export type IconType = {
  group: string;
  name: string;
  component: React.FC<React.SVGProps<SVGSVGElement>>;
  keywords?: string[];
};

export const IconsList: IconType[] = [
  {
    group: "education",
    name: "abacus",
    component: AbacusIcon,
    keywords: ["mathematics", "calculation", "counting"],
  },
  {
    group: "education",
    name: "announcement",
    component: AnnouncementIcon,
    keywords: ["notification", "alert"],
  },
  {
    group: "education",
    name: "archive",
    component: ArchiveIcon,
    keywords: ["storage", "organization", "files"],
  },
  {
    group: "education",
    name: "assignment",
    component: AssignmentIcon,
    keywords: ["homework", "task", "work"],
  },
  {
    group: "education",
    name: "audiobook",
    component: AudiobookIcon,
    keywords: ["audio", "book", "listen"],
  },
  {
    group: "education",
    name: "backpack",
    component: BackpackIcon,
    keywords: ["bag", "school", "carry"],
  },
  {
    group: "education",
    name: "basketball",
    component: BasketballIcon,
    keywords: ["sport", "ball", "game"],
  },
  {
    group: "education",
    name: "bellschool",
    component: BellschoolIcon,
    keywords: ["bell", "school", "notification"],
  },
  {
    group: "education",
    name: "blackboard",
    component: BlackboardIcon,
    keywords: ["chalkboard", "teaching", "learning"],
  },
  {
    group: "education",
    name: "board1",
    component: Board1Icon,
    keywords: ["whiteboard", "writing", "drawing"],
  },
  {
    group: "education",
    name: "board",
    component: BoardIcon,
    keywords: ["chalkboard", "teaching", "learning"],
  },
  {
    group: "education",
    name: "book01",
    component: Book01Icon,
    keywords: ["book", "cover", "reading"],
  },
  {
    group: "education",
    name: "book02",
    component: Book02Icon,
    keywords: ["book", "cover", "reading"],
  },
  {
    group: "education",
    name: "book03",
    component: Book03Icon,
    keywords: ["book", "cover", "reading"],
  },
  {
    group: "education",
    name: "book04",
    component: Book04Icon,
    keywords: ["book", "cover", "reading"],
  },
  {
    group: "education",
    name: "bookadd1",
    component: Bookadd1Icon,
    keywords: ["book", "add", "reading"],
  },
  {
    group: "education",
    name: "bookadd",
    component: BookaddIcon,
    keywords: ["book", "add", "reading"],
  },
  {
    group: "education",
    name: "bookcheck",
    component: BookcheckIcon,
    keywords: ["book", "checkmark", "reading"],
  },
  {
    group: "education",
    name: "bookdownload1",
    component: Bookdownload1Icon,
    keywords: ["book", "download", "education"],
  },
  {
    group: "education",
    name: "bookdownload",
    component: BookdownloadIcon,
    keywords: ["book", "download", "education"],
  },
  {
    group: "education",
    name: "bookfavourite",
    component: BookfavouriteIcon,
    keywords: ["book", "favorite", "education"],
  },
  {
    group: "education",
    name: "bookhelp1",
    component: Bookhelp1Icon,
    keywords: ["book", "help", "education"],
  },
  {
    group: "education",
    name: "bookhelp",
    component: BookhelpIcon,
    keywords: ["book", "help", "education"],
  },
  {
    group: "education",
    name: "bookinfo1",
    component: Bookinfo1Icon,
    keywords: ["book", "info", "education"],
  },
  {
    group: "education",
    name: "bookinfo",
    component: BookinfoIcon,
    keywords: ["book", "info", "education"],
  },
  {
    group: "education",
    name: "bookminus1",
    component: Bookminus1Icon,
    keywords: ["book", "minus", "education"],
  },
  {
    group: "education",
    name: "bookminus",
    component: BookminusIcon,
    keywords: ["book", "minus", "education"],
  },
  {
    group: "education",
    name: "bookremove1",
    component: Bookremove1Icon,
    keywords: ["book", "remove", "education"],
  },
  {
    group: "education",
    name: "bookremove",
    component: BookremoveIcon,
    keywords: ["book", "remove", "education"],
  },
  {
    group: "education",
    name: "booksearch1",
    component: Booksearch1Icon,
    keywords: ["book", "search", "education"],
  },
  {
    group: "education",
    name: "booksearch",
    component: BooksearchIcon,
    keywords: ["book", "search", "education"],
  },
  {
    group: "education",
    name: "bookupload1",
    component: Bookupload1Icon,
    keywords: ["book", "upload", "education"],
  },
  {
    group: "education",
    name: "bookupload",
    component: BookuploadIcon,
    keywords: ["book", "upload", "education"],
  },
  {
    group: "education",
    name: "book",
    component: BookIcon,
    keywords: ["book", "education"],
  },
  {
    group: "education",
    name: "books",
    component: BooksIcon,
    keywords: ["books", "education"],
  },
  {
    group: "education",
    name: "browser",
    component: BrowserIcon,
    keywords: ["browser", "education"],
  },
  {
    group: "education",
    name: "bus",
    component: BusIcon,
    keywords: ["bus", "education"],
  },
  {
    group: "education",
    name: "calculator1",
    component: Calculator1Icon,
    keywords: ["calculator", "education"],
  },
  {
    group: "education",
    name: "calculator",
    component: CalculatorIcon,
    keywords: ["calculator", "education"],
  },
  {
    group: "education",
    name: "calendar",
    component: CalendarIcon,
    keywords: ["education", "calendar", "date", "time", "event"],
  },
  {
    group: "education",
    name: "canvas",
    component: CanvasIcon,
    keywords: ["education", "canvas", "drawing", "art"],
  },
  {
    group: "education",
    name: "cartesian",
    component: CartesianIcon,
    keywords: ["education", "cartesian", "graph", "plot"],
  },
  {
    group: "education",
    name: "chart",
    component: ChartIcon,
    keywords: ["education", "chart", "graph", "data"],
  },
  {
    group: "education",
    name: "chemistry01",
    component: Chemistry01Icon,
    keywords: ["education", "chemistry", "science", "molecule"],
  },
  {
    group: "education",
    name: "chemistry02",
    component: Chemistry02Icon,
    keywords: ["education", "chemistry", "science", "molecule"],
  },
  {
    group: "education",
    name: "chemistry",
    component: ChemistryIcon,
    keywords: ["education", "chemistry", "science", "molecule"],
  },
  {
    group: "education",
    name: "clipboard",
    component: ClipboardIcon,
    keywords: ["education", "clipboard", "copy", "paste"],
  },
  {
    group: "education",
    name: "compass",
    component: CompassIcon,
    keywords: ["education", "compass", "direction", "navigation"],
  },
  {
    group: "education",
    name: "cubestack",
    component: CubestackIcon,
    keywords: ["education", "cube", "stack", "3D"],
  },
  {
    group: "education",
    name: "desk",
    component: DeskIcon,
    keywords: ["education", "desk", "work", "office"],
  },
  {
    group: "education",
    name: "diploma1",
    component: Diploma1Icon,
    keywords: ["education", "diploma", "certificate", "graduation"],
  },
  {
    group: "education",
    name: "diploma",
    component: DiplomaIcon,
    keywords: ["education", "diploma", "certificate", "graduation"],
  },
  {
    group: "education",
    name: "dividecircle",
    component: DividecircleIcon,
    keywords: ["education", "divide", "circle", "math"],
  },
  {
    group: "education",
    name: "dividerectangle",
    component: DividerectangleIcon,
    keywords: ["education", "divide", "rectangle", "math"],
  },
  {
    group: "education",
    name: "dna",
    component: DnaIcon,
    keywords: ["education", "dna", "biology", "science"],
  },
  {
    group: "education",
    name: "elearning",
    component: ElearningIcon,
    keywords: ["education", "e-learning", "online", "internet"],
  },
  {
    group: "education",
    name: "equalitycircle",
    component: EqualitycircleIcon,
    keywords: ["education", "equality", "circle", "math"],
  },
  {
    group: "education",
    name: "equalityrectangle",
    component: EqualityrectangleIcon,
    keywords: ["education", "equality", "rectangle", "math"],
  },
  {
    group: "education",
    name: "exam",
    component: ExamIcon,
    keywords: ["test", "exam", "education"],
  },
  {
    group: "education",
    name: "files",
    component: FilesIcon,
    keywords: ["documents", "files", "education"],
  },
  {
    group: "education",
    name: "flask",
    component: FlaskIcon,
    keywords: ["chemistry", "lab", "education"],
  },
  {
    group: "education",
    name: "fountainpen",
    component: FountainpenIcon,
    keywords: ["writing", "fountain pen", "education"],
  },
  {
    group: "education",
    name: "glasses",
    component: GlassesIcon,
    keywords: ["reading", "education"],
  },
  {
    group: "education",
    name: "globe",
    component: GlobeIcon,
    keywords: ["world", "education"],
  },
  {
    group: "education",
    name: "graduationcap",
    component: GraduationcapIcon,
    keywords: ["graduation", "education"],
  },
  {
    group: "education",
    name: "help01",
    component: Help01Icon,
    keywords: ["help", "education"],
  },
  {
    group: "education",
    name: "help02",
    component: Help02Icon,
    keywords: ["help", "education"],
  },
  {
    group: "education",
    name: "help",
    component: HelpIcon,
    keywords: ["help", "education"],
  },
  {
    group: "education",
    name: "idea",
    component: IdeaIcon,
    keywords: ["lightbulb", "idea", "education"],
  },
  {
    group: "education",
    name: "library",
    component: LibraryIcon,
    keywords: ["books", "library", "education"],
  },
  {
    group: "education",
    name: "lockers",
    component: LockersIcon,
    keywords: ["lockers", "education"],
  },
  {
    group: "education",
    name: "maths",
    component: MathsIcon,
    keywords: ["math", "education"],
  },
  {
    group: "education",
    name: "matrix",
    component: MatrixIcon,
    keywords: ["matrix", "education"],
  },
  {
    group: "education",
    name: "medal1",
    component: Medal1Icon,
    keywords: ["medal", "award", "education"],
  },
  {
    group: "education",
    name: "medal",
    component: MedalIcon,
    keywords: ["medal", "award", "education"],
  },
  {
    group: "education",
    name: "microscope",
    component: MicroscopeIcon,
    keywords: ["microscope", "science", "education"],
  },
  {
    group: "education",
    name: "minuscircle",
    component: MinuscircleIcon,
    keywords: ["minus", "circle", "education"],
  },
  {
    group: "education",
    name: "minusrectangle",
    component: MinusrectangleIcon,
    keywords: ["minus", "rectangle", "education"],
  },
  {
    group: "education",
    name: "monitor",
    component: MonitorIcon,
    keywords: ["computer", "monitor", "education"],
  },
  {
    group: "education",
    name: "multiplycircle",
    component: MultiplycircleIcon,
    keywords: ["multiply", "circle", "education"],
  },
  {
    group: "education",
    name: "multiplyrectangle",
    component: MultiplyrectangleIcon,
    keywords: ["multiply", "rectangle", "education"],
  },
  {
    group: "education",
    name: "notebook1",
    component: Notebook1Icon,
    keywords: ["notebook", "education"],
  },
  {
    group: "education",
    name: "notebook",
    component: NotebookIcon,
    keywords: ["notebook", "education"],
  },
  {
    group: "education",
    name: "onlinelearning",
    component: OnlinelearningIcon,
    keywords: ["online", "learning", "education"],
  },
  {
    group: "education",
    name: "paintboard",
    component: PaintboardIcon,
    keywords: ["paint", "board", "education"],
  },
  {
    group: "education",
    name: "pen",
    component: PenIcon,
    keywords: ["pen", "education"],
  },
  {
    group: "education",
    name: "pencilbook",
    component: PencilbookIcon,
    keywords: ["pencil", "book", "education"],
  },
  {
    group: "education",
    name: "pencilpaper",
    component: PencilpaperIcon,
    keywords: ["pencil", "paper", "education"],
  },
  {
    group: "education",
    name: "pencil",
    component: PencilIcon,
    keywords: ["pencil", "education"],
  },
  {
    group: "education",
    name: "physics",
    component: PhysicsIcon,
    keywords: ["physics", "education"],
  },
  {
    group: "education",
    name: "pluscircle",
    component: PluscircleIcon,
    keywords: ["plus", "circle", "education"],
  },
  {
    group: "education",
    name: "plusrectangle",
    component: PlusrectangleIcon,
    keywords: ["plus", "rectangle", "education"],
  },
  {
    group: "education",
    name: "projector",
    component: ProjectorIcon,
    keywords: ["projector", "education"],
  },
  {
    group: "education",
    name: "protractor1",
    component: Protractor1Icon,
    keywords: ["protractor", "education"],
  },
  {
    group: "education",
    name: "protractor",
    component: ProtractorIcon,
    keywords: ["protractor", "education"],
  },
  {
    group: "education",
    name: "quiz",
    component: QuizIcon,
    keywords: ["quiz", "education"],
  },
  {
    group: "education",
    name: "rank",
    component: RankIcon,
    keywords: ["rank", "education"],
  },
  {
    group: "education",
    name: "report",
    component: ReportIcon,
    keywords: ["report", "education"],
  },
  {
    group: "education",
    name: "ruler",
    component: RulerIcon,
    keywords: ["ruler", "education"],
  },
  {
    group: "education",
    name: "school",
    component: SchoolIcon,
    keywords: ["school", "education"],
  },
  {
    group: "education",
    name: "smartphonelearning",
    component: SmartphonelearningIcon,
    keywords: ["smartphone", "learning", "education"],
  },
  {
    group: "education",
    name: "squareroot",
    component: SquarerootIcon,
    keywords: ["math", "calculation", "number"],
  },
  {
    group: "education",
    name: "stationery",
    component: StationeryIcon,
    keywords: ["pencil", "paper", "office"],
  },
  {
    group: "education",
    name: "studentcard",
    component: StudentcardIcon,
    keywords: ["ID", "education", "card"],
  },
  {
    group: "education",
    name: "student",
    component: StudentIcon,
    keywords: ["person", "education", "graduate"],
  },
  {
    group: "education",
    name: "students",
    component: StudentsIcon,
    keywords: ["people", "education", "graduates"],
  },
  {
    group: "education",
    name: "tablelamp",
    component: TablelampIcon,
    keywords: ["light", "desk", "office"],
  },
  {
    group: "education",
    name: "teacher01",
    component: Teacher01Icon,
    keywords: ["person", "education", "teacher"],
  },
  {
    group: "education",
    name: "teacher",
    component: TeacherIcon,
    keywords: ["person", "education", "teaching"],
  },
  {
    group: "education",
    name: "telescope",
    component: TelescopeIcon,
    keywords: ["science", "astronomy", "observation"],
  },
  {
    group: "education",
    name: "testtube1",
    component: Testtube1Icon,
    keywords: ["science", "chemistry", "lab"],
  },
  {
    group: "education",
    name: "testtube",
    component: TesttubeIcon,
    keywords: ["science", "chemistry", "experiment"],
  },
  {
    group: "education",
    name: "transform",
    component: TransformIcon,
    keywords: ["arrows", "change", "transformation"],
  },
  {
    group: "education",
    name: "trophy",
    component: TrophyIcon,
    keywords: ["winner", "success", "achievement"],
  },
  {
    group: "education",
    name: "videocall",
    component: VideocallIcon,
    keywords: ["communication", "video", "chat"],
  },
  {
    group: "education",
    name: "whistle",
    component: WhistleIcon,
    keywords: ["sports", "referee", "whistle"],
  },
  {
    group: "business",
    name: "archive",
    component: ArchiveIcon,
    keywords: ["folder", "file", "storage"],
  },
  {
    group: "business",
    name: "barchart01",
    component: Barchart01Icon,
    keywords: ["graph", "chart", "data"],
  },
  {
    group: "business",
    name: "barchart",
    component: BarchartIcon,
    keywords: ["graph", "chart", "data"],
  },
  {
    group: "business",
    name: "briefcase01",
    component: Briefcase01Icon,
    keywords: ["work", "job", "business"],
  },
  {
    group: "business",
    name: "briefcase02",
    component: Briefcase02Icon,
    keywords: ["work", "job", "business"],
  },
  {
    group: "business",
    name: "briefcase03",
    component: Briefcase03Icon,
    keywords: ["work", "job", "business"],
  },
  {
    group: "business",
    name: "briefcase04",
    component: Briefcase04Icon,
    keywords: ["work", "job", "business"],
  },
  {
    group: "business",
    name: "briefcase",
    component: BriefcaseIcon,
    keywords: ["briefcase", "business", "work", "case"],
  },
  {
    group: "business",
    name: "calculator",
    component: CalculatorIcon,
    keywords: ["calculator", "business", "math", "numbers"],
  },
  {
    group: "business",
    name: "chartarrowdown01",
    component: Chartarrowdown01Icon,
    keywords: ["chart", "arrow", "down", "business"],
  },
  {
    group: "business",
    name: "chartarrowdownrectangle",
    component: ChartarrowdownrectangleIcon,
    keywords: ["chart", "arrow", "down", "rectangle", "business"],
  },
  {
    group: "business",
    name: "chartarrowdown",
    component: ChartarrowdownIcon,
    keywords: ["chart", "arrow", "down", "business"],
  },
  {
    group: "business",
    name: "chartarrowup01",
    component: Chartarrowup01Icon,
    keywords: ["chart", "arrow", "up", "business"],
  },
  {
    group: "business",
    name: "chartarrowuprectangle",
    component: ChartarrowuprectangleIcon,
    keywords: ["chart", "arrow", "up", "rectangle", "business"],
  },
  {
    group: "business",
    name: "chartarrowup",
    component: ChartarrowupIcon,
    keywords: ["chart", "arrow", "up", "business"],
  },
  {
    group: "business",
    name: "chartbar01",
    component: Chartbar01Icon,
    keywords: ["chart", "bar", "business"],
  },
  {
    group: "business",
    name: "chartbar",
    component: ChartbarIcon,
    keywords: ["chart", "bar", "business"],
  },
  {
    group: "business",
    name: "chartline",
    component: ChartlineIcon,
    keywords: ["chart", "line", "business"],
  },
  {
    group: "business",
    name: "chartnotification",
    component: ChartnotificationIcon,
    keywords: ["chart", "notification", "business"],
  },
  {
    group: "business",
    name: "chartpie01",
    component: Chartpie01Icon,
    keywords: ["chart", "pie", "business"],
  },
  {
    group: "business",
    name: "chartpie",
    component: ChartpieIcon,
    keywords: ["chart", "pie", "business"],
  },
  {
    group: "business",
    name: "chartwave01",
    component: Chartwave01Icon,
    keywords: ["chart", "wave", "business"],
  },
  {
    group: "business",
    name: "chartwaverectangle",
    component: ChartwaverectangleIcon,
    keywords: ["chart", "wave", "rectangle", "business"],
  },
  {
    group: "business",
    name: "chartwave",
    component: ChartwaveIcon,
    keywords: ["chart", "wave", "business"],
  },
  {
    group: "business",
    name: "deal",
    component: DealIcon,
    keywords: ["deal", "business"],
  },
  {
    group: "business",
    name: "home01",
    component: Home01Icon,
    keywords: ["home", "business"],
  },
  {
    group: "business",
    name: "home",
    component: HomeIcon,
    keywords: ["home", "house"],
  },
  {
    group: "business",
    name: "like",
    component: LikeIcon,
    keywords: ["like", "thumbs up"],
  },
  {
    group: "business",
    name: "presentationchart",
    component: PresentationchartIcon,
    keywords: ["presentation", "chart", "graph"],
  },
  {
    group: "business",
    name: "target",
    component: TargetIcon,
    keywords: ["target", "bullseye"],
  },
  {
    group: "communication",
    name: "broadcast",
    component: BroadcastIcon,
    keywords: ["broadcast"],
  },
  {
    group: "communication",
    name: "callblock",
    component: CallblockIcon,
    keywords: ["call block"],
  },
  {
    group: "communication",
    name: "callin01",
    component: Callin01Icon,
    keywords: ["call in", "phone receiver"],
  },
  {
    group: "communication",
    name: "callin",
    component: CallinIcon,
    keywords: ["call in", "phone receiver"],
  },
  {
    group: "communication",
    name: "calllove",
    component: CallloveIcon,
    keywords: ["call love", "heart and phone"],
  },
  {
    group: "communication",
    name: "callout01",
    component: Callout01Icon,
    keywords: ["call out", "phone"],
  },
  {
    group: "communication",
    name: "callout",
    component: CalloutIcon,
    keywords: ["call out", "phone"],
  },
  {
    group: "communication",
    name: "calluser",
    component: CalluserIcon,
    keywords: ["call user", "phone and person"],
  },
  {
    group: "communication",
    name: "callvoicemail",
    component: CallvoicemailIcon,
    keywords: ["call voicemail", "phone and message"],
  },
  {
    group: "communication",
    name: "call",
    component: CallIcon,
    keywords: ["call", "phone"],
  },
  {
    group: "communication",
    name: "calling",
    component: CallingIcon,
    keywords: ["calling", "phone"],
  },
  {
    group: "communication",
    name: "chat01",
    component: Chat01Icon,
    keywords: ["chat", "speech bubble"],
  },
  {
    group: "communication",
    name: "chat02",
    component: Chat02Icon,
    keywords: ["chat", "speech bubble"],
  },
  {
    group: "communication",
    name: "chataccept",
    component: ChatacceptIcon,
    keywords: ["chat accept", "speech bubble with check mark"],
  },
  {
    group: "communication",
    name: "chatarrowdown",
    component: ChatarrowdownIcon,
    keywords: ["chat arrow down", "speech bubble with arrow pointing down"],
  },
  {
    group: "communication",
    name: "chatarrowup",
    component: ChatarrowupIcon,
    keywords: ["chat arrow up", "speech bubble with arrow pointing up"],
  },
  {
    group: "communication",
    name: "chatblock",
    component: ChatblockIcon,
    keywords: ["chat block", "speech bubble with X mark"],
  },
  {
    group: "communication",
    name: "chatclock",
    component: ChatclockIcon,
    keywords: ["chat clock", "speech bubble with clock"],
  },
  {
    group: "communication",
    name: "chatclose",
    component: ChatcloseIcon,
    keywords: ["chat close", "speech bubble with X mark"],
  },
  {
    group: "communication",
    name: "chatfavourite",
    component: ChatfavouriteIcon,
    keywords: ["chat favourite", "speech bubble with heart"],
  },
  {
    group: "communication",
    name: "chatinformation",
    component: ChatinformationIcon,
    keywords: ["chat", "information", "message"],
  },
  {
    group: "communication",
    name: "chatlock",
    component: ChatlockIcon,
    keywords: ["chat", "lock", "secure"],
  },
  {
    group: "communication",
    name: "chatlove",
    component: ChatloveIcon,
    keywords: ["chat", "love", "heart"],
  },
  {
    group: "communication",
    name: "chatnegative",
    component: ChatnegativeIcon,
    keywords: ["chat", "negative", "no"],
  },
  {
    group: "communication",
    name: "chatnotification1",
    component: Chatnotification1Icon,
    keywords: ["chat", "notification", "alert"],
  },
  {
    group: "communication",
    name: "chatnotification",
    component: ChatnotificationIcon,
    keywords: ["chat", "notification", "alert"],
  },
  {
    group: "communication",
    name: "chatplus",
    component: ChatplusIcon,
    keywords: ["chat", "plus", "add"],
  },
  {
    group: "communication",
    name: "chatsad",
    component: ChatsadIcon,
    keywords: ["chat", "sad", "emoticon"],
  },
  {
    group: "communication",
    name: "chatsearch",
    component: ChatsearchIcon,
    keywords: ["chat", "search", "find"],
  },
  {
    group: "communication",
    name: "chatsilent",
    component: ChatsilentIcon,
    keywords: ["chat", "silent", "mute"],
  },
  {
    group: "communication",
    name: "chatsmile",
    component: ChatsmileIcon,
    keywords: ["chat", "smile", "emoticon"],
  },
  {
    group: "communication",
    name: "chatuser",
    component: ChatuserIcon,
    keywords: ["chat", "user", "profile"],
  },
  {
    group: "communication",
    name: "chat",
    component: ChatIcon,
    keywords: ["chat", "message"],
  },
  {
    group: "communication",
    name: "chatting",
    component: ChattingIcon,
    keywords: ["chat", "conversation"],
  },
  {
    group: "communication",
    name: "feather",
    component: FeatherIcon,
    keywords: ["feather", "pen"],
  },
  {
    group: "communication",
    name: "idcard",
    component: IdcardIcon,
    keywords: ["id", "card", "profile"],
  },
  {
    group: "communication",
    name: "mailarrowleft",
    component: MailarrowleftIcon,
    keywords: ["mail", "arrow", "left"],
  },
  {
    group: "communication",
    name: "mailarrowright",
    component: MailarrowrightIcon,
    keywords: ["mail", "arrow", "right"],
  },
  {
    group: "communication",
    name: "mailarrowdown",
    component: MailarrowdownIcon,
    keywords: ["mail", "arrow", "down"],
  },
  {
    group: "communication",
    name: "mailarrowup",
    component: MailarrowupIcon,
    keywords: ["mail", "arrow", "up"],
  },
  {
    group: "communication",
    name: "mailblock",
    component: MailblockIcon,
    keywords: ["mail", "block"],
  },
  {
    group: "communication",
    name: "maildelay",
    component: MaildelayIcon,
    keywords: ["mail", "delay"],
  },
  {
    group: "communication",
    name: "mailfail",
    component: MailfailIcon,
    keywords: ["mail", "fail"],
  },
  {
    group: "communication",
    name: "mailfast",
    component: MailfastIcon,
    keywords: ["mail", "fast"],
  },
  {
    group: "communication",
    name: "mailfavourite",
    component: MailfavouriteIcon,
    keywords: ["mail", "favourite"],
  },
  {
    group: "communication",
    name: "mailnegative",
    component: MailnegativeIcon,
    keywords: ["mail", "negative"],
  },
  {
    group: "communication",
    name: "mailnotification",
    component: MailnotificationIcon,
    keywords: ["mail", "notification"],
  },
  {
    group: "communication",
    name: "mailplus",
    component: MailplusIcon,
    keywords: ["mail", "plus"],
  },
  {
    group: "communication",
    name: "mailsilent",
    component: MailsilentIcon,
    keywords: ["mail", "silent"],
  },
  {
    group: "communication",
    name: "mail",
    component: MailIcon,
    keywords: ["mail"],
  },
  {
    group: "communication",
    name: "mailbox",
    component: MailboxIcon,
    keywords: ["mailbox"],
  },
  {
    group: "communication",
    name: "send",
    component: SendIcon,
    keywords: ["send"],
  },
  {
    group: "communication",
    name: "sentfast",
    component: SentfastIcon,
    keywords: ["sent", "fast"],
  },
  {
    group: "communication",
    name: "signal",
    component: SignalIcon,
    keywords: ["signal"],
  },
  {
    group: "communication",
    name: "sticker",
    component: StickerIcon,
    keywords: ["sticker"],
  },
  {
    group: "communication",
    name: "voicemail",
    component: VoicemailIcon,
    keywords: ["voicemail"],
  },
  {
    group: "communication",
    name: "wifi",
    component: WifiIcon,
    keywords: ["wifi"],
  },
  {
    group: "device",
    name: "aboutphone",
    component: AboutphoneIcon,
    keywords: ["about", "phone"],
  },
  {
    group: "device",
    name: "accessibility",
    component: AccessibilityIcon,
    keywords: ["accessibility"],
  },
  {
    group: "device",
    name: "airplanemodeoff",
    component: AirplanemodeoffIcon,
    keywords: ["airplane", "mode", "off"],
  },
  {
    group: "device",
    name: "airplanemode",
    component: AirplanemodeIcon,
    keywords: ["airplane", "mode"],
  },
  {
    group: "device",
    name: "autorotation",
    component: AutorotationIcon,
    keywords: ["auto", "rotation"],
  },
  { group: "device", name: "auto", component: AutoIcon, keywords: ["auto"] },
  {
    group: "device",
    name: "batteryalert",
    component: BatteryalertIcon,
    keywords: ["battery", "alert"],
  },
  {
    group: "device",
    name: "batterychargingfull",
    component: BatterychargingfullIcon,
    keywords: ["battery", "charging", "full"],
  },
  {
    group: "device",
    name: "batterycharging",
    component: BatterychargingIcon,
    keywords: ["battery", "charging"],
  },
  {
    group: "device",
    name: "batteryhorizontalcharging",
    component: BatteryhorizontalchargingIcon,
    keywords: ["battery", "horizontal", "charging"],
  },
  {
    group: "device",
    name: "batteryhorizontalfull",
    component: BatteryhorizontalfullIcon,
    keywords: ["battery", "horizontal", "full"],
  },
  {
    group: "device",
    name: "batteryhorizontallowbet",
    component: BatteryhorizontallowbetIcon,
    keywords: ["battery", "horizontal", "low", "bet"],
  },
  {
    group: "device",
    name: "batteryhorizontalsavingmode",
    component: BatteryhorizontalsavingmodeIcon,
    keywords: ["battery", "horizontal", "saving", "mode"],
  },
  {
    group: "device",
    name: "batteryhorizontal",
    component: BatteryhorizontalIcon,
    keywords: ["battery", "horizontal"],
  },
  {
    group: "device",
    name: "batterylowbet",
    component: BatterylowbetIcon,
    keywords: ["battery", "low", "bet"],
  },
  {
    group: "device",
    name: "batterysavingmode",
    component: BatterysavingmodeIcon,
    keywords: ["battery", "saving", "mode"],
  },
  {
    group: "device",
    name: "battery",
    component: BatteryIcon,
    keywords: ["battery"],
  },
  {
    group: "device",
    name: "bluetoothconnected",
    component: BluetoothconnectedIcon,
    keywords: ["bluetooth", "connected"],
  },
  {
    group: "device",
    name: "bluetoothoff",
    component: BluetoothoffIcon,
    keywords: ["bluetooth", "off"],
  },
  {
    group: "device",
    name: "bluetooth",
    component: BluetoothIcon,
    keywords: ["bluetooth"],
  },
  {
    group: "device",
    name: "cameraoff",
    component: CameraoffIcon,
    keywords: ["camera", "off"],
  },
  {
    group: "device",
    name: "cameraswitch",
    component: CameraswitchIcon,
    keywords: ["camera", "switch"],
  },
  {
    group: "device",
    name: "camera",
    component: CameraIcon,
    keywords: ["camera"],
  },
  { group: "device", name: "cast", component: CastIcon, keywords: ["cast"] },
  {
    group: "device",
    name: "colorinversion",
    component: ColorinversionIcon,
    keywords: ["color", "inversion"],
  },
  {
    group: "device",
    name: "computer",
    component: ComputerIcon,
    keywords: ["computer"],
  },
  {
    group: "device",
    name: "darkmode",
    component: DarkmodeIcon,
    keywords: ["dark", "mode"],
  },
  {
    group: "device",
    name: "devices",
    component: DevicesIcon,
    keywords: ["devices"],
  },
  {
    group: "device",
    name: "digitalhealth",
    component: DigitalhealthIcon,
    keywords: ["health", "digital", "monitoring"],
  },
  {
    group: "device",
    name: "donotdisturb",
    component: DonotdisturbIcon,
    keywords: ["do not disturb", "silence", "quiet"],
  },
  {
    group: "device",
    name: "filter01",
    component: Filter01Icon,
    keywords: ["filter", "screen", "brightness"],
  },
  {
    group: "device",
    name: "filter",
    component: FilterIcon,
    keywords: ["filter", "screen", "brightness"],
  },
  {
    group: "device",
    name: "fingerprint",
    component: FingerprintIcon,
    keywords: ["fingerprint", "security", "access"],
  },
  {
    group: "device",
    name: "flashlight",
    component: FlashlightIcon,
    keywords: ["flashlight", "torch", "light"],
  },
  {
    group: "device",
    name: "flashauto",
    component: FlashautoIcon,
    keywords: ["flash", "auto", "camera"],
  },
  {
    group: "device",
    name: "flashoff",
    component: FlashoffIcon,
    keywords: ["flash", "off", "camera"],
  },
  {
    group: "device",
    name: "flash",
    component: FlashIcon,
    keywords: ["flash", "camera", "photography"],
  },
  {
    group: "device",
    name: "gpsoff",
    component: GpsoffIcon,
    keywords: ["gps", "off", "location"],
  },
  {
    group: "device",
    name: "gps",
    component: GpsIcon,
    keywords: ["gps", "location", "navigation"],
  },
  {
    group: "device",
    name: "graphiceq",
    component: GraphiceqIcon,
    keywords: ["equalizer", "audio", "sound"],
  },
  {
    group: "device",
    name: "hotspotoff",
    component: HotspotoffIcon,
    keywords: ["hotspot", "off", "connection"],
  },
  {
    group: "device",
    name: "hotspot",
    component: HotspotIcon,
    keywords: ["hotspot", "connection", "wifi"],
  },
  {
    group: "device",
    name: "laptop",
    component: LaptopIcon,
    keywords: ["laptop", "computer", "device"],
  },
  {
    group: "device",
    name: "lightmode",
    component: LightmodeIcon,
    keywords: ["light mode", "display", "brightness"],
  },
  {
    group: "device",
    name: "location",
    component: LocationIcon,
    keywords: ["location", "pin", "map"],
  },
  {
    group: "device",
    name: "lock",
    component: LockIcon,
    keywords: ["lock", "security", "protection"],
  },
  {
    group: "device",
    name: "mobiledataoff",
    component: MobiledataoffIcon,
    keywords: ["mobile data", "off", "connection"],
  },
  {
    group: "device",
    name: "mobiledata",
    component: MobiledataIcon,
    keywords: ["mobile data", "connection", "wifi"],
  },
  {
    group: "device",
    name: "mobile",
    component: MobileIcon,
    keywords: ["phone", "device", "mobile"],
  },
  {
    group: "device",
    name: "musicoff",
    component: MusicoffIcon,
    keywords: ["music", "off", "sound", "mute"],
  },
  {
    group: "device",
    name: "music",
    component: MusicIcon,
    keywords: ["music", "sound"],
  },
  {
    group: "device",
    name: "nightmode",
    component: NightmodeIcon,
    keywords: ["night", "mode", "dark"],
  },
  {
    group: "device",
    name: "notification01",
    component: Notification01Icon,
    keywords: ["notification", "alert", "message"],
  },
  {
    group: "device",
    name: "notificationoff",
    component: NotificationoffIcon,
    keywords: ["notification", "off", "mute"],
  },
  {
    group: "device",
    name: "notification",
    component: NotificationIcon,
    keywords: ["notification", "alert", "message"],
  },
  {
    group: "device",
    name: "phonelock",
    component: PhonelockIcon,
    keywords: ["phone", "lock", "security"],
  },
  {
    group: "device",
    name: "qrscan",
    component: QrscanIcon,
    keywords: ["qr", "code", "scan"],
  },
  {
    group: "device",
    name: "restart",
    component: RestartIcon,
    keywords: ["restart", "refresh", "update"],
  },
  {
    group: "device",
    name: "rotatelock",
    component: RotatelockIcon,
    keywords: ["rotate", "lock", "screen", "orientation"],
  },
  {
    group: "device",
    name: "screenrotate",
    component: ScreenrotateIcon,
    keywords: ["rotate", "screen", "orientation"],
  },
  {
    group: "device",
    name: "screenshot",
    component: ScreenshotIcon,
    keywords: ["screenshot", "screen", "capture"],
  },
  {
    group: "device",
    name: "sdstorage",
    component: SdstorageIcon,
    keywords: ["sd", "storage", "memory"],
  },
  {
    group: "device",
    name: "security",
    component: SecurityIcon,
    keywords: ["security", "lock"],
  },
  {
    group: "device",
    name: "setting",
    component: SettingIcon,
    keywords: ["setting", "gear"],
  },
  {
    group: "device",
    name: "shutdown",
    component: ShutdownIcon,
    keywords: ["shutdown", "power", "off"],
  },
  {
    group: "device",
    name: "signalcelluler",
    component: SignalcellulerIcon,
    keywords: ["signal", "cellular", "connection"],
  },
  {
    group: "device",
    name: "signalcellulerbad",
    component: SignalcellulerbadIcon,
    keywords: ["signal", "cellular", "connection", "low"],
  },
  {
    group: "device",
    name: "signalcellulernointernet1",
    component: Signalcellulernointernet1Icon,
    keywords: ["signal", "cellular", "connection", "no", "internet"],
  },
  {
    group: "device",
    name: "signalcellulernointernet",
    component: SignalcellulernointernetIcon,
    keywords: ["signal", "cellular", "connection", "no", "internet"],
  },
  {
    group: "device",
    name: "signalcelluleroff",
    component: SignalcelluleroffIcon,
  },
  {
    group: "device",
    name: "signalcelluler",
    component: SignalcellulerIcon,
  },
  { group: "device", name: "simcard", component: SimcardIcon },
  { group: "device", name: "standbymode", component: StandbymodeIcon },
  { group: "device", name: "storage", component: StorageIcon },
  { group: "device", name: "switch", component: SwitchIcon },
  { group: "device", name: "tablet", component: TabletIcon },
  { group: "device", name: "taskcheck", component: TaskcheckIcon },
  { group: "device", name: "unlock", component: UnlockIcon },
  { group: "device", name: "usb", component: UsbIcon },
  { group: "device", name: "usercircle", component: UsercircleIcon },
  {
    group: "device",
    name: "userrectangle",
    component: UserrectangleIcon,
  },
  { group: "device", name: "user", component: UserIcon },
  { group: "device", name: "vibratemode", component: VibratemodeIcon },
  { group: "device", name: "videooff", component: VideooffIcon },
  { group: "device", name: "video", component: VideoIcon },
  { group: "device", name: "viewoff", component: ViewoffIcon },
  { group: "device", name: "viewprivacy", component: ViewprivacyIcon },
  { group: "device", name: "view", component: ViewIcon },
  { group: "device", name: "widgets", component: WidgetsIcon },
  {
    group: "device",
    name: "wifisignalbad",
    component: WifisignalbadIcon,
  },
  {
    group: "device",
    name: "wifisignalnointernet",
    component: WifisignalnointernetIcon,
  },
  {
    group: "device",
    name: "wifisignaloff",
    component: WifisignaloffIcon,
  },
  { group: "device", name: "wifisignal", component: WifisignalIcon },
  { group: "device", name: "wifioff", component: WifioffIcon },
  { group: "device", name: "wifi", component: WifiIcon },
  {
    group: "device",
    name: "wirelesscharging",
    component: WirelesschargingIcon,
  },
  {
    group: "ecommerce",
    name: "badgepercent",
    component: BadgepercentIcon,
  },
  { group: "ecommerce", name: "bag01", component: Bag01Icon },
  { group: "ecommerce", name: "bagadd", component: BagaddIcon },
  { group: "ecommerce", name: "bagcheck", component: BagcheckIcon },
  {
    group: "ecommerce",
    name: "bagfavourite",
    component: BagfavouriteIcon,
  },
  { group: "ecommerce", name: "bagminus", component: BagminusIcon },
  { group: "ecommerce", name: "bagremove", component: BagremoveIcon },
  { group: "ecommerce", name: "bag", component: BagIcon },
  { group: "ecommerce", name: "board", component: BoardIcon },
  { group: "ecommerce", name: "bookmark", component: BookmarkIcon },
  { group: "ecommerce", name: "card", component: CardIcon },
  { group: "ecommerce", name: "cart01", component: Cart01Icon },
  { group: "ecommerce", name: "cart02", component: Cart02Icon },
  { group: "ecommerce", name: "cartadd", component: CartaddIcon },
  {
    group: "ecommerce",
    name: "cartarrowdown",
    component: CartarrowdownIcon,
  },
  { group: "ecommerce", name: "cartarrowup", component: CartarrowupIcon },
  { group: "ecommerce", name: "cartcheck", component: CartcheckIcon },
  { group: "ecommerce", name: "cartminus", component: CartminusIcon },
  { group: "ecommerce", name: "cartremove", component: CartremoveIcon },
  { group: "ecommerce", name: "cart", component: CartIcon },
  { group: "ecommerce", name: "deposit", component: DepositIcon },
  { group: "ecommerce", name: "favourite", component: FavouriteIcon },
  { group: "ecommerce", name: "glassbroken", component: GlassbrokenIcon },
  { group: "ecommerce", name: "hanger", component: HangerIcon },
  { group: "ecommerce", name: "invoice01", component: Invoice01Icon },
  { group: "ecommerce", name: "invoice", component: InvoiceIcon },
  { group: "ecommerce", name: "location", component: LocationIcon },
  { group: "ecommerce", name: "money01", component: Money01Icon },
  { group: "ecommerce", name: "moneybag", component: MoneybagIcon },
  { group: "ecommerce", name: "money", component: MoneyIcon },
  {
    group: "ecommerce",
    name: "packagebox01",
    component: Packagebox01Icon,
  },
  { group: "ecommerce", name: "packagebox", component: PackageboxIcon },
  { group: "ecommerce", name: "receipt", component: ReceiptIcon },
  { group: "ecommerce", name: "reward", component: RewardIcon },
  { group: "ecommerce", name: "shield", component: ShieldIcon },
  {
    group: "ecommerce",
    name: "shoppingbagadd",
    component: ShoppingbagaddIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbagcheck",
    component: ShoppingbagcheckIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbaglove",
    component: ShoppingbagloveIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbagminus",
    component: ShoppingbagminusIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbagremove",
    component: ShoppingbagremoveIcon,
  },
  { group: "ecommerce", name: "shoppingbag", component: ShoppingbagIcon },
  {
    group: "ecommerce",
    name: "shoppingbasket01",
    component: Shoppingbasket01Icon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasket02",
    component: Shoppingbasket02Icon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasket03",
    component: Shoppingbasket03Icon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasketadd",
    component: ShoppingbasketaddIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasketarrowdown",
    component: ShoppingbasketarrowdownIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasketarrowout",
    component: ShoppingbasketarrowoutIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasketcheck",
    component: ShoppingbasketcheckIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasketlove",
    component: ShoppingbasketloveIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasketminus",
    component: ShoppingbasketminusIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasketremove",
    component: ShoppingbasketremoveIcon,
  },
  {
    group: "ecommerce",
    name: "shoppingbasket",
    component: ShoppingbasketIcon,
  },
  { group: "ecommerce", name: "store01", component: Store01Icon },
  { group: "ecommerce", name: "store02", component: Store02Icon },
  { group: "ecommerce", name: "store03", component: Store03Icon },
  { group: "ecommerce", name: "store", component: StoreIcon },
  { group: "ecommerce", name: "tag01", component: Tag01Icon },
  { group: "ecommerce", name: "tag", component: TagIcon },
  { group: "ecommerce", name: "trolley01", component: Trolley01Icon },
  { group: "ecommerce", name: "trolley", component: TrolleyIcon },
  { group: "ecommerce", name: "voucher", component: VoucherIcon },
  { group: "ecommerce", name: "wallet01", component: Wallet01Icon },
  { group: "ecommerce", name: "wallet", component: WalletIcon },
  { group: "ecommerce", name: "withdraw", component: WithdrawIcon },
  {
    group: "files and folder",
    name: "archive01",
    component: Archive01Icon,
  },
  {
    group: "files and folder",
    name: "archive1",
    component: Archive1Icon,
  },
  {
    group: "files and folder",
    name: "archiveadd",
    component: ArchiveaddIcon,
  },
  {
    group: "files and folder",
    name: "archiveclear",
    component: ArchiveclearIcon,
  },
  {
    group: "files and folder",
    name: "archivedone",
    component: ArchivedoneIcon,
  },
  {
    group: "files and folder",
    name: "archivedownload",
    component: ArchivedownloadIcon,
  },
  {
    group: "files and folder",
    name: "archivefavourite",
    component: ArchivefavouriteIcon,
  },
  {
    group: "files and folder",
    name: "archiveremove",
    component: ArchiveremoveIcon,
  },
  {
    group: "files and folder",
    name: "archivesearch",
    component: ArchivesearchIcon,
  },
  {
    group: "files and folder",
    name: "archiveupload",
    component: ArchiveuploadIcon,
  },
  { group: "files and folder", name: "archive", component: ArchiveIcon },
  {
    group: "files and folder",
    name: "attachment",
    component: AttachmentIcon,
  },
  { group: "files and folder", name: "file01", component: File01Icon },
  { group: "files and folder", name: "file02", component: File02Icon },
  { group: "files and folder", name: "file03", component: File03Icon },
  {
    group: "files and folder",
    name: "fileaccept01",
    component: Fileaccept01Icon,
  },
  {
    group: "files and folder",
    name: "fileaccept",
    component: FileacceptIcon,
  },
  {
    group: "files and folder",
    name: "fileadd01",
    component: Fileadd01Icon,
  },
  { group: "files and folder", name: "fileadd", component: FileaddIcon },
  { group: "files and folder", name: "filedoc", component: FiledocIcon },
  {
    group: "files and folder",
    name: "filedownload01",
    component: Filedownload01Icon,
  },
  {
    group: "files and folder",
    name: "filedownload",
    component: FiledownloadIcon,
  },
  {
    group: "files and folder",
    name: "filefavourite01",
    component: Filefavourite01Icon,
  },
  {
    group: "files and folder",
    name: "filefavourite",
    component: FilefavouriteIcon,
  },
  { group: "files and folder", name: "filejpg", component: FilejpgIcon },
  {
    group: "files and folder",
    name: "fileminus01",
    component: Fileminus01Icon,
  },
  {
    group: "files and folder",
    name: "fileminus",
    component: FileminusIcon,
  },
  {
    group: "files and folder",
    name: "filemusic",
    component: FilemusicIcon,
  },
  { group: "files and folder", name: "filepdf", component: FilepdfIcon },
  { group: "files and folder", name: "filepng", component: FilepngIcon },
  {
    group: "files and folder",
    name: "filereload",
    component: FilereloadIcon,
  },
  {
    group: "files and folder",
    name: "fileremove01",
    component: Fileremove01Icon,
  },
  {
    group: "files and folder",
    name: "fileremove",
    component: FileremoveIcon,
  },
  {
    group: "files and folder",
    name: "filesearch01",
    component: Filesearch01Icon,
  },
  {
    group: "files and folder",
    name: "filesearch",
    component: FilesearchIcon,
  },
  {
    group: "files and folder",
    name: "filesharing",
    component: FilesharingIcon,
  },
  {
    group: "files and folder",
    name: "fileupload01",
    component: Fileupload01Icon,
  },
  {
    group: "files and folder",
    name: "fileupload",
    component: FileuploadIcon,
  },
  {
    group: "files and folder",
    name: "filevideo",
    component: FilevideoIcon,
  },
  { group: "files and folder", name: "file", component: FileIcon },
  { group: "files and folder", name: "files01", component: Files01Icon },
  { group: "files and folder", name: "files", component: FilesIcon },
  {
    group: "files and folder",
    name: "folder01",
    component: Folder01Icon,
  },
  {
    group: "files and folder",
    name: "folder02",
    component: Folder02Icon,
  },
  {
    group: "files and folder",
    name: "folder03",
    component: Folder03Icon,
  },
  {
    group: "files and folder",
    name: "folderdownload",
    component: FolderdownloadIcon,
  },
  {
    group: "files and folder",
    name: "folderaccept01",
    component: Folderaccept01Icon,
  },
  {
    group: "files and folder",
    name: "folderaccept",
    component: FolderacceptIcon,
  },
  {
    group: "files and folder",
    name: "folderadd01",
    component: Folderadd01Icon,
  },
  {
    group: "files and folder",
    name: "folderadd",
    component: FolderaddIcon,
  },
  {
    group: "files and folder",
    name: "folderdownload01",
    component: Folderdownload01Icon,
  },
  {
    group: "files and folder",
    name: "folderfavourite01",
    component: Folderfavourite01Icon,
  },
  {
    group: "files and folder",
    name: "folderfavourite",
    component: FolderfavouriteIcon,
  },
  {
    group: "files and folder",
    name: "folderminus01",
    component: Folderminus01Icon,
  },
  {
    group: "files and folder",
    name: "folderminus",
    component: FolderminusIcon,
  },
  {
    group: "files and folder",
    name: "folderreload",
    component: FolderreloadIcon,
  },
  {
    group: "files and folder",
    name: "folderremove01",
    component: Folderremove01Icon,
  },
  {
    group: "files and folder",
    name: "folderremove",
    component: FolderremoveIcon,
  },
  {
    group: "files and folder",
    name: "foldersearch01",
    component: Foldersearch01Icon,
  },
  {
    group: "files and folder",
    name: "foldersearch",
    component: FoldersearchIcon,
  },
  {
    group: "files and folder",
    name: "foldershare",
    component: FoldershareIcon,
  },
  {
    group: "files and folder",
    name: "folderupload01",
    component: Folderupload01Icon,
  },
  {
    group: "files and folder",
    name: "folderupload",
    component: FolderuploadIcon,
  },
  { group: "files and folder", name: "folder", component: FolderIcon },
  { group: "finance and payment", name: "atm", component: AtmIcon },
  { group: "finance and payment", name: "bank", component: BankIcon },
  {
    group: "finance and payment",
    name: "barchart01",
    component: Barchart01Icon,
  },
  {
    group: "finance and payment",
    name: "barchart",
    component: BarchartIcon,
  },
  { group: "finance and payment", name: "bill", component: BillIcon },
  {
    group: "finance and payment",
    name: "briefcase01",
    component: Briefcase01Icon,
  },
  {
    group: "finance and payment",
    name: "briefcase",
    component: BriefcaseIcon,
  },
  {
    group: "finance and payment",
    name: "cardadd",
    component: CardaddIcon,
  },
  {
    group: "finance and payment",
    name: "cardarrowdown",
    component: CardarrowdownIcon,
  },
  {
    group: "finance and payment",
    name: "cardarrowleft",
    component: CardarrowleftIcon,
  },
  {
    group: "finance and payment",
    name: "cardarrowright",
    component: CardarrowrightIcon,
  },
  {
    group: "finance and payment",
    name: "cardarrowup",
    component: CardarrowupIcon,
  },
  {
    group: "finance and payment",
    name: "cardcheck",
    component: CardcheckIcon,
  },
  {
    group: "finance and payment",
    name: "cardminus",
    component: CardminusIcon,
  },
  {
    group: "finance and payment",
    name: "cardremove",
    component: CardremoveIcon,
  },
  {
    group: "finance and payment",
    name: "carduser",
    component: CarduserIcon,
  },
  {
    group: "finance and payment",
    name: "cardwithdraw",
    component: CardwithdrawIcon,
  },
  { group: "finance and payment", name: "card", component: CardIcon },
  {
    group: "finance and payment",
    name: "coindollar",
    component: CoindollarIcon,
  },
  {
    group: "finance and payment",
    name: "coineuro",
    component: CoineuroIcon,
  },
  {
    group: "finance and payment",
    name: "coinpound",
    component: CoinpoundIcon,
  },
  {
    group: "finance and payment",
    name: "coinyen",
    component: CoinyenIcon,
  },
  { group: "finance and payment", name: "coin", component: CoinIcon },
  { group: "finance and payment", name: "cube", component: CubeIcon },
  { group: "finance and payment", name: "dollar", component: DollarIcon },
  {
    group: "finance and payment",
    name: "donatecoin",
    component: DonatecoinIcon,
  },
  { group: "finance and payment", name: "donate", component: DonateIcon },
  {
    group: "finance and payment",
    name: "earnings",
    component: EarningsIcon,
  },
  { group: "finance and payment", name: "euro", component: EuroIcon },
  { group: "finance and payment", name: "gold", component: GoldIcon },
  {
    group: "finance and payment",
    name: "hartarrowdown",
    component: HartarrowdownIcon,
  },
  {
    group: "finance and payment",
    name: "hartarrowup",
    component: HartarrowupIcon,
  },
  {
    group: "finance and payment",
    name: "harthistogram",
    component: HarthistogramIcon,
  },
  {
    group: "finance and payment",
    name: "linechart01",
    component: Linechart01Icon,
  },
  {
    group: "finance and payment",
    name: "linechart02",
    component: Linechart02Icon,
  },
  {
    group: "finance and payment",
    name: "linechart",
    component: LinechartIcon,
  },
  {
    group: "finance and payment",
    name: "mastercard",
    component: MastercardIcon,
  },
  {
    group: "finance and payment",
    name: "mobilepayment",
    component: MobilepaymentIcon,
  },
  {
    group: "finance and payment",
    name: "money01",
    component: Money01Icon,
  },
  {
    group: "finance and payment",
    name: "money02",
    component: Money02Icon,
  },
  {
    group: "finance and payment",
    name: "moneybagcoin1",
    component: Moneybagcoin1Icon,
  },
  {
    group: "finance and payment",
    name: "moneybagcoin",
    component: MoneybagcoinIcon,
  },
  {
    group: "finance and payment",
    name: "moneybagdollar",
    component: MoneybagdollarIcon,
  },
  {
    group: "finance and payment",
    name: "moneybageuro",
    component: MoneybageuroIcon,
  },
  {
    group: "finance and payment",
    name: "moneybagpound",
    component: MoneybagpoundIcon,
  },
  {
    group: "finance and payment",
    name: "moneybagyen",
    component: MoneybagyenIcon,
  },
  {
    group: "finance and payment",
    name: "moneybag",
    component: MoneybagIcon,
  },
  {
    group: "finance and payment",
    name: "moneyadd",
    component: MoneyaddIcon,
  },
  {
    group: "finance and payment",
    name: "moneyarrowdown",
    component: MoneyarrowdownIcon,
  },
  {
    group: "finance and payment",
    name: "moneyarrowleft",
    component: MoneyarrowleftIcon,
  },
  {
    group: "finance and payment",
    name: "moneyarrowright",
    component: MoneyarrowrightIcon,
  },
  {
    group: "finance and payment",
    name: "moneyarrowup",
    component: MoneyarrowupIcon,
  },
  {
    group: "finance and payment",
    name: "moneycheck",
    component: MoneycheckIcon,
  },
  {
    group: "finance and payment",
    name: "moneycoin",
    component: MoneycoinIcon,
  },
  {
    group: "finance and payment",
    name: "moneydollar",
    component: MoneydollarIcon,
  },
  {
    group: "finance and payment",
    name: "moneyeuro",
    component: MoneyeuroIcon,
  },
  {
    group: "finance and payment",
    name: "moneyfavourite",
    component: MoneyfavouriteIcon,
  },
  {
    group: "finance and payment",
    name: "moneyincome",
    component: MoneyincomeIcon,
  },
  {
    group: "finance and payment",
    name: "moneyminus",
    component: MoneyminusIcon,
  },
  {
    group: "finance and payment",
    name: "moneypound",
    component: MoneypoundIcon,
  },
  {
    group: "finance and payment",
    name: "moneyprofit01",
    component: Moneyprofit01Icon,
  },
  {
    group: "finance and payment",
    name: "moneyprofit",
    component: MoneyprofitIcon,
  },
  {
    group: "finance and payment",
    name: "moneyremove",
    component: MoneyremoveIcon,
  },
  {
    group: "finance and payment",
    name: "moneyuser",
    component: MoneyuserIcon,
  },
  {
    group: "finance and payment",
    name: "moneywithdraw",
    component: MoneywithdrawIcon,
  },
  {
    group: "finance and payment",
    name: "moneyyen",
    component: MoneyyenIcon,
  },
  { group: "finance and payment", name: "money", component: MoneyIcon },
  {
    group: "finance and payment",
    name: "piechart",
    component: PiechartIcon,
  },
  { group: "finance and payment", name: "pound", component: PoundIcon },
  {
    group: "finance and payment",
    name: "profit01",
    component: Profit01Icon,
  },
  {
    group: "finance and payment",
    name: "profit02",
    component: Profit02Icon,
  },
  { group: "finance and payment", name: "profit", component: ProfitIcon },
  {
    group: "finance and payment",
    name: "pyramid",
    component: PyramidIcon,
  },
  {
    group: "finance and payment",
    name: "safebox01",
    component: Safebox01Icon,
  },
  {
    group: "finance and payment",
    name: "safebox",
    component: SafeboxIcon,
  },
  { group: "finance and payment", name: "shield", component: ShieldIcon },
  {
    group: "finance and payment",
    name: "transfer",
    component: TransferIcon,
  },
  {
    group: "finance and payment",
    name: "umbrella",
    component: UmbrellaIcon,
  },
  {
    group: "finance and payment",
    name: "wallet01",
    component: Wallet01Icon,
  },
  {
    group: "finance and payment",
    name: "walletadd01",
    component: Walletadd01Icon,
  },
  {
    group: "finance and payment",
    name: "walletadd",
    component: WalletaddIcon,
  },
  {
    group: "finance and payment",
    name: "walletarrowdown01",
    component: Walletarrowdown01Icon,
  },
  {
    group: "finance and payment",
    name: "walletarrowdown",
    component: WalletarrowdownIcon,
  },
  {
    group: "finance and payment",
    name: "walletarrowleft01",
    component: Walletarrowleft01Icon,
  },
  {
    group: "finance and payment",
    name: "walletarrowleft",
    component: WalletarrowleftIcon,
  },
  {
    group: "finance and payment",
    name: "walletarrowright01",
    component: Walletarrowright01Icon,
  },
  {
    group: "finance and payment",
    name: "walletarrowright",
    component: WalletarrowrightIcon,
  },
  {
    group: "finance and payment",
    name: "walletarrowup01",
    component: Walletarrowup01Icon,
  },
  {
    group: "finance and payment",
    name: "walletarrowup",
    component: WalletarrowupIcon,
  },
  {
    group: "finance and payment",
    name: "walletcheck01",
    component: Walletcheck01Icon,
  },
  {
    group: "finance and payment",
    name: "walletcheck",
    component: WalletcheckIcon,
  },
  {
    group: "finance and payment",
    name: "walletminus01",
    component: Walletminus01Icon,
  },
  {
    group: "finance and payment",
    name: "walletminus",
    component: WalletminusIcon,
  },
  {
    group: "finance and payment",
    name: "walletremove01",
    component: Walletremove01Icon,
  },
  {
    group: "finance and payment",
    name: "walletremove",
    component: WalletremoveIcon,
  },
  {
    group: "finance and payment",
    name: "walletuser01",
    component: Walletuser01Icon,
  },
  {
    group: "finance and payment",
    name: "walletuser",
    component: WalletuserIcon,
  },
  { group: "finance and payment", name: "wallet", component: WalletIcon },
  {
    group: "finance and payment",
    name: "withdraw",
    component: WithdrawIcon,
  },
  { group: "finance and payment", name: "yen", component: YenIcon },
  { group: "health", name: "activity", component: ActivityIcon },
  { group: "health", name: "ambulance", component: AmbulanceIcon },
  { group: "health", name: "blood01", component: Blood01Icon },
  { group: "health", name: "blood", component: BloodIcon },
  { group: "health", name: "callhospital", component: CallhospitalIcon },
  { group: "health", name: "capsule01", component: Capsule01Icon },
  { group: "health", name: "capsule", component: CapsuleIcon },
  { group: "health", name: "cardiogram", component: CardiogramIcon },
  {
    group: "health",
    name: "clipboardactivity",
    component: ClipboardactivityIcon,
  },
  { group: "health", name: "dna", component: DnaIcon },
  { group: "health", name: "eyedropper", component: EyedropperIcon },
  { group: "health", name: "eye", component: EyeIcon },
  { group: "health", name: "giveblood", component: GivebloodIcon },
  { group: "health", name: "givepill", component: GivepillIcon },
  { group: "health", name: "healthcare", component: HealthcareIcon },
  // { group: "health", name: "heart", component: HeartIcon },
  // { group: "health", name: "honey", component: HoneyIcon },
  { group: "health", name: "hospital01", component: Hospital01Icon },
  { group: "health", name: "hospitalbed", component: HospitalbedIcon },
  { group: "health", name: "hospital", component: HospitalIcon },
  { group: "health", name: "injection", component: InjectionIcon },
  {
    group: "health",
    name: "locationhospital",
    component: LocationhospitalIcon,
  },
  { group: "health", name: "mask", component: MaskIcon },
  { group: "health", name: "medicalbook", component: MedicalbookIcon },
  {
    group: "health",
    name: "medicalinformation",
    component: MedicalinformationIcon,
  },
  {
    group: "health",
    name: "medicalservice",
    component: MedicalserviceIcon,
  },
  { group: "health", name: "pilltablet", component: PilltabletIcon },
  { group: "health", name: "pills01", component: Pills01Icon },
  { group: "health", name: "pills", component: PillsIcon },
  { group: "health", name: "plaster", component: PlasterIcon },
  { group: "health", name: "stethoscope", component: StethoscopeIcon },
  { group: "health", name: "syrup", component: SyrupIcon },
  { group: "health", name: "testtube", component: TesttubeIcon },
  { group: "health", name: "thermometer", component: ThermometerIcon },
  { group: "health", name: "tooth", component: ToothIcon },
  { group: "health", name: "transfusion", component: TransfusionIcon },
  { group: "health", name: "wheelchair01", component: Wheelchair01Icon },
  { group: "health", name: "wheelchair", component: WheelchairIcon },
  { group: "interface", name: "addcircle", component: AddcircleIcon },
  {
    group: "interface",
    name: "addrectangle",
    component: AddrectangleIcon,
  },
  { group: "interface", name: "agenda", component: AgendaIcon },
  { group: "interface", name: "alarm", component: AlarmIcon },
  { group: "interface", name: "appsadd", component: AppsaddIcon },
  { group: "interface", name: "appsremove", component: AppsremoveIcon },
  { group: "interface", name: "apps", component: AppsIcon },
  { group: "interface", name: "board", component: BoardIcon },
  { group: "interface", name: "bookmark", component: BookmarkIcon },
  { group: "interface", name: "brightness", component: BrightnessIcon },
  { group: "interface", name: "browser", component: BrowserIcon },
  { group: "interface", name: "calendar", component: CalendarIcon },
  { group: "interface", name: "cameraoff", component: CameraoffIcon },
  { group: "interface", name: "camera", component: CameraIcon },
  {
    group: "interface",
    name: "checkmarkcircle",
    component: CheckmarkcircleIcon,
  },
  {
    group: "interface",
    name: "checkmarkrectangle",
    component: CheckmarkrectangleIcon,
  },
  { group: "interface", name: "checkcircle", component: CheckcircleIcon },
  {
    group: "interface",
    name: "checkrectangle",
    component: CheckrectangleIcon,
  },
  { group: "interface", name: "clearcircle", component: ClearcircleIcon },
  {
    group: "interface",
    name: "clearrectangle",
    component: ClearrectangleIcon,
  },
  {
    group: "interface",
    name: "clockcircle1",
    component: Clockcircle1Icon,
  },
  // { group: "interface", name: "clockcircle", component: ClockcircleIcon },
  {
    group: "interface",
    name: "clockrectangle1",
    component: Clockrectangle1Icon,
  },
  {
    group: "interface",
    name: "clockrectangle",
    component: ClockrectangleIcon,
  },
  { group: "interface", name: "collection", component: CollectionIcon },
  { group: "interface", name: "compass", component: CompassIcon },
  { group: "interface", name: "computer", component: ComputerIcon },
  { group: "interface", name: "copycheck", component: CopycheckIcon },
  { group: "interface", name: "copy", component: CopyIcon },
  { group: "interface", name: "cpu", component: CpuIcon },
  { group: "interface", name: "diamond", component: DiamondIcon },
  { group: "interface", name: "disk", component: DiskIcon },
  { group: "interface", name: "dislike", component: DislikeIcon },
  {
    group: "interface",
    name: "editrectangle",
    component: EditrectangleIcon,
  },
  { group: "interface", name: "edit", component: EditIcon },
  { group: "interface", name: "eye01", component: Eye01Icon },
  { group: "interface", name: "eyedisable", component: EyedisableIcon },
  { group: "interface", name: "eye", component: EyeIcon },
  {
    group: "interface",
    name: "feedrectangle",
    component: FeedrectangleIcon,
  },
  { group: "interface", name: "filter01", component: Filter01Icon },
  { group: "interface", name: "filter02", component: Filter02Icon },
  { group: "interface", name: "filter03", component: Filter03Icon },
  { group: "interface", name: "filter04", component: Filter04Icon },
  { group: "interface", name: "filter05", component: Filter05Icon },
  { group: "interface", name: "filter06", component: Filter06Icon },
  { group: "interface", name: "filter07", component: Filter07Icon },
  { group: "interface", name: "filter", component: FilterIcon },
  // { group: "interface", name: "flag", component: FlagIcon },
  { group: "interface", name: "focus", component: FocusIcon },
  { group: "interface", name: "gift", component: GiftIcon },
  { group: "interface", name: "globe", component: GlobeIcon },
  { group: "interface", name: "helppolygon", component: HelppolygonIcon },
  { group: "interface", name: "help", component: HelpIcon },
  { group: "interface", name: "home01", component: Home01Icon },
  { group: "interface", name: "home02", component: Home02Icon },
  { group: "interface", name: "home03", component: Home03Icon },
  { group: "interface", name: "home04", component: Home04Icon },
  { group: "interface", name: "imagecircle", component: ImagecircleIcon },
  {
    group: "interface",
    name: "imagerectangle",
    component: ImagerectangleIcon,
  },
  {
    group: "interface",
    name: "informationcircle",
    component: InformationcircleIcon,
  },
  {
    group: "interface",
    name: "informationpolygon",
    component: InformationpolygonIcon,
  },
  {
    group: "interface",
    name: "informationrectangle",
    component: InformationrectangleIcon,
  },
  { group: "interface", name: "information", component: InformationIcon },
  { group: "interface", name: "interactive", component: InteractiveIcon },
  { group: "interface", name: "invoice", component: InvoiceIcon },
  { group: "interface", name: "layers", component: LayersIcon },
  { group: "interface", name: "lifebuoy", component: LifebuoyIcon },
  { group: "interface", name: "like", component: LikeIcon },
  {
    group: "interface",
    name: "listviewcircle",
    component: ListviewcircleIcon,
  },
  {
    group: "interface",
    name: "listviewrectangle",
    component: ListviewrectangleIcon,
  },
  { group: "interface", name: "loading01", component: Loading01Icon },
  { group: "interface", name: "loading", component: LoadingIcon },
  { group: "interface", name: "location01", component: Location01Icon },
  { group: "interface", name: "location02", component: Location02Icon },
  { group: "interface", name: "location03", component: Location03Icon },
  { group: "interface", name: "lock01", component: Lock01Icon },
  { group: "interface", name: "lock", component: LockIcon },
  { group: "interface", name: "login01", component: Login01Icon },
  { group: "interface", name: "login", component: LoginIcon },
  { group: "interface", name: "logout01", component: Logout01Icon },
  { group: "interface", name: "logout", component: LogoutIcon },
  { group: "interface", name: "love", component: LoveIcon },
  { group: "interface", name: "micmute", component: MicmuteIcon },
  { group: "interface", name: "mic", component: MicIcon },
  {
    group: "interface",
    name: "microphonemute",
    component: MicrophonemuteIcon,
  },
  { group: "interface", name: "microphone", component: MicrophoneIcon },
  { group: "interface", name: "minus01", component: Minus01Icon },
  { group: "interface", name: "minus", component: MinusIcon },
  { group: "interface", name: "money", component: MoneyIcon },
  {
    group: "interface",
    name: "morehorizontal",
    component: MorehorizontalIcon,
  },
  {
    group: "interface",
    name: "morevertical",
    component: MoreverticalIcon,
  },
  { group: "interface", name: "mouse", component: MouseIcon },
  { group: "interface", name: "nib", component: NibIcon },
  { group: "interface", name: "notepad", component: NotepadIcon },
  {
    group: "interface",
    name: "notification",
    component: NotificationIcon,
  },
  {
    group: "interface",
    name: "notification01",
    component: Notification01Icon,
  },
  {
    group: "interface",
    name: "notification02",
    component: Notification02Icon,
  },
  {
    group: "interface",
    name: "notification03",
    component: Notification03Icon,
  },
  {
    group: "interface",
    name: "notification04",
    component: Notification04Icon,
  },
  {
    group: "interface",
    name: "notification05",
    component: Notification05Icon,
  },
  {
    group: "interface",
    name: "notificationcircle",
    component: NotificationcircleIcon,
  },
  {
    group: "interface",
    name: "notificationrectangle",
    component: NotificationrectangleIcon,
  },
  {
    group: "interface",
    name: "notificationringing1",
    component: Notificationringing1Icon,
  },
  {
    group: "interface",
    name: "notificationringing",
    component: NotificationringingIcon,
  },
  {
    group: "interface",
    name: "notificationsilent1",
    component: Notificationsilent1Icon,
  },
  {
    group: "interface",
    name: "notificationsilent",
    component: NotificationsilentIcon,
  },
  { group: "interface", name: "openbook", component: OpenbookIcon },
  { group: "interface", name: "paintbucket", component: PaintbucketIcon },
  { group: "interface", name: "pin", component: PinIcon },
  { group: "interface", name: "plugin", component: PluginIcon },
  { group: "interface", name: "plus01", component: Plus01Icon },
  { group: "interface", name: "plus", component: PlusIcon },
  { group: "interface", name: "powercircle", component: PowercircleIcon },
  {
    group: "interface",
    name: "powerrectangle",
    component: PowerrectangleIcon,
  },
  {
    group: "interface",
    name: "presentation",
    component: PresentationIcon,
  },
  { group: "interface", name: "remove01", component: Remove01Icon },
  { group: "interface", name: "remove02", component: Remove02Icon },
  {
    group: "interface",
    name: "removecircle",
    component: RemovecircleIcon,
  },
  {
    group: "interface",
    name: "removerectangle",
    component: RemoverectangleIcon,
  },
  { group: "interface", name: "remove", component: RemoveIcon },
  {
    group: "interface",
    name: "replyrectangle",
    component: ReplyrectangleIcon,
  },
  { group: "interface", name: "sadcircle", component: SadcircleIcon },
  {
    group: "interface",
    name: "sadrectangle",
    component: SadrectangleIcon,
  },
  { group: "interface", name: "search01", component: Search01Icon },
  { group: "interface", name: "search02", component: Search02Icon },
  { group: "interface", name: "search03", component: Search03Icon },
  { group: "interface", name: "searchminus", component: SearchminusIcon },
  { group: "interface", name: "searchplus", component: SearchplusIcon },
  {
    group: "interface",
    name: "searchremove",
    component: SearchremoveIcon,
  },
  { group: "interface", name: "setting", component: SettingIcon },
  {
    group: "interface",
    name: "sharerectangle",
    component: SharerectangleIcon,
  },
  { group: "interface", name: "share", component: ShareIcon },
  { group: "interface", name: "shieldcheck", component: ShieldcheckIcon },
  {
    group: "interface",
    name: "shieldwarning",
    component: ShieldwarningIcon,
  },
  { group: "interface", name: "shield", component: ShieldIcon },
  { group: "interface", name: "shoppingbag", component: ShoppingbagIcon },
  {
    group: "interface",
    name: "shoppingbasket",
    component: ShoppingbasketIcon,
  },
  // {
  //   group: "interface",
  //   name: "smileellipse",
  //   component: SmileellipseIcon,
  // },
  {
    group: "interface",
    name: "smilerectangle",
    component: SmilerectangleIcon,
  },
  {
    group: "interface",
    name: "sortarrowdown01",
    component: Sortarrowdown01Icon,
  },
  {
    group: "interface",
    name: "sortarrowdown",
    component: SortarrowdownIcon,
  },
  {
    group: "interface",
    name: "sortarrowup01",
    component: Sortarrowup01Icon,
  },
  { group: "interface", name: "sortarrowup", component: SortarrowupIcon },
  { group: "interface", name: "sortcircle", component: SortcircleIcon },
  {
    group: "interface",
    name: "sortrectangle",
    component: SortrectangleIcon,
  },
  { group: "interface", name: "speedtest", component: SpeedtestIcon },
  { group: "interface", name: "star", component: StarIcon },
  { group: "interface", name: "target", component: TargetIcon },
  { group: "interface", name: "tickdouble", component: TickdoubleIcon },
  { group: "interface", name: "tick", component: TickIcon },
  {
    group: "interface",
    name: "ticketpercent",
    component: TicketpercentIcon,
  },
  { group: "interface", name: "ticket", component: TicketIcon },
  { group: "interface", name: "training", component: TrainingIcon },
  { group: "interface", name: "trash01", component: Trash01Icon },
  { group: "interface", name: "trash", component: TrashIcon },
  { group: "interface", name: "unlock01", component: Unlock01Icon },
  { group: "interface", name: "unlock", component: UnlockIcon },
  { group: "interface", name: "usercircle", component: UsercircleIcon },
  {
    group: "interface",
    name: "userrectangle",
    component: UserrectangleIcon,
  },
  { group: "interface", name: "user", component: UserIcon },
  {
    group: "interface",
    name: "warningerror",
    component: WarningerrorIcon,
  },
  {
    group: "interface",
    name: "warningpolygon",
    component: WarningpolygonIcon,
  },
  { group: "interface", name: "warning", component: WarningIcon },
  { group: "interface", name: "zoomin", component: ZoominIcon },
  { group: "interface", name: "zoomout", component: ZoomoutIcon },
  { group: "interface", name: "zoomtext", component: ZoomtextIcon },
  { group: "interface", name: "zoom", component: ZoomIcon },
  {
    group: "multimedia and audio",
    name: "camera01",
    component: Camera01Icon,
  },
  {
    group: "multimedia and audio",
    name: "cameravideo01",
    component: Cameravideo01Icon,
  },
  {
    group: "multimedia and audio",
    name: "cameravideosilent",
    component: CameravideosilentIcon,
  },
  {
    group: "multimedia and audio",
    name: "cameravideo",
    component: CameravideoIcon,
  },
  {
    group: "multimedia and audio",
    name: "camerasilent01",
    component: Camerasilent01Icon,
  },
  {
    group: "multimedia and audio",
    name: "camerasilent",
    component: CamerasilentIcon,
  },
  {
    group: "multimedia and audio",
    name: "camera",
    component: CameraIcon,
  },
  {
    group: "multimedia and audio",
    name: "cdburning",
    component: CdburningIcon,
  },
  {
    group: "multimedia and audio",
    name: "clapperboard",
    component: ClapperboardIcon,
  },
  {
    group: "multimedia and audio",
    name: "earphone",
    component: EarphoneIcon,
  },
  {
    group: "multimedia and audio",
    name: "equalizer01",
    component: Equalizer01Icon,
  },
  {
    group: "multimedia and audio",
    name: "equalizer",
    component: EqualizerIcon,
  },
  {
    group: "multimedia and audio",
    name: "favourite",
    component: FavouriteIcon,
  },
  {
    group: "multimedia and audio",
    name: "film01",
    component: Film01Icon,
  },
  {
    group: "multimedia and audio",
    name: "film02",
    component: Film02Icon,
  },
  {
    group: "multimedia and audio",
    name: "film03",
    component: Film03Icon,
  },
  { group: "multimedia and audio", name: "film", component: FilmIcon },
  {
    group: "multimedia and audio",
    name: "floppydisk",
    component: FloppydiskIcon,
  },
  {
    group: "multimedia and audio",
    name: "headphones1",
    component: Headphones1Icon,
  },
  {
    group: "multimedia and audio",
    name: "headphonessilent",
    component: HeadphonessilentIcon,
  },
  {
    group: "multimedia and audio",
    name: "headphones",
    component: HeadphonesIcon,
  },
  {
    group: "multimedia and audio",
    name: "image01",
    component: Image01Icon,
  },
  {
    group: "multimedia and audio",
    name: "imageadd",
    component: ImageaddIcon,
  },
  {
    group: "multimedia and audio",
    name: "imagedownload",
    component: ImagedownloadIcon,
  },
  {
    group: "multimedia and audio",
    name: "imageedit",
    component: ImageeditIcon,
  },
  {
    group: "multimedia and audio",
    name: "imagefavourite",
    component: ImagefavouriteIcon,
  },
  {
    group: "multimedia and audio",
    name: "imageminus",
    component: ImageminusIcon,
  },
  {
    group: "multimedia and audio",
    name: "imageremove",
    component: ImageremoveIcon,
  },
  {
    group: "multimedia and audio",
    name: "imagetick",
    component: ImagetickIcon,
  },
  {
    group: "multimedia and audio",
    name: "imageupload",
    component: ImageuploadIcon,
  },
  { group: "multimedia and audio", name: "image", component: ImageIcon },
  { group: "multimedia and audio", name: "ipod", component: IpodIcon },
  {
    group: "multimedia and audio",
    name: "loudspeaker",
    component: LoudspeakerIcon,
  },
  {
    group: "multimedia and audio",
    name: "music05",
    component: Music05Icon,
  },
  { group: "multimedia and audio", name: "music", component: MusicIcon },
  {
    group: "multimedia and audio",
    name: "music01",
    component: Music01Icon,
  },
  {
    group: "multimedia and audio",
    name: "music02",
    component: Music02Icon,
  },
  {
    group: "multimedia and audio",
    name: "music03",
    component: Music03Icon,
  },
  {
    group: "multimedia and audio",
    name: "music04",
    component: Music04Icon,
  },
  {
    group: "multimedia and audio",
    name: "next01",
    component: Next01Icon,
  },
  { group: "multimedia and audio", name: "next", component: NextIcon },
  { group: "multimedia and audio", name: "pause", component: PauseIcon },
  {
    group: "multimedia and audio",
    name: "playadd",
    component: PlayaddIcon,
  },
  {
    group: "multimedia and audio",
    name: "playfavourite",
    component: PlayfavouriteIcon,
  },
  {
    group: "multimedia and audio",
    name: "playremove01",
    component: Playremove01Icon,
  },
  {
    group: "multimedia and audio",
    name: "playremove",
    component: PlayremoveIcon,
  },
  { group: "multimedia and audio", name: "play", component: PlayIcon },
  {
    group: "multimedia and audio",
    name: "playlist01",
    component: Playlist01Icon,
  },
  {
    group: "multimedia and audio",
    name: "playlist",
    component: PlaylistIcon,
  },
  {
    group: "multimedia and audio",
    name: "previous01",
    component: Previous01Icon,
  },
  {
    group: "multimedia and audio",
    name: "previous",
    component: PreviousIcon,
  },
  {
    group: "multimedia and audio",
    name: "projector",
    component: ProjectorIcon,
  },
  { group: "multimedia and audio", name: "queue", component: QueueIcon },
  {
    group: "multimedia and audio",
    name: "record",
    component: RecordIcon,
  },
  {
    group: "multimedia and audio",
    name: "repeat",
    component: RepeatIcon,
  },
  {
    group: "multimedia and audio",
    name: "replay",
    component: ReplayIcon,
  },
  {
    group: "multimedia and audio",
    name: "shuffle",
    component: ShuffleIcon,
  },
  {
    group: "multimedia and audio",
    name: "song01",
    component: Song01Icon,
  },
  { group: "multimedia and audio", name: "song", component: SongIcon },
  {
    group: "multimedia and audio",
    name: "speakerdisable",
    component: SpeakerdisableIcon,
  },
  {
    group: "multimedia and audio",
    name: "speakerminus",
    component: SpeakerminusIcon,
  },
  {
    group: "multimedia and audio",
    name: "speakermute",
    component: SpeakermuteIcon,
  },
  {
    group: "multimedia and audio",
    name: "speakerplus",
    component: SpeakerplusIcon,
  },
  {
    group: "multimedia and audio",
    name: "speaker",
    component: SpeakerIcon,
  },
  { group: "multimedia and audio", name: "stop", component: StopIcon },
  {
    group: "multimedia and audio",
    name: "videoadd",
    component: VideoaddIcon,
  },
  {
    group: "multimedia and audio",
    name: "videofavourite",
    component: VideofavouriteIcon,
  },
  {
    group: "multimedia and audio",
    name: "videominus",
    component: VideominusIcon,
  },
  {
    group: "multimedia and audio",
    name: "videoremove",
    component: VideoremoveIcon,
  },
  {
    group: "multimedia and audio",
    name: "videotick",
    component: VideotickIcon,
  },
  {
    group: "multimedia and audio",
    name: "vynil01",
    component: Vynil01Icon,
  },
  { group: "multimedia and audio", name: "vynil", component: VynilIcon },
  { group: "multimedia and audio", name: "waves", component: WavesIcon },
  {
    group: "navigation maps",
    name: "destination01",
    component: Destination01Icon,
  },
  {
    group: "navigation maps",
    name: "destination02",
    component: Destination02Icon,
  },
  {
    group: "navigation maps",
    name: "destination03",
    component: Destination03Icon,
  },
  {
    group: "navigation maps",
    name: "destination",
    component: DestinationIcon,
  },
  { group: "navigation maps", name: "flag01", component: Flag01Icon },
  // { group: "navigation maps", name: "flag", component: FlagIcon },
  // { group: "navigation maps", name: "globe01", component: Globe01Icon },
  { group: "navigation maps", name: "globe", component: GlobeIcon },
  { group: "navigation maps", name: "gps01", component: Gps01Icon },
  { group: "navigation maps", name: "gpsoff", component: GpsoffIcon },
  { group: "navigation maps", name: "gps", component: GpsIcon },
  {
    group: "navigation maps",
    name: "homelocation01",
    component: Homelocation01Icon,
  },
  {
    group: "navigation maps",
    name: "homelocation",
    component: HomelocationIcon,
  },
  {
    group: "navigation maps",
    name: "locationadd",
    component: LocationaddIcon,
  },
  {
    group: "navigation maps",
    name: "locationminus",
    component: LocationminusIcon,
  },
  {
    group: "navigation maps",
    name: "locationremove",
    component: LocationremoveIcon,
  },
  {
    group: "navigation maps",
    name: "locationshare",
    component: LocationshareIcon,
  },
  {
    group: "navigation maps",
    name: "locationuser",
    component: LocationuserIcon,
  },
  { group: "navigation maps", name: "location", component: LocationIcon },
  { group: "navigation maps", name: "map01", component: Map01Icon },
  { group: "navigation maps", name: "map02", component: Map02Icon },
  { group: "navigation maps", name: "map03", component: Map03Icon },
  {
    group: "navigation maps",
    name: "mapgpsnavigation01",
    component: Mapgpsnavigation01Icon,
  },
  {
    group: "navigation maps",
    name: "mapgpsnavigation03",
    component: Mapgpsnavigation03Icon,
  },
  {
    group: "navigation maps",
    name: "mapgpsnavigation04",
    component: Mapgpsnavigation04Icon,
  },
  {
    group: "navigation maps",
    name: "mapgpsnavigation",
    component: MapgpsnavigationIcon,
  },
  {
    group: "navigation maps",
    name: "maplocation01",
    component: Maplocation01Icon,
  },
  {
    group: "navigation maps",
    name: "maplocation02",
    component: Maplocation02Icon,
  },
  {
    group: "navigation maps",
    name: "maplocation03",
    component: Maplocation03Icon,
  },
  {
    group: "navigation maps",
    name: "maplocation04",
    component: Maplocation04Icon,
  },
  {
    group: "navigation maps",
    name: "maplocation05",
    component: Maplocation05Icon,
  },
  {
    group: "navigation maps",
    name: "maplocation06",
    component: Maplocation06Icon,
  },
  {
    group: "navigation maps",
    name: "maplocation07",
    component: Maplocation07Icon,
  },
  {
    group: "navigation maps",
    name: "maplocation",
    component: MaplocationIcon,
  },
  {
    group: "navigation maps",
    name: "mappeople01",
    component: Mappeople01Icon,
  },
  {
    group: "navigation maps",
    name: "mappeople",
    component: MappeopleIcon,
  },
  { group: "navigation maps", name: "mappin01", component: Mappin01Icon },
  { group: "navigation maps", name: "mappin02", component: Mappin02Icon },
  { group: "navigation maps", name: "mappin03", component: Mappin03Icon },
  { group: "navigation maps", name: "mappin", component: MappinIcon },
  { group: "navigation maps", name: "map", component: MapIcon },
  {
    group: "navigation maps",
    name: "navigation",
    component: NavigationIcon,
  },
  { group: "navigation maps", name: "people01", component: People01Icon },
  { group: "navigation maps", name: "people", component: PeopleIcon },
  { group: "navigation maps", name: "pin01", component: Pin01Icon },
  { group: "navigation maps", name: "pin02", component: Pin02Icon },
  { group: "navigation maps", name: "pin", component: PinIcon },
  { group: "navigation maps", name: "road01", component: Road01Icon },
  {
    group: "navigation maps",
    name: "roadlocation01",
    component: Roadlocation01Icon,
  },
  {
    group: "navigation maps",
    name: "roadlocation",
    component: RoadlocationIcon,
  },
  {
    group: "navigation maps",
    name: "roadpin01",
    component: Roadpin01Icon,
  },
  { group: "navigation maps", name: "roadpin", component: RoadpinIcon },
  { group: "navigation maps", name: "road", component: RoadIcon },
  {
    group: "navigation maps",
    name: "signpost01",
    component: Signpost01Icon,
  },
  {
    group: "navigation maps",
    name: "signpost02",
    component: Signpost02Icon,
  },
  {
    group: "navigation maps",
    name: "signpost03",
    component: Signpost03Icon,
  },
  {
    group: "navigation maps",
    name: "signpost04",
    component: Signpost04Icon,
  },
  { group: "navigation maps", name: "signpost", component: SignpostIcon },
  { group: "navigation maps", name: "turn01", component: Turn01Icon },
  { group: "navigation maps", name: "turnleft", component: TurnleftIcon },
  {
    group: "navigation maps",
    name: "turnright",
    component: TurnrightIcon,
  },
  { group: "navigation maps", name: "turn", component: TurnIcon },
  { group: "navigation maps", name: "uturn01", component: Uturn01Icon },
  { group: "navigation maps", name: "uturn", component: UturnIcon },
  { group: "notes and task", name: "notes01", component: Notes01Icon },
  { group: "notes and task", name: "notesadd", component: NotesaddIcon },
  {
    group: "notes and task",
    name: "notescheck",
    component: NotescheckIcon,
  },
  {
    group: "notes and task",
    name: "notesdelete",
    component: NotesdeleteIcon,
  },
  {
    group: "notes and task",
    name: "notesdownload",
    component: NotesdownloadIcon,
  },
  {
    group: "notes and task",
    name: "notesedit01",
    component: Notesedit01Icon,
  },
  {
    group: "notes and task",
    name: "noteseditadd",
    component: NoteseditaddIcon,
  },
  {
    group: "notes and task",
    name: "noteseditcheck",
    component: NoteseditcheckIcon,
  },
  {
    group: "notes and task",
    name: "noteseditdelete",
    component: NoteseditdeleteIcon,
  },
  {
    group: "notes and task",
    name: "noteseditdownload",
    component: NoteseditdownloadIcon,
  },
  {
    group: "notes and task",
    name: "noteseditfavourite",
    component: NoteseditfavouriteIcon,
  },
  {
    group: "notes and task",
    name: "noteseditminus",
    component: NoteseditminusIcon,
  },
  {
    group: "notes and task",
    name: "noteseditsearch",
    component: NoteseditsearchIcon,
  },
  {
    group: "notes and task",
    name: "noteseditupload",
    component: NotesedituploadIcon,
  },
  {
    group: "notes and task",
    name: "notesedit",
    component: NoteseditIcon,
  },
  {
    group: "notes and task",
    name: "notesfavourite",
    component: NotesfavouriteIcon,
  },
  {
    group: "notes and task",
    name: "notesremove",
    component: NotesremoveIcon,
  },
  {
    group: "notes and task",
    name: "notessearch",
    component: NotessearchIcon,
  },
  {
    group: "notes and task",
    name: "notesupload",
    component: NotesuploadIcon,
  },
  { group: "notes and task", name: "notes", component: NotesIcon },
  { group: "notes and task", name: "task01", component: Task01Icon },
  { group: "notes and task", name: "taskadd", component: TaskaddIcon },
  {
    group: "notes and task",
    name: "taskcheck",
    component: TaskcheckIcon,
  },
  {
    group: "notes and task",
    name: "taskdelete",
    component: TaskdeleteIcon,
  },
  {
    group: "notes and task",
    name: "taskdownload",
    component: TaskdownloadIcon,
  },
  {
    group: "notes and task",
    name: "taskfavourite",
    component: TaskfavouriteIcon,
  },
  {
    group: "notes and task",
    name: "taskminus",
    component: TaskminusIcon,
  },
  {
    group: "notes and task",
    name: "tasksearch",
    component: TasksearchIcon,
  },
  {
    group: "notes and task",
    name: "taskupload",
    component: TaskuploadIcon,
  },
  { group: "notes and task", name: "task", component: TaskIcon },
  { group: "shipping and delivery", name: "addto", component: AddtoIcon },
  {
    group: "shipping and delivery",
    name: "airplane",
    component: AirplaneIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxadd",
    component: BoxaddIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxarrowdown",
    component: BoxarrowdownIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxarrowup",
    component: BoxarrowupIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxcancel",
    component: BoxcancelIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxdone",
    component: BoxdoneIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxlocation",
    component: BoxlocationIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxlove",
    component: BoxloveIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxminus",
    component: BoxminusIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxsearch",
    component: BoxsearchIcon,
  },
  {
    group: "shipping and delivery",
    name: "boxtime",
    component: BoxtimeIcon,
  },
  {
    group: "shipping and delivery",
    name: "cargoship",
    component: CargoshipIcon,
  },
  { group: "shipping and delivery", name: "cargo", component: CargoIcon },
  {
    group: "shipping and delivery",
    name: "deliveryairplane",
    component: DeliveryairplaneIcon,
  },
  {
    group: "shipping and delivery",
    name: "deliverycancel",
    component: DeliverycancelIcon,
  },
  {
    group: "shipping and delivery",
    name: "deliveryconveyor",
    component: DeliveryconveyorIcon,
  },
  {
    group: "shipping and delivery",
    name: "deliverydone",
    component: DeliverydoneIcon,
  },
  {
    group: "shipping and delivery",
    name: "deliveryparachute",
    component: DeliveryparachuteIcon,
  },
  {
    group: "shipping and delivery",
    name: "deliverytime",
    component: DeliverytimeIcon,
  },
  {
    group: "shipping and delivery",
    name: "delivery",
    component: DeliveryIcon,
  },
  {
    group: "shipping and delivery",
    name: "donation",
    component: DonationIcon,
  },
  {
    group: "shipping and delivery",
    name: "fastdelivery",
    component: FastdeliveryIcon,
  },
  {
    group: "shipping and delivery",
    name: "fasttime",
    component: FasttimeIcon,
  },
  {
    group: "shipping and delivery",
    name: "openbox01",
    component: Openbox01Icon,
  },
  {
    group: "shipping and delivery",
    name: "openboxadd",
    component: OpenboxaddIcon,
  },
  {
    group: "shipping and delivery",
    name: "openboxarrowdown",
    component: OpenboxarrowdownIcon,
  },
  {
    group: "shipping and delivery",
    name: "openboxarrowup",
    component: OpenboxarrowupIcon,
  },
  {
    group: "shipping and delivery",
    name: "openboxdone",
    component: OpenboxdoneIcon,
  },
  {
    group: "shipping and delivery",
    name: "openboxlove",
    component: OpenboxloveIcon,
  },
  {
    group: "shipping and delivery",
    name: "openboxminus",
    component: OpenboxminusIcon,
  },
  {
    group: "shipping and delivery",
    name: "openboxremove",
    component: OpenboxremoveIcon,
  },
  {
    group: "shipping and delivery",
    name: "openboxsearch",
    component: OpenboxsearchIcon,
  },
  {
    group: "shipping and delivery",
    name: "openbox",
    component: OpenboxIcon,
  },
  {
    group: "shipping and delivery",
    name: "package01",
    component: Package01Icon,
  },
  {
    group: "shipping and delivery",
    name: "package02",
    component: Package02Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox01",
    component: Packagebox01Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox02",
    component: Packagebox02Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox03",
    component: Packagebox03Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox04",
    component: Packagebox04Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox05",
    component: Packagebox05Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox06",
    component: Packagebox06Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox07",
    component: Packagebox07Icon,
  },
  {
    group: "shipping and delivery",
    name: "packagebox",
    component: PackageboxIcon,
  },
  {
    group: "shipping and delivery",
    name: "package",
    component: PackageIcon,
  },
  {
    group: "shipping and delivery",
    name: "packaginglocation",
    component: PackaginglocationIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingadd",
    component: PackagingaddIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingarrowdown",
    component: PackagingarrowdownIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingarrowup",
    component: PackagingarrowupIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingcancel",
    component: PackagingcancelIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingdone",
    component: PackagingdoneIcon,
  },
  {
    group: "shipping and delivery",
    name: "packaginglocation",
    component: PackaginglocationIcon,
  },
  {
    group: "shipping and delivery",
    name: "packaginglove",
    component: PackagingloveIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingminus",
    component: PackagingminusIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingsearch",
    component: PackagingsearchIcon,
  },
  {
    group: "shipping and delivery",
    name: "packagingtime",
    component: PackagingtimeIcon,
  },
  {
    group: "shipping and delivery",
    name: "packaging",
    component: PackagingIcon,
  },
  {
    group: "shipping and delivery",
    name: "parcel",
    component: ParcelIcon,
  },
  {
    group: "shipping and delivery",
    name: "return01",
    component: Return01Icon,
  },
  {
    group: "shipping and delivery",
    name: "return",
    component: ReturnIcon,
  },
  {
    group: "shipping and delivery",
    name: "shipping",
    component: ShippingIcon,
  },
  {
    group: "shipping and delivery",
    name: "trolley",
    component: TrolleyIcon,
  },
  {
    group: "shipping and delivery",
    name: "warehouse",
    component: WarehouseIcon,
  },
  { group: "smart house", name: "ac", component: AcIcon },
  { group: "smart house", name: "barcode", component: BarcodeIcon },
  { group: "smart house", name: "cam", component: CamIcon },
  { group: "smart house", name: "compas", component: CompasIcon },
  { group: "smart house", name: "door", component: DoorIcon },
  { group: "smart house", name: "eyescanner", component: EyescannerIcon },
  {
    group: "smart house",
    name: "fingerprint",
    component: FingerprintIcon,
  },
  { group: "smart house", name: "home01", component: Home01Icon },
  { group: "smart house", name: "homeadd01", component: Homeadd01Icon },
  { group: "smart house", name: "homeadd", component: HomeaddIcon },
  {
    group: "smart house",
    name: "homearrowdown01",
    component: Homearrowdown01Icon,
  },
  {
    group: "smart house",
    name: "homearrowdown",
    component: HomearrowdownIcon,
  },
  {
    group: "smart house",
    name: "homearrowup01",
    component: Homearrowup01Icon,
  },
  {
    group: "smart house",
    name: "homecelcius01",
    component: Homecelcius01Icon,
  },
  {
    group: "smart house",
    name: "homecelcius",
    component: HomecelciusIcon,
  },
  {
    group: "smart house",
    name: "homecheck01",
    component: Homecheck01Icon,
  },
  { group: "smart house", name: "homecheck", component: HomecheckIcon },
  {
    group: "smart house",
    name: "homecloud01",
    component: Homecloud01Icon,
  },
  { group: "smart house", name: "homecloud", component: HomecloudIcon },
  {
    group: "smart house",
    name: "homedownload01",
    component: Homedownload01Icon,
  },
  {
    group: "smart house",
    name: "homedownload",
    component: HomedownloadIcon,
  },
  { group: "smart house", name: "homeeye01", component: Homeeye01Icon },
  { group: "smart house", name: "homeeye", component: HomeeyeIcon },
  {
    group: "smart house",
    name: "homefavourite01",
    component: Homefavourite01Icon,
  },
  {
    group: "smart house",
    name: "homefavourite",
    component: HomefavouriteIcon,
  },
  {
    group: "smart house",
    name: "homefilter01",
    component: Homefilter01Icon,
  },
  { group: "smart house", name: "homefilter", component: HomefilterIcon },
  {
    group: "smart house",
    name: "homeflash01",
    component: Homeflash01Icon,
  },
  { group: "smart house", name: "homeflash", component: HomeflashIcon },
  {
    group: "smart house",
    name: "homefluid01",
    component: Homefluid01Icon,
  },
  { group: "smart house", name: "homefluid", component: HomefluidIcon },
  {
    group: "smart house",
    name: "homegraph01",
    component: Homegraph01Icon,
  },
  { group: "smart house", name: "homegraph", component: HomegraphIcon },
  {
    group: "smart house",
    name: "homeinformation01",
    component: Homeinformation01Icon,
  },
  {
    group: "smart house",
    name: "homeinformation",
    component: HomeinformationIcon,
  },
  { group: "smart house", name: "homelink01", component: Homelink01Icon },
  { group: "smart house", name: "homelink", component: HomelinkIcon },
  {
    group: "smart house",
    name: "homelocation01",
    component: Homelocation01Icon,
  },
  {
    group: "smart house",
    name: "homelocation",
    component: HomelocationIcon,
  },
  { group: "smart house", name: "homelock01", component: Homelock01Icon },
  { group: "smart house", name: "homelock", component: HomelockIcon },
  { group: "smart house", name: "homemail01", component: Homemail01Icon },
  { group: "smart house", name: "homemail", component: HomemailIcon },
  {
    group: "smart house",
    name: "homeminus01",
    component: Homeminus01Icon,
  },
  { group: "smart house", name: "homeminus", component: HomeminusIcon },
  {
    group: "smart house",
    name: "homenotification01",
    component: Homenotification01Icon,
  },
  {
    group: "smart house",
    name: "homenotification",
    component: HomenotificationIcon,
  },
  {
    group: "smart house",
    name: "homepower01",
    component: Homepower01Icon,
  },
  { group: "smart house", name: "homepower", component: HomepowerIcon },
  {
    group: "smart house",
    name: "homeremove01",
    component: Homeremove01Icon,
  },
  { group: "smart house", name: "homeremove", component: HomeremoveIcon },
  {
    group: "smart house",
    name: "homesetting01",
    component: Homesetting01Icon,
  },
  {
    group: "smart house",
    name: "homesetting",
    component: HomesettingIcon,
  },
  {
    group: "smart house",
    name: "homeshare01",
    component: Homeshare01Icon,
  },
  { group: "smart house", name: "homeshare", component: HomeshareIcon },
  {
    group: "smart house",
    name: "homeshield01",
    component: Homeshield01Icon,
  },
  { group: "smart house", name: "homeshield", component: HomeshieldIcon },
  {
    group: "smart house",
    name: "homeupload01",
    component: Homeupload01Icon,
  },
  { group: "smart house", name: "homeupload", component: HomeuploadIcon },
  {
    group: "smart house",
    name: "homewarning01",
    component: Homewarning01Icon,
  },
  {
    group: "smart house",
    name: "homewarning",
    component: HomewarningIcon,
  },
  { group: "smart house", name: "homewave01", component: Homewave01Icon },
  { group: "smart house", name: "homewave1", component: Homewave1Icon },
  { group: "smart house", name: "homewave", component: HomewaveIcon },
  { group: "smart house", name: "homewifi01", component: Homewifi01Icon },
  { group: "smart house", name: "homewifi", component: HomewifiIcon },
  { group: "smart house", name: "home", component: HomeIcon },
  { group: "smart house", name: "humidity", component: HumidityIcon },
  { group: "smart house", name: "music", component: MusicIcon },
  { group: "smart house", name: "power", component: PowerIcon },
  { group: "smart house", name: "press", component: PressIcon },
  { group: "smart house", name: "remote", component: RemoteIcon },
  { group: "smart house", name: "sensor", component: SensorIcon },
  { group: "smart house", name: "smartkey", component: SmartkeyIcon },
  { group: "smart house", name: "smartkey", component: SmartkeyIcon },
  { group: "smart house", name: "smartlight", component: SmartlightIcon },
  { group: "smart house", name: "smartlock", component: SmartlockIcon },
  { group: "smart house", name: "smartphone", component: SmartphoneIcon },
  { group: "smart house", name: "smartplug", component: SmartplugIcon },
  { group: "smart house", name: "smarttv", component: SmarttvIcon },
  { group: "smart house", name: "smartwatch", component: SmartwatchIcon },
  { group: "smart house", name: "solarpanel", component: SolarpanelIcon },
  { group: "smart house", name: "speaker", component: SpeakerIcon },
  { group: "smart house", name: "switch", component: SwitchIcon },
  { group: "smart house", name: "thermostat", component: ThermostatIcon },
  { group: "time and date", name: "alarmadd", component: AlarmaddIcon },
  {
    group: "time and date",
    name: "alarmcheck",
    component: AlarmcheckIcon,
  },
  {
    group: "time and date",
    name: "alarmdelete",
    component: AlarmdeleteIcon,
  },
  {
    group: "time and date",
    name: "alarmminus",
    component: AlarmminusIcon,
  },
  { group: "time and date", name: "alarm", component: AlarmIcon },
  {
    group: "time and date",
    name: "calendar01",
    component: Calendar01Icon,
  },
  {
    group: "time and date",
    name: "calendaradd",
    component: CalendaraddIcon,
  },
  {
    group: "time and date",
    name: "calendarcheck",
    component: CalendarcheckIcon,
  },
  {
    group: "time and date",
    name: "calendardelete",
    component: CalendardeleteIcon,
  },
  {
    group: "time and date",
    name: "calendarminus",
    component: CalendarminusIcon,
  },
  { group: "time and date", name: "calendar", component: CalendarIcon },
  {
    group: "time and date",
    name: "hourglassend",
    component: HourglassendIcon,
  },
  {
    group: "time and date",
    name: "hourglassstart",
    component: HourglassstartIcon,
  },
  { group: "time and date", name: "hourglass", component: HourglassIcon },
  {
    group: "time and date",
    name: "stopwatchadd",
    component: StopwatchaddIcon,
  },
  {
    group: "time and date",
    name: "stopwatchcheck",
    component: StopwatchcheckIcon,
  },
  {
    group: "time and date",
    name: "stopwatchdelete",
    component: StopwatchdeleteIcon,
  },
  {
    group: "time and date",
    name: "stopwatchfast",
    component: StopwatchfastIcon,
  },
  {
    group: "time and date",
    name: "stopwatchminus",
    component: StopwatchminusIcon,
  },
  { group: "time and date", name: "stopwatch", component: StopwatchIcon },
  {
    group: "time and date",
    name: "timequarter",
    component: TimequarterIcon,
  },
  { group: "time and date", name: "time10", component: Time10Icon },
  { group: "time and date", name: "time24", component: Time24Icon },
  { group: "time and date", name: "time60", component: Time60Icon },
  { group: "time and date", name: "timeadd", component: TimeaddIcon },
  { group: "time and date", name: "timecheck", component: TimecheckIcon },
  {
    group: "time and date",
    name: "timedelete",
    component: TimedeleteIcon,
  },
  { group: "time and date", name: "timefast", component: TimefastIcon },
  {
    group: "time and date",
    name: "timeforward",
    component: TimeforwardIcon,
  },
  {
    group: "time and date",
    name: "timehalfpast",
    component: TimehalfpastIcon,
  },
  { group: "time and date", name: "timeminus", component: TimeminusIcon },
  {
    group: "time and date",
    name: "timeoclock",
    component: TimeoclockIcon,
  },
  {
    group: "time and date",
    name: "timequarterpast",
    component: TimequarterpastIcon,
  },
  { group: "time and date", name: "watch01", component: Watch01Icon },
  { group: "time and date", name: "watch", component: WatchIcon },
  {
    group: "weather",
    name: "cloudflashrain",
    component: CloudflashrainIcon,
  },
  { group: "weather", name: "cloudflash", component: CloudflashIcon },
  {
    group: "weather",
    name: "cloudhalfmoonflashrain",
    component: CloudhalfmoonflashrainIcon,
  },
  {
    group: "weather",
    name: "cloudhalfmoonflash",
    component: CloudhalfmoonflashIcon,
  },
  {
    group: "weather",
    name: "cloudhalfmoonrainhigh",
    component: CloudhalfmoonrainhighIcon,
  },
  {
    group: "weather",
    name: "cloudhalfmoonrainlow",
    component: CloudhalfmoonrainlowIcon,
  },
  {
    group: "weather",
    name: "cloudhalfmoonrainmid",
    component: CloudhalfmoonrainmidIcon,
  },
  {
    group: "weather",
    name: "cloudhalfmoonsnow",
    component: CloudhalfmoonsnowIcon,
  },
  {
    group: "weather",
    name: "cloudhalfmoonwindrain",
    component: CloudhalfmoonwindrainIcon,
  },
  {
    group: "weather",
    name: "cloudhalfmoon",
    component: CloudhalfmoonIcon,
  },
  {
    group: "weather",
    name: "cloudmoonflashrain",
    component: CloudmoonflashrainIcon,
  },
  {
    group: "weather",
    name: "cloudmoonflash",
    component: CloudmoonflashIcon,
  },
  {
    group: "weather",
    name: "cloudmoonrainhigh",
    component: CloudmoonrainhighIcon,
  },
  {
    group: "weather",
    name: "cloudmoonrainlow",
    component: CloudmoonrainlowIcon,
  },
  {
    group: "weather",
    name: "cloudmoonrainmid",
    component: CloudmoonrainmidIcon,
  },
  {
    group: "weather",
    name: "cloudmoonsnow",
    component: CloudmoonsnowIcon,
  },
  {
    group: "weather",
    name: "cloudmoonwindrain",
    component: CloudmoonwindrainIcon,
  },
  { group: "weather", name: "cloudmoon", component: CloudmoonIcon },
  {
    group: "weather",
    name: "cloudrainhigh",
    component: CloudrainhighIcon,
  },
  { group: "weather", name: "cloudrainlow", component: CloudrainlowIcon },
  { group: "weather", name: "cloudrainmid", component: CloudrainmidIcon },
  { group: "weather", name: "cloudsnow", component: CloudsnowIcon },
  {
    group: "weather",
    name: "cloudsunflashrain",
    component: CloudsunflashrainIcon,
  },
  {
    group: "weather",
    name: "cloudsunflash",
    component: CloudsunflashIcon,
  },
  {
    group: "weather",
    name: "cloudsunrainhigh",
    component: CloudsunrainhighIcon,
  },
  {
    group: "weather",
    name: "cloudsunrainlow",
    component: CloudsunrainlowIcon,
  },
  {
    group: "weather",
    name: "cloudsunrainmid",
    component: CloudsunrainmidIcon,
  },
  { group: "weather", name: "cloudsunsnow", component: CloudsunsnowIcon },
  {
    group: "weather",
    name: "cloudsunwindrain",
    component: CloudsunwindrainIcon,
  },
  { group: "weather", name: "cloudsun", component: CloudsunIcon },
  {
    group: "weather",
    name: "cloudwindrain",
    component: CloudwindrainIcon,
  },
  { group: "weather", name: "cloudwind", component: CloudwindIcon },
  { group: "weather", name: "cloud", component: CloudIcon },
  { group: "weather", name: "clouds", component: CloudsIcon },
  { group: "weather", name: "flash", component: FlashIcon },
  { group: "weather", name: "halfmoon", component: HalfmoonIcon },
  {
    group: "weather",
    name: "halfmoonphase",
    component: HalfmoonphaseIcon,
  },
  {
    group: "weather",
    name: "lastquartermoon",
    component: LastquartermoonIcon,
  },
  { group: "weather", name: "snow", component: SnowIcon },
  // { group: "weather", name: "sun", component: SunIcon },
  { group: "weather", name: "sunrise", component: SunriseIcon },
  { group: "weather", name: "sunset", component: SunsetIcon },
  {
    group: "weather",
    name: "temperaturecold",
    component: TemperaturecoldIcon,
  },
  {
    group: "weather",
    name: "temperatureminus",
    component: TemperatureminusIcon,
  },
  {
    group: "weather",
    name: "temperatureplus",
    component: TemperatureplusIcon,
  },
  { group: "weather", name: "tornado", component: TornadoIcon },
  { group: "weather", name: "wave", component: WaveIcon },
  { group: "weather", name: "windhigh", component: WindhighIcon },
  { group: "weather", name: "windlow", component: WindlowIcon },
  { group: "weather", name: "windmid", component: WindmidIcon },
];

export const IconsMap: { [name: string]: ReactElement } = IconsList.reduce(
  (acc: any, icon: IconType) => {
    acc[icon.name] = icon.component;
    return acc;
  },
  {}
);
