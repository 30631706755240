import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateSubject,
  UpdateSubject,
} from "../graphql/subjects/subjects.mutations";
import { GetSubjects } from "../graphql/subjects/subjects.queries";
import Subject from "../models/Subject";
import {
  SortOrder,
  SubjectCreateInput,
  SubjectUpdateInput,
  SubjectWhereUniqueInput,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class SubjectsStore extends BaseStore<Subject> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Subject, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchDistrictSubjects = (districtId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetSubjects,
          variables: {
            where: {
              district: {
                id: districtId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const subjects = res.data.subjects;

          if (!subjects) {
            reject(false);
          }

          runInAction("Populate subjects", () => {
            subjects.forEach((subject) => {
              console.log(subject);

              invariant(subject.district, "Subject must have a district");
              invariant(subject.department, "Subject must have a department");

              const sanitizeSubject = {
                cedsCode: subject.cedsCode,
                code: subject.code,
                createdAt: subject.createdAt,
                deletedAt: subject.deletedAt,
                description: subject.description,
                districtId: subject.district.id,
                departmentId: subject.department.id,
                edlinkId: subject.edlinkId,
                id: subject.id,
                identifiers: subject.identifiers,
                name: subject.name,
                properties: subject.properties,
                updatedAt: subject.updatedAt,
              };

              this.add(sanitizeSubject);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  save = async (args: Partial<Subject>): Promise<Subject> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as SubjectCreateInput);
      } else {
        return this.update(
          rest as SubjectUpdateInput,
          { id } as SubjectWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: SubjectCreateInput): Promise<Subject> {
    const res = await this.apolloClient.mutate({
      mutation: CreateSubject,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createSubject) {
      throw Error("Failed to create subject.");
    }

    const subject = res.data.createSubject;

    invariant(subject.district, "Subject must have a district");
    invariant(subject.department, "Subject must have a department");

    const sanitizeSubject = {
      cedsCode: subject.cedsCode,
      code: subject.code,
      createdAt: subject.createdAt,
      deletedAt: subject.deletedAt,
      description: subject.description,
      districtId: subject.district.id,
      departmentId: subject.department.id,
      edlinkId: subject.edlinkId,
      id: subject.id,
      identifiers: subject.identifiers,
      name: subject.name,
      properties: subject.properties,
      updatedAt: subject.updatedAt,
    };

    return this.add(sanitizeSubject);
  }

  async update(
    data: SubjectUpdateInput,
    where: SubjectWhereUniqueInput
  ): Promise<Subject> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateSubject,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateSubject) {
      throw Error("Failed to update subject.");
    }

    const subject = res.data.updateSubject;

    invariant(subject.district, "Subject must have a district");
    invariant(subject.department, "Subject must have a department");

    const sanitizeSubject = {
      cedsCode: subject.cedsCode,
      code: subject.code,
      createdAt: subject.createdAt,
      deletedAt: subject.deletedAt,
      description: subject.description,
      districtId: subject.district.id,
      departmentId: subject.department.id,
      edlinkId: subject.edlinkId,
      id: subject.id,
      identifiers: subject.identifiers,
      name: subject.name,
      properties: subject.properties,
      updatedAt: subject.updatedAt,
    };

    return this.add(sanitizeSubject);
  }

  getSubjectsForDistrict = (districtId: string): Subject[] => {
    return this.sortedData.filter(
      (school) => school.districtId === districtId && !school.deletedAt
    );
  };
}
