import { Outlet } from "react-router-dom";
// import Modals from "../components/Modals/Modals";
// import { Toasts } from "../components/Toasts/Toasts";
import Modals from "../components/Modals/Modals";
import { Toasts } from "../components/Toasts/Toasts";
import useIsMobile from "../hooks/useIsMobile";
import useDisableScrollBounce from "../utils/useDisableScrollBounce";

export default function Layout() {
  useDisableScrollBounce();

  const isMobile = useIsMobile();

  if (isMobile) {
    // Display message to user saying Mobile is not supported yet
    return (
      <div className={"h-screen w-full"}>
        {/*  */}
        <div className="flex h-full w-full items-center justify-center py-20">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-center text-2xl font-bold">
              Mobile is not supported yet.
            </h1>
            <h3 className="mt-8 text-center text-lg">
              Please use a desktop browser to access the app.
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"h-screen w-full"}>
      <Modals />
      <Toasts />
      {/* We can render global components and loading states here (e.g. Global Loader) */}
      <Outlet />
      <div
        id="popper-portal"
        style={{
          zIndex: 10002,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
}
