import { computed, observable } from "mobx";
import BlockSchedule from "./BlockSchedule";
import DeletableModel from "./DeletableModel";

class AlternateSchedule extends DeletableModel {
  @observable
  id: string;

  @observable
  name: string;

  @observable
  rotationScheduleId: string;

  @computed
  get blockSchedules(): BlockSchedule[] {
    const { blockSchedules } = this.store.rootStore;

    return blockSchedules.getBlockScheduleForAlternateDays(this.id);
  }
}

export default AlternateSchedule;
