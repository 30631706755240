import { computed, observable } from "mobx";
import {
  EnumCourseV2GradeLevels,
  EnumCourseV2SubjectCodes,
} from "../__generated__/graphql";
import DeletableModel from "./DeletableModel";

class CourseV2 extends DeletableModel {
  @observable
  id: string;

  @observable
  districtId: string;

  @observable
  edlinkId: string | null;

  @observable
  gradeLevels: EnumCourseV2GradeLevels[];

  @observable
  identifiers: any | null;

  @observable
  name: string;

  @observable
  properties: any | null;

  @observable
  schoolId: string | null;

  @observable
  sessionId: string | null;

  @observable
  subjectId: string | null;

  @observable
  subjectCodes: EnumCourseV2SubjectCodes[];

  @observable
  urlId: string;

  @computed
  get schoolName(): string {
    const { schools } = this.store.rootStore;

    const fetchSchool = schools.get(this.schoolId);

    return fetchSchool ? fetchSchool.name : "-";
  }

  @computed
  get sessionName(): string {
    const { sessions } = this.store.rootStore;

    const fetchSession = sessions.get(this.sessionId);

    return fetchSession ? fetchSession.name : "-";
  }

  @computed
  get subjectName(): string {
    const { subjects } = this.store.rootStore;

    const fetchSubject = subjects.get(this.subjectId);

    return fetchSubject ? fetchSubject.name : "-";
  }
}

export default CourseV2;
