import { gql } from "../../__generated__/gql";

export const CreateRotationSchedule = gql(`
    mutation CreateRotationSchedule($data: RotationScheduleCreateInput!) {
        createRotationScheduleWithCalendar(data: $data) {
            createdAt
            deletedAt
            id
            name
            session {
                id 
            }
            rotationDays {
                id
            }
            calendarDays {
                id
            }
            daysOfWeek
            gradeLevels
            skipNoSchoolDays
            updatedAt  
        }
    }
`);

export const UpdateRotationSchedule = gql(`
    mutation UpdateRotationSchedule($data: RotationScheduleUpdateInput!, $where: RotationScheduleWhereUniqueInput!) {
        updateRotationSchedule(data: $data, where: $where) {
            createdAt
            deletedAt
            id
            name
            session {
                id 
            }
            rotationDays {
                id
            }
            calendarDays {
                id
            }
            daysOfWeek
            gradeLevels
            skipNoSchoolDays
            updatedAt 
        }
    }
`);

export const PopulateRotationDays = gql(`
    mutation PopulateRotationDays($data: [CreateRotationDayInput!]!, $rotationSchedule: RotationScheduleWhereUniqueInput!) {
        populateRotationDays(data: $data, rotationSchedule: $rotationSchedule) 
    }
`);

export const CreateBlock = gql(`
    mutation CreateBlock($data: BlockCreateInput!) {
        createBlock(data: $data) {
            abbreviation
            color 
            createdAt 
            deletedAt
            description 
            id 
            rotationSchedule {
                id
            }
            updatedAt 
        }
    }
`);

export const UpdateBlock = gql(`
    mutation UpdateBlock($data: BlockUpdateInput!, $where: BlockWhereUniqueInput!) {
        updateBlock(data: $data, where: $where) {
            abbreviation
            color 
            createdAt 
            deletedAt
            description 
            id 
            rotationSchedule {
                id
            }
            updatedAt 
        }
    }
`);

export const PopulateBlockSchedules = gql(`
    mutation PopulateBlockSchedules($data: [CreateBlockScheduleInput!]!, $block: BlockWhereUniqueInput!) {
        populateBlockSchedules(data: $data, block: $block) 
    }
`);

export const CreateAlternateSchedule = gql(`
    mutation CreateAlternateSchedule($data: AlternateScheduleCreateInput!) {
        createAlternateSchedule(data: $data) {
            createdAt 
            deletedAt
            id 
            name
            rotationSchedule {
                id
            }
            updatedAt
        }
    }
`);

export const UpdateAlternateSchedule = gql(`
    mutation UpdateAlternateSchedule($data: AlternateScheduleUpdateInput!, $where: AlternateScheduleWhereUniqueInput!) {
        updateAlternateSchedule(data: $data, where: $where) {
            createdAt 
            deletedAt
            id 
            name
            rotationSchedule {
                id
            }
            updatedAt
        }
    }
`);

export const PopulateAlternateDaySchedules = gql(`
    mutation PopulateAlternateDaySchedules($data: [CreateAlternateScheduleInput!]!, $alternateSchedule: AlternateScheduleWhereUniqueInput!) {
        populateAlternateDaySchedules(data: $data, alternateSchedule: $alternateSchedule) 
    }
`);

export const CreateCalendar = gql(`
    mutation CreateCalendar($data: CalendarCreateInput!) {
        createCalendar(data: $data) {
            calendarType
            classV2 {
                id
            }
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            id
            rotationSchedule {
                id
            }
            section {
                id
            }
            session {
                id
            }
            timezone
            updatedAt
            urlId
            user {
                id
            }
        }
    }
`);

export const UpdateCalendar = gql(`
    mutation UpdateCalendar($data: CalendarUpdateInput!, $where: CalendarWhereUniqueInput!) {
        updateCalendar(data: $data, where: $where) {
            calendarType
            classV2 {
                id
            }
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            id
            rotationSchedule {
                id
            }
            section {
                id
            }
            session {
                id
            }
            timezone
            updatedAt
            urlId
            user {
                id
            }
        }
    }
`);

export const AssignClassSchedules = gql(`
    mutation AssignClassSchedules($classIds: [String!]!, $rotationScheduleId: String!) {
        assignClassSchedules(classIds: $classIds, rotationScheduleId: $rotationScheduleId) 
    }
`);

export const ModifyCalendarDay = gql(`
    mutation ModifyCalendarDay($data: UpdateCalendarDayInput!, $calendarDay: CalendarDayWhereUniqueInput!) {
        modifyCalendarDay(data: $data, calendarDay: $calendarDay) {
            alternateSchedule {
                id
                name
                blockSchedules {
                    id
                    block {
                        id
                        abbreviation
                        color
                        description
                    }
                    startTime
                    endTime
                }
            }
            calendar {
                id
                timezone
            }
            createdAt
            date
            edlinkId
            id
            rotationDay {
                id
                abbreviation
                blockSchedules {
                    id
                    block {
                        id
                        abbreviation
                        color
                        description
                    }
                    startTime
                    endTime
                }
            }
            rotationSchedule {
                id
            }
            updatedAt
        }
    }
`);

export const SetFirstAndLastDayEvents = gql(`
    mutation SetFirstAndLastDayEvents($data: FirstLastDaysInput!) {
        setFirstAndLastDayEvents(data: $data) {
            startEvent {
                allDay 
                calendar {
                    id
                    timezone
                    calendarType
                }
                calendarDay {
                    id
                }
                calendarSchedule {
                    id
                    blockSchedule {
                        id
                        block {
                            id
                            abbreviation
                            color
                            description
                        }
                    }
                }
                createdAt
                date 
                deletedAt
                description
                edlinkId 
                endTime 
                eventType 
                id 
                startTime 
                timezone 
                title 
                updatedAt 
                urlId 
                recurring
            }
            endEvent {
                allDay 
                calendar {
                    id
                    timezone
                    calendarType
                }
                calendarDay {
                    id
                }
                calendarSchedule {
                    id
                    blockSchedule {
                        id
                        block {
                            id
                            abbreviation
                            color
                            description
                        }
                    }
                }
                createdAt
                date 
                deletedAt
                description
                edlinkId 
                endTime 
                eventType 
                id 
                startTime 
                timezone 
                title 
                updatedAt 
                urlId 
                recurring
            }
        }
    }
`);

export const CreateEvent = gql(`
    mutation CreateEvent($data: EventCreateInput!) {
        createEvent(data: $data) {
            allDay 
            calendar {
                id
                timezone
                calendarType
            }
            calendarDay {
                id
            }
            calendarSchedule {
                id
                blockSchedule {
                    id
                    block {
                        id
                        abbreviation
                        color
                        description
                    }
                }
            }
            createdAt
            date 
            deletedAt
            description
            edlinkId 
            endTime 
            eventType 
            id 
            startTime 
            timezone 
            title 
            updatedAt 
            urlId 
            recurring
        }
    }
`);

export const UpdateEvent = gql(`
    mutation UpdateEvent($data: EventUpdateInput!, $where: EventWhereUniqueInput!) {
        updateEvent(data: $data, where: $where) {
            allDay 
            calendar {
                id
                timezone
                calendarType
            }
            calendarDay {
                id
            }
            calendarSchedule {
                id
                blockSchedule {
                    id
                    block {
                        id
                        abbreviation
                        color
                        description
                    }
                }
            }
            createdAt
            date 
            deletedAt
            description
            edlinkId 
            endTime 
            eventType 
            id 
            startTime 
            timezone 
            title 
            updatedAt 
            urlId 
            recurring
        }
    }
`);

export const CreateCalendarEntry = gql(`
    mutation CreateCalendarEntry($data: CalendarEntryCreateInput!) {
        createCalendarEntry(data: $data) {
            calendar {
                id
            }
            color
            createdAt
            id
            user {
                id
            }
        }
    }
`);

export const UpdateCalendarEntry = gql(`
    mutation UpdateCalendarEntry($data: CalendarEntryUpdateInput!, $where: CalendarEntryWhereUniqueInput!) {
        updateCalendarEntry(data: $data, where: $where) {
            calendar {
                id
            }
            color
            createdAt
            id
            user {
                id
            }
        }
    }
`);

export const PopulateCalendarSchedules = gql(`
    mutation PopulateCalendarSchedules($data: [CreateCalendarScheduleInput!]!, $section: SectionV2WhereUniqueInput!, $session: SessionWhereUniqueInput!) {
        populateCalendarSchedules(data: $data, section: $section, session: $session) 
    }
`);

export const ReinitializeRotationDaysPreview = gql(`
    mutation ReinitializeRotationDayPreview($calendarDay: CalendarDayWhereUniqueInput!, $rotationDay: RotationDayWhereUniqueInput!) {
        reinitializeRotationDayPreview(calendarDay: $calendarDay, rotationDay: $rotationDay) {
            firstDate
            lastDate
            changes {
                date
                rotationDay {
                    id 
                    abbreviation
                    description
                }
                change
                beforeDate
                shiftAmount
            }
        }
    }
`);
