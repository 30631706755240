import { gql } from "../../__generated__/gql";

export const CreateCourseV2 = gql(`
    mutation createCourseV2($data: CourseV2CreateInput!) {
        createCourseV2(data: $data) {
            code 
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            gradeLevels
            id 
            identifiers
            name
            properties
            school {
                id
            }
            session {
                id
            }
            subject {
                id
            }
            subjectCodes 
            updatedAt
            urlId
        }
    }
`);

export const UpdateCourseV2 = gql(`
    mutation updateCourseV2($data: CourseV2UpdateInput!, $where: CourseV2WhereUniqueInput!) {
        updateCourseV2(data: $data, where: $where) {
            code 
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            gradeLevels
            id 
            identifiers
            name
            properties
            school {
                id
            }
            session {
                id
            }
            subject {
                id
            }
            subjectCodes 
            updatedAt
            urlId
        }
    }
`);
