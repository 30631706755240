import { Transition } from "@headlessui/react";
import React, { ReactNode } from "react";

interface SlideOverModalProps {
  isOpen: boolean;
  onCloseModal?: () => void;
  children: ReactNode;
}

const SlideOverModal: React.FC<SlideOverModalProps> = (props) => {
  const { isOpen, onCloseModal, children } = props;

  return (
    <div className="fixed inset-0 z-[500] overflow-hidden">
      <div className="absolute inset-0 z-[501] overflow-hidden">
        <Transition
          show={isOpen}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={onCloseModal}
            className="absolute inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <section
            className="m:pl-16 absolute inset-y-0 right-0 flex max-w-full"
            aria-labelledby="slide-over-heading"
          >
            <Transition
              show={isOpen}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="h-full w-screen max-w-md">{children}</div>
            </Transition>
          </section>
        </Transition>
      </div>
    </div>
  );
};

export default SlideOverModal;
