import { action, computed, observable } from "mobx";
import {
  EnumSessionSessionType,
  EnumSessionState,
} from "../__generated__/graphql";
import ClassV2 from "./ClassV2";
import DeletableModel from "./DeletableModel";
import RotationSchedule from "./RotationSchedule";

class Session extends DeletableModel {
  @observable
  activeRotationScheduleId: string | null;

  @observable
  id: string;

  @observable
  districtId: string;

  @observable
  edlinkId: string | null;

  @observable
  endDate: string | null;

  @observable
  identifiers: any | null;

  @observable
  name: string;

  @observable
  properties: any | null;

  @observable
  schoolId: string | null;

  @observable
  sessionType: EnumSessionSessionType;

  @observable
  state: EnumSessionState;

  @observable
  startDate: string | null;

  @observable
  urlId: string;

  @action
  setActiveRotationScheduleId = (id: string | null) => {
    this.activeRotationScheduleId = id;
  };

  @computed
  get activeRotationSchedule(): RotationSchedule | undefined {
    const { rotationSchedules } = this.store.rootStore;

    if (!this.activeRotationScheduleId) {
      return undefined;
    }

    return rotationSchedules.get(this.activeRotationScheduleId);
  }

  @computed
  get classes(): ClassV2[] {
    const { classes } = this.store.rootStore;

    return classes.getClassesForSession(this.id);
  }
}

export default Session;
