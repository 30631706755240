import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { MbscCalendarDayData } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-day";
import clsx from "clsx";
import { observer } from "mobx-react";
import useStores from "../../hooks/useStores";
import { CalendarView } from "../../types";
import CalendarDayDropdownMenu from "../Dropdown/CalendarDayDropdownMenu";

type CustomDayProps = {
  day: MbscCalendarDayData;
  mobiscrollView: any;
  calendarView: CalendarView;
  calendarId: string;
  editCalendarDay: boolean;
  onEditCalendarDay?: (id: string) => void;
};

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function CustomDay({
  day,
  mobiscrollView,
  calendarId,
  calendarView,
  onEditCalendarDay,
  editCalendarDay,
}: CustomDayProps) {
  const stores = useStores();

  const { calendarDays } = stores;

  const date = day.date;

  console.log("date", date.toISOString().split("T")[0]);

  const calendarDayForDate = calendarDays.getDayForDate(
    calendarId,
    date.toISOString().split("T")[0]
  );

  console.log("calendarDayForDate", calendarDayForDate);

  const selected = day.selected;
  const isCalendarView = mobiscrollView.calendar;

  // Check if there is a calendar day occuring on this date

  if (isCalendarView) {
    return (
      <div
        className={clsx("pl-5", calendarView !== "month" && "border-l-2")}
        style={{
          textAlign: mobiscrollView.calendar ? "left" : "center",
        }}
      >
        <p className="pr-2 text-right">
          <div>{date.getDate()}</div>
        </p>
      </div>
    );
  }

  return (
    <div
      className="border-l pl-5 pr-2"
      style={{
        textAlign: mobiscrollView.calendar ? "left" : "center",
      }}
    >
      <div className="group flex items-center justify-between">
        <div className="flex flex-col items-start">
          <div className="flex items-center">
            <div className={selected ? "pr-1" : ""}>{days[date.getDay()]}</div>
            <div
              className={`${
                selected ? "bg-blue11 text-white" : "text-blue11"
              } flex h-6 w-6 items-center justify-center rounded-full text-center`}
              style={{
                borderRadius: 20,
                background: selected ? "#0062ff" : "none",
                color: selected ? "white" : "black",
              }}
            >
              {date.getDate()}
            </div>
          </div>
          {/* Render rotation day letter */}
          {calendarDayForDate ? (
            <div className="text-slate11 mt-1 text-sm">
              {calendarDayForDate.rotationDayName}
            </div>
          ) : null}
        </div>
        {calendarDayForDate && editCalendarDay && (
          <CalendarDayDropdownMenu
            date={date}
            onEditCalendarDay={() => {
              onEditCalendarDay && onEditCalendarDay(calendarDayForDate.id);
            }}
          >
            <button
              className="text-slate11 hover:bg-slate3 relative flex h-[28px] w-[28px] items-center justify-center rounded-md opacity-100 outline-none group-hover:opacity-100"
              aria-label="Customise options"
            >
              <EllipsisHorizontalIcon className="text-slate11 h-6 w-6" />
            </button>
          </CalendarDayDropdownMenu>
        )}
      </div>
    </div>
  );
}

export default observer(CustomDay);
