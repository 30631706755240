import clsx from "clsx";
import { throttle } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import useStores from "../../hooks/useStores";
import useEventHook from "../../utils/useEventHook";
import Button from "../Button";
import Transition from "../ResponsiveLayout/Transition";
import styles from "./Header.module.css";

type Props = {
  left?: React.ReactNode;
  title: React.ReactNode;
  right?: React.ReactNode;
  showSidebar?: boolean;
  alwaysShowTitle?: boolean;
};

function Header({ left, title, right, showSidebar, alwaysShowTitle }: Props) {
  const { ui } = useStores();

  const isMobile = useIsMobile;
  const showMobileSidebar = showSidebar && isMobile;

  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = React.useMemo(
    () => throttle(() => setScrolled(window.scrollY > 80), 50),
    []
  );

  useEventHook("scroll", handleScroll, window, { capture: false });

  const handleClickTitle = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (!title && !left && !right) {
    return null;
  }

  console.log("Always show title", alwaysShowTitle);

  return (
    <>
      <div id="Header-Target" className="w-full" />
      <div
        className={clsx(
          "flex items-center border-b-[1px] border-gray-100",
          styles.Header_Wrapper
        )}
      >
        {left || showMobileSidebar ? (
          <div className={styles.Header_Left}>
            {showMobileSidebar && (
              <Button
                type="button"
                onClick={ui.toggleMobileSidebar}
                icon="mobile-menu"
                theme="tertiary"
                className={styles.Header_Menu_Button}
              />
            )}
            {left}
          </div>
        ) : null}

        {alwaysShowTitle ? (
          <div className={styles.Header_Title}>{title}</div>
        ) : isScrolled ? (
          <div className={styles.Header_Title} onClick={handleClickTitle}>
            <Transition>{title}</Transition>
          </div>
        ) : (
          <div />
        )}
        <div className={styles.Header_Right}>{right}</div>
      </div>
    </>
  );
}

export default observer(Header);
