import React, { useCallback, useEffect, useState } from "react";
import { ToastType } from "../../contexts/toasts";
import { Spinner } from "../LoadingIndicators/Spinner";

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";

type ToastProps = {
  toast: ToastType;
  onClose: () => void;
  closeAfter?: number;
};

export const Toast = ({ toast, onClose, closeAfter = 2500 }: ToastProps) => {
  // Stores reference to current timeout function
  const timeout = React.useRef<ReturnType<typeof setTimeout>>();
  const [pulse, setPulse] = useState(false);
  const { action, type = "info", recurring } = toast;

  // Sets timeout to close toast after specified time
  useEffect(() => {
    if (toast.timeout !== 0) {
      timeout.current = setTimeout(onClose, toast.timeout || closeAfter);

      return () => {
        clearTimeout(timeout.current);
      };
    }
  }, [toast.timeout, closeAfter, onClose]);

  useEffect(() => {
    if (recurring) {
      setPulse(!!recurring);
      // must match animation time in css below vvv
      setTimeout(() => setPulse(false), 300);
    }
  }, [recurring]);

  const handlePause = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  const handleResume = useCallback(() => {
    if (timeout.current && toast.timeout !== 0) {
      timeout.current = setTimeout(onClose, toast.timeout || closeAfter);
    }
  }, [onClose, toast, closeAfter]);

  return (
    <li
      className={clsx(pulse ? "animate-pulseToast" : "", "z-[1000]")}
      onMouseEnter={handlePause}
      onMouseLeave={handleResume}
    >
      <div
        onClick={action ? undefined : onClose}
        className="animate-fadeToast border-md inline-flex cursor-default items-center rounded-md border-[1px]  border-gray-300 bg-white px-3 py-4 text-sm shadow-lg "
      >
        {type === "loading" && <Spinner color="currentColor" />}
        {type === "info" && (
          <InformationCircleIcon
            className="h-6 w-6 text-blue-400"
            aria-hidden="true"
          />
        )}
        {type === "success" && (
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        )}
        {type === "warning" && (
          <ExclamationTriangleIcon
            className="h-6 w-6 text-yellow-400"
            aria-hidden="true"
          />
        )}
        {type === "error" && (
          <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        )}
        <p className="inline-block select-none py-2 px-2 font-medium">
          {toast.message}
        </p>
        {/* {action && <Action onClick={action.onClick}>{action.text}</Action>} */}
      </div>
    </li>
  );
};
