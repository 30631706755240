import { ChevronRightIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BlockSchedules, {
  BlockScheduleFormEntity,
} from "../components/BlockSchedules";
import Button from "../components/Button";
import Link from "../components/Link";
import PageLayout from "../components/PageLayout";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";
import { sessionRoute } from "../utils/routeHelper";
import { CreateBlockScheduleInput } from "../__generated__/graphql";

function BlockSchedule() {
  const { blockId } = useParams();

  const { blocks, ui, districts, sessions, blockSchedules } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId, activeSessionId } = ui;

  const [modifiedBlockSchedules, setModifiedBlockSchedules] = useState<
    BlockScheduleFormEntity[]
  >([]);

  const [blockScheduleErrors, setBlockScheduleErrors] = useState<string[]>([]);

  if (!activeDistrictId || !activeSessionId || !blockId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  const activeSession = sessions.getByUrlParam(activeSessionId);

  if (!activeDistrict || !activeSession) return null;

  const activeRotationSchedule = activeSession.activeRotationSchedule;

  if (!activeRotationSchedule) return null;

  const activeBlock = blocks.get(blockId);

  if (!activeBlock) return null;

  const submitBlockSchedules = async () => {
    const errors = submitValidationForBlockSchedules();

    if (errors.length > 0) {
      return;
    }

    const createBlockSchedulesInput: CreateBlockScheduleInput[] = [];

    modifiedBlockSchedules.forEach((blockSchedule) => {
      // We need to check both the start and end time to see if they are filled out or not
      if (blockSchedule.startTime && blockSchedule.endTime) {
        createBlockSchedulesInput.push({
          id: blockSchedule.id,
          rotationDay: {
            id: blockSchedule.rotationDayId,
          },
          startTime: blockSchedule.startTime,
          endTime: blockSchedule.endTime,
        });
      }
    });

    console.log("create Block Schedules input", createBlockSchedulesInput);

    try {
      const response = await blockSchedules.saveBlockSchedules(
        createBlockSchedulesInput,
        activeBlock.id
      );

      if (response) {
        addToast("Block Schedules saved successfully.", {
          type: "success",
        });
      } else {
        addToast("Error saving Block Schedules.", {
          type: "error",
        });
      }
    } catch (e) {
      addToast("Error saving Block Schedules.", {
        type: "error",
      });
    }
  };

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon=""
        buttonText="Save"
        padding="medium"
        rounded="medium"
        onClick={() => submitBlockSchedules()}
      />
    </div>
  );

  const submitValidationForBlockSchedules = (): string[] => {
    // We loop over all the block schedules and check for errors
    // Here is the schema for the block schedule input
    // id?: string;
    // rotationDayId: string;
    // rotationDayName: string;
    // startTime?: string;
    // endTime?: string;

    // We need to check for the following errors:
    // 1. For each rotation day, either both start and end time are filled out or neither are
    // 2. For each rotation day, the start time is before the end time

    let counter = 0;

    // Keep track of the errors
    const errors = [];

    // Loop over all the block schedules
    for (const blockSchedule of modifiedBlockSchedules) {
      // If the start time is filled out but the end time is not
      if (blockSchedule.startTime && !blockSchedule.endTime) {
        errors.push(`Row ${counter + 1}: Please fill out the end time.`);
      }

      // If the end time is filled out but the start time is not
      if (blockSchedule.endTime && !blockSchedule.startTime) {
        errors.push(`Row ${counter + 1}: Please fill out the start time.`);
      }

      // If the start time is filled out but the end time is not
      if (blockSchedule.startTime && blockSchedule.endTime) {
        // If the start time is after the end time
        // The time is in HH:MM format so must convert to date to compare
        const startTime = new Date(`1970-01-01T${blockSchedule.startTime}`);
        const endTime = new Date(`1970-01-01T${blockSchedule.endTime}`);

        if (startTime > endTime) {
          errors.push(
            `Row ${counter + 1}: The start time must be before the end time.`
          );
        }
      }

      counter++;
    }

    setBlockScheduleErrors(errors);

    return errors;
  };

  const renderBlockScheduleBreadcrumbs = () => {
    return (
      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex items-center">
              <Link
                to={sessionRoute(
                  // activeDistrict,
                  activeSession,
                  "blocks"
                )}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={undefined}
              >
                Blocks
              </Link>
            </div>
          </li>

          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <Link
                to={activeBlock.id}
                className="ml-4 text-sm font-medium text-gray-700"
                aria-current={"page"}
              >
                {activeBlock.abbreviation}
              </Link>
            </div>
          </li>
        </ol>
      </nav>
    );
  };

  const renderLeft = (
    <div className="flex items-center">{renderBlockScheduleBreadcrumbs()}</div>
  );

  console.log("modifiedBlockSchedules", modifiedBlockSchedules);

  return (
    <React.Fragment>
      <PageLayout left={renderLeft} right={renderRight}>
        <div className="w-full px-20 py-2 pt-4">
          {/* Render the table */}
          <BlockSchedules
            activeBlockSchedules={activeBlock.blockSchedules}
            setModifiedBlockSchedules={setModifiedBlockSchedules}
            rotationDays={activeRotationSchedule.rotationDays}
          />

          {/* Render errors */}
          {blockScheduleErrors.length > 0 && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    There were {blockScheduleErrors.length} errors with your
                    submission
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      {blockScheduleErrors.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </PageLayout>
    </React.Fragment>
  );
}

export default observer(BlockSchedule);
