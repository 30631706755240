import { gql } from "../../__generated__/gql";

export const GetCourseV2s = gql(`
    query GetCourseV2s($where: CourseV2WhereInput!, $orderBy: [CourseV2OrderByInput!]) {
        courseV2s(where: $where, orderBy: $orderBy) {
            code 
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            gradeLevels
            id 
            identifiers
            name
            properties
            school {
                id
            }
            session {
                id
            }
            subject {
                id
            }
            subjectCodes 
            updatedAt
            urlId
        }
    }
`);
