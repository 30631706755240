import React, { useState } from "react";

interface SwitchProps {
  toggleValue: boolean;
  onToggle: (value: boolean) => void;
  id: string;
  label: string;
}

const Switch: React.FC<SwitchProps> = ({
  toggleValue,
  onToggle,
  id,
  label,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="flex items-center" id={id}>
      <span className="mr-3">
        <span className="text-slate11 text-sm font-medium">{label}</span>
      </span>
      <span
        onClick={() => {
          setIsFocused(false);
          onToggle(!toggleValue);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="flex-no-shrink relative inline-flex h-5 w-10 cursor-pointer items-center justify-center focus:outline-none"
        role="checkbox"
        tabIndex={0}
        aria-checked={toggleValue}
      >
        <span
          aria-hidden="true"
          className={`${
            toggleValue ? "bg-blue-600" : "bg-gray-200"
          } absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
        />
        <span
          aria-hidden="true"
          className={`${toggleValue ? "translate-x-5" : "translate-x-0"} ${
            isFocused ? "focused" : "shadow-outline border-blue-300"
          } absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out`}
        />
      </span>
    </div>
  );
};

export default Switch;
